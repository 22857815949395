<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Create Paywall Subscription</h3>
        <a (click)="navigateToList()" class="btn v3"><i class="fa fa-list"></i>Package List
        </a>
      </div>
      <div class="card-body">
        <form [formGroup]="addPackageForm">
          <div class="row g-3 panel-body tabs-menu-body">
            <div class="col-12">
              <label class="required form-label">Plan Name <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="name" placeholder="Enter plan Name" type="text"
                  class="form-control">
                <mat-error *ngIf="addPackageForm?.get('name').invalid && addPackageForm?.get('name').touched">
                  Plan name is required
                </mat-error>
              </div>
            </div>
            <div class="col-12">
              <label class="required form-label">Monthly Price <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="amount" placeholder="Enter monthly price" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="addPackageForm?.get('amount').invalid && addPackageForm?.get('amount').touched">
                 Monthly price is required
                </mat-error>
              </div>
            </div>

            <div class="col-12">
              <label class="required form-label">Yearly Price <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="yearlyPrice" placeholder="Enter yearly price" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="addPackageForm.get('yearlyPrice').invalid && addPackageForm.get('yearlyPrice').touched">
                Yearly price is required
                </mat-error>
              </div>
            </div>

            <div class="col-12">
              <label class="required form-label">Sort Order <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="sortOrder" placeholder="Enter sort order" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="addPackageForm.get('sortOrder').invalid && addPackageForm.get('sortOrder').touched">
                  Sort order is required
                </mat-error>
              </div>
            </div>

            <div class="col-12">
              <label class="required form-label">Select Ribbon</label>
              <div class="filter-checkbox">
                <ng-select class="form-select drop_down form-control" style="background-color: transparent;"
                  formControlName="ribbon" [placeholder]="addPackageForm.get('ribbon').value ? '' : 'Select ribbon'">
                  <ng-option *ngFor="let ribbon of ribbonList" [value]="ribbon.id"> {{ribbon.title}}</ng-option>
                </ng-select>
              </div>
            </div>

            <div *ngFor="let field of features.controls; let i = index" class="col-12" formArrayName="features">
              <div [formGroupName]="i">
                <div class="d-flex align-items-end gap-3" style="margin-top: 20px;">
                  <div class="flex-grow-1 border">
                  <ngx-editor-menu [editor]="getEditor(i)" [toolbar]="toolbar"></ngx-editor-menu>
                  <ngx-editor [editor]="getEditor(i)" formControlName="label"></ngx-editor>
                </div>
                <div class="text-end"><button class="btn btn-danger-light" *ngIf="i > 0" type="button"
                  (click)="removeField(i)"><i class="fa fa-trash"></i></button></div>
                </div>
                <mat-error *ngIf="(field.get('label').touched || field.get('label').dirty) && field.get('label').invalid">
                  Value is required
                </mat-error>
              </div>
            </div>
            <div class="col-12 my-5 text-end">
              <button class="btn btn-primary-light" type="button" (click)="addField()">Add More</button>
            </div>
            <div class="btn-list">
              <a class="btn btn-primary-light" style="float: right; color: white;" (click)="submit()">
                Submit
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>