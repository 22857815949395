import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { ManagerService } from '../services/manager.service';
import { QueriesService } from '../services/queries.service';
import { SpaceService } from '../services/space.service';

export class Manager {
  id: number
  name: string;
  email: string;
  mobile: string;
  status: number;
  spaces_data: any;
  space_ids: number[];
  file: any;
  profile_image: string;
  departmentId:number
}

@Component({
  selector: 'update-manager',
  templateUrl: './update-manager-dialog.component.html',
  styleUrls: ['./update-manager-dialog.component.css']
})

export class UpdateManagerDialog {
  public manager: Manager;
  public is_add_manager: boolean = false;
  public ref;
  public selected_manager;
  public action_type;
  public message = '';
  public spaces;
  public final_url : any;
  public statusArray = [{
    id: 1,
    name: 'Active'
  }, {
    id: 0,
    name: 'In-Active'
  }];
  department: any;
  departmentId:any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private managerService: ManagerService,
    private queryService: QueriesService,
    public snackBar: MatSnackBar,
    private _appGlobals: AppGlobals,
    private loaderService: LoaderService,
    public _spaceservice: SpaceService,
  ) {
    this.manager = new Manager;

  }

  ngOnInit() {
    if (this.action_type == 1) {
      this.is_add_manager = true;
      this.manager.space_ids = [];
      this.final_url = 'http://placehold.it/180';
    }
    if (this.action_type == 2) {
      console.log(this.selected_manager);
      
      this.manager = Object.assign({}, this.selected_manager);
      this.manager.space_ids = [];
      if (this.manager.profile_image) {
        this.final_url = this.manager.profile_image;
      } else {
        this.final_url = 'http://placehold.it/180';
      }
      this.manager.space_ids = this.selected_manager.spaces_data?.map(m => m.id);
    }
    this.getAllDepartment();
  }

  public getAllDepartment() {
    this.queryService.getAllActiveDepartment()
      .then(res => {
        if (res.success) {
          this.department = res.data;
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  fileChangeEvent(fileInput) {
    if (fileInput) {
      const file: File = fileInput.target.files[0];
      this.manager.file = file;
      const reader = new FileReader();
      reader.onload = e => this.final_url = reader.result;
      reader.readAsDataURL(file);
    }
  }

  addOrUpdate() {
    let final_data :any= Object.assign({}, this.manager);
    var formData = new FormData();
    formData.append("file", final_data.file || this.selected_manager?.profile_image);
    formData.append("name", final_data.name);
    formData.append("email", final_data.email);
    formData.append("mobile", final_data.mobile);
    formData.append("departmentId", final_data.departmentId);
    formData.append("type", '0');
    formData.append("status", '1');
    if (this.action_type == 2) {
      this.managerService.updateDetails(this.selected_manager.id, formData)
        .then(data => {
          if (data.success) {
            this.message = data.message;
            this.closeDialog({ success: true, message: data.message });
          } 
          this.openSnackBar(this.message, "Dismiss");
        })
        .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
          console.log(error);
        })
    } else {
      this.managerService.addDetails(formData)
        .then(data => {
          console.log(data, 'respoonse data');
          if (data.success) {
            this.message = data.message;
            this.closeDialog({ success: true, message: data.message });
            this.openSnackBar(this.message, "Dismiss");
          } else {
            this.message = data.message;
            this.openSnackBar(this.message, "Dismiss");
          }
        })
        .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
          console.log(error);
        })
    }
  }
  closeDialog(options) {
    this.ref.close(options);
  }

}