<div class="page-wrapper">
    <!--Sidebar Menu Starts-->

    <!--Sidebar Menu ends-->

    <!--Dashboard content Wrapper starts-->
    <div class="dash-content-wrap">
        <!--Dashboard breadcrumb starts-->
        <div class="dash-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dash-bookings">
                            <div class="act-title col-md-12" style="display: flex; justify-content: space-between;">
                                <h5><i class="ion-ios-copy-outline"></i>Credit Inquiry</h5>
                            </div>

                            <mat-tab-group (selectedTabChange)="changeStatusCoworking($event)">

                                <mat-tab [label]="pendingCount">
                                    <div class="div" style="display: flex; justify-content: end;">
                                        <input type="text" matInput (input)="applyFilter($event.target.value)"
                                            placeholder="Type to filter">
                                    </div>
                                    <div *ngIf="status == '0'">
                                        <div class="table-wrapper">
                                            <table mat-table [dataSource]="dataSource" matSort
                                                class="tableContainer w-100">

                                                <ng-container matColumnDef="serial">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="id">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Id </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row"> {{ row.id
                                                        }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="date">
                                                    <th style="width: 12%;" mat-header-cell *matHeaderCellDef mat-sort-header> Date and Time
                                                    </th>
                                                    <td style="width: 12%;" mat-cell *matCellDef="let row"> {{row.updatedAt}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="hostName">
                                                    <th style="width: 8%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Host Name
                                                    </th>
                                                    <td style="width: 8%;" mat-cell *matCellDef="let row">
                                                        {{row.hostName}} </td>
                                                </ng-container>


                                                <ng-container matColumnDef="name">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Name
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row"> {{row.name}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="mobile">
                                                    <th style="width: 9%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> mobile
                                                    </th>
                                                    <td style="width: 9%;" mat-cell *matCellDef="let row">
                                                        {{row.phone_code}} {{row.mobile}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="email">
                                                    <th style="width: 13%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Email
                                                    </th>
                                                    <td style="width: 13%;" mat-cell *matCellDef="let row">
                                                        {{row.email}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="companyName">
                                                    <th style="width: 12%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Company Name
                                                    </th>
                                                    <td style="width: 12%;" mat-cell *matCellDef="let row">
                                                        {{row.companyName}} </td>
                                                </ng-container>
                                                <ng-container  matColumnDef="description">
                                                    <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Description
                                                    </th>
                                                    <td style="width: 10%;" mat-cell *matCellDef="let row"> {{row.description}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="action">
                                                    <th style="width: 17%;" mat-header-cell *matHeaderCellDef> Actions </th>
                                                    <td mat-cell *matCellDef="let row"
                                                        style="width:17%">
                                                        <button class="lead-btns text-white"
                                                            style="background-color: green; margin: 5px 3px 5px 0px;" mat-mini-fab
                                                            (click)="approveInquiryDialog(row.id)">
                                                            <i class="fa-solid fa-check"></i> Approve</button>
                                                        <button class="lead-btns text-white"
                                                            style="background-color: red; margin: 5px 0px 5px 3px;" mat-mini-fab
                                                            (click)="rejectInquiryDialog(row.id)"><i
                                                                class="fa-solid fa-xmark"></i> Reject</button>
                                                        &nbsp;
                                                    </td>
                                                </ng-container>

                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4" style="text-align: center;">No data
                                                        found</td>
                                                </tr>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                </tr>

                                            </table>

                                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                [length]="dataSource?.data.length">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </mat-tab>

                                <mat-tab [label]="approveCount">
                                    <div class="div" style="display: flex; justify-content: end;">
                                        <input type="text" matInput (input)="applyFilter($event.target.value)"
                                            placeholder="Type to filter">
                                    </div>
                                    <div *ngIf="status == '1'">
                                        <div class="table-wrapper">
                                            <table mat-table [dataSource]="approvedLeadDataSource" matSort="sort"
                                                class="tableContainer w-100">

                                                <ng-container matColumnDef="serial">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="id">
                                                    <th style="width: 55px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Id </th>
                                                    <td style="width: 55px;" mat-cell *matCellDef="let row"> {{ row.id
                                                        }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="date">
                                                    <th style="width: 155px;" mat-header-cell *matHeaderCellDef mat-sort-header> Date and Time
                                                    </th>
                                                    <td style="width: 155px;" mat-cell *matCellDef="let row"> {{row.updatedAt}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="hostName">
                                                    <th style="width: 180px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Host Name
                                                    </th>
                                                    <td style="width: 180px;" mat-cell *matCellDef="let row">
                                                        {{row.hostName}} </td>
                                                </ng-container>


                                                <ng-container matColumnDef="name">
                                                    <th style="width: 90px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Name
                                                    </th>
                                                    <td style="width: 90px;" mat-cell *matCellDef="let row"> {{row.name}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="mobile">
                                                    <th style="width: 110px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> mobile
                                                    </th>
                                                    <td style="width: 110px;" mat-cell *matCellDef="let row">
                                                        {{row.phone_code}} {{row.mobile}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="email">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Email
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.email}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="companyName">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.companyName}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="description">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                                                </ng-container>


                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4" style="text-align: center;">No data
                                                        found</td>
                                                </tr>
                                                <tr mat-header-row *matHeaderRowDef="approvedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: approvedColumns;">
                                                </tr>
                                            </table>

                                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                [length]="approvedLeadDataSource?.data.length">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </mat-tab>

                                <mat-tab [label]="rejectCount">
                                    <div class="div" style="display: flex; justify-content: end;">
                                        <input type="text" matInput (input)="applyFilter($event.target.value)"
                                            placeholder="Type to filter">
                                    </div>
                                    <div *ngIf="status == '2'">
                                        <div class="table-wrapper">
                                            <table mat-table [dataSource]="rejectedLeadDataSource" matSort="sort"
                                                class="tableContainer w-100">

                                                <ng-container matColumnDef="serial">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                                                </ng-container>
                                                
                                                <ng-container matColumnDef="id">
                                                    <th style="width: 55px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Id </th>
                                                    <td style="width: 55px;" mat-cell *matCellDef="let row"> {{ row.id
                                                        }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="date">
                                                    <th style="width: 155px;" mat-header-cell *matHeaderCellDef mat-sort-header> Date and Time
                                                    </th>
                                                    <td style="width: 155px;" mat-cell *matCellDef="let row"> {{row.updatedAt}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="hostName">
                                                    <th style="width: 180px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Host Name
                                                    </th>
                                                    <td style="width: 180px;" mat-cell *matCellDef="let row">
                                                        {{row.hostName}} </td>
                                                </ng-container>


                                                <ng-container matColumnDef="name">
                                                    <th style="width: 90px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Name
                                                    </th>
                                                    <td style="width: 90px;" mat-cell *matCellDef="let row"> {{row.name}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="mobile">
                                                    <th style="width: 110px;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> mobile
                                                    </th>
                                                    <td style="width: 110px;" mat-cell *matCellDef="let row">
                                                        {{row.phone_code}} {{row.mobile}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="email">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Email
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.email}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="companyName">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.companyName}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="description">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                                                </ng-container>

                                                

                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4" style="text-align: center;">No data
                                                        found</td>
                                                </tr>
                                                <tr mat-header-row *matHeaderRowDef="rejectedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: rejectedColumns;">
                                                </tr>
                                            </table>

                                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                [length]="rejectedLeadDataSource?.data.length">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Dashboard content ends-->
    <!--Dashboard footer ends-->
</div>
<!--Dashboard content Wrapper ends-->