<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Add Ribbon</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="addRibbonForm">
          <div class="row g-3 panel-body tabs-menu-body">
            <div class="col-12">
              <label class="required form-label">Name<span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input matInput formControlName="title" placeholder="Enter name" type="text"
                  class="form-control filter-input">
                <mat-error *ngIf="addRibbonForm.get('title').invalid && addRibbonForm.get('title').touched">
                  Name is required
                </mat-error>
              </div>
            </div>
            <div class="col-12 mt-3">
              <label class="required form-label">Color <span class="text-danger">*</span></label>
              <div class="filter-checkbox" style="width: 10%;">
                <input matInput formControlName="color" placeholder="Enter color" type="color"
                  class="form-control filter-input">
                <mat-error *ngIf="addRibbonForm.get('color').invalid && addRibbonForm.get('color').touched">
                  Color is required
                </mat-error>
              </div>
            </div>
            <div class="btn-list mt-4">
              <a class="btn btn-primary-light" style="float: right; color: white;" (click)="submit()">
                Submit
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>