<!-- <app-page-header title="Wallet" active_item="{{spaceName}}"></app-page-header> -->
 
    <div class="row row-sm">
        <div class="col-lg-12">
            <div class="card custom-card p-4">
                <h3>Filter your space rating</h3>
                <div class="booking-list-filter">
                    <div class="row align-items-end">
                        <div class="col-md-4">
                            <p class="mg-b-10" style="font-size: 15px;font-weight: 700;">Select Date</p>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Enter a date</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input [(ngModel)]="startDate" (dateChange)="dateRangeChange();" (click)="picker.open()"
                                        matStartDate placeholder="Start date">
                                    <input [(ngModel)]="endDate" (click)="picker.open()" (dateChange)="dateRangeChange();"
                                        matEndDate placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 form-group category mb-0">
                            <p class="mg-b-10">Select stars</p>
                            <ng-select [placeholder]="selectedStars?.length > 0 ? '' : 'Select stars'" class="pb-0 placeholder_stars" [multiple]="true" (change)="getStar($event)">
                                <ng-option value="1"><i class="fa fa-star text-warning"></i></ng-option>
                                <ng-option value="2"><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i></ng-option>
                                <ng-option value="3"><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i></ng-option>
                                <ng-option value="4"><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i></ng-option>
                                <ng-option value="5"><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i><i class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i><i
                                        class="fa fa-star text-warning"></i></ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <button (click)="getAllReviews()" class="btn btn-primary search-btn">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row row-sm">
        <div class="col-lg-12">
            <div class="card custom-card p-4">
    
                <div class="d-flex justify-content-between align-items-center">
                    <div class="waletBalance">
                        <h2 class="pb-2" style="border-bottom: 1px solid #0000001f;">Reviews Listing of <b>{{spaceName}}</b>
                        </h2>
                    </div>
                    <div class="d-flex align-items-center">
                        <ul class="product-rating-c d-flex">
                            <li class="text-warning">
                                <i style="cursor: context-menu;"
                                    [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 1, 'fa-star-half-o': spaceRatingAvg > 0 && spaceRatingAvg < 1, 'fa-star-o': spaceRatingAvg <= 0}"
                                    aria-hidden="true"></i>
                            </li>
                            <li class="text-warning">
                                <i style="cursor: context-menu;"
                                    [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 2, 'fa-star-half-o': spaceRatingAvg > 1 && spaceRatingAvg < 2, 'fa-star-o': spaceRatingAvg <= 1}"
                                    aria-hidden="true"></i>
                            </li>
                            <li class="text-warning">
                                <i style="cursor: context-menu;"
                                    [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 3, 'fa-star-half-o': spaceRatingAvg > 2 && spaceRatingAvg < 3, 'fa-star-o': spaceRatingAvg <= 2}"
                                    aria-hidden="true"></i>
                            </li>
                            <li class="text-warning">
                                <i style="cursor: context-menu;"
                                    [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 4, 'fa-star-half-o': spaceRatingAvg > 3 && spaceRatingAvg < 4, 'fa-star-o': spaceRatingAvg <= 3}"
                                    aria-hidden="true"></i>
                            </li>
                            <li class="text-warning">
                                <i style="cursor: context-menu;"
                                    [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 5, 'fa-star-half-o': spaceRatingAvg > 4 && spaceRatingAvg < 5, 'fa-star-o': spaceRatingAvg <= 4}"
                                    aria-hidden="true"></i>
                            </li>
                        </ul>
                        <p class="rating mb-0">{{spaceRatingAvg}}</p>
                        <p class="review mb-0">Total reviews : {{transactionHistory?.length}}</p>
                    </div>
                </div>
    
                <div class="card-body ">
                    <div class="table-responsive">
                        
                        <table mat-table [dataSource]="dataSource" matSort class="tableContainer w-100">
    
                            <ng-container matColumnDef="Serial_no">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial no. </th>
                                <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 + (paginator?.pageIndex *
                                    paginator?.pageSize) }} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                <td mat-cell *matCellDef="let row"> {{ row.createdAt | date: 'dd-MM-yyyy hh:mm:ss' }} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="user_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> User name. </th>
                                <td mat-cell *matCellDef="let row"> {{row.userDetail?.firstName + row.userDetail?.lastName
                                    || 'N/A'}} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="rating">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
                                <td mat-cell *matCellDef="let row">
                                    <ul class="product-rating-c d-flex">
                                        <li class="text-warning">
                                            <i style="cursor: context-menu;"
                                                [ngClass]="{'fa': true, 'fa-star': row?.rating >= 1, 'fa-star-half-o': row?.rating > 0 && row?.rating < 1, 'fa-star-o': row?.rating <= 0}"
                                                aria-hidden="true"></i>
                                        </li>
                                        <li class="text-warning">
                                            <i style="cursor: context-menu;"
                                                [ngClass]="{'fa': true, 'fa-star': row?.rating >= 2, 'fa-star-half-o': row?.rating > 1 && row?.rating < 2, 'fa-star-o': row?.rating <= 1}"
                                                aria-hidden="true"></i>
                                        </li>
                                        <li class="text-warning">
                                            <i style="cursor: context-menu;"
                                                [ngClass]="{'fa': true, 'fa-star': row?.rating >= 3, 'fa-star-half-o': row?.rating > 2 && row?.rating < 3, 'fa-star-o': row?.rating <= 2}"
                                                aria-hidden="true"></i>
                                        </li>
                                        <li class="text-warning">
                                            <i style="cursor: context-menu;"
                                                [ngClass]="{'fa': true, 'fa-star': row?.rating >= 4, 'fa-star-half-o': row?.rating > 3 && row?.rating < 4, 'fa-star-o': row?.rating <= 3}"
                                                aria-hidden="true"></i>
                                        </li>
                                        <li class="text-warning">
                                            <i style="cursor: context-menu;"
                                                [ngClass]="{'fa': true, 'fa-star': row?.rating >= 5, 'fa-star-half-o': row?.rating > 4 && row?.rating < 5, 'fa-star-o': row?.rating <= 4}"
                                                aria-hidden="true"></i>
                                        </li>
                                    </ul>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Review">
                                <th class="mat-column-Review" mat-header-cell *matHeaderCellDef mat-sort-header="false"> Reviews </th>
                                <td mat-cell *matCellDef="let row"> {{ row.Review }} </td>
                              </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    Activate Review </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-slide-toggle [(ngModel)]="row.approved" (click)="getStatus($event,row,row.id)" color="#f76900"></mat-slide-toggle>
                                </td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "</td>
                            </tr>
                        </table>
                        <!-- <h4 *ngIf="totalItems == 0" class="text-center">No record found</h4> -->
                        <div class="mb-3" *ngIf="totalItems > 0">
                            <div class="float-end">
                                <ngb-pagination [collectionSize]="totalItems" [(page)]="page1" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                                    <ng-template ngbPaginationNext>Next</ng-template>
                                    <ng-template ngbPaginationNumber let-p>{{ getPageSymbol(p) }}</ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
