import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SpaceService } from 'src/app/services/space.service';

@Component({
  selector: 'app-credit-wallet-history',
  templateUrl: './credit-wallet-history.component.html',
  styleUrls: ['./credit-wallet-history.component.css']
})
export class CreditWalletHistoryComponent implements OnInit {
  hostId: any;
  host: any[] = [];
  startDate: any;
  endDate: any;

  startMax: Date | null = null;
  endMin: Date | null = null;

  startDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.endMin || date <= this.endMin;
  };

  endDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.startMax || date >= this.startMax;
  };

  onStartDateChange(event: any): void {
    this.startMax = this.startDate;
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
  }

  onEndDateChange(event: any): void {
    this.endMin = this.endDate;
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  }

  dataSource: any;
  displayedColumns: string[] = ['serial','id', 'createdAt', 'hostName', 'invoiceNo', 'creditPlanName', 'creditPlanPrice', 'quantity', 'GST', 'grandTotal', 'paymentMethod', 'Actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize!: MatPaginator;

  constructor(private invoiceService: InvoiceService, private snackBar: MatSnackBar, private datePipe: DatePipe, private spaceService: SpaceService) { }


  ngOnInit(): void {
    this.getHost();
    this.getPurchaseHistory();
  }

  getHost() {
    this.spaceService.getHost().subscribe((res: any) => {
      for (let i = 0; i < res.data.host.length; i++) {
        this.host.push(res.data.host[i]);
      }
    })
  }

  getFilteredData() {
    let data = {
      startDate: this.startDate ? this.startDate + ' 00:00:00' : "",
      endDate: this.endDate ? this.endDate + ' 23:59:59' : "",
      hostId: this.hostId ? this.hostId : ''
    };
    this.invoiceService.getFilteredWalletHistory(data.startDate, data.endDate, data.hostId).then((res: any) => {
      if (res.success) {
        this.dataSource = new MatTableDataSource(res.data);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }, 200);
      }
    })
  }

  getPurchaseHistory() {
    this.invoiceService.getWalletHistory().then((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 200);
    }
    )
  }


  applyFilter(value: any) {
    value = value.value.trim().toLowerCase();
    this.dataSource.filter = value;
  }

  openInvoice(list) {
    if (list.invoiceName) {
      window.open(list.invoiceName)
    } else {
      this.createInvoice(list)
    }
  }

  createInvoice(list: any) {
    const data = {
      hostId: list.hostId,
      creditPlanId: list.id
    }
    this.invoiceService.createWalletInvoice(data).then((res: any) => {
      if (res.success) {
        this.getPurchaseHistory()
        window.open(res.s3Url)
      } else {
        this.openSnackBar(res.message)
      }
    })
  }


  openSnackBar(message: string, action = "Dismiss") {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

}

