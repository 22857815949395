import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-view-lead2',
  templateUrl: './view-lead.component.html',
  styleUrls: ['./view-lead.component.css']
})
export class ViewLeadComponent implements OnInit {

  public ref;
  public id: any;
  lead_data:any;
  tags:string;

  constructor(
  private _queriesservice: QueriesService,
  private loaderService: LoaderService,
  public snackBar: MatSnackBar,
  @Inject(MAT_DIALOG_DATA) public data: any
  ){  }

  ngOnInit() { 
    this.populateLeads(this.data.id);
  }
  
  populateLeads(id) {
    this._queriesservice
      .getLeadById(id).then((res) => {
        if (res.success) {
          this.lead_data = { 
            ...res.leads,
            location : JSON.parse(res.leads?.location),
            city: JSON.parse(res.leads?.city_name),
            space: JSON.parse(res.leads?.spaceType),
            tags: JSON.parse(res.leads?.tags)
          }
        } else {
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  closeDialog(options) {
    this.ref.close(options);
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
