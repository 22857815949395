import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-reject-dialog-box',
  templateUrl: './confirm-reject-dialog-box.component.html',
  styleUrls: ['./confirm-reject-dialog-box.component.css']
})
export class ConfirmRejectDialogBoxComponent {

  showError: boolean = false;
  message: any;

  constructor(public dialogRef: MatDialogRef<ConfirmRejectDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}
    
    ngOnInit(): void {
      this.message = this.data.message;
    }

    onReasonInput(value: string): void {
      this.showError = value.trim() === '';
    }
  
    onYesClick(reason:any): void {
      if (!reason || reason.trim() === '') {
        this.showError = true;
        return;
      }
      this.dialogRef.close(reason);
    }
  
    onNoClick(): void {
      this.dialogRef.close(false);
    }
}
