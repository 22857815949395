<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Create Credit Package</h3>
        <a (click)="navigateToList()" class="btn v3"><i class="fa fa-list"></i>Package List
        </a>
      </div>
      <div class="card-body">
        <form [formGroup]="addPackageForm">
          <div class="row g-3 panel-body tabs-menu-body">
            <div class="col-12">
              <label class="required form-label">Package Name <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input  formControlName="title" placeholder="Enter Package Name" type="text"
                  class="form-control">
                <mat-error *ngIf="addPackageForm.get('title').invalid && addPackageForm.get('title').touched">
                  Package name is required
                </mat-error>
              </div>
            </div>
            <div class="col-12">
              <label class="required form-label">Price <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input  formControlName="amount" placeholder="Enter price" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="addPackageForm.get('amount').invalid && addPackageForm.get('amount').touched">
                  Price is required
                </mat-error>
              </div>
            </div>
            <div class="col-12">
              <label class="required form-label">Flexo Credits <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input  formControlName="creditPoint" placeholder="Enter flexo credits" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error
                  *ngIf="addPackageForm.get('creditPoint').invalid && addPackageForm.get('creditPoint').touched">
                  Flexo credit is required
                </mat-error>
              </div>
            </div>

            <div class="col-12">
              <label class="required form-label">Sort Order <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input  formControlName="sortOrder" placeholder="Enter sort order" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="addPackageForm.get('sortOrder').invalid && addPackageForm.get('sortOrder').touched">
                  Sort order is required
                </mat-error>
              </div>
            </div>

            <div *ngFor="let field of discription.controls; let i = index" class="col-12" formArrayName="discription">
              <div [formGroupName]="i">
                <div class="d-flex align-items-end gap-3" style="margin-top: 20px;">
                  <div class="flex-grow-1 border">
                    <ngx-editor-menu [editor]="getEditor(i)" [toolbar]="toolbar"></ngx-editor-menu>
                    <ngx-editor [editor]="getEditor(i)" formControlName="label"></ngx-editor>
                  </div>
                  <div class="text-end"><button class="btn btn-danger-light" *ngIf="i > 0" type="button"
                    (click)="removeField(i)"><i class="fa fa-trash"></i></button></div>
                  </div>
                  <mat-error 
                  *ngIf="field.get('label').invalid && (field.get('label').dirty || field.get('label').touched)">
                    Value is required
                  </mat-error>
              </div>
            </div>
            <div class="col-12 my-5 text-end">
              <button class="btn btn-primary-light" type="button" (click)="addField()">Add More</button>
            </div>
            <div class="btn-list">
              <a class="btn btn-primary-light" style="float: right;" (click)="submit()">
                Submit
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>