<p style="text-align: right;"><i (click)="_ref.close()" style="cursor: pointer;" class="material-icons">
  close</i>
</p>
<mat-dialog-content>
<div style="font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;">
  <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%;background:#f9f9f9">
    <tbody>
      <tr>
        <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%;background:black">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:black">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top" style="width:100.0%;padding:3.75pt 0cm 3.75pt 0cm">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td width="100%" style="width:100.0%;padding:11.25pt 0cm 11.25pt 0cm">
                                      <p class="MsoNormal" align="center" style="text-align:center">
                                        <img border="0" width="160" height="46" style="width:1.6666in;height:.475in"
                                          id="m_-46844755274958524_x0000_i1039"
                                          src="https://ci6.googleusercontent.com/proxy/3KKKXcCPZlNXfTmQ91G-JNY3uaUSQP23v8kUjufNG5svQ6dI0FOf5FVVG4lW9p_BXt_06AFRoO4ThKBbEZSwPrzUmxUGmQI-BRvTJxPBOw85jZz4Kjd_LtlOzHMf3VhXILJwwxj7OMtOlKNZ9ycQVEyci0GVJ8ufhMUE-yvxZS9I4FJxAWiBmhda5KI=s0-d-e1-ft#https://615830bd01.imgdist.com/public/users/Integrators/BeeProAgency/992614_977343/Flexo%20Logo%20160%20%C3%97%2045px.png"
                                          alt="Alternate text" class="CToWUd" data-bit="iit"><u></u><u></u>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700" style="width:525.0pt">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top" style="width:100.0%;padding:0cm 0cm 0cm 0cm">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td style="padding:0cm 0cm 0cm 0cm"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:white">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top"
                              style="width:100.0%;padding:3.75pt 0cm 3.75pt 0cm;word-break:break-word">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td style="padding:18.75pt 7.5pt 7.5pt 7.5pt">
                                      <div>
                                        <div>
                                          <p align="center" style="margin:0cm;text-align:center"><strong><span
                                                style="font-size:21.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">{{_data.heading}}</span></strong><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e"><u></u><u></u></span>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p class="MsoNormal"><span style="color:black;display:none"><u></u>&nbsp;<u></u></span>
                              </p>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;word-break:break-word">
                                <tbody>
                                  <tr>
                                    <td style="padding:6.0pt 6.0pt 6.0pt 6.0pt">
                                      <div>
                                        <div>
                                          <p style="margin:0cm"><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:black">
                                              {{_data.description}}
                                            </span><span
                                              style="font-size:9.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;"><u></u><u></u></span></p>
                                          <p style="margin:0cm"><span
                                              style="font-size:9.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;">&nbsp;<u></u><u></u></span>
                                          </p>
                                          <p style="margin:0cm"><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:black">Find your
                                              workspaces below :</span><span
                                              style="font-size:9.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;"><u></u><u></u></span></p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div>
                                <p class="MsoNormal" style="line-height:26.25pt"><span
                                    style="font-size:1.0pt;color:black"> </span><span
                                    style="font-size:1.0pt;color:black"><u></u><u></u></span></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div *ngFor="let option of _data.options">
            <div align="center">
              <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
                <tbody>
                  <tr>
                    <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                      <div align="center">
                        <table border="1" cellspacing="0" cellpadding="0" width="700"
                          style="width:525.0pt;background:white;border:solid #939090 1.0pt;border-radius:10px">
                          <tbody>
                            <tr style="display: flex;">
                              <td width="50%" valign="top"
                                style="width:50.0%;border:none;padding:20pt 10pt 20pt 20pt">
                                <!-- <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;border-style:initial;border-color:initial">
                                  <tbody>
                                    <tr>
                                      <td width="100%" style="width:100.0%;padding:0cm 0cm 0cm 0cm">
                                        <p class="MsoNormal" align="center" style="text-align:center">
                                          <a href="#m_-46844755274958524_m_8900005190237364350_m_747360718753681"><span
                                              style="text-decoration:none">
                                             
                                            </span></a><u></u><u></u>
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table> -->
                                <img width="100%" height="100%" style="width:100%;height:100%;border-radius:20pt;"
                                  src="https://s3.ap-south-1.amazonaws.com/worker-app/details_images/{{ option.id}}/{{option.images[0]}}">
                              </td>
                              <td width="50%" valign="top"
                                style="width:50.0%;border:none;padding:20pt 20pt 20pt 10pt;border-style:initial;border-color:initial">
                                <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;word-break:break-word">
                                  <tbody>
                                    <tr>
                                      <td style="padding:0cm 7.5pt 0cm 7.5pt">
                                        <div>
                                          <div>
                                            <p style="margin:0cm">
                                              <strong>
                                                <span *ngIf="!option.mask_name" style="font-size:15.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                  {{option.actual_name}}
                                                </span>
                                                <span *ngIf="option.mask_name" style="font-size:15.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                  {{option.landmark}}, {{option.location_name}}, {{option.city}}
                                                </span>
                                              </strong>
                                              <span
                                                style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e"><u></u><u></u></span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="MsoNormal"><span
                                    style="color:black;display:none"><u></u>&nbsp;<u></u></span>
                                </p>
                                <table *ngIf="!option.mask_name" border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;word-break:break-word">
                                  <tbody>
                                    <tr>
                                      <td style="padding:0cm 7.5pt 7.5pt 7.5pt">
                                        <div>
                                          <div>
                                            <p style="margin:0cm"><strong><span
                                                  style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                  {{option.landmark}}, {{option.location_name}}, {{option.city}}
                                                </span></strong><span
                                                style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e"><u></u><u></u></span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;word-break:break-word">
                                  <tbody>
                                    <tr>
                                      <td style="padding:0cm 7.5pt 7.5pt 7.5pt">
                                        <div>
                                          <div>
                                            <p style="margin:0cm"><span
                                                style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555">Inclusions:
                                                <span *ngFor="let inclusion of option.inclusions; let i = index;">
                                                  {{inclusion}}<span *ngIf="i+1 < (option.inclusions.length - 1)">,</span>
                                                </span>
                                              </span><span
                                                style="font-size:9.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555"><u></u><u></u></span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;word-break:break-word">
                                  <tbody>
                                    <tr>
                                      <td style="padding:0cm 7.5pt 3.75pt 7.5pt">
                                        <div>
                                          <div>
                                            <p style="margin:0cm"><strong><span
                                                  style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555">Space
                                                  Type: {{option.space_type}}</span></strong><span
                                                style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555"><u></u><u></u></span>
                                            </p>
                                            <p style="margin:0cm"><strong><span
                                                  style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555">Quoted
                                                  Price/Seat: Rs. {{option.quoted_price_per_seat}} /
                                                  {{option.quoted_price_unit}}</span></strong><span
                                                style="font-size:10.5pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555"><u></u><u></u></span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                  style="width:100.0%;word-break:break-word">
                                  <tbody>
                                    <tr>
                                      <td style="padding:0cm 7.5pt 0pt 11.25pt">
                                        <div>
                                          <div>
                                            <p style="margin:0cm;line-height:200%"><strong><span
                                                  style="font-size:10.5pt;line-height:200%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#ff7800">Rs.
                                                </span></strong><strong><span
                                                  style="font-size:15.0pt;line-height:200%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#ff7800">{{option.total}}</span></strong><strong><span
                                                  style="font-size:13.5pt;line-height:200%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">&nbsp;</span></strong><strong><span
                                                  style="font-size:10.5pt;line-height:200%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:gray">/
                                                  {{option.total_unit}} + GST <span *ngIf="option.negotiable">(negotiable)</span></span></strong><span
                                                style="font-size:10.5pt;line-height:200%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#555555"><u></u><u></u></span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table *ngIf="!option.not_add_link" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
                                  <tbody>
                                    <tr>
                                      <td style="padding:6.0pt 6.0pt 6.0pt 6.0pt">
                                        <div
                                          style="border:solid windowtext 1.0pt;display:inline-block;border-radius:4px;border-color:transparent">
                                          <a class="MsoNormal" align="center"
                                           [href]="'https://www.flexospaces.com/coworking-space/'+option.link_name"
                                            style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:white;text-align:center;background:#ff7800;border-radius:5px;text-decoration:none;display:block;padding: 5pt 25pt;">
                                            <span>View Property</span></a><span
                                            style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:white"><u></u><u></u></span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          </div>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:white">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top" style="width:100.0%;padding:3.75pt 0cm 3.75pt 0cm">
                              <table *ngIf="!_data.hide_steps_image" border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td width="100%" style="width:100.0%;padding:0cm 0cm 0cm 0cm">
                                      <p class="MsoNormal" align="center" style="text-align:center">
                                        <img border="0" width="700" style="width:7.2916in"
                                          id="m_-46844755274958524_x0000_i1034"
                                          [src]="_data.steps_image_link"
                                          class="CToWUd a6T" data-bit="iit" tabindex="0">
                                      </p>
                                      <div class="a6S" dir="ltr" style="opacity: 0.01; left: 862px; top: 2845.34px;">
                                        <div id=":o6" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button"
                                          tabindex="0" aria-label="Download attachment "
                                          jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB; 4:WyIjbXNnLWY6MTc2OTEyNTYxNzI4MjI4MzgzMCIsbnVsbCxbXV0."
                                          data-tooltip-class="a1V" data-tooltip="Download">
                                          <div class="akn">
                                            <div class="aSK J-J5-Ji aYr"></div>
                                          </div>
                                        </div>
                                      </div><u></u><u></u>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div>
                                <p class="MsoNormal" style="line-height:15.75pt"><span
                                    style="font-size:1.0pt;color:black"> <u></u><u></u></span></p>
                              </div>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;word-break:break-word">
                                <tbody>
                                  <tr>
                                    <td style="padding:6.0pt 6.0pt 6.0pt 6.0pt">
                                      <div>
                                        <div>
                                          <p align="center" style="margin:0cm;text-align:center"><strong><span
                                                style="font-size:21.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#f76900">
                                                {{_data.advantage_text}}</span></strong><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                              <u></u><u></u></span></p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div>
                                <p class="MsoNormal" style="line-height:15.75pt"><span
                                    style="font-size:1.0pt;color:black"> </span><span
                                    style="font-size:1.0pt;color:black"><u></u><u></u></span></p>
                              </div>
                              <table *ngIf="!_data.hide_why_image" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
                                <tbody>
                                  <tr>
                                    <td width="100%" style="width:100.0%;padding:0cm 0cm 0cm 0cm">
                                      <p class="MsoNormal" align="center" style="text-align:center">
                                        <img border="0" width="700" style="width:7.2916in"
                                          id="m_-46844755274958524_x0000_i1033"
                                          [src]="_data.why_image_link"
                                          class="CToWUd a6T" data-bit="iit" tabindex="0">
                                      </p>
                                      <div class="a6S" dir="ltr" style="opacity: 0.01; left: 862px; top: 3453.09px;">
                                        <div id=":o7" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button"
                                          tabindex="0" aria-label="Download attachment "
                                          jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB; 4:WyIjbXNnLWY6MTc2OTEyNTYxNzI4MjI4MzgzMCIsbnVsbCxbXV0."
                                          data-tooltip-class="a1V" data-tooltip="Download">
                                          <div class="akn">
                                            <div class="aSK J-J5-Ji aYr"></div>
                                          </div>
                                        </div>
                                      </div><u></u><u></u>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:white">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top" style="width:100.0%;padding:3.75pt 0cm 3.75pt 0cm">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td style="padding:6.0pt 6.0pt 6.0pt 6.0pt">
                                      <div align="center">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                          style="width:100.0%">
                                          <tbody>
                                            <tr>
                                              <td
                                                style="border:none;border-top:solid #bbbbbb 1.0pt;padding:0cm 0cm 0cm 0cm">
                                                <p class="MsoNormal"><span style="font-size:1.0pt"> </span><span
                                                    style="font-size:1.0pt"><u></u><u></u></span></p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:white">
                        <tbody>
                          <tr>
                            <td width="33%" valign="top" style="width:33.0%;padding:3.75pt 0cm 3.75pt 0cm">

                              <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
                                <tbody>
                                  <tr>
                                    <td width="100%" style="width:100.0%;padding:0cm 0cm 0cm 0cm">
                                      <p class="MsoNormal" align="center" style="text-align:center">
                                        <a href="#m_-46844755274958524_m_8900005190237364350_m_747360718753681"><span
                                            style="text-decoration:none"><img border="0" width="175"
                                              style="width:1.825in" id="m_-46844755274958524_x0000_i1032"
                                              [src]="_data.manager.image === '' ? 'https://ci3.googleusercontent.com/proxy/-RSSA1EBw2QjQmVaHYKCLGYvFlJ2rsFqISlEAJTGQMK7ALebtzMd-DHSkkg7qLKMuhsWl7YXFyzAWNgKO9Lpkp2CozH3bxipsj4EMNgnUgP5wnesthe3_j08F_-1hJW5Zc5bfMJlCcKjFj6_PzddXrr9nuuHqFda=s0-d-e1-ft#https://615830bd01.imgdist.com/public/users/Integrators/BeeProAgency/992614_977343/Flexo%20Expert.png' : _data.manager.image"
                                              alt="Image" class="CToWUd" data-bit="iit"></span></a><u></u><u></u>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="33%" valign="top"
                              style="width:33.0%;padding:3.75pt 0cm 3.75pt 0cm;border-style:initial;border-color:initial">

                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;word-break:break-word">
                                <tbody>
                                  <tr>
                                    <td style="padding:7.5pt 0pt">
                                      <div>
                                        <div>
                                          <p style="margin:0cm"><strong><span
                                                style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                {{ _data.manager.name}}</span><br>
                                              <span style="font-size:11.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                {{_data.manager.email}}</span><br>
                                              <span style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">
                                                +91 - {{_data.manager.mobile}}</span></strong><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e"><u></u><u></u></span>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p class="MsoNormal"><span style="color:black;display:none"><u></u>&nbsp;<u></u></span>
                              </p>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;display:none;max-height:0px;overflow:hidden">
                                <tbody>
                                  <tr style="display:none">
                                    <td style="padding:0cm 7.5pt 11.25pt 7.5pt">
                                      <div align="center">
                                        <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                          style="width:100.0%;display:none;max-height:0px;overflow:hidden">
                                          <tbody>
                                            <tr style="display:none">
                                              <td
                                                style="border:none;border-top:dotted #1d3b7e 1.0pt;padding:0cm 0cm 0cm 0cm">
                                                <p class="MsoNormal">
                                                  <span style="font-size:1.0pt;display:none"> </span>
                                                  <span>
                                                    style="font-size:1.0pt;display:none"><u></u><u></u></span>
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="33%" valign="top"
                              style="width:33.0%;padding:3.75pt 0cm 3.75pt 0cm;border-style:initial;border-color:initial">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;word-break:break-word">
                                <tbody>
                                  <tr>
                                    <td style="padding:15.0pt 7.5pt 7.5pt 18.75pt;word-break:break-word">
                                      <div>
                                        <div>
                                          <p align="center" style="margin:0cm;text-align:center"><strong><span
                                                style="font-size:18.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e">Get In
                                                Touch With Your Dedicated Workspace Advisor</span></strong><span
                                              style="font-size:12.0pt;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#34495e"><u></u><u></u></span>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:white">
                        <tbody>
                          <tr>
                            <td width="100%" valign="top" style="width:100.0%;padding:3.75pt 0cm 3.75pt 0cm">
                              <div>
                                <p class="MsoNormal" style="line-height:18.75pt"><span
                                    style="font-size:1.0pt;color:black"> 
                                  </span><span style="font-size:1.0pt;color:black"><u></u><u></u></span></p>
                              </div>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;word-break:break-word">
                                <tbody>
                                  <tr>
                                    <td style="padding:6.0pt 6.0pt 6.0pt 6.0pt">
                                      <p style="margin:0cm;line-height:120%"><span
                                          style="font-size:7.0pt;line-height:120%;font-family:Poppins,Montserrat,Helvetica,Arial,sans-serif;color:#101112">
                                          {{_data.text}}
                                          <u></u><u></u></span></p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
          <div align="center">
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
              <tbody>
                <tr>
                  <td style="padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                    <div align="center">
                      <table border="0" cellspacing="0" cellpadding="0" width="700"
                        style="width:525.0pt;background:black">
                        <tbody>
                          <tr>
                            <td width="50%" valign="top" style="width:50.0%;padding:3.75pt 0cm 0cm 0cm">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="width:100.0%;border-style:initial;border-color:initial">
                                <tbody>
                                  <tr>
                                    <td width="100%" style="width:100.0%;padding:11.25pt 0cm 0cm 0cm">
                                      <p class="MsoNormal" align="center" style="text-align:center">
                                        <a href="https://www.flexospaces.com/" target="_blank"
                                          data-saferedirecturl="https://www.google.com/url?q=http://www.example.com/&amp;source=gmail&amp;ust=1687256075547000&amp;usg=AOvVaw2Uvwe0-vObFwxhQwGbYMow"><span
                                            style="text-decoration:none"><img border="0" width="140"
                                              style="width:1.4583in" id="m_-46844755274958524_x0000_i1031"
                                              src="https://ci6.googleusercontent.com/proxy/3KKKXcCPZlNXfTmQ91G-JNY3uaUSQP23v8kUjufNG5svQ6dI0FOf5FVVG4lW9p_BXt_06AFRoO4ThKBbEZSwPrzUmxUGmQI-BRvTJxPBOw85jZz4Kjd_LtlOzHMf3VhXILJwwxj7OMtOlKNZ9ycQVEyci0GVJ8ufhMUE-yvxZS9I4FJxAWiBmhda5KI=s0-d-e1-ft#https://615830bd01.imgdist.com/public/users/Integrators/BeeProAgency/992614_977343/Flexo%20Logo%20160%20%C3%97%2045px.png"
                                              alt="Logo" class="CToWUd" data-bit="iit"></span></a><u></u><u></u>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="50%" valign="top"
                              style="width:50.0%;padding:0cm 0cm 0cm 0cm;border-style:initial;border-color:initial">
                              <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%">
                                <tbody>
                                  <tr>
                                    <td style="padding:15.0pt 7.5pt 15.0pt 7.5pt">
                                      <div align="center">
                                        <table border="0" cellspacing="0" cellpadding="0"
                                          style="display:inline-block">
                                          <tbody>
                                            <tr>
                                              <td style="padding:0cm 3.75pt 0cm 0cm">
                                                <p class="MsoNormal"><a href="https://www.facebook.com/flexospaces/"
                                                    target="_blank"
                                                    data-saferedirecturl="https://www.google.com/url?q=http://www.example.com/&amp;source=gmail&amp;ust=1687256075547000&amp;usg=AOvVaw2Uvwe0-vObFwxhQwGbYMow"><span
                                                      style="text-decoration:none"><img border="0" width="32"
                                                        height="32" style="width:.3333in;height:.3333in"
                                                        id="m_-46844755274958524_x0000_i1030"
                                                        src="https://ci5.googleusercontent.com/proxy/GBZD_abgGMYpKZY3jati6aI8xNZi8gm5ZGKC0H7q-QToo76-Q8V6HTZLt7rN9JJ3EzSSN_Ekf2rclDdXsx3bp74FYigVdZGIroqAl-JaX30aIMd8hLPD1g4QHav9Rk0WHIJ1px_oClknGKU2lj10QgmOfTEAYAw2das=s0-d-e1-ft#https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-white/facebook@2x.png"
                                                        alt="Facebook" class="CToWUd"
                                                        data-bit="iit"></span></a><u></u><u></u></p>
                                              </td>
                                              <td style="padding:0cm 3.75pt 0cm 0cm">
                                                <p class="MsoNormal"><a href="https://twitter.com/flexospaces"
                                                    target="_blank"
                                                    data-saferedirecturl="https://www.google.com/url?q=http://www.example.com/&amp;source=gmail&amp;ust=1687256075547000&amp;usg=AOvVaw2Uvwe0-vObFwxhQwGbYMow"><span
                                                      style="text-decoration:none"><img border="0" width="32"
                                                        height="32" style="width:.3333in;height:.3333in"
                                                        id="m_-46844755274958524_x0000_i1029"
                                                        src="https://ci3.googleusercontent.com/proxy/LnD37YLXRh76-h-igMkY_YYOFz3Zjd3ddRofPa4CWsvn-Paa0CXEsbHu_IBRZxlYgWFRqOSOAn5jhkmQ8LyMszRQAA7zZo7z7d4AVub4lQlBxKjNu8CtrNDB12igmmESUMl3Hlj1kHIptksadF7_7If3NOS0zk6POw=s0-d-e1-ft#https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-white/twitter@2x.png"
                                                        alt="Twitter" class="CToWUd"
                                                        data-bit="iit"></span></a><u></u><u></u></p>
                                              </td>
                                              <td style="padding:0cm 3.75pt 0cm 0cm">
                                                <p class="MsoNormal"><a href="https://www.instagram.com/flexospaces/"
                                                    target="_blank"
                                                    data-saferedirecturl="https://www.google.com/url?q=http://www.example.com/&amp;source=gmail&amp;ust=1687256075547000&amp;usg=AOvVaw2Uvwe0-vObFwxhQwGbYMow"><span
                                                      style="text-decoration:none"><img border="0" width="32"
                                                        height="32" style="width:.3333in;height:.3333in"
                                                        id="m_-46844755274958524_x0000_i1028"
                                                        src="https://ci6.googleusercontent.com/proxy/-4_xdf4J6HLc3stttNzqjQ3e1EkjkPyi4efGy1E3GP32Eae3_ysDndWuy5-dd8Cn-fKe5RJ4ao48VdvNYyTVvVDyo8f4d62DXaVLx5W1lTrBRa-eE3E-o6QJ1Wa7geUy-rVvks-SrIN5MLm6wVrTvdodZ3i8ljhnzizh=s0-d-e1-ft#https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-white/instagram@2x.png"
                                                        alt="Instagram" class="CToWUd"
                                                        data-bit="iit"></span></a><u></u><u></u></p>
                                              </td>
                                              <td style="padding:0cm 3.75pt 0cm 0cm">
                                                <p class="MsoNormal"><a
                                                    href="https://www.linkedin.com/company/flexospaces/"
                                                    target="_blank"
                                                    data-saferedirecturl="https://www.google.com/url?q=http://www.example.com/&amp;source=gmail&amp;ust=1687256075548000&amp;usg=AOvVaw2KGV-_PF0ZZ8RwsTqfhm-D"><span
                                                      style="text-decoration:none"><img border="0" width="32"
                                                        height="32" style="width:.3333in;height:.3333in"
                                                        id="m_-46844755274958524_x0000_i1027"
                                                        src="https://ci3.googleusercontent.com/proxy/AHw9qLK9KnudCjLuO7eEzb6_GqQAdVgUSYl0N02EaoCnoAaPc5M5exXfYF6x-VPHglacEcVBo29JLiVyxbv89B2V7pavGZl3YsxUTFD7yfGMBhloD3dH9rZl4B8D-vgcJINzY9JsjIebcUaKCzZXZ90gTO2HQ-neTbQ=s0-d-e1-ft#https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-white/linkedin@2x.png"
                                                        alt="LinkedIn" class="CToWUd"
                                                        data-bit="iit"></span></a><u></u><u></u></p>
                                              </td>
                                              <td style="padding:0cm 3.75pt 0cm 0cm">
                                                <p class="MsoNormal"><a href="https://www.youtube.com/"
                                                    target="_blank"
                                                    data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/&amp;source=gmail&amp;ust=1687256075548000&amp;usg=AOvVaw1mHqptKGEc7nEHyq_7N3gk"><span
                                                      style="text-decoration:none"><img border="0" width="32"
                                                        height="32" style="width:.3333in;height:.3333in"
                                                        id="m_-46844755274958524_x0000_i1026"
                                                        src="https://ci3.googleusercontent.com/proxy/Dn7EEcce8R2z7TM6Nu2KBZDvVAwSGf6kUjPktoapAJqPSrhqRsCppIojgSrDW0r4O-Fwrma8A055kYaYaEnZKa2-HiWqfKlBvlp__F1Rvf77u3yfSwU_KwvOY5vPSQbBRBW358kLwRWldoBxT_-Syd0p2pzEvAW2yw=s0-d-e1-ft#https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-white/youtube@2x.png"
                                                        alt="YouTube" class="CToWUd"
                                                        data-bit="iit"></span></a><u></u><u></u></p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</mat-dialog-content>