import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ApproveLeadComponent } from "src/app/leads/approve-lead/approve-lead.component";
import { LoaderService } from "src/app/services/loader.service";
import { QueriesService } from "src/app/services/queries.service";

@Component({
  selector: 'app-enterprise-enquiry',
  templateUrl: './enterprise-enquiry.component.html',
  styleUrls: ['./enterprise-enquiry.component.css'],
  providers: [MatDialog]
})
export class EnterpriseEnquiryComponent implements OnInit {

  public leads_data = [];
  pendingLeadDataSource: [] = [];
  approvedLeadDataSource!: MatTableDataSource<any>;
  rejectedLeadDataSource!: MatTableDataSource<any>;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['serial','id', 'date', 'hostName', 'name', 'mobile', 'email', 'companyName', 'description', 'action'];
  approvedColumns: string[] = ['serial','id', 'date', 'hostName', 'name', 'mobile', 'email', 'companyName', 'description'];
  rejectedColumns: string[] = ['serial','id', 'date', 'hostName', 'name', 'mobile', 'email', 'companyName', 'description'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  status = "0";
  pendingLeads: boolean = true;
  approvedLeads: boolean = true;
  rejectedLeads: boolean = true;
  pendingCount: any;
  approveCount: any;
  rejectCount: any;
  date:any;

  constructor(public viewContainerRef: ViewContainerRef, public snackBar: MatSnackBar, private datePipe: DatePipe, private _queriesservice: QueriesService, private loaderService: LoaderService, private dialog:MatDialog){}

  ngOnInit(): void {
    this.populatePendingInquiry();
    this.populateApprovedInquiry();
    this.populateRejectedInquiry();
    this.dataSource = new MatTableDataSource(this.pendingLeadDataSource);
    this.approvedLeadDataSource = new MatTableDataSource(this.pendingLeadDataSource);
    this.rejectedLeadDataSource = new MatTableDataSource(this.pendingLeadDataSource);    
  }

  formatDate(inputDate: string): string {
    // Parse the ISO date string into a Date object
    const date = new Date(inputDate);

    // Define options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 12-hour clock with AM/PM
    };

    // Format date using `Intl.DateTimeFormat`
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  populatePendingInquiry(data?: any) {
    this._queriesservice
      .getCreditInquiry('pending').then((res) => {
        if (res.success) {
          this.pendingCount = `Pending (${res.data?.length})`

          this.dataSource = new MatTableDataSource(res.data);
          // this.dataSource.sort = this.sort;
          this.leads_data = res.data;

          this.leads_data.forEach((item:any) => {
            item.updatedAt = this.formatDate(item.updatedAt);
          })

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, 200);
        } else {
          this.pendingLeads = false;
          this.dataSource = new MatTableDataSource([]);
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  populateApprovedInquiry(data?: any) {
    this._queriesservice
      .getCreditInquiry('approved').then((res) => {
        if (res.success) {
          this.approveCount = `Approved (${res.data?.length})`
          this.approvedLeadDataSource = new MatTableDataSource(res.data);

          this.leads_data = res.data;

          this.leads_data.forEach((item:any) => {
            item.updatedAt = this.formatDate(item.updatedAt);
          })

          setTimeout(() => {
            this.approvedLeadDataSource.paginator = this.paginator;
            this.approvedLeadDataSource.sort = this.sort;
          }, 500);
        } else {
          this.approvedLeads = false;
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  populateRejectedInquiry(data?: any) {
    this._queriesservice
      .getCreditInquiry('rejected').then((res) => {
        if (res.success) {
         
          this.rejectCount = `Rejected (${res.data?.length})`
          this.rejectedLeadDataSource = new MatTableDataSource(res.data);

          this.leads_data = res.data;

          this.leads_data.forEach((item:any) => {
            item.updatedAt = this.formatDate(item.updatedAt);
          })

          setTimeout(() => {
            this.rejectedLeadDataSource.paginator = this.paginator;
            this.rejectedLeadDataSource.sort = this.sort;
          }, 500);
        } else {
          this.rejectedLeads = false;
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public changeStatusCoworking(event: any) {
    let status = event.tab.textLabel;
    if (status.startsWith("Pending")) {
      this.status = "0";
      this.populatePendingInquiry();
    } else if (status.startsWith("Approved")) {
      this.status = "1";
      this.populateApprovedInquiry();
    } else if (status.startsWith("Rejected")) {
      this.status = "2";
      this.populateRejectedInquiry();
    }
  }

  applyFilter(value: string) {
    if (this.status == '0') {
      value = value.trim().toLowerCase();
      this.dataSource.filter = value;
    } else if (this.status == '1') {
      value = value.trim().toLowerCase();
      this.approvedLeadDataSource.filter = value;
    } else if (this.status == '2') {
      value = value.trim().toLowerCase();
      this.rejectedLeadDataSource.filter = value;
    }
  }

  approveInquiryDialog(id: any) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;
    config.data = { title: "approve" };

    let lead_view_dialogRef = this.dialog.open(ApproveLeadComponent, config);
    lead_view_dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = {
          status: "Approved"
        }
        this._queriesservice.updateCreditInquiryById(id, data).then((res) => {
          if (res.success) {
            this.populateApprovedInquiry()
            this.populatePendingInquiry()
            this.populateRejectedInquiry()
          }
          this._openSnackBar(res.message,"Dismiss")
        })
      }
    })
  }

  rejectInquiryDialog(id: any) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;
    config.data = { title: "reject" };

    let lead_view_dialogRef = this.dialog.open(ApproveLeadComponent, config);
    lead_view_dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = {
          status: "Rejected"
        }
        this._queriesservice.updateCreditInquiryById(id, data).then((res) => {
          if (res.success) {
            this.populateApprovedInquiry()
            this.populatePendingInquiry()
            this.populateRejectedInquiry()
          }
          this._openSnackBar(res.message,"Dismiss")
        })
      }
    })
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

}
