<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Tell Users about your space</h3>
                <!-- <button (click)="openModal()">Open</button> -->
            </div>
            <div class="card-body">
                <div class="row align-items-start">
                    <div class="col-lg-3 col-md-4 panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu radius-tab">
                                <ul class="mb-md-0 mb-5 nav panel-tabs panel-info" (navChange)="onTabChange($event)"
                                    ngbNav #nav3="ngbNav" [activeId]="activeTab">
                                    <li [ngbNavItem]="0" class="w-100">
                                        <a ngbNavLink class="d-flex justify-content-between align-items-center"><span style="text-transform:capitalize"><i
                                                    class="fa fa-home me-2"></i>Select your Host</span><span
                                                class="tab_check"></span></a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="head_label required">Select your Host</label>
                                                    <div class="form-group mt-3">
                                                        <label class="fw-bold fs-5 required text-dark">Host <span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox host_select">
                                                            <ng-select class="form-selec drop_down form-control"
                                                                [placeholder]="(createSpace.hostId == 0 || '' || createSpace.hostId == null || createSpace.hostId == undefined) ? 'Select Host' : ''"
                                                                [(ngModel)]="(createSpace.hostId == 0 || '' || null) ? '' : createSpace.hostId"
                                                                (change)="getHostList($event)">
                                                                <ng-option *ngFor="let host of host" [value]="host.id">
                                                                    {{host.name}} - {{host.companyName ?? 'N/A'}} - {{host.mobile}}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="btn-list">
                                                            <a href="javascript:void(0)" class="btn btn-primary-light "
                                                                style="float: right;"
                                                                (click)="onNextButtonClick()">Next</a>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="1" class="w-100">
                                        <a style="text-transform:capitalize" ngbNavLink class=""><i class="fa fa-home me-2"></i>Select your category</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="head_label required">What Type Of Space Are You Listing?<span class="text-danger">*</span></label>      
                                                    <p class="label_txt">Choosing the right space type ensures your listing is discoverable.</p>
                                                    <div class="pt-1 pb-4" style="font-size: 15px;">
                                                        <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                        <b>Example:</b> Coworking Space, Podcast Studio, Meeting Room, Photo Studio, Private Office etc.
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label class="fw-bold fs-5 required text-dark">Category<span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <ng-select class="form-select drop_down form-control"
                                                                [(ngModel)]="(createSpace.spaceType == 0 || '' || null) ? '' : createSpace.spaceType"
                                                                #spaceType="ngModel"
                                                                [placeholder]="(createSpace.spaceType == 0 || createSpace.spaceType === '' || createSpace.spaceType === null || createSpace.spaceType === undefined) ? 'Select Category' : ''"
                                                                (change)="getSelectedWorkSpace($event)"
                                                                [items]="workSpaceNames" required>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="btn-list">
                                                            <a href="javascript:void(0)" class="btn btn-primary-light"
                                                                (click)="onPreviousButtonClick()">Previous</a>
                                                            <a href="javascript:void(0)" class="btn btn-primary-light "
                                                                style="float: right;"
                                                                (click)="onNextButtonClick()">Next</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2" class="w-100">
                                        <a (click)="initializeAutocomplete()" ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>Space address</a>
                                        <ng-template ngbNavContent>
                                            <div class="row align-items-start">
                                                <div class="col-12 mb-4">
                                                    <label class="head_label required">Space Address</label>
                                                    <p class="label_txt">Your space's address will not be displayed publicly on your Flexo listing. It will only be shared with guests after they book the space.</p>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="createSpace.spacetypeValue !== 'Long-Term'">
                                                    <div class="form-group">
                                                        <label for="actual_name">Name of your space <span
                                                                class="text-danger">*</span></label>
                                                        <input
                                                            placeholder="Enter name of your space" type="text"
                                                            class="form-control filter-input" name="input"
                                                            id="actual_name" [(ngModel)]="createSpace.actual_name"
                                                            required #actualName="ngModel">
                                                        <div *ngIf="actualName.invalid && (actualName.dirty || actualName.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="actualName.errors && actualName.errors['required']">
                                                                Name of your space is required.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="createSpace.spacetypeValue !== 'Long-Term'">
                                                    <div class="form-group">
                                                        <label>Enter your website URL<span
                                                                style="font-weight: 100;font-size: 12px;">
                                                                (company.com)</span></label>
                                                        <input [(ngModel)]="createSpace.yourWebsite"
                                                            #yourWebsite="ngModel" type="url" name="address"
                                                            class="form-control filter-input"
                                                            placeholder="Enter your website URL"
                                                            pattern="^(http[s]?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,63}\/?$">

                                                        <div *ngIf="yourWebsite.invalid && (yourWebsite.dirty || yourWebsite.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="yourWebsite.errors && yourWebsite.errors['required']">
                                                                Website URL is required.
                                                            </div>
                                                            <div *ngIf="yourWebsite.errors?.['pattern']">
                                                                Please enter a valid website URL.
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Country <span class="text-danger">*</span></label>
                                                        <ng-select class="drop_down"
                                                            (change)="onCountryChange();createSpace.state='';createSpace.contact_city_name='';"
                                                            [placeholder]="createSpace.country ? '' : 'Select COuntry'"
                                                            [items]="countryList" [(ngModel)]="createSpace.country"
                                                            bindLabel="country_name">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>State <span class="text-danger">*</span></label>
                                                        <ng-select class="drop_down"
                                                            (change)="onStateChange();createSpace.contact_city_name='';"
                                                            [placeholder]="createSpace.state ? '' : 'Select State'"
                                                            [items]="stateList" [(ngModel)]="createSpace.state"
                                                            bindLabel="name">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>City <span class="text-danger">*</span></label>
                                                        <ng-select class="drop_down" (change)="onCityChange($event)"
                                                            [placeholder]="createSpace.contact_city_name ? '' : 'Select City'"
                                                            [items]="cityList"
                                                            [(ngModel)]="createSpace.contact_city_name"
                                                            bindLabel="name">
                                                        </ng-select>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>
                                                            Location Name <span class="text-danger">*</span></label>

                                                        <div class="autocomplete-container">
                                                            <input type="text"
                                                                onkeypress="return /[a-zA-Z ]/.test(event.key)"
                                                                [(ngModel)]="createSpace.location_name"
                                                                name="location_name"
                                                                placeholder="Enter a place"
                                                                class="form-control filter-input"
                                                                #location_name="ngModel" required />
                                                        </div>
                                                        <div *ngIf="location_name.invalid && (location_name.dirty || location_name.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="location_name.errors && location_name.errors['required']">
                                                                Location Name is required.</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Enter your building name<span
                                                                class="text-danger">*</span></label>
                                                        <input [(ngModel)]="createSpace.buildingName"
                                                            #buildingName="ngModel" type="text" name="buildingname"
                                                            class="form-control filter-input"
                                                            placeholder="Enter building name" required>

                                                        <div *ngIf="buildingName.invalid && (buildingName.dirty || buildingName.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="buildingName.errors && buildingName.errors['required']">
                                                                Building Name is required.</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="required">Select your floor</label>
                                                        <ng-select class="drop_down" [placeholder]="createSpace.floorNumber ? '' : 'Select an option'"
                                                            [(ngModel)]="createSpace.floorNumber" #floorNumber="ngModel">

                                                            <ng-option value="" aria-selected="true" disabled>Select
                                                                floor</ng-option>
                                                            <ng-option value="lower_basement">Lower Basement</ng-option>
                                                            <ng-option value="upper_basement">Upper Basement</ng-option>
                                                            <ng-option value="ground">Ground</ng-option>
                                                            <ng-container
                                                                *ngFor="let i of [].constructor(200); let index = index">
                                                                <ng-option [value]="index + 1">{{ index + 1
                                                                    }}</ng-option>
                                                            </ng-container>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Enter your unit no.<span
                                                                class="text-danger">*</span></label>
                                                        <input [(ngModel)]="createSpace.unitno" #unitno="ngModel"
                                                            type="tel" name="floorno" class="form-control filter-input"
                                                            placeholder="Enter unit no.">

                                                        <div *ngIf="unitno.invalid && (unitno.dirty || unitno.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="unitno.errors && unitno.errors['required']">
                                                                Unit no. field is required.</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Enter Address line 1 <span
                                                                class="text-danger">*</span></label>
                                                        <input type="text" name="address"
                                                            [(ngModel)]="createSpace.address"
                                                            class="form-control filter-input"
                                                            placeholder="Enter Address line 1" required
                                                            #address="ngModel">

                                                        <div *ngIf="address.invalid && (address.dirty || address.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="address.errors && address.errors['required']">
                                                                Address field is required.</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Enter Address line 2</label>
                                                        <input [(ngModel)]="createSpace.address2" required
                                                            #address2="ngModel" type="text" name="address2"
                                                            class="form-control filter-input"
                                                            placeholder="Enter address line 2">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Zip/Postal code <span
                                                                class="text-danger">*</span></label>
                                                        <input minlength="6" maxlength="6"
                                                            onkeypress="return /[0-9 ]/i.test(event.key)"
                                                            #zipcode="ngModel" type="text" name="zip_code"
                                                            [(ngModel)]="createSpace.zipcode"
                                                            class="form-control filter-input"
                                                            placeholder="Enter Zip Code" required>

                                                        <div *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="zipcode.errors && zipcode.errors['required']">
                                                                Zip Code is required.
                                                            </div>
                                                            <div *ngIf="zipcode.errors && zipcode.errors['minlength']">
                                                                <!-- Added minlength error check -->
                                                                Zip Code must be at least 6 characters long.
                                                            </div>
                                                            <div *ngIf="zipcode.errors && zipcode.errors['maxlength']">
                                                                <!-- Added maxlength error check -->
                                                                Zip Code cannot exceed 6 characters.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12"
                                                    *ngIf="createSpace.spacetypeValue !== 'Long-Term'">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label> Nearest metro station name</label>
                                                                <input type="text"
                                                                    [(ngModel)]="createSpace.near_by_metro"
                                                                    name="near_by_metro"
                                                                    class="form-control filter-input"
                                                                    placeholder="Nearest metro"
                                                                    #near_by_metro="ngModel">
                                                                <!-- <div *ngIf="near_by_metro.errors && near_by_metro.errors['required']">
                                                                        metro name is required.</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <div class="form-group">
                                                                    <label> Nearest metro station distance
                                                                        (KM)</label>
                                                                    <input type="text"
                                                                        onkeypress="return /[0-9.]/.test(event.key) && !(/\./.test(this.value) && event.key === '.') && (this.value.split('.')[1]?.length < 1 || !this.value.includes('.'))"
                                                                        [(ngModel)]="createSpace.metro_distance"
                                                                        name="metro_distance"
                                                                        class="form-control filter-input"
                                                                        placeholder="Nearest metro distance"
                                                                        #metro_distance="ngModel">
                                                                    <!-- <div *ngIf="metro_distance.invalid && (metro_distance.dirty || metro_distance.touched)"
                                                                class="text-danger">
                                                                <div *ngIf="metro_distance.errors && metro_distance.errors['required']">
                                                                    metro distance is required.</div>
                                                            </div> -->
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label> Nearest railway station name</label>
                                                                <input type="text"
                                                                    [(ngModel)]="createSpace.near_by_railway_name"
                                                                    name="near_by_railway"
                                                                    class="form-control filter-input"
                                                                    placeholder="Nearest Railway station"
                                                                    #near_by_railway_name="ngModel">
                                                                <!-- <div *ngIf="near_by_railway_name.invalid && (near_by_railway_name.dirty || near_by_railway_name.touched)"
                                                                class="text-danger">
                                                                <div *ngIf="near_by_railway_name.errors && near_by_railway_name.errors['required']">
                                                                    Railway name is required.</div>
                                                            </div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    Nearest railway station distance (KM)</label>
                                                                <input type="text"
                                                                    onkeypress="return /[0-9.]/.test(event.key) && !(/\./.test(this.value) && event.key === '.') && (this.value.split('.')[1]?.length < 1 || !this.value.includes('.'))"
                                                                    [(ngModel)]="createSpace.railway_distance"
                                                                    name="railway_distance"
                                                                    class="form-control filter-input"
                                                                    placeholder="Nearest Railway Station Distance">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">
                                                            Google Location <span class="text-danger">*</span></label>
                                                            <div class="pt-1 pb-4" style="font-size: 15px;">
                                                                <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                Choose the location of your space on Google. If your space is not on google, choose the building your space is located in. If your building is not on google, choose the nearest landmark.
                                                            </div>

                                                        <div class="autocomplete-container">
                                                            <input #autocomplete type="text"
                                                            [(ngModel)]="createSpace.location"
                                                            name="location" id="autocomplete"
                                                            placeholder="Enter a place"
                                                            class="form-control filter-input"
                                                            #location="ngModel" required />
                                                        </div>
                                                        <div *ngIf="location.invalid && (location.dirty || location.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="location.errors && location.errors['required']">
                                                                Google location is required.</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row col-md-12">
                                                    <div class="col-md-6">
                                                        <!-- <div class="form-group">
                                                        <label>Enter your space latitude<span
                                                                class="text-danger">*</span></label>
                                                        <input [(ngModel)]="createSpace.lat" #lat="ngModel"
                                                            onkeypress="return /[0-9. ]/i.test(event.key)" type="tel"
                                                            name="latitude" class="form-control filter-input"
                                                            placeholder="Enter your space latitude" required>

                                                        <div *ngIf="lat.invalid && (lat.dirty || lat.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="lat.errors && lat.errors['required']">
                                                                Latitude field is required.</div>

                                                        </div>
                                                    </div> -->
                                                    </div>
                                                    <div class="col-md-6">
                                                        <!-- <div class="form-group">
                                                        <label>Enter your space longitude<span
                                                                class="text-danger">*</span></label>
                                                        <input [(ngModel)]="createSpace.longi" #longi="ngModel"
                                                            onkeypress="return /[0-9. ]/i.test(event.key)" type="tel"
                                                            name="longitude" class="form-control filter-input"
                                                            placeholder="Enter your space longitude" required>

                                                        <div *ngIf="longi.invalid && (longi.dirty || longi.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="longi.errors && longi.errors['required']">
                                                                Longitude field is required.</div>

                                                        </div>
                                                    </div> -->
                                                    </div>
                                                    <!-- <div class="col-md-12">
                                              <div class="form-group">
                                                  <label for="list_info" class="required">Description</label>
                                                  <textarea class="form-control" [(ngModel)]="createSpace.about"
                                                      name="about" id="list_info" rows="4"
                                                      placeholder="Enter your text here" required
                                                      #about="ngModel"></textarea>
                                                  <div *ngIf="about.invalid && (about.dirty || about.touched)"
                                                      class="text-danger">
                                                      <div *ngIf="about.errors && about.errors['required']">
                                                          Description is required.</div>

                                                  </div>
                                              </div>
                                          </div> -->
                                                    <!-- <div class="col-md-2">
                                              <div class="form-group">
                                                  <label>Rating</label>
                                                  <mat-select placeholder="Rating" [(ngModel)]="createSpace.rating">
                                                      <mat-option *ngFor="let rating of ratings" [value]="rating">
                                                          {{rating}}
                                                      </mat-option>
                                                  </mat-select>
                                              </div>
                                          </div> -->

                                                </div>
                                                <div class="col-12">
                                                    <div class="btn-list">
                                                        <a href="javascript:void(0)" class="btn btn-primary-light"
                                                            (click)="onPreviousButtonClick()">Previous</a>
                                                        <a href="javascript:void(0)" class="btn btn-primary-light "
                                                            style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3" class="w-100">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i> About the space</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12  br_btm">
                                                    <div class="form-group">
                                                        <label class="head_label required">Give your space a
                                                            title</label>
                                                            <p class="label_txt">Provide a unique and descriptive title for your space that stands out. Do not include your business name as your listing will not be approved.</p>
                                                            <ul class="p-0 mt-1 abt_space_list mb-3">
                                                                <div class="label_txt mb-1">Try to include the following elements:</div>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i><b>Location</b> - close to metro, business district, neighbourhood</li>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i><b>Type of space</b> - podcast studio, coworking space, meeting room</li>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i><b>Unique adjective</b> - modern, elegant, spacious, well-equipped</li>
                                                            </ul>
                                                            <div class="pt-1 pb-4 label_txt" style="font-size: 14px;">
                                                                <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                <b>Example:</b> "Well-equipped Podcast Studio In Andheri West", "Modern Coworking Space Close To BKC Metro", "Spacious Meeting Room With Sea View"
                                                            </div>
                                                        <input [(ngModel)]="createSpace.spaceTitle" #spaceTitle="ngModel"
                                                            type="text" minlength="30" maxlength="200" name="spacetitle"
                                                            class="form-control filter-input"
                                                            placeholder="Enter your space title" required>

                                                        <small class="text-muted">
                                                            {{ createSpace.spaceTitle ? createSpace.spaceTitle.length : 0 }} / 200 characters
                                                        </small>

                                                        <div *ngIf="spaceTitle.invalid && (spaceTitle.dirty || spaceTitle.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="spaceTitle.errors && spaceTitle.errors['required']">
                                                                space title field is required.</div>

                                                            <div
                                                                *ngIf="spaceTitle.errors && spaceTitle.errors['minlength']">
                                                                Space title must be at least 30 characters long.
                                                            </div>

                                                            <div
                                                                *ngIf="spaceTitle.errors && spaceTitle.errors['maxlength']">
                                                                Space title cannot be more than 200 characters long.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 my-5 br_btm">
                                                    <div class="form-group">
                                                        <!-- <label class="required">Add a description of your space <span
                                                            style="font-weight: 100;font-size: 12px;">(Only 600
                                                            characters are allowed)</span> <span class="text-danger">
                                                            *</span></label> -->
                                                        <label class="head_label required">Add a description of your
                                                            space</label>
                                                            <p class="label_txt">Provide a compelling description of your space to attract potential guests.</p>
                                                            <ul class="p-0 mt-1 abt_space_list mb-3">
                                                                <div class="label_txt mb-1">Tips to Guide You:</div>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i> Describe how your space can be used for various activities such as meetings, workshops, photo shoots, or events.</li>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Share details about the layout of your space and any unique amenities it offers, such as natural lighting, high-speed internet, or flexible seating arrangements.</li>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i> Describe the atmosphere and vibe of your space to give guests a sense of what to expect.</li>
                                                            </ul>
                                                            <ul class="p-0 mt-3 abt_space_list mb-3">
                                                                <div class="label_txt mb-1">Do not include:</div>
                                                                <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i><b>Contact information:</b> Do not include your contact information such as phone number, email, address, business name or links to external websites in the description as your listing will not be approved.</li>
                                                            </ul>
                                                        <textarea minlength="300" maxlength="600"
                                                            class="form-control" [(ngModel)]="createSpace.about"
                                                            name="spacedecription" id="list_info" rows="4"
                                                            placeholder="Enter a decsription of your space" required
                                                            #about="ngModel"></textarea>

                                                            <small class="text-muted">
                                                                {{ createSpace.about ? createSpace.about.length : 0 }} / 600 characters
                                                            </small>

                                                        <div *ngIf="about.invalid && (about.dirty || about.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="about.errors && about.errors['required']">
                                                                decsription of your space field is required.</div>

                                                            <div *ngIf="about.errors && about.errors['minlength']">
                                                                Description of your space must be at least 300
                                                                characters long.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="createSpace.spacetypeValue !== 'Long-Term' "
                                                    class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <label for="">Are there parking options at or near your
                                                            space? <span class="text-danger">*</span></label>
                                                        <label class="custom-switch ms-2">
                                                            <input [checked]="showParkingOptions"
                                                                (change)="toggleParkingOptions($event)" type="checkbox"
                                                                class="custom-switch-input">
                                                            <span
                                                                class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 my-5" *ngIf="showParkingOptions && createSpace.spacetypeValue !== 'Long-Term' ">
                                                    <span class="text-dark fs-5">Select all that apply</span>
                                                    <div class="row mt-4">
                                                        <div class="col-md-6">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Free onsite parking')"
                                                                            (change)="parkingOptionsValues($event, 'Free onsite parking')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck1">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck1">Free onsite
                                                                            parking</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Paid site parking')"
                                                                            (change)="parkingOptionsValues($event, 'Paid site parking')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck2">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck2">Paid onsite
                                                                            parking</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Free street parking')"
                                                                            (change)="parkingOptionsValues($event, 'Free street parking')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck3">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck3">Free street
                                                                            parking</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Metered street parking')"
                                                                            (change)="parkingOptionsValues($event, 'Metered street parking')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck4">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck4">Metered street
                                                                            parking</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Valet parking')"
                                                                            (change)="parkingOptionsValues($event, 'Valet parking')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck5">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck5">Valet parking</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group form-check">
                                                                        <input
                                                                            [checked]="selectedParkingOptions.includes('Near by parking lot')"
                                                                            (change)="parkingOptionsValues($event, 'Near by parking lot')"
                                                                            type="checkbox"
                                                                            style="margin-top: .25em !important;"
                                                                            class="form-check-input" id="exampleCheck6">
                                                                        <label class="form-check-label"
                                                                            for="exampleCheck6">Nearby parking
                                                                            lot</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span class="text-danger" *ngIf="selectedParkingOptions.length < 1">Please select at least 1 parking option</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 br_btm my-5"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' ">
                                                    <div class="form-group">
                                                        <!-- <label class="required">Describe the parking options <span
                                                            style="font-weight: 100;font-size: 12px;">(Only 350
                                                            characters are allowed)</span></label> -->
                                                        <label class="head_label required">Describe the parking
                                                            options</label>
                                             
                                                        <textarea minlength="30" maxlength="1000"
                                                            class="form-control mt-1"
                                                            [(ngModel)]="createSpace.parkingDescription"
                                                            #parkingDescription="ngModel" name="spacedecription"
                                                            id="list_info" rows="4"
                                                            placeholder="Enter a decsription of your parking"
                                                            required></textarea>

                                                            <small class="text-muted">
                                                                {{ createSpace.parkingDescription ? createSpace.parkingDescription.length : 0 }} / 1000 characters
                                                            </small>

                                                        <div *ngIf="parkingDescription.invalid && (parkingDescription.dirty || parkingDescription.touched)"
                                                            class="text-danger">

                                                            <div
                                                                *ngIf="parkingDescription.errors && parkingDescription.errors['minlength']">
                                                                Parking description must be at least 30 characters long.
                                                            </div>

                                                            <div
                                                                *ngIf="parkingDescription.errors && parkingDescription.errors['maxlength']">
                                                                Parking description cannot be more than 1000 characters
                                                                long.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 br_btm my-5"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="form-group">
                                                        <label class="head_label required">Describe the lighting options (If relevant)</label>
                                                                 <div class="my-2">
                                                                    <p class="label_txt">Example: The space has natural light, which can be blocked out. 2 Umbrella Lights are included with the booking.</p>
                                                                </div>
                                                        <textarea maxlength="350" minlength="100"
                                                            class="form-control"
                                                            [(ngModel)]="createSpace.lightingDescription"
                                                            #lightingDescription="ngModel" name="lightningdescription"
                                                            id="list_info" rows="4"
                                                            placeholder="Enter a description of lighting"
                                                            required></textarea>

                                                            <small class="text-muted">
                                                                {{ createSpace.lightingDescription ? createSpace.lightingDescription.length : 0 }} / 350 characters
                                                            </small>

                                                        <div *ngIf="lightingDescription.invalid && (lightingDescription.dirty || lightingDescription.touched)"
                                                            class="text-danger">

                                                            <div
                                                                *ngIf="lightingDescription.errors && lightingDescription.errors['minlength']">
                                                                Lighting description must be at least 100 characters
                                                                long.
                                                            </div>

                                                            <div
                                                                *ngIf="lightingDescription.errors && lightingDescription.errors['maxlength']">
                                                                Lighting description cannot be more than 10000
                                                                characters long.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 br_btm my-5"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="form-group">
                                                        <!-- <label class="required">Describe the sound options <span
                                                            style="font-weight: 100;font-size: 12px;">(Only 350
                                                            characters are allowed)</span></label> -->
                                                        <label class="head_label required">Describe the sound options (If relevant)</label>
                                                        <div class="my-2">
                                                            <p class="label_txt">Example: Our studio is equipped with professional-grade audio equipment to ensure high-quality sound recording for your projects. 2 condenser microphones and portable recorders are included with the booking.                                                                    </p>
                                                        </div>
                                                        <textarea maxlength="350" minlength="100"
                                                            class="form-control"
                                                            [(ngModel)]="createSpace.soundDescription"
                                                            #soundDescription="ngModel" name="spacedecription"
                                                            id="list_info" rows="4"
                                                            placeholder="Enter a description of sound"
                                                            required></textarea>

                                                            <small class="text-muted">
                                                                {{ createSpace.soundDescription ? createSpace.soundDescription.length : 0 }} / 350 characters
                                                            </small>

                                                        <div *ngIf="soundDescription.invalid && (soundDescription.dirty || soundDescription.touched)"
                                                            class="text-danger">

                                                            <div
                                                                *ngIf="soundDescription.errors && soundDescription.errors['minlength']">
                                                                Sound description must be at least 100 characters long.
                                                            </div>

                                                            <div
                                                                *ngIf="soundDescription.errors && soundDescription.errors['maxlength']">
                                                                Sound description cannot be more than 10000 characters
                                                                long.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-12 br_btm my-5"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="form-group">
                                                        <!-- <label class="required">What are you host rules <span
                                                        style="font-weight: 100;font-size: 12px;">(Only 350
                                                        characters are allowed)</span></label> -->
                                                        <label class="head_label required">Set Your Host Rules</label>
                                                        <p class="label_txt">Specify any regulation or guidelines for guests regarding the usage of your space. Do not include your business name and contact details.</p>
                                                        <ul class="p-0 mt-1 abt_space_list mb-3">
                                                            <div class="label_txt mb-1">Example:</div>
                                                            <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Smoking, consumption of liquor and other prohibited substances are strictly prohibited in the space.</li>
                                                            <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>The client will be responsible for any damage made to studio property. </li>
                                                            <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Maintain basic hygiene and cleanliness. No pets allowed. External catering permitted. </li>
                                                            <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Usage of any liquids, powders, color bombs, spray paints etc is strictly prohibited.</li>
                                                        </ul>
                                                        <textarea maxlength="350" minlength="100"
                                                            class="form-control"
                                                            [(ngModel)]="createSpace.hostRulesDescription"
                                                            #hostRulesDescription="ngModel" name="spacedecription"
                                                            id="list_info" rows="4"
                                                            placeholder="Enter a rules of your host"
                                                            required></textarea>
                                                            <small class="text-muted">
                                                                {{ createSpace.hostRulesDescription ? createSpace.hostRulesDescription.length : 0 }} / 350 characters
                                                            </small>
                                                            <div class="text-danger" *ngIf="hostRulesDescription.errors && hostRulesDescription.errors['minlength']">
                                                                Rule description must be at least 100 characters long.
                                                            </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-12 my-5 br_btm"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="form-group">
                                                        <!-- <label class="required">Provide arrival instructions <span
                                                    style="font-weight: 100;font-size: 12px;">(Only 350
                                                    characters are allowed)</span></label> -->
                                                        <label class="head_label required">Provide Arrival Instructions (If relevant)</label>
                                                        <p class="label_txt">Help your guests and their attendees find and enter your space.</p>
                                                        <ul class="p-0 mt-1 abt_space_list mb-3">
                                                            <div class="label_txt fs-6 mb-1">Try to include:</div>
                                                            <li class="label_txt"><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Direction to your space, building access, door buzzers floors, stairs/elevator access etc.</li>
                                                        </ul>
                                                        <textarea maxlength="350"
                                                            class="form-control"
                                                            [(ngModel)]="createSpace.arrivalInstructions"
                                                            #arrivalInstructions="ngModel" name="instructions"
                                                            id="list_info" rows="4"
                                                            placeholder="Enter a arrival instructions"
                                                            required></textarea>
                                                        <small class="text-muted">
                                                            {{ createSpace.arrivalInstructions ? createSpace.arrivalInstructions.length : 0 }} / 350 characters
                                                        </small>
                                                    </div>
                                                </div>

                                                <!-- <div class="col-md-12 my-5 br_btm"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <label class="head_label required">What's your wifi name and
                                                        password</label>
                                                    <p class="label_txt">Fields for Wifi Name and Password to be
                                                        different</p>
                                                    <div class="row">
                                                        <div class="col-md-6"
                                                            *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                            <div class="form-group">
                                                                <label class="required">Name</label>
                                                                <input [(ngModel)]="createSpace.wifiName"
                                                                    #wifiName="ngModel" type="text" maxlength="50"
                                                                    name="wifi" class="form-control filter-input"
                                                                    placeholder="Enter a Wi-fi Name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6"
                                                            *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                            <div class="form-group">
                                                                <label class="required">Password</label>
                                                                <input [(ngModel)]="createSpace.wifiPassword"
                                                                    #wifiPassword="ngModel" type="password"
                                                                    maxlength="50" name="wifi"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter a Wi-fi Password">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->


                                                <div class="col-md-6"
                                                    *ngIf="createSpace?.spacetypeValue == 'Long-Term'">
                                                    <div class="form-group">
                                                        <label class="head_label required">What is the status of your space?<span class="text-danger">*</span></label>
                                                        <ng-select [placeholder]="createSpace.spaceStatus ? '' : 'Select an option' "
                                                            [(ngModel)]="createSpace.spaceStatus" #spaceStatus="ngModel"
                                                            class="form-selec drop_down form-control">
                                                            <ng-option value="Bare shell">Bare shell</ng-option>
                                                            <ng-option value="Warm shell">Warm shell</ng-option>
                                                            <ng-option value="Semi-furnished">Semi-furnished</ng-option>
                                                            <ng-option value="Furnished">Furnished</ng-option>
                                                        </ng-select>
                                                        <div class="text-danger" *ngIf="spaceStatus == null || spaceStatus == undefined">please select space status</div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-5">
                                                    <div class="btn-list">
                                                        <a href="javascript:void(0)" class="btn btn-primary-light"
                                                            (click)="onPreviousButtonClick()">Previous</a>
                                                        <a href="javascript:void(0)" class="btn btn-primary-light "
                                                            style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                    </div>
                                                </div>

                                            </div>


                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="4" class="w-100">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i> Size/Capacity</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12 mb-3">
                                                    <label class="head_label required">Size & Capacity</label>
                                                    <p class="label_txt" *ngIf="createSpace.spacetypeValue !== 'Coworking'">Please only include the size of the space that guests can use during their booking.</p>
                                                    <p class="label_txt" *ngIf="createSpace.spacetypeValue == 'Coworking'">Please include the size of the entire space.</p>
                                                    <div *ngIf="createSpace.spacetypeValue !== 'Coworking' && createSpace.spacetypeValue !== 'Long-Term'" class="pt-1 pb-4" style="font-size: 15px;">
                                                        <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                        <b class="label_txt">Example: If your space is 3000 sq ft, but guests are booking a 200 sq ft meeting room, you would enter "200"</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <label *ngIf="createSpace?.spacetypeValue == 'Long-Term'" style="font-size: 18px;">How big is
                                                        the
                                                        space ? (carpet area sq ft) <span
                                                            class="text-danger">*</span></label>
                                                    <label *ngIf="createSpace?.spacetypeValue == 'Short-Term'" style="font-size: 18px;">How big
                                                        is
                                                        the space ? (Square Feet Area) <span
                                                            class="text-danger">*</span></label>
                                                    <label *ngIf="createSpace?.spacetypeValue == 'Coworking'" style="font-size: 18px;">What is
                                                        the
                                                        size of your space in sqft ? <span
                                                            class="text-danger">*</span></label>
                                                    <input (keyup)="calcualteSqftPrice()"
                                                        onkeypress="return /[0-9 ]/.test(event.key)"
                                                        [(ngModel)]="createSpace.spacesqft" #spacesqft="ngModel"
                                                        type="tel" maxlength="50" name="spacestatus"
                                                        class="form-control filter-input"
                                                        placeholder="Enter how big is your space" required>

                                                    <div *ngIf="spacesqft.invalid && (spacesqft.dirty || spacesqft.touched)"
                                                        class="text-danger">
                                                        <div *ngIf="spacesqft.errors && spacesqft.errors['required']">
                                                            Space field is required.</div>

                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-3"
                                                    *ngIf="createSpace?.spacetypeValue == 'Long-Term'">
                                                    <label style="font-size: 18px;">How big is the space (built-up area sq ft)</label>
                                                    <input [(ngModel)]="createSpace.spaceBuiltUpArea"
                                                        #spaceBuiltUpArea="ngModel" (keyup)="calcualteSqftPrice()"
                                                        onkeypress="return /[0-9 ]/.test(event.key)" type="tel"
                                                        maxlength="50" name="spacestatus"
                                                        class="form-control filter-input"
                                                        placeholder="Enter how big is your space">

                                                    <!-- <div *ngIf="spaceBuiltUpArea.invalid && (spaceBuiltUpArea.dirty || spaceBuiltUpArea.touched)"
                                                    class="text-danger">
                                                    <div
                                                        *ngIf="spaceBuiltUpArea.errors && spaceBuiltUpArea.errors['required']">
                                                        Space field is required.</div>

                                                </div> -->
                                                </div>
                                            </div>
                                            <div class="row mt-4" *ngIf="createSpace?.spacetypeValue !== 'Long-Term'">
                                                <div class="col-md-12">
                                                    <label style="font-size: 18px;">How many people can be accomodated in your space <span
                                                            class="text-danger">*</span></label>
                                                            <div *ngIf="createSpace.spacetypeValue !== 'Coworking'" class="pt-1 pb-4" style="font-size: 15px;">
                                                                <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                <b class="label_txt">Please include the maximum number of guests that are included with the booking.</b>
                                                            </div>
                                                            
                                                            <div *ngIf="createSpace.spacetypeValue == 'Coworking'" class="pt-1 pb-4" style="font-size: 15px;">
                                                                <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                <b class="label_txt">Please include the total seating capacity of the space.</b>
                                                            </div>
                                                    <input onkeypress="return /[0-9 ]/i.test(event.key)"
                                                        [(ngModel)]="createSpace.howManyPeopleInYourSpace"
                                                        #howManyPeopleInYourSpace="ngModel" placeholder="Enter capacity"
                                                        type="tel" class="form-control filter-input" required>

                                                    <div *ngIf="howManyPeopleInYourSpace.invalid && (howManyPeopleInYourSpace.dirty || howManyPeopleInYourSpace.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="howManyPeopleInYourSpace.errors && howManyPeopleInYourSpace.errors['required']">
                                                            how many people accomodated field is required.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn-list" style="margin-top:20px">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="5" class="w-100">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>
                                            Amenities</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <ng-container>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="head_label required">Amenities<span class="text-danger">*</span></label>
                                                            <p class="label_txt">Only select amenities that are included in your price. If you have amenities that you charge for, do not include them here. you can add those in a later section.</p>
                                                            <!-- <div class="pt-1 pb-4" style="font-size: 15px;">
                                                                <svg class="me-2" stroke="currentColor" fill="#f76900"
                                                                    stroke-width="0" viewBox="0 0 352 512" height="20px"
                                                                    width="20px" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z">
                                                                    </path>
                                                                </svg>
                                                                <b class="text-dark">Example:</b> You must include WIFI,
                                                                tables, and chairs to offer meeting.
                                                            </div> -->
                                                            <div class="filter-checkbox row">
                                                                <span class="col-md-4 col-6 my-4"
                                                                    *ngFor="let facility of amenities"
                                                                    style="display: inline-flex;align-items: center;">
                                                                    <input [id]="'fac-'+facility.id" type="checkbox"
                                                                        [checked]="space_facilities.includes(facility.id)"
                                                                        name="check"
                                                                        (change)="updateSpaceFacilities(facility.id, $event)">
                                                                    <label [for]="'fac-'+facility.id">{{facility.amenities}}</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger" *ngIf="space_facilities.length < 4">Please
                                                            select at least 4 amenities</span>
                                                    </div>
                                                </ng-container>
                                                <!-- <ng-container
                                                    *ngIf="createSpace?.spacetypeValue !== 'Short-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>How many parkings are included with your
                                                                space</label>
                                                            <input [(ngModel)]="createSpace.howMenyParking"
                                                                #howMenyParking="ngModel" type="tel"
                                                                class="form-control filter-input"
                                                                placeholder="How many parkings are included with your space">
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                                <!-- <ng-container
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>List the equipment that are include with the
                                                            booking</label>

                                                        <ng-select placeholder="Select an option "
                                                            [(ngModel)]="createSpace.equipment" #equipment="ngModel"
                                                            class="form-control select2 form-select p-0 optin drop_down">
                                                            <ng-option value="" aria-selected="true" disabled>Select an
                                                                option</ng-option>
                                                            <ng-option value="Laptop">Laptop</ng-option>
                                                            <ng-option value="Celling fan">Celling fan</ng-option>
                                                            <ng-option value="Tablet">Tablet</ng-option>
                                                            <ng-option value="Mobile phone">Mobile phone</ng-option>
                                                        </ng-select>

                                                    </div>
                                                </div>
                                            </ng-container> -->
                                                <ng-container
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Does your space have security cameras or recording
                                                                devices?</label>

                                                            <div class="d-flex content mt-2">
                                                                <label class="custom-control custom-radio">
                                                                    <input [(ngModel)]="createSpace.spaceSecurity"
                                                                        #spaceSecurity="ngModel" type="radio"
                                                                        class="custom-control-input"
                                                                        name="example-radios" value="1" checked>
                                                                    <span class="custom-control-label">Yes</span>
                                                                </label>
                                                                <label class="custom-control custom-radio ms-4">
                                                                    <input [(ngModel)]="createSpace.spaceSecurity"
                                                                        #spaceSecurity="ngModel" type="radio"
                                                                        class="custom-control-input"
                                                                        name="example-radios" value="0">
                                                                    <span class="custom-control-label">No</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="col-md-6">
                                                    <label style="color: red"
                                                        *ngIf="price_message">{{price_message}}</label>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="6" *ngIf="createSpace.spacetypeValue !== 'Long-Term'" class="w-100">
                                        <a ngbNavLink class="" style="text-transform:capitalize"><i class="fa fa-home me-2"></i>
                                            Operating hours</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">    
                                            <div class="row mb-3">
                                                <div class="col-md-12 mb-3">
                                                    <label class="fw-bold fs-5 required">What are your operating hours?</label>
                                                    <p class="label_txt" *ngIf="createSpace.spacetypeValue !== 'Coworking'">Operating hours are the days and hours of the week that your space is open for bookings (i.e. your general availability). Guests will not be able to book times outside of your operating hours.</p>
                                                    <p class="label_txt" *ngIf="createSpace.spacetypeValue !== 'Short-Term'">Operating hours are business hours where the space is operational and airconditioning is on.</p>
                                                    <!-- <div class="pt-1 pb-4" style="font-size: 15px;">
                                                        <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M235.4 172.2c0-11.4 9.3-19.9 20.5-19.9 11.4 0 20.7 8.5 20.7 19.9s-9.3 20-20.7 20c-11.2 0-20.5-8.6-20.5-20zm1.4 35.7H275V352h-38.2V207.9z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
                                                        Operating hours end times are restricted to 12 AM for this space type.
                                                    </div> -->
                                                
                                                </div>
                                              <div class="col-md-12">
                                                <mat-checkbox *ngIf="createSpace.spacetypeValue !== 'Long-Term'" [(ngModel)]="has_247_access_toggle"
                                                    [checked]="has_247_access_toggle" 
                                                    (change)="check247access([])">
                                                    Operational 24x7
                                                </mat-checkbox>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Monday</label>
                                                        <span *ngIf="isMondayClosed" class="text-danger">*</span>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <mat-slide-toggle [checked]="!is_monday_closed" name="check"
                                                        (change)="satSunClosed(3)" ></mat-slide-toggle>
                                                    </div>
                                                    <div class="col-md-4" [class.hidden]="!isMondayClosed">
                                                        <label>Opening Time</label>
                                                        <span *ngIf="isMondayClosed" class="text-danger">*</span>
                                                        <ejs-timepicker #picker (click)="picker.show()" [max]='maxTime' (ngModelChange)="getTimeValue(3,'start', $event)" [(ngModel)]="createSpace.mon_opening_time"
                                                            name="mon_opening_time" class="form-control filter-input"
                                                            name="open_time" (change)="onTimePickerChange(3, 'open', $event)" [disabled]="is_monday_closed">
                                                        </ejs-timepicker>
                                                    </div>
                                                    <div class="col-md-4" [class.hidden]="!isMondayClosed">
                                                        <label>Closing Time</label>
                                                        <span *ngIf="isMondayClosed" class="text-danger">*</span>
                                                        <ejs-timepicker #picker2 (click)="picker2.show()" #timepicker (ngModelChange)="getTimeValue(3,'end', $event)" [(ngModel)]="createSpace.mon_closing_time"
                                                            name="mon_closing_time" class="form-control filter-input"
                                                            name="open_time" (change)="onTimePickerChange(3, 'close', $event)" [disabled]="is_monday_closed && createSpace.mon_opening_time == undefined">
                                                        </ejs-timepicker>
                                                    </div>
                                                </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Tuesday</label>
                                                                    <span *ngIf="isTuesdayClosed" class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_tuesday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(4)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isTuesdayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isTuesdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker3 (click)="picker3.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(4,'start', $event)"
                                                                        [(ngModel)]="createSpace.tue_opening_time"
                                                                        name="tue_opening_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(4, 'open', $event)"
                                                                        [disabled]="is_tuesday_closed">
                                                                    </ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isTuesdayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isTuesdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker4 (click)="picker4.show()"
                                                                        #timepicker2
                                                                        (ngModelChange)="getTimeValue(4,'end', $event)"
                                                                        [(ngModel)]="createSpace.tue_closing_time"
                                                                        name="tue_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(4, 'close', $event)"
                                                                        [disabled]="is_tuesday_closed && createSpace.tue_opening_time == undefined">
                                                                    </ejs-timepicker>
                                                                </div>
                                                            </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Wednesday</label>
                                                                    <span *ngIf="isWednesdayClosed" class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_wednesday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(5)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isWednesdayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isWednesdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker5 (click)="picker5.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(5,'start', $event)"
                                                                        [(ngModel)]="createSpace.wed_opening_time"
                                                                        name="wed_opening_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(5, 'open', $event)"
                                                                        [disabled]="is_wednesday_closed">
                                                                    </ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isWednesdayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isWednesdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker6 (click)="picker6.show()"
                                                                        #timepicker3
                                                                        (ngModelChange)="getTimeValue(5,'end', $event)"
                                                                        [(ngModel)]="createSpace.wed_closing_time"
                                                                        name="wed_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(5, 'close', $event)"
                                                                        [disabled]="is_wednesday_closed || createSpace.wed_opening_time == undefined">
                                                                    </ejs-timepicker>
                                                                </div>
                                                            </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Thursday</label>
                                                                    <span *ngIf="isThursdayClosed" class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_thursday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(6)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isThursdayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isThursdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker7 (click)="picker7.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(6,'start', $event)"
                                                                        [(ngModel)]="createSpace.thu_opening_time"
                                                                        name="thu_opening_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(6, 'open', $event)"
                                                                        [disabled]="is_thursday_closed">
                                                                    </ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isThursdayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isThursdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker8 (click)="picker8.show()"
                                                                        #timepicker4
                                                                        (ngModelChange)="getTimeValue(6,'end', $event)"
                                                                        [(ngModel)]="createSpace.thu_closing_time"
                                                                        name="thu_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(6, 'close', $event)"
                                                                        [disabled]="is_thursday_closed || createSpace.thu_opening_time == undefined">
                                                                    </ejs-timepicker>
                                                                </div>
                                                            </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Friday</label>
                                                                    <span *ngIf="isFridayClosed" class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_friday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(7)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isFridayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isFridayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker9 (click)="picker9.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(7,'start', $event)"
                                                                        [(ngModel)]="createSpace.fri_opening_time"
                                                                        name="fri_opening_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        [disabled]="is_friday_closed"
                                                                        (change)="onTimePickerChange(7, 'open', $event)">
                                                                    </ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isFridayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isFridayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker10 (click)="picker10.show()"
                                                                        #timepicker5
                                                                        (ngModelChange)="getTimeValue(7,'end', $event)"
                                                                        [(ngModel)]="createSpace.fri_closing_time"
                                                                        name="fri_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        (change)="onTimePickerChange(7, 'close', $event)"
                                                                        [disabled]="is_friday_closed || createSpace.fri_opening_time == undefined">
                                                                    </ejs-timepicker>
                                                                </div>
                                                            </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Saturday</label>
                                                                    <span *ngIf="isSaturdayClosed" class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_saturday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(1)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isSaturdayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isSaturdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker11 (click)="picker11.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(1,'start', $event)"
                                                                        [(ngModel)]="createSpace.saturday_opening_time"
                                                                        name="saturday_open_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        [disabled]="is_saturday_closed"
                                                                        (change)="onTimePickerChange(1, 'open', $event)"></ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isSaturdayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isSaturdayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker12 (click)="picker12.show()"
                                                                        #timepicker6
                                                                        (ngModelChange)="getTimeValue(1,'end', $event)"
                                                                        [(ngModel)]="createSpace.saturday_closing_time"
                                                                        name="saturday_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        [disabled]="is_saturday_closed || createSpace.saturday_opening_time == undefined"
                                                                        (change)="onTimePickerChange(1, 'close', $event)"></ejs-timepicker>
                                                                </div>
                                                            </div>
    
                                                            <div class="mainDiv">
                                                                <div class="col-md-2">
                                                                    <label class="fix_spacing">Sunday</label>
                                                                    <span *ngIf="isSundayClosed" class="text-danger">*</span>

                                                                </div>
                                                                <div class="col-md-2">
                                                                    <mat-slide-toggle [checked]="!is_sunday_closed"
                                                                        name="check"
                                                                        (change)="satSunClosed(2)"></mat-slide-toggle>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isSundayClosed">
                                                                    <label>Opening Time</label>
                                                                    <span *ngIf="isSundayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker13 (click)="picker13.show()"
                                                                        [max]='maxTime'
                                                                        (ngModelChange)="getTimeValue(2,'start', $event)"
                                                                        [(ngModel)]="createSpace.sunday_opening_time"
                                                                        name="sunday_opening_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        [disabled]="is_sunday_closed"
                                                                        (change)="onTimePickerChange(2, 'open', $event)">
                                                                    </ejs-timepicker>
                                                                </div>
                                                                <div class="col-md-4" [class.hidden]="!isSundayClosed">
                                                                    <label>Closing Time</label>
                                                                    <span *ngIf="isSundayClosed" class="text-danger">*</span>
                                                                    <ejs-timepicker #picker14 (click)="picker14.show()"
                                                                        #timepicker7
                                                                        (ngModelChange)="getTimeValue(2,'end', $event)"
                                                                        [(ngModel)]="createSpace.sunday_closing_time"
                                                                        name="sunday_closing_time"
                                                                        class="form-control filter-input" name="open_time"
                                                                        [disabled]="is_sunday_closed && createSpace.sunday_opening_time == undefined"
                                                                        (change)="onTimePickerChange(2, 'close', $event)">
                                                                    </ejs-timepicker>
                                                                </div>
    
                                                </div>
                                              </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="7" class="w-100">
                                        <a ngbNavLink class="" style="text-transform:capitalize"><i class="fa fa-home me-2"></i> Pricing</a>
                                        <ng-template ngbNavContent *ngIf="createSpace.spacetypeValue == 'Short-Term'">
                                            <label class="required" class="fw-bold fs-5 mb-3">Pricing</label>    
                                            <div class="row g-3 align-items-start">
                                                <div class="col-md-12">      
                                                    <div class="col-md-12" *ngIf="createSpace.spacetypeValue == 'Short-Term' ">
                                                        <label for="">Select minimum hours<span class="text-danger">*</span></label>
                                                        <ng-select class="drop_down  filter-input" [(ngModel)]="createSpace.minimum_hours"
                                                                #minimum_hours="ngModel" [placeholder]="createSpace.minimum_hours ? '' : 'Select minimum hours'"
                                                                name="service" id="select-beast" required>
                                                                <ng-option value="" disabled="true">Select minimum hours</ng-option>
                                                                <ng-option value="30">30 min</ng-option>
                                                                <ng-option value="60">1 Hour</ng-option>
                                                                <ng-option value="90">1.5 Hour</ng-option>
                                                                <ng-option value="120">2 Hour</ng-option>
                                                                <ng-option value="150">2.5 Hour</ng-option>
                                                                <ng-option value="180">3 Hour</ng-option>
                                                                <ng-option value="210">3.5 Hour</ng-option>
                                                                <ng-option value="240">4 Hour</ng-option>
                                                                <ng-option value="270">4.5 Hour</ng-option>
                                                                <ng-option value="300">5 Hour</ng-option>
                                                                <ng-option value="330">5.5 Hour</ng-option>
                                                                <ng-option value="360">6 Hour</ng-option>
                                                                <ng-option value="390">6.5 Hour</ng-option>
                                                                <ng-option value="420">7 Hour</ng-option>
                                                                <ng-option value="450">7.5 Hour</ng-option>
                                                                <ng-option value="480">8 Hour</ng-option>
                                                                <ng-option value="510">8.5 Hour</ng-option>
                                                                <ng-option value="540">9 Hour</ng-option>
                                                                <ng-option value="570">9.5 Hour</ng-option>
                                                                <ng-option value="600">10 Hour</ng-option>
                                                                <ng-option value="630">10.5 Hour</ng-option>
                                                                <ng-option value="660">11 Hour</ng-option>
                                                                <ng-option value="690">11.5 Hour</ng-option>
                                                                <ng-option value="720">12 Hour</ng-option>
                                                                <ng-option value="750">12.5 Hour</ng-option>
                                                                <ng-option value="780">13 Hour</ng-option>
                                                                <ng-option value="810">13.5 Hour</ng-option>
                                                                <ng-option value="840">14 Hour</ng-option>
                                                                <ng-option value="870">14.5 Hour</ng-option>
                                                                <ng-option value="900">15 Hour</ng-option>
                                                                <ng-option value="930">15.5 Hour</ng-option>
                                                                <ng-option value="960">16 Hour</ng-option>
                                                                <ng-option value="990">16.5 Hour</ng-option>
                                                                <ng-option value="1020">17 Hour</ng-option>
                                                                <ng-option value="1050">17.5 Hour</ng-option>
                                                                <ng-option value="1080">18 Hour</ng-option>
                                                                <ng-option value="1110">18.5 Hour</ng-option>
                                                                <ng-option value="1140">19 Hour</ng-option>
                                                                <ng-option value="1170">19.5 Hour</ng-option>
                                                                <ng-option value="1200">20 Hour</ng-option>
                                                                <ng-option value="1230">20.5 Hour</ng-option>
                                                                <ng-option value="1260">21 Hour</ng-option>
                                                                <ng-option value="1290">21.5 Hour</ng-option>
                                                                <ng-option value="1320">22 Hour</ng-option>
                                                                <ng-option value="1350">22.5 Hour</ng-option>
                                                                <ng-option value="1380">23 Hour</ng-option>
                                                                <ng-option value="1410">23.5 Hour</ng-option>
                                                                <ng-option value="1440">24 Hour</ng-option>
                                                            </ng-select>
        
                                                        <div class="text-danger" *ngIf="minimum_hours.invalid && (minimum_hours.dirty || minimum_hours.touched)">please select minimum hours</div>
                                                    </div>                               
                                          <!-- <div class="coworking_space">
                                                <div class="fw-bold text-dark fs-6 pt-4">
                                                    Co-Working Space
                                                </div>
                                                
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Private Office</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Private Office price starts at" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Managed office</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Managed Office price starts at" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Dedicated Desk</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Dedicated Desk price starts at" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Flexible Desk</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Flexible Desk price starts at" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Virtual Office</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Virtual Office price starts at" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="mainDiv">
                                                    <div class="col-md-2">
                                                        <label class="fix_spacing">Day pass</label>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label class="custom-switch d-block ms-2">
                                                            <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                        <span class="price_input d-flex align-items-center h-100">
                                                            <input type="text" placeholder="Price (Per Guest)" class="form-control w-100 position-relative">
                                                            <button type="button" class="btn price_btn text-muted bg-light rounded">per day</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> -->
    
                                                <div class="col-12">
                                                    <div class="text-dark pt-4" style="font-size: 18px;font-weight: 600;">Choose Your Booking Preference<span class="text-danger">*</span> </div>
                                                    <div class="pt-1 pb-4" style="font-size: 15px;">
                                                        <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M235.4 172.2c0-11.4 9.3-19.9 20.5-19.9 11.4 0 20.7 8.5 20.7 19.9s-9.3 20-20.7 20c-11.2 0-20.5-8.6-20.5-20zm1.4 35.7H275V352h-38.2V207.9z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
                                                        Choose between Request To Book and Instant Book options. This is a very important choice and will affect how your space is booked. Select Instant Book only if you can update the availibilty of your space in real-time. 
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 mt-4">
                                                            <div>
                                                             <div class="form-check d-flex align-items-center">
                                                                <input (click)="toggleIsrequest()" [checked]="createSpace.isInstant === 0" value="1" class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                                <label class="form-check-label" for="flexRadioDefault1" style="font-size: 18px;">
                                                                    Request to Book
                                                                </label>
                                                             </div>
                                                                <div class="my-3 label_txt" style="font-size: 13px;"> 
                                                                    <svg class="me-1" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                    Select Request to Book option for spaces where real-time availability cannot be guaranteed and requires host approval. Guests will need to submit a booking request, which you will review and approve before finalizing the reservation.
                                                                    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea> -->
                                                                </div> 
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 mt-4">
                                                            <div>
                                                               <div class="form-check d-flex align-items-center">
                                                                 <input (click)="toggleIsInstant()" [checked]="createSpace.isInstant === 1" value="1" class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                                <label class="form-check-label" for="flexRadioDefault1" style="font-size: 18px;">
                                                                    Instant Book
                                                                </label>
                                                               </div>
                                                                <div class="my-3 label_txt" style="font-size: 13px;"> 
                                                                    <svg class="me-1" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                    Choose Instant Book option for spaces that can be booked instantly without needing host approval. Guests can secure their booking immediately once they complete the reservation process.
                                                                    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea> -->
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                </div>
    
                                                <div class="col-12 mt-5">
                                                    <div>
                                                            <label for="inputnumber"
                                                                class="form-label fw-bold" style="font-size: 18px;">Price<span class="text-danger">*</span> (in INR)</label>
                                                                <div class="my-3 label_txt" style="font-size: 13px;"> 
                                                                    <svg class="me-1" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                                    Enter the per hour pricing of your space. Do not include GST in the price here as it will be added automatically.
                                                                    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea> -->
                                                                </div> 
                                                        <div class="price_input d-flex align-items-center w-100 h-100">
                                                            <input required [(ngModel)]="createSpace.price" #price="ngModel"
                                                                onkeypress="return /[0-9 ]/.test(event.key)" type="tel"
                                                                id="inputnumber" class="form-control"
                                                                aria-describedby="passwordHelpInline" placeholder="eg12">
                                                            <span style="cursor: auto;"
                                                                class="btn price_btn text-muted bg-light rounded">Per
                                                                Hour</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="price.invalid && (price.dirty || price.touched)"
                                                        class="text-danger">
                                                        <div *ngIf="price.errors && price.errors['required']">
                                                            Price field is required.</div>
                                                    </div>
                                                </div>
    
                                                <div class="btn-list mt-5">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                       
                                        </ng-template>
                                        <ng-template ngbNavContent *ngIf="createSpace.spacetypeValue == 'Coworking'">
                                            <div class="row">
                                                <!-- <div class="coworking_space">
                                                    <div class="fw-bold text-dark fs-6 pt-4">
                                                        Co-Working Space
                                                    </div>
                                                    
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Private Office</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Private Office price starts at" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Managed office</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Managed Office price starts at" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Dedicated Desk</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Dedicated Desk price starts at" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Flexible Desk</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Flexible Desk price starts at" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Virtual Office</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Virtual Office price starts at" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per seat month</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mainDiv">
                                                        <div class="col-md-2">
                                                            <label class="fix_spacing">Day pass</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label class="custom-switch d-block ms-2">
                                                                <input [checked]="showParkingOptions" (change)="toggleParkingOptions($event)" type="checkbox" class="custom-switch-input">
                                                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-8" [class.hidden]="!isMondayClosed">
                                                            <span class="price_input d-flex align-items-center h-100">
                                                                <input type="text" placeholder="Price (Per Guest)" class="form-control w-100 position-relative">
                                                                <button type="button" class="btn price_btn text-muted bg-light rounded">per day</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <ng-container *ngIf="createSpace.spacetypeValue == 'Coworking'">
                                                    <div class="coworking_space w-100">
                                                        <label class="head_label mb-3 required">Pricing (in INR)<span class="text-danger">*</span></label>  
                                                        <div class="my-3 fs-6 label_txt"> 
                                                            <svg class="me-1" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                            Enter the starting price per seat / month of your coworking space. 
                                                        </div> 
                                                        <div class="mainDiv mt-5" *ngFor="let resource_type of resource_types">
                                                            <div class="col-md-2">
                                                                <label class="fix_spacing">{{resource_type.name}}</label>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label class="custom-switch d-block ms-2">
                                                                    <input type="checkbox" [id]="'res-check-'+resource_type.id"
                                                                    name="check" value=""
                                                                    [checked]="createSpace?.[resource_type.column_name] ?? ''"
                                                                    (change)="setMinimumResourcePriceVisible(resource_type.id, $event)" class="custom-switch-input">
                                                                    <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <span class="price_input d-flex align-items-center h-100">
                                                                    <input type="tel" 
                                                                    onkeypress="return /[0-9 ]/i.test(event.key)" class="form-control filter-input"
                                                                    [id]='"res-" + resource_type.id'
                                                                    [(ngModel)]="createSpace[resource_type.column_name]"
                                                                    placeholder='Enter starting price of {{resource_type.name}}'
                                                                    [disabled]="!createSpace?.[resource_type.column_name] ?? ''"
                                                                     class="form-control w-100 position-relative">
                                                                    <span style="cursor: auto;" class="btn price_btn text-muted bg-light rounded" *ngIf="resource_type.id!==8 && resource_type.id!==5">per seat / month</span>
                                                                    <span style="cursor: auto;" class="btn price_btn text-muted bg-light rounded" *ngIf="resource_type.id==5">per month</span>
                                                                    <span style="cursor: auto;" class="btn price_btn text-muted bg-light rounded" *ngIf="resource_type.id==8">per day</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- <div class="col-md-12">
                                                    <label>Select available resources</label>
                                                    <div class="form-group" *ngIf="createSpace.spacetypeValue == 'Coworking'">
                                                        <div class="filter-checkbox">
                                                            <span *ngFor="let resource_type of resource_types" class="d-inline-flex">
                                                                <input type="checkbox" [id]="'res-check-'+resource_type.id"
                                                                    name="check" value=""
                                                                    [checked]="createSpace?.[resource_type.column_name] ?? ''"
                                                                    (change)="setMinimumResourcePriceVisible(resource_type.id, $event)">
                                                                <label [for]="'res-check-'+resource_type.id"
                                                                    class="amenities ms-1 me-3">{{resource_type.name}}</label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> -->
    
    
                                                    <!-- <ng-container *ngIf="createSpace.spacetypeValue == 'Coworking'">
                                                    <div class="col-md-6" *ngFor="let resource_type of resource_types">
                                                        <div class="form-group" *ngIf="resource_type.id!=8">
                                                            <label>{{resource_type.name}} price starts at (per seat /month)</label>
                                                            <input type="tel" class="form-control filter-input"
                                                                [id]='"res-" + resource_type.id'
                                                                [(ngModel)]="createSpace[resource_type.column_name]"
                                                                placeholder='Enter starting price of {{resource_type.name}}'
                                                                [disabled]="!createSpace?.[resource_type.column_name] ?? ''">
                                                        </div>
                                                        <div class="form-group" *ngIf="resource_type.id==8">
                                                            <div class="row mx-0">
                                                                <div class="col-2">
                                                                    <div class="text-muted"> Duration </div>
                                                                    <div class="mt-2 text-muted px-4 py-2 bg-light rounded border border-2 text-center"> Daily</div>
                                                                </div>
                                                                <div class="col-10">
                                                                    <div class="text-muted">Price (Per Guest)</div>
                                                                    <div class="row border border-2 mt-2 ">
                                                                        <div class="col-1">
                                                                            <label for="inputnumber"
                                                                                class="col-form-label fw-bold">INR</label>
                                                                        </div>
                                                                        <div class="col-11 pe-0">
                                                                            <input [(ngModel)]="createSpace.price"
                                                                                #price="ngModel"
                                                                                [disabled]="!createSpace?.[resource_type.column_name] ?? ''"
                                                                                onkeypress="return /[0-9 ]/.test(event.key)"
                                                                                type="tel" [id]='"res-8"'
                                                                                class="form-control border-0"
                                                                                aria-describedby="passwordHelpInline"
                                                                                placeholder="eg12">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                                <div class="btn-list">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbNavContent *ngIf="createSpace.spacetypeValue == 'Long-Term'">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label  class="fw-bold fs-5 mb-3 required">Pricing (in INR)<span class="text-danger">*</span> </label>  
                                                    <div class="form-group">
                                                        <label>What is your expected monthly rental? <span class="text-danger">*</span></label>
                                                        <input maxlength="8" required [(ngModel)]="createSpace.price" #price="ngModel"
                                                            onkeypress="return /[0-9]/.test(event.key)" (keyup)="calcualteSqftPrice()" type="text"
                                                            name="priceconfig" class="form-control filter-input"
                                                            placeholder="What is your expected monthly rental?">
    
                                                            <div *ngIf="price.invalid && (price.dirty || price.touched)"
                                                                class="text-danger">
                                                                <div *ngIf="price.errors && price.errors['required']">
                                                                    Price field is required.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>How many months of security deposit are you
                                                                expecting? <span class="text-danger">*</span> </label>
                                                            <input maxlength="2" required
                                                                [(ngModel)]="createSpace.securityDeposit"
                                                                #securityDeposit="ngModel"
                                                                onkeypress="return /[0-9 ]/i.test(event.key)" type="tel"
                                                                name="priceconfig" class="form-control filter-input"
                                                                placeholder="How many months of security deposit are you expecting?">
    
                                                            <div *ngIf="securityDeposit.invalid && (securityDeposit.dirty || securityDeposit.touched)"
                                                                class="text-danger">
                                                                <div
                                                                    *ngIf="securityDeposit.errors && securityDeposit.errors['required']">
                                                                    Security deposit field is required.</div>
                                                            </div>
    
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <!-- <input type="checkbox" (change)="toggleNegotiable($event)" [(ngModel)]="createSpace.negociable_price">
                                                        <label>Do you want it to be negotiable? </label> -->
                                                        <label class="custom-switch d-flex align-items-center">
                                                            <input type="checkbox" (change)="toggleNegotiable($event)" [(ngModel)]="createSpace.negociable_price" class="custom-switch-input">
                                                            <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                              <span class="fs-6 text-dark ms-2">Is it negotiable? </span>
                                                        </label>
                                                    </div>
                                                </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                 <div class="col-md-6 pe-md-0">
                                                    <div class="form-group text-center border border-primary px-2 py-4">
                                                        <span class="fs-6">Price / sqft (carpet) <b>{{createSpace.spaceCarpetAreaPrice | number:'1.0-0'}}</b></span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 ps-md-0">
                                                    <div class="form-group text-center border border-primary px-2 py-4">
                                                        <span class="fs-6">Price / sqft (built-up) <b>{{createSpace.spaceBuiltUpAreaPrice | number:'1.0-0'}}</b></span>
                                                    </div>
                                                </div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="8"
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i> Add on services</a>
                                        <ng-template ngbNavContent>
                                            <!-- Loop to display additional rows if present -->
                                            <label class="head_label required">Add on services</label>
                                            <p class="label_txt">List any extra services available at an additional cost, such as a photographer, catering, or equipment rentals. These services are not included in the base price of your space.</p>
                                            <div
                                                *ngFor="let details of spaceServiceDetailsArray.slice(0); let i = index">
                                                <div class="row align-items-start mt-5">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="required">Service name</label>

                                                            <!-- <ng-select [(ngModel)]="details.serviceName"
                                                            #serviceName="ngModel" name="service" id="select-beast"
                                                            required class="drop_down">
                                                            <ng-option value="" disabled="true">Select your
                                                                service</ng-option>
                                                            <ng-option value="Professional Photographer">Professional
                                                                Photographer</ng-option>
                                                            <ng-option value="Photography Equipment">Photography
                                                                Equipment</ng-option>
                                                            <ng-option value="Speaker">Speaker</ng-option>
                                                        </ng-select> -->

                                                            <input onkeypress="return /[a-zA-Z ]/.test(event.key)"
                                                                type="text" [(ngModel)]="details.serviceName"
                                                                #serviceName="ngModel" class="form-control filter-input"
                                                                placeholder="Enter service name">

                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Enter service charge</label>
                                                            <input onkeypress="return /[0-9]/.test(event.key)"
                                                                type="text" [(ngModel)]="details.servicePrice"
                                                                #servicePrice="ngModel"
                                                                class="form-control filter-input"
                                                                placeholder="Enter service charge">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Service charge type</label>
                                                            <ng-select [(ngModel)]="details.servicePriceType"
                                                                name="service_charge" id="select-beast" required
                                                                class="drop_down form-control filter-input">
                                                                <ng-option value="" disabled="true">Select service
                                                                    charge
                                                                    type</ng-option>
                                                                <ng-option value="Hour">Per hour</ng-option>
                                                                <ng-option value="Flat Fee">Flat fee</ng-option>
                                                                <ng-option value="Per person">Per person</ng-option>
                                                            </ng-select>
                                                            <!-- <input onkeypress="return /[a-zA-Z ]/.test(event.key)"
                                                            type="text" [(ngModel)]="details.servicePriceType"
                                                            #servicePriceType="ngModel"
                                                            class="form-control filter-input"
                                                            placeholder="Enter service charge type"> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <button class="btn btn-danger mar-top-20 removeContent"
                                                            (click)="removeSpaceDetails(details)">Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn bg-white mar-top-20"
                                                style="border:1px solid #f76900;color:#f76900;margin-bottom: 10px;"
                                                (click)="addSpaceDetails()"><i class="fa fa-plus" style="color:#f76900"
                                                    aria-hidden="true"></i> ADD MORE</button>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                              style="float: right;" (click)="onNextButtonClick()">Next</a> -->
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="9"
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i> Cancellation Policy</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label lass="head_label required">Choose your Cancellation
                                                        Policy</label>
                                                        <div class="pt-2 pb-4" style="font-size: 15px;">
                                                            <svg class="me-2" stroke="currentColor" fill="#f76900" stroke-width="0" viewBox="0 0 352 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                                            Providing cancellation flexibity to users will maximise your booking opportunities.
                                                        </div>
                                                    <div class="row my-5 g-4">
                                                        <div class="col-md-4">
                                                            <div class="radioContent">
                                                                <div class="d-flex align-items-center"
                                                                    style="min-height:90px">
                                                                    <span class="">
                                                                        <input class="align-middle" type="radio" id="Flexible" name="options"
                                                                            value="FLEXIBLE" [attr.checked]="createSpace.cancellationPolicy === 'Flexible' ? true : false"
                                                                            [(ngModel)]="createSpace.cancellationPolicy">
                                                                    </span>
                                                                    <label for="Flexible" class="heading"
                                                                        style="font-size: 16px;">{{flexibleData?.policyName}}
                                                                        <span
                                                                            *ngIf="flexibleData?.policyName == 'Cancellable up to 24 Hours Before Event' "
                                                                            class="in_show position-relative">
                                                                            (Recommended)
                                                                            <!-- <span class="inhsow_box position-absolute text-dark">
                                                                .Providing cancellation flexibity to users will maximise your booking opportunities
                                                               </span> -->
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div class="mt-3 ps-3 descriptionContaint">
                                                                    <p>{{flexibleData?.FlexibleDescriptionBefore24}}
                                                                    </p>
                                                                    <p>{{flexibleData?.FlexibleDescriptionAfter24}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="radioContent">
                                                                <div class="d-flex align-items-center"
                                                                    style="min-height:90px">
                                                                    <span class="">
                                                                        <input class="align-middle" type="radio" id="Moderate" name="options"
                                                                            value="MODERATE"
                                                                            [(ngModel)]="createSpace.cancellationPolicy">
                                                                    </span>
                                                                    <label for="Moderate" class="heading"
                                                                        style="font-size: 16px;">{{moderateData?.policyName}}</label>
                                                                </div>
                                                                <div class="mt-3 ps-3 descriptionContaint">
                                                                    <p>{{moderateData?.ModerateDescriptionBefore3}} </p>
                                                                    <p>{{moderateData?.ModerateDescriptionAfter3}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="radioContent">
                                                                <div class="d-flex align-items-center"
                                                                    style="min-height:90px">
                                                                    <span class="">
                                                                        <input class="align-middle" type="radio" id="Strict" name="options"
                                                                            value="STRICT"
                                                                            [(ngModel)]="createSpace.cancellationPolicy">
                                                                    </span>
                                                                    <label for="Strict" class="heading"
                                                                        style="font-size: 16px;">{{strictData?.policyName}}</label>
                                                                </div>
                                                                <div class="mt-3 ps-3 descriptionContaint">
                                                                    <p>{{strictData?.StrictDescriptionBefore7}} </p>
                                                                    <p>{{strictData?.StrictDescriptionAfter7}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-primary-light"
                                                (click)="onPreviousButtonClick()">Previous</a>
                                            <a href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                          style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="10">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>Upload Photos</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <label class="head_label required" style="width: 100%">Upload photos of your
                                                    space (*upload at least 5 images)<span class="text-danger">*</span></label>
                                                    <p class="label_txt">Photos are the first thing that guests will see. We recommend adding 10 or more high quality photos.</p>
                                                    <ul class=" mt-1 abt_space_list mb-3">
                                                        <div class="text-dark fs-6 fw-bold mb-1">Photo requirements:</div>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Recommended resolution - 1200x800 pixels</li>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>File Types: JPG/JPEG/PNG</li>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Max File Size: 10MB</li>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Horizontal orientation - No vertical photos</li>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Clean Photos - No photos with business name, logo, contact information etc.</li>
                                                        <li class=""><i class="fa fa-circle me-1 text-dark" aria-hidden="true"></i>Misc. - No collages, screenshots, or watermarks</li>
                                                    </ul>
                                                <div class="form-group">
                                                    <form class="photo-upload">
                                                        <div class="form-group">
                                                            <div class="add-listing__input-file-box">
                                                                <div class="col-md-8">
                                                                    <input type="file" (change)="onFileSelected($event)"
                                                                        multiple #fileInput style="display: none;" accept=".jpeg, .jpg, .png" />

                                                                    <div class="upload_bx" (click)="fileInput.click()"
                                                                        (dragover)="onDragOver($event)"
                                                                        (drop)="onDrop($event)">
                                                                        <div class="upload_iocn d-block text-center">
                                                                            <svg stroke="currentColor" fill="#a2a1a1"
                                                                                stroke-width="0" viewBox="0 0 24 24"
                                                                                height="40px" width="40px"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill="none" d="M0 0h24v24H0V0z">
                                                                                </path>
                                                                                <path
                                                                                    d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z">
                                                                                </path>
                                                                            </svg>
                                                                        </div>
                                                                        <span style="color: #a2a1a1;" class="fs-6">Add/Upload Images</span>
                                                                        <span style="color: #a2a1a1;" class="fs-6">*Please upload at least 5 images</span>
                                                                    </div>
                                                                    <div class="pt-1 d-flex align-items-center"
                                                                        style="font-size: 14px;">
                                                                        <svg stroke="currentColor" fill="#f76900"
                                                                            stroke-width="0" viewBox="0 0 352 512"
                                                                            height="30px" width="30px"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z">
                                                                            </path>
                                                                        </svg>
                                                                        <div class="ms-2">
                                                                            Drag and drop your photos to change the order. Your first photo is what your guests will see when browsing so make sure it represents your space.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <!-- <div  cdkDropListGroup>
                                                    <div *ngIf="filesToUpload?.length"
                                                        class="example-container my-5">
                                                    <div cdkDropList (cdkDropListEntered)="onDropListEnteredAdd($event)" (cdkDropListDropped)="onDropListDroppedAdd()"></div>
                                                        <div 
                                                        cdkDropList (cdkDropListEntered)="onDropListEnteredAdd($event)"
                                                        (cdkDropListDropped)="onDropListDroppedAdd()" class=" image-preview"
                                                            *ngFor="let file of filesToUpload;let i =index">
                                                            <div *ngIf="i===0 && !createSpace.images?.length" class="text-center">Cover Image</div>
                                                            <div cdkDrag class="position-relative p-2">
                                                            <img [src]="getImageUrl(file)" alt="Selected Image" [width]="i===0 && !createSpace.images?.length ?  300 : 150">
                                                          
                                                                <i class="fa fa-trash fa-xl text-danger position-absolute cursor-pointer" (click)="removeFile(file)"></i>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    </div> -->
                                                    <!-- <div *ngIf="fileDuringUpdate?.length>0"
                                                        class="selected-images row my-5" cdkDropList
                                                        (cdkDropListDropped)="drop($event)">
                                                        <h1>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur, quasi.</h1>
                                                        <div class="col-md-2 col-4 image-preview position-relative"
                                                            *ngFor="let file of fileDuringUpdate" cdkDrag>
                                                            <img [src]="getImageUrl(file)" alt="Selected Image">
                                                            <div>{{ file.name }}</div>
                                                            <div>{{ getSizeInMb(file.size) }} MB</div>
                                                            <span class="ion-close position-absolute"
                                                                (click)="removeFile(file)"></span>
                                                        </div>
                                                    </div> -->
                                                    <!-- <hr>
                                                    <div class="add-btn"
                                                        *ngIf="filesToUpload && filesToUpload?.length > 0">
                                                        <button (click)="uploadImages();"
                                                            [disabled]="is_add_button_disabled"
                                                            class="btn btn-primary mar-top-20">Add
                                                            Images</button>
                                                    </div> -->
                                                </div>
                                                <!-- start -->
                                                <div *ngIf="fileTypeError" class="error-message">
                                                    <p class="text-danger">Please select a valid image file.</p>
                                                </div>
                                                <div *ngIf="fileSizeError" class="error-message">
                                                    <p class="text-danger">File size exceeds 10 MB. Please select a smaller file.</p>
                                                </div>
                                                <!-- <div class="error-message" *ngIf="!fileSizeError && !fileTypeError">
                                                    <p class="text-danger">Please upload image in JPEG/JPG or PNG format, up to 10 MB.</p>
                                                </div> -->
                                                <!-- <div class="example-container" cdkDropListGroup>
                                                    <div cdkDropList (cdkDropListEntered)="onDropListEntered($event)"
                                                        (cdkDropListDropped)="onDropListDropped()"></div>
                                                    <div cdkDropList (cdkDropListEntered)="onDropListEntered($event)"
                                                        (cdkDropListDropped)="onDropListDropped()"
                                                        *ngFor="let item of createSpace.images;let i =index;">
                                                        <div *ngIf="i===0" class="text-center">Cover Image</div>
                                                        <div cdkDrag class="position-relative p-3">
                                                            <img class="border" [src]="image_base_url + '/' + item"
                                                                alt="" [width]="i===0 ?  360 : 180">
                                                            <i class="fa fa-trash fa-xl text-danger position-absolute cursor-pointer"
                                                                (click)="deleteImage(item)"></i>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                
                                                <!-- <div *ngFor="let item of combinedImages; let i = index" cdkDropListGroup>
                                                    <div *ngIf="i === 0" class="text-center">Cover Image</div>
                                                    <div cdkDrag class="position-relative p-3">
                                                        <img [src]="item.url" [width]="i === 0 ? 360 : 180" class="border" alt="Image">
                                                        <i class="fa fa-trash fa-xl text-danger position-absolute cursor-pointer" (click)="deleteImages(item)"></i>
                                                    </div>
                                                </div> -->
                                                <div class="example-container" cdkDropListGroup>
                                                    <!-- Drop List for combinedImages -->
                                                    <div cdkDropList (cdkDropListEntered)="onDropListEntered($event)" (cdkDropListDropped)="onDropListDropped()"></div>
                                                    <div cdkDropList (cdkDropListEntered)="onDropListEntered($event)" (cdkDropListDropped)="onDropListDropped()" *ngFor="let item of combinedImages; let i = index">
                                                            <div *ngIf="i === 0" class="text-center">Cover Image</div>
                                                            <div cdkDrag class="position-relative p-3">
                                                                <img [src]="item.url" [width]="i === 0 ? 360 : 180" class="border" alt="Image">
                                                                <i class="fa fa-trash fa-xl text-danger position-absolute cursor-pointer"
                                                                    (click)="deleteImages(item)"></i>
                                                            </div>
                                                    </div>
                                                </div>



                                                <!-- end -->
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Youtube Url</label>
                                                        <input type="text" name="youtube_url"
                                                            [(ngModel)]="createSpace.youtube_url"
                                                            class="form-control filter-input"
                                                            placeholder="Enter youtube url">
                                                    </div>

                                                </div>
                                                <div class="col-md-12">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>


                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                          style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="11">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>Additional Contact
                                            Details</a>
                                            
                                        <ng-template ngbNavContent>
                                            <div class="row content">
                                                <div class="col-md-12">
                                                    <label class="head_label required">Additional Contact
                                                        Details</label>
                                                    <div class="text-dark list_head mt-4">Your Notifications</div>
                                                    <div class="form-group mt-2">
                                                        <label class="mb-2">When someone contacts you through your cowork page, which email address do you want your messages, reservation requests, & tour bookings sent to?  </label>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <label class="mb-1">Notification email 1 <span
                                                                        class="text-danger">*</span></label>
                                                                <input [(ngModel)]="createSpace.notificationemail"
                                                                    name="notificationemail"
                                                                    #notificationemail="ngModel" type="email"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter your contact email address"
                                                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                                                                    required [autocomplete]="true">

                                                                <div *ngIf="spaceSubmit || notificationemail.invalid && (notificationemail.dirty || notificationemail.touched)"
                                                                    class="text-danger">
                                                                    <div
                                                                        *ngIf="notificationemail.errors && notificationemail.errors?.['required']">
                                                                        Notification email field is required.
                                                                    </div>
                                                                    <div
                                                                        *ngIf="notificationemail.errors && notificationemail.errors?.['pattern']">
                                                                        Please enter a valid email address.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label class="mb-1"> Notification email 2</label>
                                                                <input name="notificationemail"
                                                                    [(ngModel)]="createSpace.notificationemail2"
                                                                    #notificationemail2="ngModel" type="email"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter your notification email address 2"
                                                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}">

                                                                <div *ngIf="spaceSubmit || notificationemail2.invalid && (notificationemail2.dirty || notificationemail2.touched)"
                                                                    class="text-danger">
                                                                    <!-- <div *ngIf="notificationphone.errors && notificationphone.errors?.['required']">
                                                                        Notification phone number field is required.
                                                                    </div> -->
                                                                    <div
                                                                        *ngIf="notificationemail2.errors && notificationemail2.errors?.['pattern']">
                                                                        Please enter a valid email address.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                    <label class="pb-2">When someone contacts you through your page,
                                                        which phone number do you want your messages, sent to? </label>
                                                    <div class="form-group">
                                                        <label class="mb-1">Mobile Number</label>
                                                        <input [(ngModel)]="createSpace.notificationphone"
                                                            name="notificationphone" #notificationphone="ngModel"
                                                            type="tel" min="10" max="10"
                                                            class="form-control filter-input"
                                                            placeholder="Enter your contact number" pattern="[0-9]{10}" [autocomplete]="true">

                                                        <div *ngIf="spaceSubmit || notificationphone.invalid && (notificationphone.dirty || notificationphone.touched)"
                                                            class="text-danger">
                                                            <!-- <div *ngIf="notificationphone.errors && notificationphone.errors?.['required']">
                                                             Notification phone number field is required.
                                                         </div> -->
                                                            <div
                                                                *ngIf="notificationphone.errors && notificationphone.errors?.['pattern']">
                                                                Please enter a valid phone number.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="text-dark list_head my-2">Your Contact Details
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Contact Person Name<span
                                                                        class="text-danger">*</span></label>
                                                                <input onkeypress="return /[a-zA-Z ]/.test(event.key)"
                                                                    type="text" name="contact_name"
                                                                    [(ngModel)]="createSpace.contactName"
                                                                    #contactName="ngModel"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter Contact Person Name" required>

                                                                <div *ngIf="spaceSubmit || contactName.invalid && (contactName.dirty || contactName.touched)"
                                                                    class="text-danger">
                                                                    <div
                                                                        *ngIf="contactName.errors && contactName.errors['required']">
                                                                        Contact name field is required.</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Contact Person Email<span
                                                                        class="text-danger">*</span></label>
                                                                <input type="text" name="contact_email"
                                                                    [(ngModel)]="createSpace.contactemail"
                                                                    #contactemail="ngModel"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter Contact Person Email"
                                                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                                                                    required>

                                                                <div *ngIf="spaceSubmit || contactemail.invalid && (contactemail.dirty || contactemail.touched)"
                                                                    class="text-danger">
                                                                    <div
                                                                        *ngIf="contactemail.errors && contactemail.errors['required']">
                                                                        Contact person email field is required.</div>

                                                                    <div
                                                                        *ngIf="contactemail.errors && contactemail.errors?.['pattern']">
                                                                        Please enter a valid email address.
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Contact Person Mobile<span
                                                                        class="text-danger">*</span></label>
                                                                <input onkeypress="return /[0-9]/i.test(event.key)"
                                                                    minlength="10" maxlength="10" type="tel"
                                                                    name="contact_mobile"
                                                                    [(ngModel)]="createSpace.contactmobile"
                                                                    #contactmobile="ngModel"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter Contact Person Mobile"
                                                                    pattern="[0-9]{10}" required [autocomplete]="true">

                                                                <div *ngIf="spaceSubmit || contactmobile.invalid && (contactmobile.dirty || contactmobile.touched)"
                                                                    class="text-danger">
                                                                    <div *ngIf="contactmobile.errors">
                                                                        <div *ngIf="contactmobile.errors?.['required']">
                                                                            Contact person mobile field is required.
                                                                        </div>
                                                                        <div *ngIf="contactmobile.errors?.['pattern']">
                                                                            Please enter a valid 10-digit mobile number.
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Alternate Person Contact Name</label>
                                                                <input onkeypress="return /[a-zA-Z ]/.test(event.key)"
                                                                    type="text" name="alternate_contact_name"
                                                                    [(ngModel)]="createSpace.alternate_contact_name"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter alternate person contact name">

                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Alternate Person Contact Email</label>
                                                                <input type="text" name="alternate_contact_email"
                                                                    [(ngModel)]="createSpace.alternate_contact_email"
                                                                    #alternate_contact_email="ngModel"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter alternate person contact email"
                                                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}">

                                                                <div *ngIf="alternate_contact_email.dirty || alternate_contact_email.touched"
                                                                    class="text-danger">

                                                                    <div
                                                                        *ngIf="alternate_contact_email.errors && alternate_contact_email.errors?.['pattern']">
                                                                        Please enter a valid email address.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Alternate Person Contact Mobile</label>
                                                                <input onkeypress="return /[0-9]/i.test(event.key)"
                                                                    maxlength="10" minlength="10" type="tel"
                                                                    name="alternate_contact_mobile"
                                                                    [(ngModel)]="createSpace.alternate_contact_mobile"
                                                                    #alternate_contact_mobile="ngModel"
                                                                    class="form-control filter-input"
                                                                    placeholder="Enter alternate person contact mobile"
                                                                    pattern="[0-9]{10}" [autocomplete]="true"> 

                                                                <div *ngIf="alternate_contact_mobile.dirty || alternate_contact_mobile.touched"
                                                                    class="text-danger">

                                                                    <div
                                                                        *ngIf="alternate_contact_mobile.errors && alternate_contact_mobile.errors?.['pattern']">
                                                                        Please enter a valid 10-digit mobile number.
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                            <!-- <a *ngIf="!is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Submit and Create Space</a>
                                        <a *ngIf="is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update Space details</a> -->
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                          style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="12"
                                        *ngIf="createSpace.spacetypeValue !== 'Long-Term'">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>Billing Details</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-md-12">
                                                <label class="head_label required">Billing Details</label>
                                            </div>
                                            <div class="col-lg-12 col-md-12"
                                                *ngIf="createSpace.spacetypeValue !== 'Long-Term'">
                                                <div class="form-group mt-3">
                                                    <div class="d-flex align-items-center">
                                                        <label class="custom-switch">
                                                            <input type="checkbox"
                                                                (change)="fetchDetailsFromProfile($event)"
                                                                [(ngModel)]="createSpace.isSameBillingAddress"
                                                                #isBillingAddress="ngModel"
                                                                class="custom-switch-input ">
                                                            <span
                                                                class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span>
                                                        </label>
                                                        <label for="exampleInputEmail1" class="ms-2">Do you want to
                                                            fetch your details from the profile section?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Billing Country <span class="text-danger">*</span></label>
                                                    <ng-select class="drop_down form-control filter-input"
                                                        (change)="onBillingCOuntryChange();this.createSpace.billing_state='';this.createSpace.billing_contact_city_name='';"
                                                        [(ngModel)]="createSpace.billing_country"
                                                        #billing_country="ngModel"
                                                        [placeholder]="createSpace.billing_country ? '' : 'Select Country'"
                                                        [items]="biilingCountryData" bindLabel="country_name">
                                                    </ng-select>

                                                    <div *ngIf="billing_country.invalid && (billing_country.dirty || billing_country.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="billing_country.errors && billing_country.errors['required']">
                                                            Billing country field is required.</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Billing state <span class="text-danger">*</span></label>
                                                    <ng-select class="drop_down form-control filter-input"
                                                        (change)="onBillingStateChange();this.createSpace.billing_contact_city_name='';"
                                                        [(ngModel)]="createSpace.billing_state" #billing_state="ngModel"
                                                        [placeholder]="createSpace.billing_state ? '' : 'Select Billing State'"
                                                        [items]="billingState" bindLabel="name">
                                                    </ng-select>

                                                    <div *ngIf="billing_state.invalid && (billing_state.dirty || billing_state.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="billing_state.errors && billing_state.errors['required']">
                                                            Billing state field is required.</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Billing city <span class="text-danger">*</span></label>
                                                    <ng-select class="drop_down form-control filter-input" (change)="onBillingCityChange($event)"
                                                        [(ngModel)]="createSpace.billing_contact_city_name"
                                                        #billing_contact_city_name="ngModel"
                                                        [placeholder]="createSpace.billing_contact_city_name ? '' : 'Select Billing City'"
                                                        [items]="billingCityList" bindLabel="name">
                                                    </ng-select>

                                                    <div *ngIf="billing_contact_city_name.invalid && (billing_contact_city_name.dirty || billing_contact_city_name.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="billing_contact_city_name.errors && billing_contact_city_name.errors['required']">
                                                            Billing city field is required.</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Enter billing address line 1 <span
                                                            class="text-danger">*</span></label>
                                                    <input [(ngModel)]="createSpace.billingaddress"
                                                        #billingaddress="ngModel" type="text" name="address"
                                                        class="form-control filter-input"
                                                        placeholder="Enter your billing address" required>

                                                    <div *ngIf="billingaddress.invalid && (billingaddress.dirty || billingaddress.touched) && !createSpace.isSameBillingAddress"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="billingaddress.errors && billingaddress.errors['required']">
                                                            Billing address is required.</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Enter billing address line 2</label>
                                                    <input [(ngModel)]="createSpace.billing_address2"
                                                        #billing_address2="ngModel" type="text" name="address2"
                                                        class="form-control filter-input"
                                                        placeholder="Enter address line 2">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Enter billing zip/postal code <span
                                                            class="text-danger">*</span></label>
                                                    <input [(ngModel)]="createSpace.billing_zip_code"
                                                        #billing_zip_code="ngModel" minlength="6" maxlength="6"
                                                        onkeypress="return /[0-9 ]/i.test(event.key)" type="text"
                                                        name="zip_code" class="form-control filter-input"
                                                        placeholder="Enter billing Zip Code" required>

                                                    <div *ngIf="billing_zip_code.invalid && (billing_zip_code.dirty || billing_zip_code.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="billing_zip_code.errors && billing_zip_code.errors['required']">
                                                            Billing zip code is required.
                                                        </div>

                                                    </div>
                                                </div>
                                                </div>

                                          <div class="col-12">
                                            <a href="javascript:void(0)" class="btn btn-primary-light"
                                            (click)="onPreviousButtonClick()">Previous</a>
                                           <a href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onNextButtonClick()">Next</a>
                                          </div>
                                          </div>
                                            <!-- <a *ngIf="!is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Submit and Create Space</a>
                                        <a *ngIf="is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update Space details</a> -->
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li class="w-100" [ngbNavItem]="13">
                                        <a ngbNavLink style="text-transform:capitalize"><i class="fa fa-home me-2"></i>For Internal Purpose</a>
                                        <ng-template ngbNavContent>
                                            <label class="head_label required">For Internal Purpose</label>
                                            <div class="row content align-items-start mt-3">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Property Sourced by:</label>
                                                        <input [(ngModel)]="createSpace.property_sourced_by"
                                                            name="property_sourced_by" #property_sourced_by="ngModel"
                                                            type="text" class="form-control filter-input"
                                                            placeholder="Enter your source details" maxlength="60">

                                                        <!-- <div *ngIf="spaceSubmit || property_sourced_by.invalid && (property_sourced_by.dirty || property_sourced_by.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="property_sourced_by.errors && property_sourced_by.errors?.['required']">
                                                            Source details field is required.
                                                        </div> -->
                                                        <!-- <div
                                                            *ngIf="property_sourced_by.errors && property_sourced_by.errors?.['pattern']">
                                                            Please enter a valid email address.
                                                        </div> -->
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Sourcing Date</label>
                                                        <input name="sourcing_date"
                                                            [(ngModel)]="createSpace.sourcing_date"
                                                            #sourcing_date="ngModel" type="date"
                                                            class="form-control filter-input" placeholder="Enter Date">

                                                        <!-- <div *ngIf="spaceSubmit || sourcing_date.invalid && (sourcing_date.dirty || sourcing_date.touched)" 
                                                            class="text-danger">
                                                            <div *ngIf="sourcing_date.errors && sourcing_date.errors?.['required']">
                                                                Date field is required.
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Property current status </label>
                                                        <ng-select class="form-select drop_down form-control"
                                                            [placeholder]="createSpace.property_current_status ? '' : 'Select status'"
                                                            [(ngModel)]="createSpace.property_current_status"
                                                            (change)="getPropertyStatus($event)">
                                                            <ng-option *ngFor="let item of property_status"
                                                                [value]="item">
                                                                {{ item }}
                                                            </ng-option>
                                                        </ng-select>

                                                        <!-- <div *ngIf="spaceSubmit || property_status.invalid && (property_status.dirty || property_status.touched)" 
                                                          class="text-danger">
                                                         <div *ngIf="notificationphone.errors && notificationphone.errors?.['required']">
                                                             Notification phone number field is required.
                                                         </div>
                                                     </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Property Details Updated by</label>
                                                        <input [(ngModel)]="createSpace.property_details_updated_by"
                                                            name="property_details_updated_by"
                                                            #property_details_updated_by="ngModel" type="text"
                                                            class="form-control filter-input"
                                                            placeholder="Enter your property details" maxlength="60">

                                                        <!-- <div *ngIf="spaceSubmit || property_details_updated_by.invalid && (property_details_updated_by.dirty || property_details_updated_by.touched)"
                                                        class="text-danger">
                                                        <div
                                                            *ngIf="property_details_updated_by.errors && property_details_updated_by.errors?.['required']">
                                                            Property details field is required.
                                                        </div>
                                                    </div> -->
                                                    </div>
                                                </div>
                                                <div class="row mt-2 col-md-12 pe-0">
                                                    <div class="col-md-4"><a href="javascript:void(0)"
                                                            class="btn btn-primary-light"
                                                            (click)="onPreviousButtonClick()">Previous</a></div>
                                                    <div *ngIf="space_id" class="col-md-4 text-center">
                                                        <a (click)="openDeleteSpaceDialog(idForDeleteSpace,'delete')"
                                                            style="color: white !important;background:red !important;"
                                                            class="btn btn-primary-light">Delete Space</a>
                                                    </div>
                                                    <div [ngClass]="space_id ? 'col-md-4' :'col-md-8' " class="text-end pe-0">
                                                        <a *ngIf="!is_id_exist" href="javascript:void(0)" class="btn btn-primary-light " (click)="onSubmit()">Submit
                                                            and Create Space</a>
                                                        <a *ngIf="is_id_exist" href="javascript:void(0)"
                                                            class="btn btn-primary-light" (click)="onSubmit()">Update
                                                            Space details</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="is_id_exist" class="listing-button position-relative-con mt-3">
                                            <button (click)="openDeleteSpaceDialog(idForDeleteSpace)" class="btn v5"><i class="ion-android-delete"></i>
                                              Delete</button>
                                        </div> -->

                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                          style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8 panel-body tabs-menu-body pl-md-3 py-0">
                        <div [ngbNavOutlet]="nav3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>