import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { SpaceService } from 'src/app/services/space.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css'],
  providers: [DatePipe]
})
export class BookingsComponent {

  workSpace = [
    { workSpaceName: 'Coworking Space', typeOfSpace: "Coworking" },
    { workSpaceName: 'Managed Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Private Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Shared Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Virtual Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Coworking Café/Restaurant', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Shoot Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Recording Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Podcast Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Activity Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Turf', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Venue', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Party Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Banquet Hall', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Gallery', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Classroom', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Private Cabin', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Meeting Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Training Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Event Space', typeOfSpace: "Short-Term" },
  ]
  workSpaceNames: any = [];
  startDate: any;
  endDate: any;
  spaceType: any;
  allBookings: any = [];
  bookingType!: string;
  selectedSpaceName: string[] = [];
  selectedBookingType: string = 'allBooking';
  selectedStatus: any;
  bookingIdValueForSearch: any;
  hostSpaceName: any = [];
  spaceNames: any = [];
  host: any[] = [];
  selectedHost: any;
  selectHostValue: any;
  pageSize = 25; // Default page size
  currentPage = 0;
  paginatedBookings: any = [];


  constructor(private datePipe: DatePipe, private spaceService: SpaceService) { }

  ngOnInit(): void {
    this.getAllSpaceByHostId();
    this.getAllHostSpaces();
    this.getHost();
  }

  getHost() {
    this.spaceService.getHost().subscribe((res: any) => {
      for (let i = 0; i < res.data.host.length; i++) {
        this.host.push(res.data.host[i]);
      }
    })
  }

  hostValueChange(event: any) {
    this.selectHostValue = event;
    this.getWorkSpace();
    console.log(this.selectHostValue);
  }

  getWorkSpace() {
    this.spaceService.getAllActiveSpaceType(this.selectHostValue).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        this.workSpaceNames.push(res[i]);
      }
    });
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    if (this.startDate && this.endDate || !this.startDate && !this.endDate) {
      this.getAllHostSpaces();
    }
  }

  getAllSpaceByHostId() {
    this.spaceService.getAllSpaceNameByHostId().subscribe((res: any) => {
      this.hostSpaceName = res.data?.spaces;
      for (let i = 0; i < this.hostSpaceName.length; i++) {
        this.spaceNames.push(this.hostSpaceName[i]);
      }
    })
  }

  handleImageError(event: any) {
    const imgElement = event.target as HTMLImageElement
    imgElement.src = 'assets/images/details_placeholder_image.jpg';
    imgElement.alt = 'Failed to Load Image';
  }

  getAllHostSpaces() {
    let data = {
      // userId: this.userId,
      bookingType: this.selectedBookingType,
      spaceType: this.spaceType,
      startDate: this.startDate,
      endDate: this.endDate,
      bookingStatus: this.selectedStatus,
      bookingId: this.bookingIdValueForSearch,
      spaceId: this.selectedSpaceName,
      hostId: this.selectedHost
    };
    this.spaceService.getAllHostSpaces(data).subscribe((data: any) => {
      console.log(data);
      this.allBookings = data?.bookings;
      this.updatePaginatedBookings();
      console.log(this.allBookings)
    })
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.allBookings.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }

}
