<h1 class="text-center">LIST YOUR SPACE ON INDIA'S PREMIER COWORKING NETWORK.
  <br> MAXIMISE VISIBILITY AND GROW YOUR BUSINESS
</h1>
<h2 class="text-center mb-0">Choose A Plan That's Right For You</h2>
<h2 class="text-center">Join over 2000 Coworking Spaces Across India</h2>

<div class="row main_row">
  <div  class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 [ngClass]="plansList.length || 'invisible' " class="card-title">Active Subscription Packages</h3>
        <a (click)="navigateToList()" class="btn v3"><i class="ion-plus-round"></i>Create Package
        </a>
      </div>
      <div *ngIf="plansList.length" class="card-body">
        <div class="row" style="row-gap: 1rem;">
          <ng-container *ngFor="let list of plansList;let i=index">
            <div class="col-md-3">
              <div class="card plan_card h-100 border-0 sub_card">
                <div class="card-body p-0 bg-white" >
                  <div class="d-flex gap-1 flex-column px-3">
                    <div class="text-center d-block subs_title" [ngClass]="'color-' + i"
                    [ngStyle]="{'background-color': getColorClass(i)}" >
                      <h4 class="card-title title_font text-white mb-0">{{list.name | titlecase}}</h4>
                      <div class="price-value text-center px-5 text-white d-flex align-items-center justify-content-center">
                        <svg style="scale:.30;vertical-align: top;margin-right: -12px;margin-left: -14px" version="1.1"
                          xmlns="http://www.w3.org/2000/svg" width="42" height="60">
                          <path
                            d="M0 0 C13.86 0 27.72 0 42 0 C42 2.64 42 5.28 42 8 C38.04 8 34.08 8 30 8 C30.495 8.94875 30.99 9.8975 31.5 10.875 C33 14 33 14 33 16 C35.97 16 38.94 16 42 16 C42 18.97 42 21.94 42 25 C39.03 25 36.06 25 33 25 C32.6596875 26.5778125 32.6596875 26.5778125 32.3125 28.1875 C30.51419436 34.42497969 25.44689864 37.87093057 20 41 C18.68 41 17.36 41 16 41 C21.31429814 47.17252553 21.31429814 47.17252553 27 53 C25.70700306 55.88437779 24.34058637 57.87219421 22 60 C16.5852468 57.64346638 12.88182545 53.29027355 8.75 49.1875 C7.90953125 48.37990234 7.0690625 47.57230469 6.203125 46.74023438 C5.01203125 45.55977539 5.01203125 45.55977539 3.796875 44.35546875 C2.70391113 43.28268677 2.70391113 43.28268677 1.58886719 42.18823242 C-0.33321464 39.54108746 -0.49327943 38.20332474 0 35 C2.72728976 32.27271024 4.44519804 32.70583158 8.25 32.5625 C13.16548702 32.25290579 16.86582159 31.90509834 21 29 C21.66 28.01 22.32 27.02 23 26 C11.615 25.505 11.615 25.505 0 25 C0 22.03 0 19.06 0 16 C7.59 16 15.18 16 23 16 C18.57285488 10.4660686 17.1864717 9.37157976 10.44921875 8.5859375 C9.20785156 8.51632812 7.96648438 8.44671875 6.6875 8.375 C5.43324219 8.30023438 4.17898437 8.22546875 2.88671875 8.1484375 C1.93410156 8.09945313 0.98148437 8.05046875 0 8 C0 5.36 0 2.72 0 0 Z "
                            fill="#fff" transform="translate(0,0)" />
                        </svg>{{ list.showYearly ? list.yearlyPrice
                        : list.amount}} <small> / year</small> </div>
                    </div>
                    <!-- (change)="list.showYearly = !list.showYearly;" -->
                    <!-- <div class="text-end"><mat-slide-toggle onclick="return false" checked>{{
                        list.showYearly ? 'Yearly' : 'Monthly' }}</mat-slide-toggle></div> -->
                  
                    <div class="price-list">
                      <ul>
                        <li class="d-flex px-3" *ngFor="let list of list.features" [innerHTML]="list">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex rounded-0 px-3 justify-content-between align-items-center card-footer" 
                >
                  <mat-slide-toggle (click)="confirmation($event,list)" checked></mat-slide-toggle>
                  <button (click)="edit(list.id)" class="btn h-100  bg-transparent p-0"><i class="fa fa-edit fa-xl"></i></button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="deactivePackagesList.length" class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-center">
        <h3 class="card-title">De-Active Flexo Subscription Plan</h3>
      </div>
      <div class="card-body">
        <div class="row" style="row-gap: 1rem;">
          <ng-container *ngFor="let list of deactivePackagesList;let i =index">
            <div class="col-md-3">
              <div class="card plan_card active_card_plan h-100 border-0 sub_card">
                <div class="card-body p-0 bg-white">
                  <div class="d-flex gap-1 flex-column px-3">
                    <div class="text-center d-block subs_title color_accent2">
                      <h4 class="card-title title_font text-white m-0">{{list.name | titlecase}}</h4>
                      <div class="price-value text-center px-5 text-white d-flex align-items-center justify-content-center">
                        <svg style="scale:.30;vertical-align: top;margin-right: -12px;margin-left: -14px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                          width="42" height="60">
                          <path
                            d="M0 0 C13.86 0 27.72 0 42 0 C42 2.64 42 5.28 42 8 C38.04 8 34.08 8 30 8 C30.495 8.94875 30.99 9.8975 31.5 10.875 C33 14 33 14 33 16 C35.97 16 38.94 16 42 16 C42 18.97 42 21.94 42 25 C39.03 25 36.06 25 33 25 C32.6596875 26.5778125 32.6596875 26.5778125 32.3125 28.1875 C30.51419436 34.42497969 25.44689864 37.87093057 20 41 C18.68 41 17.36 41 16 41 C21.31429814 47.17252553 21.31429814 47.17252553 27 53 C25.70700306 55.88437779 24.34058637 57.87219421 22 60 C16.5852468 57.64346638 12.88182545 53.29027355 8.75 49.1875 C7.90953125 48.37990234 7.0690625 47.57230469 6.203125 46.74023438 C5.01203125 45.55977539 5.01203125 45.55977539 3.796875 44.35546875 C2.70391113 43.28268677 2.70391113 43.28268677 1.58886719 42.18823242 C-0.33321464 39.54108746 -0.49327943 38.20332474 0 35 C2.72728976 32.27271024 4.44519804 32.70583158 8.25 32.5625 C13.16548702 32.25290579 16.86582159 31.90509834 21 29 C21.66 28.01 22.32 27.02 23 26 C11.615 25.505 11.615 25.505 0 25 C0 22.03 0 19.06 0 16 C7.59 16 15.18 16 23 16 C18.57285488 10.4660686 17.1864717 9.37157976 10.44921875 8.5859375 C9.20785156 8.51632812 7.96648438 8.44671875 6.6875 8.375 C5.43324219 8.30023438 4.17898437 8.22546875 2.88671875 8.1484375 C1.93410156 8.09945313 0.98148437 8.05046875 0 8 C0 5.36 0 2.72 0 0 Z "
                            fill="#fff" transform="translate(0,0)" />
                        </svg>{{ list.showYearly ? list.yearlyPrice
                          : list.amount}} <small> / year</small></div>
                    </div>
                    <!-- (change)="list.showYearly = !list.showYearly;" -->
                    <!-- <div class="text-end"><mat-slide-toggle onclick="return false" checked>{{
                        list.showYearly ? 'Yearly' : 'Monthly' }}</mat-slide-toggle></div> -->
                   
                    <div class="price-list px-5">
                      <ul>
                        <li class="d-flex" *ngFor="let list of list.features" [innerHTML]="list">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex rounded-0 px-3 justify-content-between align-items-center card-footer"
                >
                  <mat-slide-toggle (click)="confirmation($event,list)"> </mat-slide-toggle>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>






