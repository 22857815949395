import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _moment from 'moment';
import { Moment } from 'moment';
import { CsvDownloadFileService } from 'src/app/services/csv-download.service';
import { SpaceService } from 'src/app/services/space.service';
const moment = _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-booking-report',
  templateUrl: './booking-report.component.html',
  styleUrls: ['./booking-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class BookingReportComponent implements OnInit {

  host: any[] = [];
  hostId: any;

  dataSource: any;
  displayedColumns: string[] = ['serial','id', 'createdAt', 'bookingId', 'hostName', 'spaceCategory', 'space', 'amount', 'Actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize!: MatPaginator;
  totalAmount: any;
  currentYear = new Date().getFullYear()
  currentMonth = String(new Date().getMonth() + 1)
  value = `${this.currentYear}-${this.currentMonth.padStart(2, "0") }`
  csvData: any;

  constructor(private downloadService: CsvDownloadFileService, private spaceService: SpaceService) { }


  ngOnInit(): void {
    this.getHost()
    this.getFilteredData()
  }


  resetMonth(){
    this.date.setValue(moment());
  }

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  getHost() {
    this.spaceService.getHost().subscribe((res: any) => {
      for (let i = 0; i < res.data.host.length; i++) {
        this.host.push(res.data.host[i]);
      }
    })
  }

  public downloadCSV() {
    return this.downloadService.downloadFile(
      this.csvData,
      [
        "id",
        "dateTime",
        "bookingId",
        "hostName",
        "spaceCategory",
        "spaceName",
        "amount",
      ],
      "bookingReport.csv"
    );
  }
  

  getFilteredData() {
    const month = String(this.date.value.month() + 1).padStart(2, "0")
    const year = this.date.value.year()
    this.spaceService.getFilteredBookingReport(this.hostId ?? '', month, year).then((res: any) => {
      if (res.success) {
        this.dataSource = new MatTableDataSource(res.bookings);
        this.csvData = res.bookings
        this.totalAmount = res.bookings.reduce((acc: any, val: any) => acc + val.amount, 0)
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }, 200);
      }
    })
  }

  


  applyFilter(value: any) {
    value = value.value.trim().toLowerCase();
    this.dataSource.filter = value;
  }

}

