<div>
  <div class="limiter">
    <div class="box" *ngIf="!is_logged_in || alert_message">
      <div *ngIf="!is_logged_in" style="color:red;font-size: 16px;
    padding: 9px;
    text-align: center;">
        {{alert_message}}
      </div>
      <div *ngIf="alert_success == 'warn'" style="color: red;
    font-size: 16px;
    padding: 9px;
    text-align: center;">*{{alert_message}} </div>
      <div *ngIf="alert_success == 'success'" style="color:green;
    font-size: 16px;
    padding: 9px;
    text-align: center;">{{alert_message}} </div>
    </div>
    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-pic js-tilt" data-tilt>
          <a><img src="assets/images/logo.png" alt="IMG"></a>
        </div>

        <form class="login100-form validate-form" (ngSubmit)="validate()"
          *ngIf="!show_forgot_password && !show_update_password_form">
          <span class="login100-form-title">
            <b>Admin Login</b>
          </span>
          <mat-form-field appearance="outline" class="wrap-input100">
            <mat-label>Mobile</mat-label>
            <input class="input100" matInput placeholder="Mobile" name="mobile" [formControl]="mobile" type="text"
              [(ngModel)]="user.mobile" required>
            <mat-error *ngIf="!mobile.valid">{{getMobileErrorMessage()}}</mat-error>
            <span class="focus-input100"></span>
            <!-- <span class="symbol-input100">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </span> -->
          </mat-form-field>
          <mat-form-field appearance="outline" class="wrap-input100">
            <mat-label>Password</mat-label>
            <input class="input100" matInput placeholder="Password" name="password" [formControl]="password"
              type="password" [(ngModel)]="user.password" required>
            <mat-error *ngIf="!password.valid">{{getPasswordErrorMessage()}}</mat-error>
            <span class="focus-input100"></span>
            <!-- <span class="symbol-input100">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </span> -->
          </mat-form-field>

          <div class="text-center p-t-12">

            <div class="container-login100-form-btn">
              <button class="login-button" mat-flat-button [disabled]="!mobile.valid || !password.valid"
                color="primary">
                LOGIN </button>
            </div>
            <a [routerLink]="[]" class="txt2" (click)="show_forgot_password=true;">
              Forgot Password?
            </a>
          </div>
        </form>
        <form class="login100-form validate-form" *ngIf="show_forgot_password && !show_update_password_form">
          <span class="login100-form-title">
            <b>Forgot Password</b>
          </span>
          <div><label class="forgot-link">
              <i style="position: relative; top: 6px" class="material-icons">
                keyboard_arrow_left
              </i>
              <a [routerLink]="[]" (click)="show_forgot_password=false;otpClear()">Go back to login</a>
            </label></div>
          <br>
          <mat-form-field appearance="outline" class="wrap-input100">
            <mat-label>Mobile</mat-label>
            <input class="input100" matInput placeholder="Mobile" name="mobile" [formControl]="mobile" type="text"
              [(ngModel)]="user.mobile" required>
            <mat-error *ngIf="!mobile.valid">{{getMobileErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="wrap-input100" *ngIf="otp_sent">
            <mat-label>Otp</mat-label>
            <input class="input100" matInput placeholder="OTP" name="otp" [formControl]="otp" type="text"
              [(ngModel)]="otp_" required>
            <mat-error *ngIf="!otp.valid">{{getOtpErrorMessage()}}</mat-error>
          </mat-form-field>
          <p *ngIf="otp_sent" (click)="resendOtp()" style="text-align: end;text-decoration: underline;color: #f76900;cursor: pointer;">resend otp</p>
          <button class="login-button" mat-raised-button [disabled]="!mobile.valid" color="primary"
            (click)="forgotPassword()" *ngIf="!otp_sent"> Submit </button>
          <button class="login-button" mat-raised-button [disabled]="!mobile.valid || !otp.valid" color="primary"
            (click)="verifyOtp()" *ngIf="otp_sent"> Verify </button>
        </form>
        <form class="login100-form validate-form" [formGroup]="form" (ngSubmit)="updatePassword()"
          *ngIf="!show_forgot_password && show_update_password_form">
          <span class="login100-form-title">
            <b>Update Password</b>
          </span>
          <!-- <div><label class="forgot-link">
              <i style="position: relative; top: 6px" class="material-icons">
                keyboard_arrow_left
              </i>
              <a [routerLink]="" (click)="show_forgot_password=false;">Go back to login</a>
            </label></div> -->
          <br>
          <mat-form-field appearance="outline" class="wrap-input100">
            <mat-label>Enter New Password</mat-label>
            <input class="input100" type="password" matInput formControlName="password" [(ngModel)]="user.password"
              name="password" placeholder="Enter Password" required>
            <mat-error *ngIf="f.password.errors?.required">Password is required.</mat-error>

          </mat-form-field>
          <mat-form-field appearance="outline" class="wrap-input100" *ngIf="otp_sent">
            <mat-label>Confirm Password</mat-label>
            <input class="input100" type="password" matInput formControlName="confirm_password" name="confirm_password"
              placeholder="Confirm Password" required>
            <mat-error *ngIf="f.confirm_password.errors?.required">Password is required.</mat-error>
            <mat-error *ngIf="f.confirm_password.errors?.confirmedValidator"> Passwords didn't match </mat-error>
          </mat-form-field>
          <button class="login-button" mat-raised-button [disabled]="!form.valid" color="primary"> Submit </button>
        </form>
      </div>
    </div>
  </div>
</div>
