import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from '../../services/space.service';

@Component({
  selector: 'app-space-review',
  templateUrl: './space-review.component.html',
  styleUrls: ['./space-review.component.css'],
  providers: [DatePipe]
})
export class SpaceReviewComponent {

  displayedColumns = ['Serial_no', 'createdAt', 'user_name' ,'rating', 'Review', 'status'];
  dataSource!: MatTableDataSource<any>;
  availablePoints:any;
  transactionHistory: any []=[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  spaceId:any;
  spaceName: any;
  spaceRatingAvg: any;
  startDate: any;
  endDate: any;
  selectedStars = [];
  page1 = 1;
  totalItems = 0;

  constructor(private datePipe: DatePipe, private route: ActivatedRoute, private _spaceService: SpaceService, private router: Router, public snackBar: MatSnackBar, private loaderService: LoaderService, private queryService:QueriesService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.spaceId =params.get('id');
    });
    this.getAllReviews();
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  }

  getStar(event:any){
    this.selectedStars = event;
  }

 getAllReviews(){
  this._spaceService.getAllReviewBySpaceId(this.spaceId,{startDate:this.startDate,endDate:this.endDate,selectedStars:this.selectedStars}).then((res:any)=>{

    this.spaceName = res?.data?.spaceName;
    this.transactionHistory = res?.data?.reviews;
    this.spaceRatingAvg = res?.data?.ratingsAvg;
    this.dataSource =new MatTableDataSource(this.transactionHistory);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  },(error) => {
    if(error.status ===500){
      this.router.navigate(['/auth/login']);
    }
  })
 }

 getPageSymbol(current: number): string {
  return current.toString();
}

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getStatus(e:any,row:any, id:any){
    e.preventDefault()
    if (this.queryService.hasPermission('Space', 'edit')) {
    let status = row.approved ? 1 : 0

    this._spaceService.getReviewStatus(id,status).then((res:any)=>{
      this.snackBar.open(res.message);
    },(error) => {
      if(error.status ===500){
        this.router.navigate(['/auth/login']);
      }
    })
  } else {
    this.queryService.accessDeniedAlert()
  }
  }
}
