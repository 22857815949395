import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class InvoiceService {

  public selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);

  }

  private base_url = 'api/v1/billing/invoice';
  private updateDetailsUrl = environment.apiUrl + this.base_url + '/update';
  private updatePaymentDetailsUrl = environment.apiUrl + this.base_url + '/updatePayment';
  private addDetailsUrl = environment.apiUrl + this.base_url + '/add';
  private deleteDetailsUrl = environment.apiUrl + this.base_url + '/delete';
  private sendDetailsUrl = environment.apiUrl + this.base_url + '/send';
  private sendDetailsForTestingUrl = environment.apiUrl + this.base_url + '/sendForTesting';
  private sendReceiptUrl = environment.apiUrl + this.base_url + '/sendReceipt';
  private getCSVDataUrl = environment.apiUrl + this.base_url + '/csvData';
  private getAllUrl = environment.apiUrl + this.base_url + '/all';
  private getPdfUrl = environment.apiUrl + this.base_url + '/download';
  private getZipUrl = environment.apiUrl + this.base_url + '/bulkDownload';
  private getProfileDetailsUrl = environment.apiUrl + this.base_url + '/details';
  private purchaseHistoryUrl = environment.apiUrl + 'api/v1/admin/getPaywallPurchaseInvoice'
  private invoiceUrl = environment.apiUrl + 'api/v1/admin/create-paywall-invoice'
  private walletHistoryUrl = environment.apiUrl + 'api/v1/admin/getCreditPurchaseInvoice'
  private walletInvoiceUrl = environment.apiUrl + 'api/v1/admin/create-credit-invoice'

  private headers = new HttpHeaders({
    Authorization: `Bearer ${localStorage.getItem("token")},`,
    'Content-Type': 'application/json'
  });

  private _options = {
    headers: this.headers,
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAll(start_month: string, end_month: string, date_filter_type): Promise<any> {
    let location_condition = this.selectedLocationId && this.selectedLocationId != 0 ? `&location_id=${this.selectedLocationId}` : '';

    return this.http.get(this.getAllUrl + '?start_month=' + start_month + '&end_month=' + end_month + location_condition + '&date_filter_type=' + date_filter_type,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getProfileDetails(invoice_id: string, entity_type: number): Promise<any> {
    return this.http.get(this.getProfileDetailsUrl + '?id=' + invoice_id + '&entity_type=' + entity_type,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addDetails(details: any): Promise<any> {
    return this.http.post(this.addDetailsUrl, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateDetails(details: any, member_id: number): Promise<any> {
    return this.http.put(this.updateDetailsUrl + '/' + member_id, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updatePaymentDetails(details: any, invoice_id: number): Promise<any> {
    return this.http.put(this.updatePaymentDetailsUrl + '/' + invoice_id, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  deleteDetails(invoice_id: number): Promise<any> {
    return this.http.put(this.deleteDetailsUrl + '/' + invoice_id, {}, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  sendDetailsToEntity(invoice_id: number, entity_type: number): Promise<any> {
    return this.http.put(this.sendDetailsUrl + '/' + invoice_id + '/' + entity_type, {}, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  sendDetailsToEntityForTesting(invoice_id: number, entity_type: number, email: string): Promise<any> {
    return this.http.put(this.sendDetailsForTestingUrl + '/' + invoice_id + '/' + entity_type + '?email=' + email, {}, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  sendReceipt(receipt_details: any): Promise<any> {
    return this.http.put(this.sendReceiptUrl, receipt_details, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  exportToCSV(start_month: string, end_month: string, date_filter_type): Promise<any> {
    let location_condition = this.selectedLocationId && this.selectedLocationId != 0 ? `&location_id=${this.selectedLocationId}` : '';
    return this.http.get(this.getCSVDataUrl + '?start_month=' + start_month + '&end_month=' + end_month + location_condition + '&date_filter_type=' + date_filter_type,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  download(id: string) {
    return this.http.get(this.getPdfUrl + "?id=" + id,
      {
        withCredentials: true,
        responseType: 'blob'
      })
  }

  bulkDownload(ids: any) {
    console.log(ids);
    return this.http.get(this.getZipUrl + "?id=" + ids,
      {
        withCredentials: true,
        responseType: 'blob'
      })
  }

  getPurchaseHistory(): Promise<any> {
    return this.http.get(this.purchaseHistoryUrl, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getFilteredPurchaseHistory(startDate: string, endDate: string, hostId: number): Promise<any> {
    return this.http.get(`${this.purchaseHistoryUrl}?startDate=${startDate}&endDate=${endDate}&hostId=${hostId}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  createInvoice(data: any): Promise<any> {
    return this.http.post(`${this.invoiceUrl}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  getWalletHistory(): Promise<any> {
    return this.http.get(this.walletHistoryUrl, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getFilteredWalletHistory(startDate: string, endDate: string, hostId: number): Promise<any> {
    return this.http.get(`${this.walletHistoryUrl}?startDate=${startDate}&endDate=${endDate}&hostId=${hostId}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  createWalletInvoice(data: any): Promise<any> {
    return this.http.post(`${this.walletInvoiceUrl}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }
}
