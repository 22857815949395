<div class="review-popup p-3">
  <div class="div">
    <div>
      <label class="top-label">{{data?.id ? 'Update' : 'Create'}} Lead</label>
      <i class=" cross material-icons" (click)="closeDialog(null)">
        close
      </i>
    </div>
    <div>
      <form [formGroup]="spaceForm" (ngSubmit)="submitForm()" class="space-form w-100 row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Space type</mat-label>
            <mat-select placeholder="Select your space type" multiple name="inquirySpaceCapacity"
              formControlName="spaceType" required>
              <mat-option value="Private Cabin">Private Cabin</mat-option>
              <mat-option value="Dedicated Desk">Dedicated Desk</mat-option>
              <mat-option value="Flexi Desk">Flexi Desk</mat-option>
              <mat-option value="Private Office">Private Office</mat-option>
              <mat-option value="Managed Office">Managed Office</mat-option>
              <mat-option value="Virtual Office">Virtual Office</mat-option>
              <mat-option value="Hybrid Work">Hybrid Work</mat-option>
              <mat-option value="Day Pass">Day Pass</mat-option>
              <mat-option value="Customized Space">Customized Space</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('spaceType')?.errors?.required && (spaceForm.get('spaceType').dirty || spaceForm.get('spaceType').touched)">
            Space type is required.
          </mat-error>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>No. of seats</mat-label>
            <mat-select placeholder="Select number of seats" (selectionChange)="calculateLeadPoints();getRevenue() "
              name="inquirySpaceCapacity" formControlName="number_of_seats" required>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
              <mat-option value="13">13</mat-option>
              <mat-option value="14">14</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="16">16</mat-option>
              <mat-option value="17">17</mat-option>
              <mat-option value="18">18</mat-option>
              <mat-option value="19">19</mat-option>
              <mat-option value="20">20</mat-option>
              <mat-option value="21-50">21-50</mat-option>
              <mat-option value="51-100">51-100</mat-option>
              <mat-option value="100+">100+</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('number_of_seats')?.errors?.required && (spaceForm.get('number_of_seats').dirty || spaceForm.get('number_of_seats').touched)">
            Number of seats is required.
          </mat-error>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City</mat-label>
            <mat-chip-list #chipList formControlName="city_name">
              <mat-chip *ngFor="let city of selectedCities" [removable]="true" (removed)="removeCity(city)">
                {{ city }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <input class="w-100" placeholder="Select City" type="text" [matChipInputFor]="chipList" matInput
              [formControl]="cityFilterCtrl" [matAutocomplete]="auto" (matChipInputTokenEnd)="addCity($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectCity($event)">
              <mat-option *ngFor="let city of filteredCities" [value]="city">{{ city }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('city_name').hasError('required') && (spaceForm.get('city_name').dirty || spaceForm.get('city_name').touched)">
            City Name is required.
          </mat-error>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Location</mat-label>
            <mat-chip-list #chipGrid aria-label="Enter keywords" formControlName="location">
              <mat-chip class="h-100" *ngFor="let keyword of keywords" (removed)="removeKeyword(keyword)">
                {{keyword}}
                <button matChipRemove aria-label="'remove ' + keyword">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
            <input class="w-100" placeholder="location..." [matChipInputFor]="chipGrid"
              (matChipInputTokenEnd)="add($event)" />
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('location')?.invalid && (spaceForm.get('location').dirty || spaceForm.get('location').touched)">
              location is required.
            </mat-error>
        </div>
        <!-- <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let location of location" [removable]="true" (removed)="removeLocation(location)">
                  {{ location }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>

              <input placeholder="Location *" class="hero__form-input custom-select form-control" type="text"
                name="place-event" [matChipInputFor]="chipList" id="key-word"
                style="font-family: Poppins, FontAwesome; width: 430px;" [formControl]="control"
                [matAutocomplete]="auto" formControlName="location" (input)="onInputChange($event.target.value)" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option (onSelectionChange)="getLocationValue($event)" *ngFor="let val of filteredPlaces"
                  [value]="val">{{val}}
                  <ng-container *ngFor="let segment of getHighlightedSegments(val)">
                    <span [ngStyle]="{'background-color': segment.highlight ? '#f3b47fcc' : 'transparent'}">
                      {{ segment.text }}
                    </span>
                  </ng-container>
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="spaceForm.get('location').hasError('required') && (spaceForm.get('location').dirty || spaceForm.get('location').touched)">
                Location Name is required.
              </mat-error>
            </mat-form-field>
          </div> -->



        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Approx Start</mat-label>
            <mat-select placeholder="Select Approx Start Time" name="inquirySpaceCapacity"
              formControlName="approx_start" required>
              <mat-option value="immediate">Immediate</mat-option>
              <mat-option value="1 week">Within 1 week</mat-option>
              <mat-option value="2 weeks">Within 2 weeks</mat-option>
              <mat-option value="1 month">Within 1 month</mat-option>
              <mat-option value="2 months">Within 2 months</mat-option>
              <mat-option value="3 months">Within 3 months</mat-option>
              <mat-option value="after 3 months">After 3 months</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('approx_start')?.errors?.required && (spaceForm.get('approx_start').dirty || spaceForm.get('approx_start').touched)">
            Start time is required.
          </mat-error>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tenure</mat-label>
            <mat-select placeholder="Select Approx Start Time" name="inquirySpaceCapacity" formControlName="tenure"
              (selectionChange)="calculateLeadPoints();getRevenue() " required>
              <mat-option *ngFor="let month of tenure" [value]="month.label">{{month.label | titlecase}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('tenure')?.errors?.required && (spaceForm.get('tenure').dirty || spaceForm.get('tenure').touched)">
            Tenure is required.
          </mat-error>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Budget min</mat-label>
            <mat-select placeholder="Select Approx Start Time" name="inquirySpaceCapacity" formControlName="budget"
              (selectionChange)="calculateLeadPoints();getMaxBudget();getRevenue()" required>
              <mat-option *ngFor="let budget of amount" [value]="budget.label">{{budget.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('budget')?.errors?.required && (spaceForm.get('budget').dirty || spaceForm.get('budget').touched)">
            Min budget is required.
          </mat-error>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Budget max</mat-label>
            <mat-select placeholder="Select min budget" name="inquirySpaceCapacity" formControlName="budgetMax"
              (selectionChange)="calculateLeadPoints()" required>
              <mat-option *ngFor="let budget of maxBudgetAmount" [value]="budget.label">{{budget.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('budgetMax')?.errors?.required && (spaceForm.get('budgetMax').dirty || spaceForm.get('budgetMax').touched)">
            Max budget is required.
          </mat-error>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Verified By</mat-label>
            <mat-select formControlName="verifiedBy">
              <mat-option value="">None</mat-option>
              <mat-option value="Verified on Call">Verified on Call</mat-option>
              <mat-option value="Whatsapp Verified">Whatsapp Verified</mat-option>
              <mat-option value="Phone Number Verified">Phone Number Verified</mat-option>
              <mat-option value="Email Verified">Email Verified</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Source</mat-label>
            <mat-select placeholder="Select Lead Source" name="inquirySpaceCapacity" formControlName="lead_source">
              <mat-option value="Website inquiry">Website Inquiry</mat-option>
              <mat-option value="Website chart">Website Chat</mat-option>
              <mat-option value="Whatsapp">Whatsapp</mat-option>
              <mat-option value="Inbound call">Inbound Call</mat-option>
              <mat-option value="Social media">Social Media</mat-option>
              <mat-option value="Social Media - Paid Ads">Social Media - Paid Ads</mat-option>
              <mat-option value="Google ads">Google Ads</mat-option>
              <mat-option value="Referral lead">Referral Leads</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tag</mat-label>
            <mat-select placeholder="Select Tag" name="inquirySpaceCapacity" multiple formControlName="tags"
              [errorStateMatcher]="errorStateMatcher" (selectionChange)="onSelectionChange()">
              <mat-option value="Hot lead" [disabled]="isOptionDisabled('Hot lead')">Hot Lead</mat-option>
              <mat-option value="Urgent requirement" [disabled]="isOptionDisabled('Urgent requirement')">Urgent
                Requirement</mat-option>
              <mat-option value="High value" [disabled]="isOptionDisabled('High value')">High Value</mat-option>
              <mat-option value="Good budget" [disabled]="isOptionDisabled('Good budget')">Good Budget</mat-option>
              <mat-option value="Immediate closure" [disabled]="isOptionDisabled('Immediate closure')">Immediate
                Closure</mat-option>
              <mat-option value="Wants to visit ASAP" [disabled]="isOptionDisabled('Wants to visit ASAP')">Wants to
                visit ASAP</mat-option>
              <mat-option value="Immediate start" [disabled]="isOptionDisabled('Immediate start')">Immediate
                Start</mat-option>
              <mat-option value="Option to leave blank" [disabled]="isOptionDisabled('Option to leave blank')">Option
                to leave blank</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput onkeypress="return /[a-zA-Z ]/.test(event.key)" type="text"
              formControlName="inquiryDescription" placeholder="Enter your description"></textarea>
          </mat-form-field>
        </div>



        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput onkeypress="return /[a-zA-Z ]/.test(event.key)" maxlength="30" type="text"
              formControlName="firstName" placeholder="Enter first name" required />
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('firstName')?.errors?.required && (spaceForm.get('firstName').dirty || spaceForm.get('firstName').touched)"
              class="text-danger">
              First Name is required.
            </mat-error>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput onkeypress="return /[a-zA-Z ]/.test(event.key)" maxlength="30" type="text"
              formControlName="lastName" placeholder="Enter last name" required />
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('lastName')?.errors?.required && (spaceForm.get('lastName').dirty || spaceForm.get('lastName').touched)"
              class="text-danger">
              Last Name is required.
            </mat-error>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Lead Email</mat-label>
            <input matInput type="text" formControlName="userEmail" placeholder="Enter lead email" required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" />
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('userEmail')?.errors?.required && (spaceForm.get('userEmail').dirty || spaceForm.get('userEmail').touched)"
              class="text-danger">
              Email is required.
            </mat-error>
            <mat-error *ngIf="spaceForm.get('userEmail')?.errors?.pattern">
              Enter correct email format
            </mat-error>
        </div>

        <div class="col-md-2">
          <mat-form-field appearance="outline" floatLabel="always">
            <input type="text" matInput class="d-none">
            <mat-label class="required">Country Code <span>*</span> </mat-label>
          <ng-select #select (change)="onCountryCodeChange($event)" formControlName="countryCode" [searchable]="false" [clearable]="false">
            <ng-template ng-header-tmp>
              <input autofocus class="form-control search_input" placeholder="search for country"  #country type="text" (input)="select.filter(country.value)" />
            </ng-template>
            <ng-option *ngFor="let list of countryCodes" [value]="list.dialcode">
              <img src="{{list.flag}}" alt="" width="20px">
              {{list.dialcode}}&nbsp; {{list.name}} </ng-option>
          </ng-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Lead Phone</mat-label>
            <input [attr.autocomplete]="true" matInput type="text" formControlName="userMobile" required
              onkeypress="return /[0-9]/i.test(event.key)" type="tel" [maxlength]="placeholder?.length"/>
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('userMobile')?.errors?.required && (spaceForm.get('userMobile').dirty || spaceForm.get('userMobile').touched)"
              class="text-danger">
              Lead Phone is required.
            </mat-error>
            <mat-error *ngIf="spaceForm.get('userMobile')?.errors?.minlength" class="text-danger">
              Minimum {{placeholder?.length}} digits are required.
            </mat-error>
        </div>
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Lead Company</mat-label>
            <input matInput formControlName='company_name' maxlength="30" placeholder="Enter lead company"
              onkeypress="return /[a-zA-Z ]/.test(event.key)" type="text" />
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm.get('company_name')?.errors?.required && (spaceForm.get('company_name').dirty || spaceForm.get('company_name').touched)"
            class="text-danger">
            Lead Company is required.
          </mat-error>
        </div>
        
        <div class="col-md-6">
          <fieldset disabled>
            <mat-form-field appearance="outline">
              <mat-label>Revenue Opportunity</mat-label>
              <input matInput [value]="revenue">
            </mat-form-field>
          </fieldset>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Lead Price</mat-label>
            <input onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" type="tel" matInput type="text"
              [(ngModel)]="leadPoints" style="background: none;" formControlName="lead_price" placeholder="Enter lead price"
              required>
            </mat-form-field>
            <mat-error
              *ngIf="spaceForm.get('lead_price')?.errors?.required && (spaceForm.get('lead_price').dirty || spaceForm.get('lead_price').touched)"
              class="text-danger">
              Price is required.
            </mat-error>
        </div>

        <div class="w-100 text-end mt-3">
          <button mat-raised-button color="accent" style="color: white;" type="submit">{{data?.id ? 'Update' :
            'Submit'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>