import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from '@angular/router';
import { NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';
import { ConfirmDeleteAmenityComponent } from '../confirm-delete-amenity/confirm-delete-amenity.component';

@Component({
  selector: 'app-amenities-list',
  templateUrl: './amenities-list.component.html',
  styleUrls: ['./amenities-list.component.css'],
  providers: [ErrorStateMatcher, MatDialog]
})
export class AmenitiesListComponent {

  activeTab = 0;
  @ViewChild(NgbNavItem) navItem!: NgbNavItem;
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  cancellationForm: FormGroup;
  amenitiesForm: FormGroup;
  iconCss: any;
  fallbackIcon = '';
  name: any;
  icon: any = '';
  displayedColumns: string[] = ['position', 'name', 'icon_name', 'icon', 'actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;

  constructor(private spaceService: SpaceService, private snackBar: MatSnackBar, public dialog: MatDialog, private queryService: QueriesService,private router :Router) { }

  ngOnInit() {
    this.getAmenities();
  }

  getAmenities() {
    this.spaceService.getAmenities().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 200);
    })
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updateDetails(id:number){
    if (this.queryService.hasPermission('Amenities', 'edit')) {
      this.router.navigate([`/home/update-amenities/${id}`])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }
  

  getCancelFormValues() {
    if (this.cancellationForm.valid) {
      console.log(this.cancellationForm.controls.name.value);
      console.log(this.cancellationForm.controls.description.value);
      console.log(this.cancellationForm.controls.status.value);
    } else {
      this.cancellationForm.markAllAsTouched();
    }
  }

  delete(id: any) {
    if (this.queryService.hasPermission('Amenities', 'delete')) {
      const dialogRef = this.dialog.open(ConfirmDeleteAmenityComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.spaceService.deleteAmenity(id).subscribe((res: any) => {
            this.openSnackBar(res.message, "Dismiss");
            this.getAmenities();
          })
        }
      });
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

}
