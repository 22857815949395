<!-- <app-page-header title="Pages" active_item="My Profile"></app-page-header> -->
<!-- ROW-1 OPEN -->
<div class="row main">
  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
    <div class="card edit-profile">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between align-items-center">
            <h3 class="card-title">My Profile</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputname"First >First Name  <span class="text-danger">*</span></label>
                            <input
                            type="text"
                            class="form-control {{
                                profileForm.get('name')?.invalid && (profileForm.get('name')?.dirty || profileForm.get('name')?.touched)
                                ? 'is-invalid state-invalid'
                                : ''
                            }} "
                            id="exampleInputname"
                            placeholder="Enter First Name"
                            formControlName="name"
                            />

                            <div *ngIf="profileForm.get('name')?.invalid && (profileForm.get('name')?.dirty || profileForm.get('name')?.touched)" class="text-danger">
                                <div *ngIf="profileForm.get('name')?.errors">
                                    Please enter first name
                              </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputname">Last Name  <span class="text-danger">*</span></label>
                            <input
                            type="text"
                            class="form-control {{
                                profileForm.get('lastName')?.invalid && (profileForm.get('lastName')?.dirty || profileForm.get('lastName')?.touched)
                                ? 'is-invalid state-invalid'
                                : ''
                            }} "
                            id="exampleInputname"
                            placeholder="Enter Last Name"
                            formControlName="lastName"
                            />

                            <div *ngIf="profileForm.get('lastName')?.invalid && (profileForm.get('lastName')?.dirty || profileForm.get('lastName')?.touched)" class="text-danger">
                                <div *ngIf="profileForm.get('lastName')?.errors">
                                    Please enter last name
                              </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputEmail">Email address <span class="text-danger">*</span></label>
                            <input type="email" class="form-control {{
                                profileForm.get('email')?.invalid && (profileForm.get('email')?.dirty || profileForm.get('email')?.touched)
                                ? 'is-invalid state-invalid'
                                : ''
                            }} " id="exampleInputEmail1" placeholder="Email address" formControlName="email">

                            <mat-error  *ngIf="profileForm.get('email')?.errors?.required && (profileForm.get('email').dirty || profileForm.get('email').touched)" class="invalid-feedback">Please enter email</mat-error>

                            <mat-error *ngIf="profileForm.get('email')?.errors?.pattern">Please enter valid email</mat-error>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputnumber">Contact Number  <span class="text-danger">*</span></label>
                            <ngx-intl-tel-input
                                [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="val"
                                [separateDialCode]="true"
                                [maxLength]="15"
                                [phoneValidation]="true"
                                name="phone"
                                formControlName="mobile"
                            ></ngx-intl-tel-input>
                            <!-- <div *ngIf="profileForm.controls['mobile'].invalid && profileForm.controls['mobile'].touched">
                                <mat-error>
                                  Please enter a valid mobile number
                                </mat-error>
                            </div> -->
                        </div>
                    </div>

                    
                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Company Name</label>
                            <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            placeholder="Company Name"
                            formControlName="companyName"
                            />
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Pan Number </label>
                            <input minlength="10" maxlength="10"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').toUpperCase();"
                            type="text"
                            ngPattern="/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/"
                            class="form-control" 
                            
                            id="exampleInputEmail1"
                            placeholder="Pan Number"
                            formControlName="PanNo"
                            />
                           
                            <mat-error *ngIf="profileForm.get('PanNo')?.errors?.pattern">
                                Enter correct PAN format
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputnumber">GST </label>
                            <input minlength="15" maxlength="15"
                            type="text"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').toUpperCase();"
                            class="form-control" 
                            id="exampleInputnumber"
                            placeholder="GST number"
                            formControlName="GST"
                            ngPattern="'^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$'"
                            />
                            
                            <mat-error *ngIf="profileForm.get('GST')?.errors?.pattern">
                                Enter correct GST format
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                          <label>Country <span class="text-danger">*</span></label>
                          <mat-select  (selectionChange)="onCountryChange($event)" formControlName="country_id" class="drop_down form-control">
                            <mat-option *ngFor="let country of countryList" [value]="country.country_name">{{country.country_name}}</mat-option>
                          </mat-select>
          
                          <div
                            *ngIf="profileForm.get('country_id')?.invalid && (profileForm.get('country_id')?.dirty || profileForm.get('country_id')?.touched)"
                            class="text-danger">
                            <div *ngIf="profileForm.get('country_id')?.errors">
                              Please select country
                            </div>
                          </div>
                        </div>
                      </div>
          
                      <div class="col-6">
                        <div class="form-group">
                          <label>State <span class="text-danger">*</span></label>
          
                          <mat-select (selectionChange)="onStateChange($event)" formControlName="state_id" class="drop_down form-control">
                            <mat-option *ngFor="let state of stateList" [value]="state.name">{{state.name}}</mat-option>
                          </mat-select>
          
                          <div
                            *ngIf="profileForm.get('state_id')?.invalid && (profileForm.get('state_id')?.dirty || profileForm.get('state_id')?.touched)"
                            class="text-danger">
                            <div *ngIf="profileForm.get('state_id')?.errors">
                                Please select state
                            </div>
                          </div>
                        </div>
                      </div>
          
                      <div class="col-6">
                        <div class="form-group">
                          <label>City <span class="text-danger">*</span></label>
          
                          <mat-select (selectionChange)="onCityChange($event)" formControlName="city_id" class="drop_down form-control">
                            <mat-option *ngFor="let city of cityList" [value]="city.name">{{city.name}}</mat-option>
                          </mat-select>
          
                          <div
                            *ngIf="profileForm.get('city_id')?.invalid && (profileForm.get('city_id')?.dirty || profileForm.get('city_id')?.touched)"
                            class="text-danger">
                            <div *ngIf="profileForm.get('city_id')?.errors">
                             Please select city
                            </div>
                          </div>
                        </div>
                      </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputnumber">Pincode <span class="text-danger">*</span></label>
                            <input type="tel" class="form-control" id="exampleInputnumber" placeholder="Pincode" formControlName="pincode" [maxlength]="6" [minlength]="6" onkeypress="return /[0-9 ]/i.test(event.key)"
                            />

                            <div *ngIf="profileForm.get('pincode')?.invalid && (profileForm.get('pincode')?.dirty || profileForm.get('pincode')?.touched)"
                            class="text-danger">
                            <div *ngIf="profileForm.get('pincode')?.errors">
                                Please enter pincode
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputnumber">Address 1 <span class="text-danger">*</span></label>
                            <input
                            type="text"
                            class="form-control"
                            id="exampleInputnumber"
                            placeholder="Address"
                            formControlName="address"
                            />

                            <div *ngIf="profileForm.get('address')?.invalid && (profileForm.get('address')?.dirty || profileForm.get('address')?.touched)"
                            class="text-danger">
                            <div *ngIf="profileForm.get('address')?.errors">
                                Please enter address
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="exampleInputnumber">Address 2 (optional)</label>
                            <input
                            type="text"
                            class="form-control"
                            id="exampleInputnumber"
                            placeholder="Address"
                            formControlName="address2"
                            />
                        </div>
                    </div>

                    <h3 class="card-title w-100">Billing Details</h3>
                    <div class="col-6">
                        <div class="form-group">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Is the Billing address the same as the communication address?</label>
                                <label class="custom-switch d-block">
                                <input type="checkbox" formControlName="isSameBillingAddress" class="custom-switch-input" [(ngModel)]="isSameBillingAddress">
                                <span class="custom-switch-indicator custom-switch-indicator-xl custom-radius"></span> 
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="width: 100%;" *ngIf="!profileForm.value?.isSameBillingAddress">
                        <div class="col-6">
                            <div class="form-group">
                              <label>Billing Country <span class="text-danger">*</span></label>
                              <mat-select (selectionChange)="onBillingCountryChange($event)" formControlName="billingcountry_id" class="drop_down form-control">
                                <mat-option *ngFor="let country of billingCountryData" [value]="country.country_name">{{country.country_name}}</mat-option>
                              </mat-select>
                              <div
                                *ngIf="profileForm.get('billingcountry_id')?.invalid && (profileForm.get('billingcountry_id')?.dirty || profileForm.get('billingcountry_id')?.touched)"
                                class="text-danger">
                                <div *ngIf="profileForm.get('billingcountry_id')?.errors">
                                    Please select billing country
                                </div>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-6">
                            <div class="form-group">
                              <label>Billing State <span class="text-danger">*</span></label>
            
                              <mat-select (selectionChange)="onBillingStateChange($event)"formControlName="billingstate_id" class="drop_down form-control">
                                <mat-option *ngFor="let state of billing_stateList" [value]="state.name">{{state.name}}</mat-option>
                              </mat-select>
            
                              <div
                                *ngIf="profileForm.get('billingstate_id')?.invalid && (profileForm.get('billingstate_id')?.dirty || profileForm.get('billingstate_id')?.touched)"
                                class="text-danger">
                                <div *ngIf="profileForm.get('billingstate_id')?.errors">
                                    Please select billing state
                                </div>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-6">
                            <div class="form-group">
                              <label>Billing City <span class="text-danger">*</span></label>
            
                              <mat-select (selectionChange)="onBillingCityChange($event)" formControlName="billingcity_id" class="drop_down form-control">
                                <mat-option *ngFor="let city of billing_cityList" [value]="city.name">{{city.name}}</mat-option>
                              </mat-select>
            
                              <div
                                *ngIf="profileForm.get('billingcity_id')?.invalid && (profileForm.get('billingcity_id')?.dirty || profileForm.get('billingcity_id')?.touched)"
                                class="text-danger">
                                <div *ngIf="profileForm.get('billingcity_id')?.errors">
                                    Please select billing city
                                </div>
                              </div>
                            </div>
                          </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputnumber">Billing pincode <span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" id="exampleInputnumber" placeholder="Pincode" formControlName="billing_pincode" [maxlength]="6" [minlength]="6" onkeypress="return /[0-9 ]/i.test(event.key)" />
                    
                                    <div *ngIf="profileForm.get('billing_pincode')?.invalid && (profileForm.get('billing_pincode')?.dirty || profileForm.get('billing_pincode')?.touched)"
                                    class="text-danger">
                                    <div *ngIf="profileForm.get('billing_pincode')?.errors">
                                        Please enter billing pincode
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputnumber">Address 1 <span class="text-danger">*</span></label>
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputnumber"
                                    placeholder="Address"
                                    formControlName="billing_address"
                                    />
                    
                                    <div *ngIf="profileForm.get('billing_address')?.invalid && (profileForm.get('billing_address')?.dirty || profileForm.get('billing_address')?.touched)"
                                    class="text-danger">
                                    <div *ngIf="profileForm.get('billing_address')?.errors">
                                        Please enter billing pincode
                                    </div>
                                    </div>
                                </div>
                            </div>

                        <div class="col-6">
                            <div class="form-group">
                            <label for="exampleInputnumber">Address 2 (optional)</label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputnumber"
                                placeholder="Address"
                                formControlName="billing_address2"
                                />
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="card-footer text-center">
                    <button class="btn update_btn" type="submit">Update</button> 
                </div>
            </div>
        </form>
    </div>
  </div>

</div>
<!-- ROW-1 CLOSED -->

<!-- ROW-2 OPEN -->
<!-- <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header ">
                <h3 class="card-title ">Projects</h3>
                <div class="card-options">
                    <button type="button" class="btn btn-md btn-primary"><i class="fa fa-plus"></i> Add a new
                        Project</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-hover card-table table-vcenter table-outline text-nowrap">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Project Name</th>
                            <th scope="col">Backend</th>
                            <th scope="col">Deadline</th>
                            <th scope="col">Team Members</th>
                            <th scope="col">Edit Project Details </th>
                            <th scope="col">list info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let products of productData">
                            <td>{{products.id}}</td>
                            <td>{{products.name}}</td>
                            <td>{{products.backend}}</td>
                            <td>{{products.date}}</td>
                            <td>{{products.team}}</td>
                            <td>
                                <a class="btn btn-sm btn-primary me-1" href="javascript:void(0)"><i
                                        class="fa fa-edit "></i> Edit</a>
                                <a class="btn btn-sm btn-danger me-1" href="javascript:void(0)"
                                    (click)="click(products.id)"><i class="fa fa-trash "></i> Delete</a>
                            </td>
                            <td><a class="btn btn-sm btn-secondary" href="javascript:void(0)"><i
                                        class="fa fa-info-circle"></i> Details</a> </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->
<!-- ROW-2 CLOSED -->
