<!--Dashboard breadcrumb ends-->

<!-- Dashboard Content starts-->
<div class="row dash-content-wrap2" style="padding: 20px 5px;">
  <div class="col-md-12">
    <div class="dash-breadcrumb-content">
      <div class="dash-breadcrumb-left">
        <!-- <div class="breadcrumb-menu text-right sm-left"> -->
        <!-- <p class="list-address">Showing <b>{{filtered_spaces_data?.length}}</b> spaces</p> -->
        <div class="first-container">
          <ul class="nav-container">
            <li class="tabs" [ngClass]="{'tab-active':selected_status == null}" (click)="changeStatus(null)">
              All
            </li>
            <li class="tabs" [ngClass]="{'tab-active':selected_status == 1}" (click)="changeStatus(1)">
              Active
            </li>
            <li class="tabs" [ngClass]="{'tab-active':selected_status == 0}" (click)="changeStatus(0)">
              Inactive
            </li>
          </ul>
        </div>

        <!-- </div> -->
      </div>
      <div class="dash-breadcrumb-right">
        <a class="btn v3" (click)="navigateToAdd()"><i class="ion-plus-round"></i>Add Space </a>
        <!-- <a class="btn v3 ml-3" routerLink="/home/all-spaces-review"><i class="ion-plus-round"></i>Add Review </a> -->
      </div>
    </div>
  </div>
</div>
<div class="row dash-content-wrap2">
  <div class="col-md-12">
    <div class="recent-activity my-listing d-block mb-3">
      <div class="act-title col-md-12 ">
        <div class="row align-items-center">
          <div class="col">
            <div class="form-group mt-lg-0 mt-md-0 mt-3">
              <label class="form-label"></label>
              <h5>Category</h5>
              <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                [placeholder]="selectedWorkSpace ? '' : 'Select category'" [items]="workSpaceNames"
                [(ngModel)]="selectedWorkSpace" (change)="changeCategory(selectedWorkSpace)">
              </ng-select>
            </div>
          </div>

          <div class="col">
            <div class="form-group mt-lg-0 mt-md-0 mt-3">
              <label class="form-label"></label>
              <h5>Country</h5>
              <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                (change)="onCountryChange(countryData); stateData=''; cityData='';" [items]="countryList"
                bindLabel="country_name" [(ngModel)]="countryData" [placeholder]="countryData ? '' : 'Select country'">
              </ng-select>
            </div>
          </div>

          <div class="col">
            <div class="form-group mt-lg-0 mt-md-0 mt-3">
              <label class="form-label"></label>
              <h5>State</h5>
              <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                [placeholder]="stateData ? '' : 'Select state'" (change)="onStateChange(stateData)" [items]="stateList"
                bindLabel="name" [(ngModel)]="stateData">
              </ng-select>
            </div>
          </div>

          <div class="col">
            <div class="form-group mt-lg-0 mt-md-0 mt-3">
              <label class="form-label"></label>
              <h5>City</h5>
              <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                [placeholder]="cityData ? '' : 'Select city'" [(ngModel)]="cityData" placeholder="Select City"
                [items]="cityList" bindLabel="name" (change)="onCityChange(cityData)">
              </ng-select>
            </div>
          </div>

          <div class="col-12 text-end">
            <button class="btn btn v8" (click)="getSpaceByFilter()">
              Apply filter
            </button>
          </div>
        </div>
        <p class="list-address">Showing <b>{{filtered_spaces_data?.length}}</b> spaces</p>
      </div>
    </div>
    <div class="col-md-12 p-0">
      <div class="card">
        <div class="card-header w-100">
          <div class="row align-items-center justify-content-between w-100">
            <div class="col-lg-7 col-md-5 col-12">
              <h4 class="card-title text-md-start text-center">
                <i class="ion-social-buffer-outline pe-1"></i>Spaces
              </h4>
            </div>
            <div class="col-lg-5 col-md-7 col-12">
              <div class="d-flex align-items-center justify-content-end">
                <span class="">
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Search space name</mat-label>
                    <input type="text" matInput #matInput [(ngModel)]="search_string" placeholder="Search space name">
                    <mat-icon *ngIf="search_string" matSuffix style="cursor: pointer;" (click)="clearSearchInput()">
                      cancel
                    </mat-icon>
                  </mat-form-field>
                </span>
                <span class="ps-3">
                  <button class="btn v8" (click)="applyFilter()">Search</button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" style="row-gap: .6rem;">
          <ng-container *ngFor="let element of paginatedBookings; let i = index">
            <div class="col-md-4 mt-3">
              <div class="viewd-item-wrap h-100 position-relative">
                <div class="ribbon-label" [style.backgroundColor]="element.ribbon_color" *ngIf="element.ribbon">
                  {{element.ribbon}}
                </div>
                <div class="most-viewed-item">
                  <div class="most-viewed-img w-100 h-100" style="max-width: 100%;">
                    <a class="w-100"><img (error)="imageErrorHandler($event)" src="{{element.images[0]}}" class="w-100"
                        alt="space image"></a>
                  </div>
                  <div class="most-viewed-detail">
                    <!-- <a class="category" href="#"><span class="list-bg aqua"><i class="icofont-hotel"></i></span>Hotel</a> -->
                    <!-- <div style="align-self: center;" [ngStyle]="{'color':'green'}">
                                                        <i class="material-icons">verified</i>
                                                      </div> -->
                    <div class="d-flex justify-content-between align-items-center">

                      <h3>
                        <a target="_blank" *ngIf="element?.spaceType == 'Coworking Space'"
                          href="{{website_base_url}}/coworking-space/{{element.link_name}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Podcast Studio'"
                          href="{{website_base_url}}/podcast-studio/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Managed Office'"
                          href="{{website_base_url}}/managed-office/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.spaceTitle}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Training Room'"
                          href="{{website_base_url}}/training-room/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Private Office'"
                          href="{{website_base_url}}/private-office/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.spaceTitle}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Shoot Studio'"
                          href="{{website_base_url}}/shoot-studio/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Shared Office'"
                          href="{{website_base_url}}/shared-office/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.spaceTitle}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Gallery'"
                          href="{{website_base_url}}/gallery/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Sports Turf'"
                          href="{{website_base_url}}/sports-turf/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Classroom'"
                          href="{{website_base_url}}/classroom/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Coworking Café/Restaurant'"
                          href="{{website_base_url}}/coworking-café/restaurant/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Virtual Office'"
                          href="{{website_base_url}}/virtual-office/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.spaceTitle}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Recording Studio'"
                          href="{{website_base_url}}/recording-studio/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Activity Space'"
                          href="{{website_base_url}}/activity-space/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Sports Venue'"
                          href="{{website_base_url}}/sports-venue/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Party Space'"
                          href="{{website_base_url}}/party-space/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Banquet Hall'"
                          href="{{website_base_url}}/banquet-hall/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Private Cabin'"
                          href="{{website_base_url}}/private-cabin/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Meeting Room'"
                          href="{{website_base_url}}/meeting-room/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                        <a target="_blank" *ngIf="element?.spaceType == 'Event Space'"
                          href="{{website_base_url}}/event-space/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.actual_name}}</a>
                      </h3>
                      <a (click)="edit(element.id)" class="cursor-pointer"><svg style="width: 20px; height: 20px;" stroke="#777777" fill="#777777" stroke-width="0"
                        viewBox="0 0 24 24" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg">
                        <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                        <path
                          d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z">
                        </path>
                      </svg>
                    </a>
                    </div>

                    <div class="list-content-data">
                      <p class="list-address"><i class="icofont-google-map"></i><span
                          *ngIf="element.isCoworking == 1 || element.isShortterm == 1">{{element.city_name}} |&nbsp;</span> <span
                          *ngIf="element.isLongterm == 1 || element.isShortterm == 1 || element.isCoworking == 1">{{element?.location_name}} |&nbsp;</span><span
                          *ngIf="element.isLongterm == 1 || element.isShortterm == 1 || element.isCoworking == 1">{{element?.buildingname}}</span>
                        <span class="book-cancel" *ngIf="element.status == 0">In-Active</span><span
                          class="book-approved" *ngIf="element.status == 1">Active</span>&nbsp;<span
                          class="book-pending" *ngIf="element.is_veriifed">Verified</span>
                      </p>
                      <!-- <div class="ratings">
                        <i class="ion-ios-star"></i>
                        <i class="ion-ios-star"></i>
                        <i class="ion-ios-star"></i>
                        <i class="ion-ios-star"></i>
                        <i class="ion-ios-star-half"></i>
                      </div> -->
                      <div *ngIf="element.isCoworking == 1 || element.isShortterm == 1" class="views">Actual Name :
                        <span>{{element.actual_name}}</span></div>
                      <div *ngIf="element.isLongterm == 1" class="views">Building Name : {{element.buildingname}}</div>
                      <!-- <div *ngIf="element.isLongterm == 1" class="views">Space Title : <a href="{{website_base_url}}/private-office/{{formatUrl(element.contact_city_name)}}/{{formatUrl(element.location_name)}}/{{element.id}}">{{element.spaceTitle}}</a></div> -->
                      <div class="d-flex justify-content-between mt-2">
                        <div>
                          <mat-slide-toggle [checked]="element.status == 1" color="#f76900"
                            (click)="ActiveDeactiveSpace($event,element)">
                            {{!element.space_status_toggle ? 'Activate Space' : 'Active Space'}}
                          </mat-slide-toggle>
                        </div>
                        <div class="ms-5 me-4" *ngIf="element.spaceType == 'Coworking Space'">
                          Plan Name: <strong>{{element.planName}} </strong>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row listing-button d-flex flex-wrap position-relative-con mt-3">
                    <div class="col-6 mb-2">
                      <a (click)="openRibbonDialog(element.id,'tier')" class="btn v2 w-100 text-white"><i class="ion-edit"></i>Assign Tier</a>
                    </div>
                    <div class="col-6 mb-2">
                      <a (click)="review(element.id)" target="_blank" class="btn w-100 v2 text-white">Review</a>
                    </div>
                    <div class="col-6 mb-2">
                      <a (click)="openRibbonDialog(element.id,'ribbon')" class="btn w-100 v2 text-white"> <i
                          class="fa fa-arrow-right-arrow-left"></i> Assign
                        Ribbon</a>
                    </div>
                    <div class="col-6 mb-2">
                      <a *ngIf="element.spaceType == 'Coworking Space'" (click)="openRibbonDialog(element.id,'paywall')"
                        class="btn w-100 v2 text-white"> <i class="fa fa-arrow-right-arrow-left pe-1"></i>Manage
                        plan</a>
                    </div>

                  </div>
                  <div>



                    <!-- <button (click)="openDeleteSpaceDialog(element)" class="btn v5"><i class="ion-android-delete"></i>
                      Delete</button> -->

                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(i + 1) % 3 === 0 || i === spaces_data.length - 1" class="w-100"></div>
          </ng-container>
          <!-- <button *ngIf="displayedSpaces?.length < filtered_spaces_data?.length" (click)="loadMore()" class="btn btn-primary mt-3">See more</button> -->
        </div>
      </div>
      <mat-paginator [length]="filtered_spaces_data?.length" [pageSize]="pageSize" [pageSizeOptions]="[25,50,75,100]"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </div>
</div>

<!-- Dashboard Content ends-->