<div class="page-wrapper">
  <!--Sidebar Menu Starts-->
  <aside class="menu-sidebar js-right-sidebar d-block d-lg-none" [ngClass]="{'minimized': isMinimized}" 
  (mouseenter)="isMinimized = false;" (mouseleave)="isMinimized = true">
    <div class="logo">
      <a   routerLink="#">
        <img  [src]="isMinimized ? 'assets/images/iconlogo.png' : 'assets/images/logo.png'"  alt="logo" (click)="toggleSidebar()"/>
      </a>
    </div>
    <div *ngIf="isPermissionloading" class="menu-sidebar__content js-scrollbar2 ps ps--active-y">
      <nav class="navbar-sidebar2">
        <ul class="list-unstyled navbar__list">
          <li>
            <a (click)="minimizeSidebar()" routerLink="/home/dashboard" [routerLinkActive]="['active']">
              <i class="fas fa-tachometer-alt"></i>
              <span class="link-text">
                Dashboard
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Space')">
            <a (click)="minimizeSidebar()" routerLink="/home/spaces" [routerLinkActive]="['active']">
              <i class="fas fa-building"></i>
              <span class="link-text">
                Spaces
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Space','view')">
            <a (click)="minimizeSidebar()" routerLink="/home/add-space" [routerLinkActive]="['active']">
              <i class="fas fa-building"></i>
              <span class="link-text">
                Add Space
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Paywall')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #subscriptionaccordion="cdkAccordionItem" class="example-accordion-item" role="button"
                  tabindex="0" [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="subscriptionaccordion.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="subscriptionaccordion.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-credit-card me-5"></i>
                      <span class="link-text">
                        Paywall Subscription
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' "  role="region"
                    [style.display]="subscriptionaccordion.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + 1"
                    [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a (click)="minimizeSidebar()" routerLink="/home/plans-list" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Packages
                            </span>
                          </div>
                        </li>
                      </a>
                      <a (click)="minimizeSidebar()" routerLink="/home/subscription-inquiry" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Enterprise Inquiry <span class="pending_badge">{{subPendingQuery}}</span>
                            </span>
                          </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          
          </li>
          <li
            *ngIf="queriesService.hasSubmodulePermission('Credit Wallet', 'Add Point', 'add') || queriesService.hasSubmodulePermission('Credit Wallet', 'Package', 'view') ">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #accordionItem2="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                  [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="accordionItem2.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="accordionItem2.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-coins me-5"></i>
                      <span class="link-text">
                        Credit Wallet
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body"  [ngClass]="isMinimized && 'd-none' " role="region" [style.display]="accordionItem2.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + 1" [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="queriesService.hasSubmodulePermission('Credit Wallet', 'Add Point', 'view')"
                        (click)="minimizeSidebar()" routerLink="/home/credit-point" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Add Credits
                            </span>
                          </div>
                        </li>
                      </a>
                      <a *ngIf="queriesService.hasSubmodulePermission('Credit Wallet', 'Package', 'view')"
                        (click)="minimizeSidebar()" routerLink="/home/package-list" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Credit Plans
                            </span>
                          </div>
                        </li>
                      </a>
                      <a (click)="minimizeSidebar()" routerLink="/home/credit-inquiry" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Enterprise Inquiry <span class="pending_badge">{{creditPendingQuery}}</span>
                            </span>
                          </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          
          </li>
          <li *ngIf="canAccess('Leads')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #accordionItem3="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                  [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="accordionItem3.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="accordionItem3.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-user me-5"></i>
                      <span class="link-text">
                        Leads
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region" [style.display]="accordionItem3.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + 1" [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="queriesService.hasSubmodulePermission('Leads', 'Co Working', 'view')" (click)="minimizeSidebar()"
                        routerLink="/home/leads-co-working" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Co-working
                            </span>
                          </div>
                        </li>
          
                      </a>
                      <a *ngIf="queriesService.hasSubmodulePermission('Leads', 'Long Term', 'view')" (click)="minimizeSidebar()"
                        routerLink="/home/leads-long-term" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Long-term
                            </span>
                          </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          <li *ngIf="canAccess('Lead Price')">
            <a (click)="minimizeSidebar()" routerLink="/home/lead-price" [routerLinkActive]="['active']">
              <i class="fas fa-dollar-sign"></i>
              <span class="link-text">
                Lead Price
              </span>
            </a>
          </li>
          <li *ngIf="queriesService.hasSubmodulePermission('Bookings', 'List', 'view') || queriesService.hasSubmodulePermission('Bookings', 'Report', 'view')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #bookingAccordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                  [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="bookingAccordionItem.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="bookingAccordionItem.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-clipboard-list me-5"></i>
                      <span class="link-text">
                        Bookings
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region" [style.display]="bookingAccordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + 1" [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="queriesService.hasSubmodulePermission('Bookings', 'List', 'view')"  (click)="minimizeSidebar()"  routerLink="/home/booking-list" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i> 
                            <span class="link-text">
                              List
                            </span>
                          </div>
                        </li>
                      </a>
                      <!-- <a  (click)="minimizeSidebar()"  routerLink="/home/bookings" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Cancelled
                            </span>
                            </div>
                        </li>
                      </a> -->
                      <a *ngIf="queriesService.hasSubmodulePermission('Bookings', 'Report', 'view')"  (click)="minimizeSidebar()"  routerLink="/home/booking-report" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i class="fa-solid fa-circle fa-fw me-3"
                              style="font-size: 8px;"></i>
                              <span class="link-text">
                                Monthly Report
                              </span>
                            </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          <li *ngIf="canAccess('Invoice Management')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #invoiceAccordion="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                  [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="invoiceAccordion.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="invoiceAccordion.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-file-invoice me-5"></i>
                      <span class="link-text">
                        Invoice Management
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region" [style.display]="invoiceAccordion.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + 1" [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="queriesService.hasSubmodulePermission('Invoice Management', 'Paywall', 'view')"
                        (click)="minimizeSidebar()" routerLink="/home/paywall-transaction" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Paywall
                            </span>
                          </div>
                        </li>
          
                      </a>
                      <a *ngIf="queriesService.hasSubmodulePermission('Invoice Management', 'Credit Wallet', 'view')"
                        (click)="minimizeSidebar()" routerLink="/home/wallet-transaction" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                            <span class="link-text">
                              Flexo Wallet
                            </span>
                          </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          <li *ngIf="canAccess('Host View')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/host-view" [routerLinkActive]="['active']">
              <i class="fas fa-user me-5"></i>
              <span class="link-text">
                Host View
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('User View')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/user-view" [routerLinkActive]="['active']">
              <i class="fas fa-user me-5"></i>
              <span class="link-text">
                User View
              </span>
            </a>
          </li>
          <!-- <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/amenities" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Amenities
            </a>
          </li> -->
          <li *ngIf="canAccess('Amenities','add') || canAccess('Amenities','view')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                  tabindex="0" [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="accordionItem.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-wifi me-5"></i> 
                      <span class="link-text">
                        Amenities
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + 1"
                    [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="canAccess('Amenities','view')"  (click)="minimizeSidebar()"  routerLink="/home/amenities" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                Add
                              </span>
                            </div>
                        </li>

                      </a>
                      <a *ngIf="canAccess('Amenities','view')"  (click)="minimizeSidebar()"  routerLink="/home/amenities-list" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                List
                              </span>
                            </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          <li *ngIf="canAccess('Visit Schedule')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/visit-schedule" [routerLinkActive]="['active']">
              <i class="fas fa-calendar-check"></i>
              <span class="link-text">
                Visit Schedule
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Cancellation Policy')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/cancellation-policy" [routerLinkActive]="['active']">
              <i class="fas fa-times-circle"></i>
              <span class="link-text">
                Cancellation Policy
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Space Request','view')" >
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #accordionItem1="cdkAccordionItem" class="example-accordion-item" role="button"
                  tabindex="0" [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="accordionItem1.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="accordionItem1.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-building me-5"></i> 
                      <span class="link-text">
                        Space Listing Request <span class="pending_badge">{{totalPendingRequest}}</span>
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region"
                    [style.display]="accordionItem1.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + 1"
                    [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="canAccess('Space Request','view')"  (click)="minimizeSidebar()"  routerLink="/home/space-request-create" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                New Listing <span class="pending_badge">{{totalNewListing}}</span>
                              </span>
                            </div>
                        </li>

                      </a>
                      <a *ngIf="canAccess('Space Request','view')"  (click)="minimizeSidebar()"  routerLink="/home/space-request-edit" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                Edit Listing <span class="pending_badge">{{totalEditListing}}</span>
                              </span>
                            </div>
                        </li>
                      </a>

                      <a *ngIf="canAccess('Space Request','view')"  (click)="minimizeSidebar()"  routerLink="/home/space-request-delete" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                Delete Listing <span class="pending_badge">{{totalDeleteListing}}</span>
                              </span>
                            </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          <!-- <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/space-request-create" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Space Request Create
            </a>
          </li>
          <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/space-request-edit" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Space Request Edit
            </a>
          </li>
          <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/space-request-delete" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Space Request Delete
            </a>
          </li> -->
          <!-- <li class="has-sub">
            <a class="js-arrow"  (click)="minimizeSidebar()"  routerLink="#">
              <i class="ion-social-buffer-outline"></i>My Listings
            </a>
            <ul class="list-unstyled navbar__sub-list js-sub-list">
              <li>
                <a  (click)="minimizeSidebar()"  routerLink="db-my-listing.html">Active</a>
                <span class="inbox-num active">6</span>
              </li>
              <li>
                <a  (click)="minimizeSidebar()"  routerLink="db-my-listing.html">Pending</a>
                <span class="inbox-num pending">8</span>
              </li>
            </ul>
          </li>-->
          <li *ngIf="canAccess('Managers')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/managers" [routerLinkActive]="['active']">
              <i class="fas fa-user-tie"></i>
              <span class="link-text">
                Managers
              </span>
            </a>
          </li>

          <!-- <li *ngIf="canAccess('Workers')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/workers" [routerLinkActive]="['active']">
              <i class="ion-ios-copy-outline"></i>
              <span class="link-text">
                Workers
              </span>
            </a>
          </li> -->
          <li *ngIf="canAccess('Ribbon','add') || canAccess('Ribbon','view')">
            <a>
              <cdk-accordion class="example-accordion">
                <cdk-accordion-item #ribbonAccordion="cdkAccordionItem" class="example-accordion-item" role="button"
                  tabindex="0" [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="ribbonAccordion.expanded"
                  [attr.aria-controls]="'accordion-body-' + 1">
                  <div class="example-accordion-item-header" (click)="ribbonAccordion.toggle()">
                    <span class="example-accordion-item-description">
                      <i class="fas fa-ribbon me-5"></i> 
                      <span class="link-text">
                        Ribbon
                      </span>
                    </span>
                  </div>
                  <div class="example-accordion-item-body" [ngClass]="isMinimized && 'd-none' " role="region"
                    [style.display]="ribbonAccordion.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + 1"
                    [attr.aria-labelledby]="'accordion-header-' + 1">
                    <ul class="p-0 m-0">
                      <a *ngIf="canAccess('Ribbon','view')"   (click)="minimizeSidebar()"  routerLink="/home/add-ribbon" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                Add
                              </span>
                            </div>
                        </li>

                      </a>
                      <a *ngIf="canAccess('Ribbon','view')"  (click)="minimizeSidebar()"  routerLink="/home/ribbon-list" [routerLinkActive]="['active']">
                        <li class="amenityList">
                          <div class="ms-5 bg-transparent border-0 text-white aminitytext"><i
                              class="fa-solid fa-circle fa-fw me-3" style="font-size: 8px;"></i>
                              <span class="link-text">
                                List
                              </span>
                            </div>
                        </li>
                      </a>
                    </ul>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
            </a>
          </li>
          
          <li *ngIf="canAccess('Send Proposal')">
            <a (click)="sendProposalDialog()">
              <i class="fas fa-envelope"></i>
              <span class="link-text">
                Send Proposal
              </span>
            </a>
          </li>
          <li *ngIf="canAccess('Queries')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/queries" [routerLinkActive]="['active']">
              <i class="fas fa-question-circle"></i>
              <span class="link-text">
                Queries
              </span>
            </a>
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <li *ngIf="canAccess('Department')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/add-permission" [routerLinkActive]="['active']">
              <i class="fas fa-layer-group"></i>
              <span class="link-text">
                Department
              </span>
            </a>
          </li>
          <!-- <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/teamcabins" [routerLinkActive]="['active']">
              <i class="ion-ios-email-outline"></i>Teamcabins</a
            >
            <span class="inbox-num pending">3</span>
          </li> -->
          <!-- <li>
            <a  (click)="minimizeSidebar()"  routerLink="/home/meeting-rooms" [routerLinkActive]="['active']">
              <i class="ion-ios-email-outline"></i>Meeting Rooms</a
            >
            <span class="inbox-num pending">3</span>
          </li> -->
          <li *ngIf="canAccess('FAQs')">
            <a  (click)="minimizeSidebar()"  routerLink="/home/faqs" [routerLinkActive]="['active']">
              <i class="ionicons ion-help"></i>
              <span class="link-text">
                FAQs
              </span>
            </a>
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <!-- <li class="has-sub">
            <a class="js-arrow"  (click)="minimizeSidebar()"  routerLink="#">
              <i class="ion-ios-star-outline"></i>Reviews
            </a>
            <ul class="list-unstyled navbar__sub-list js-sub-list">
              <li>
                <a  (click)="minimizeSidebar()"  routerLink="db-recieve-reviews.html">
                  Recieved Reviews</a>
                <span class="inbox-num active">6</span>
              </li>
              <li>
                <a  (click)="minimizeSidebar()"  routerLink="db-submit-reviews.html">
                  Submitted reviews</a>
                <span class="inbox-num pending">8</span>
              </li>
            </ul>
          </li> -->
          <!-- <li>
            <a  (click)="minimizeSidebar()"  routerLink="db-my-profile.html">
              <i class="ion-ios-person-outline"></i>My profile</a>
          </li>
          <li>
            <a  (click)="minimizeSidebar()"  routerLink="db-invoices.html">
              <i class="ion-ios-printer-outline"></i>Invoices</a>
          </li>-->
          <li *ngIf="canAccess('Review')">
            <a (click)="minimizeSidebar()" routerLink="/home/all-spaces-review" [routerLinkActive]="['active']">
              <i class="fas fa-star"></i>
              <span class="link-text">
                Reviews <span class="pending_badge">{{totalPendingReviews}}</span>
              </span>
            </a>
          </li>
          <li>
            <a (click)="openLogoutDialog()">
              <i class="fas fa-sign-out-alt"></i>
              <span class="link-text">
                Logout
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <div class="link-text shadow-sm d-flex mb-3 align-items-center position-sticky bg-light" [ngClass]="isMinimized ? 'minimized-text' : 'expanded-text'" style="height: 80px;top:0;z-index: 99;"> 
    <i class="fa-solid fa-xl ms-2 cursor-pointer p-3" (click)="toggleSidebar()"  [ngClass]="isMinimized ? 'fa-chevron-right' : 'fa-chevron-left'"></i>
  </div>
  <!--Sidebar Menu ends-->
  <!--Dashboard content Wrapper starts-->
  <div class="link-text" [ngClass]="isMinimized ? 'minimized-text' : 'expanded-text'">
    <div [hidden]="!objLoaderStatus">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="header-button-item js-sidebar-btn" *ngIf="isMobile" style="padding: 10px; display: flex">
      <span style="flex: 1"><i style="font-size: 20px" class="ion-android-menu"></i> &nbsp;
        &nbsp;</span>
      <span style="flex: 2"><img src="assets/images/logo.png"
          style="border-radius: 0px; height: inherit; width: inherit" alt="Listagram" />
      </span>
    </div>
    <div class="dash-content" style="height: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>