<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Permission List</h3>
      </div>
      <div class="card-body">
        <div class="card tabs-menu-body">
          <div class="card-header ps-2">
            <h3 class="card-title">Permission</h3>
          </div>
          <div class="card-body">
            <div>
              <div class="col-md-12">
                <div class="div align-items-center" style="display: flex; justify-content: end;gap:30px">
                  <label class="form-label">Search</label>
                  <input type="text" matInput (input)="applyFilter($event.target)" placeholder="Type to search" >
                </div>
              </div>
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">

                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr. No. </th>
                    <td mat-cell *matCellDef="let row; let i = index">{{ (paginator.pageIndex *
                      paginator.pageSize) + (i + 1) }} </td>

                  </ng-container>

                  <ng-container matColumnDef="departmentName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.departmentName | titlecase}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> <mat-slide-toggle [checked]="row.status === '1' " (click)="changeStatus($event,row)"> </mat-slide-toggle>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                      <button (click)="updateDetails(row.id)"  mat-mini-fab color="primary" class="me-4">
                       <i class="fa-solid fa-pen text-white"> </i>
                      </button>
                    </td>
                  </ng-container>
                  

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row text-center" *matNoDataRow>
                    <td class="mat-cell" colspan="10">No data matching the filter</td>
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator pageIndex="0" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                [showFirstLastButtons]="true" [length]="dataSource?.data?.length">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>