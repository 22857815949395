<div>
  <h1 class="text-center">Get Qualified Leads To Boost Your Occupancy and Revenue.
    <br> Explore Our Lead Credit Packages to Purchase Leads!
  </h1>
  <h2 class="text-center">NO BROKERAGE ON PURCHASED LEADS. <br> <small>(Broker Commission Applicable on leads not
      purchased)</small> </h2>
  <h2 class="text-center fs-4 py-4">Flexo Credits Packages</h2>
</div>
<div class="row main_row">
  <div  class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 [ngClass]="packagesList.length || 'invisible' " class="card-title">Active Flexo Credits Packages</h3>
        <a (click)="navigateToAdd()" class="btn v3"><i class="ion-plus-round"></i>Create Package
        </a>
      </div>
      <div *ngIf="packagesList.length" class="card-body">
        <div class="row" style="row-gap: 20px;">
          <ng-container *ngFor="let list of packagesList;let i = index">
            <div class="col-md-3">
              <div class="card plan_card h-100 border-0 sub_card"
                [ngClass]="getCardClass(i)">
                <div class="card-body p-0 bg-white">
                  <div class="d-flex gap-1 flex-column px-3">
                    <div class="text-center d-block subs_title color_accent" >
                      <h1 class="card-title title-font text-white">{{list.title | titlecase}}</h1>
                      <div class="price-value text-center px-5 text-white d-flex align-items-center justify-content-center">{{list.amount | currency :'INR': 'symbol' : '1.0-0'}}</div>
                       <div class="text-center text-white mt-2">Flexo Credits: {{list.creditPoint}}</div>
                    </div>
                    <div class="price-list">
                      <ul>
                        <li class="d-flex" *ngFor="let item of list.discription" [innerHTML]="item">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex rounded-0 px-3 justify-content-between align-items-center card-footer">
                  <mat-slide-toggle (click)="confirmation($event,list)" checked> </mat-slide-toggle>
                  <button (click)="edit(list.id)" class="btn h-100 bg-transparent p-0"><i class="fa fa-edit fa-xl"></i></button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="deactivePackagesList.length" class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-center">
        <h3 class="card-title">De-Active Flexo Credits Packages</h3>
      </div>
      <div class="card-body">
        <div class="row" style="row-gap: 20px;">
          <ng-container *ngFor="let list of deactivePackagesList;let i = index">
            <div class="col-md-3">
              <div class="card plan_card active_card_plan h-100 border-0 sub_card"
                [ngClass]="getCardClass(i)">
                <div class="card-body p-0 bg-white">
                  <div class="d-flex gap-1 flex-column px-3">
                    <div class="text-center d-block subs_title color_accent2" >
                      <h1 class="card-title title-font text-white" >{{list.title | titlecase}}</h1>
                      <div class="price-value text-white">{{list.amount | currency :'INR': 'symbol' : '1.0-0'}}</div>
                      <div class="mt-2 text-white">Flexo Credits: {{list.creditPoint}}</div>
                    </div>                
                    <div class="price-list">
                      <ul>
                        <li class="d-flex" *ngFor="let item of list.discription" [innerHTML]="item">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex rounded-0 px-3 justify-content-between align-items-center card-footer">
                  <mat-slide-toggle (click)="confirmation($event,list)"> </mat-slide-toggle>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>