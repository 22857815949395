import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
// import { MatOption } from '@angular/material/option';
// import { MatOption } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { LoaderService } from '../services/loader.service';
// import { GlobalVariables } from '../global/global-variables';

import { ManagerService } from '../services/manager.service';
import { QueriesService } from '../services/queries.service';
import { DeleteManagerDialog } from './delete-manager-dialog.component';
import { UpdateManagerDialog } from './update-manager-dialog.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-managers',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagersComponent implements OnInit {
  public managers_data = [];
  public selected_managers_data = [];
  public search_string;
  public ManagersDataSource;
  pageSize = 25; // Default page size
  currentPage = 0;
  paginatedBookings = [];

  constructor(
    private loaderService: LoaderService,
    public manager_dialog: MatDialog,
    public snackBar: MatSnackBar,
    public manager_viewContainerRef: ViewContainerRef,
    private _managerservice: ManagerService,
    private queryService: QueriesService,
  ) { }
  manager_dialogRef: MatDialogRef<any>
  selection = new SelectionModel<Worker>(true, []);

  ManagersDisplayedColumns: string[] = ['serial','id', 'name', 'space_name', 'admin', 'status'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.populateManagers();
  }

  public populateManagers() {
    this._managerservice.getAllDetails()
      .then(res => {
        if (res.success) {
          this.managers_data = Object.assign([], res.data);
          this.selected_managers_data = this.managers_data;
          this.updatePaginatedBookings();
          this.ManagersDataSource = new MatTableDataSource(this.selected_managers_data)
          this.ManagersDataSource.sort = this.sort;
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }
  applyFilter(filterValue: string) {
    this.ManagersDataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearSearchInput() {
    this.search_string = '';
    this.applyFilter('');
  }
  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openEditManagerDialog(manager_obj, action_type, type: string) {
    if (this.queryService.hasPermission('Managers', type)) {
      let config = new MatDialogConfig();
      config.viewContainerRef = this.manager_viewContainerRef;
      config.disableClose = true;
      config.minWidth = '80vw';

      this.manager_dialogRef = this.manager_dialog.open(UpdateManagerDialog, config);
      this.manager_dialogRef.componentInstance.ref = this.manager_dialogRef;
      this.manager_dialogRef.componentInstance.selected_manager = manager_obj;
      this.manager_dialogRef.componentInstance.action_type = action_type;
      this.manager_dialogRef.afterClosed().subscribe(result => {
        if (result && result.success) {
          this.populateManagers();
        }
        this.manager_dialogRef = null;
      });
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  openDeleteManagerDialog(manager_obj) {
    if (this.queryService.hasPermission('Managers', 'delete')) {
      let config = new MatDialogConfig();
      config.viewContainerRef = this.manager_viewContainerRef;
      config.disableClose = true;

      this.manager_dialogRef = this.manager_dialog.open(DeleteManagerDialog, config);
      this.manager_dialogRef.componentInstance.ref = this.manager_dialogRef;
      this.manager_dialogRef.componentInstance.selected_manager = manager_obj;
      this.manager_dialogRef.afterClosed().subscribe(result => {
        if (result && result.success) {
          this.populateManagers();
        }
        this.manager_dialogRef = null;
      });
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.managers_data.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }
}