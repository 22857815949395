import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
import { AppGlobals } from "./app-globals";

@Injectable()
export class SpaceService {
  private _selectedLocationId;
  statusRemark: string;

  constructor(private http: HttpClient, private _appGlobals: AppGlobals, private snackBar: MatSnackBar) {
    this._appGlobals.selectedLocationId.subscribe(
      (id) => (this._selectedLocationId = id)
    );
  }

  private headers = new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")},`,
  });

  private base_url = "api/v1/admin";

  private options = {
    headers: this.headers,
    withCredentials: true,
  };

  openSnackBar(message: string, action = "Dismiss") {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private getAllUrl = environment.apiUrl + this.base_url + "/spaces";
  private ggetSpacesByAreaForProposalsUrl =
    environment.apiUrl + "api/v1/spaces/getSpacesByAreaForProposals";
  private getSpacesByCityIdForProposalsUrl =
    environment.apiUrl + `api/v1/spaces/getSpacesByCityIdForProposals`;
  // private getSpacesByCityId =
  //   environment.apiUrl + `api/v1/spaces/getSpacesByCityId`;
  private updateUrl = 
    environment.apiUrl + this.base_url + "/update_space";
  private SpaceActiveDeactive = 
    environment.apiUrl + this.base_url + "/update_space_status";
  private updateCoverImageUrl =
    environment.apiUrl + this.base_url + "/updateCoverImage";
  private deleteUrl = 
    environment.apiUrl + this.base_url + "/deleteSpace";
  private addUrl = 
    environment.apiUrl + this.base_url + "/add_space";
  private deleteDetailsImageUrl =
    environment.apiUrl + this.base_url + "/deleteDetailsImage";
  private sendMailForLeadsUrl =
    environment.apiUrl + this.base_url + "/sendMailForLead";
  private getSpaceDetailsUrl =
    environment.apiUrl + this.base_url + "/getSpaceDetailsForAdmin";
  private getViewSpaceDetailsUrl =
    environment.apiUrl + "api/superAdmin/" + "spaceRequestListById";
  private uploadImagesUrl =
    environment.apiUrl + "api/superAdmin" + "/uploadImages";
  private deleteImagesUrl =
    environment.apiUrl + this.base_url + "/deleteImages";
  private getAllCountries = 
    environment.apiUrl + "api/host/getAllCountries";
  private getAllStates = 
    environment.apiUrl + "api/host/getAllStatesById";
  private getAllCity = 
    environment.apiUrl + "api/host//getAllCities";
  private getFlexibleUrl =
    environment.apiUrl + "api/superAdmin/cancellationPolicy/Flexible";
  private getModerateUrl =
    environment.apiUrl + "api/superAdmin/cancellationPolicy/Moderate";
  private getStrictUrl =
    environment.apiUrl + "api/superAdmin/cancellationPolicy/Strict";
  private getHostUrl = 
    environment.apiUrl + "api/host/hostlist/";
  private filterSpaces = 
    environment.apiUrl + "api/v1/admin/getAllSpaces";
  private filterSpaces2 = 
    environment.apiUrl + "api/v1/admin/spaces";
  private getAllSpaceNameByHostIdUrl = 
    environment.apiUrl + "api/v1/admin/spaceList";
  private getAllHostSpacesUrl = 
    environment.apiUrl + "api/v1/admin/bookings";
  private getHostSpaceDetailsUrl =
    environment.apiUrl + "api/v1/admin/bookingDetails";
  private getHostVisitUrl = 
    environment.apiUrl + "api/v1/admin/getUserVisit";
    private postAmenitiesUrl =
    environment.apiUrl + "api/superAdmin/createAmenities";
  private getAmenitiesUrl = 
    environment.apiUrl + "api/superAdmin/getAllAmenities";
  private getAllHostUrl = 
    environment.apiUrl + "api/v1/admin/getAllHosts";
  private getAllUserUrl = 
    environment.apiUrl + "api/v1/admin/getAllUsers";
  private activeDeactiveHostUrl = 
    environment.apiUrl + "api/v1/admin/updateHostStatus";
  private activeDeactiveuserUrl = 
    environment.apiUrl + "api/v1/admin/updateUserStatus";
  private getCancellationPolicyUrl =
    environment.apiUrl + "api/superAdmin/cancellationPolicy/all";
  private getCancellationPolicyByIdUrl =
    environment.apiUrl + "api/superAdmin/getCancellationPolicy/";
  private updateCancellationPolicyUrl =
    environment.apiUrl + "api/superAdmin/updatecancellationPolicy";
  private updateAmenityUrl = 
    environment.apiUrl + "api/superAdmin/updateAmenities/";
  private getAmenityByIdUrl =
    environment.apiUrl + "api/superAdmin/geAmenitiesById/";
  private deleteAmenityUrl =
    environment.apiUrl + "api/superAdmin/deleteAmenities/";
  private getAllCancellationPolicyUrl =
    environment.apiUrl + "api/superAdmin/getCancellationPolicy";
  private getAllReviewByIdUrl =
    environment.apiUrl + "api/v1/admin/getAllReviewsById/";
  private getReviewById = 
    environment.apiUrl + "api/v1/admin/getAllReviewsById/";
  private updateReview =
    environment.apiUrl + "api/v1/admin/updateUserRating/";
  private getActiveSpacesType = 
    environment.apiUrl + "api/v1/admin/getAllActiveSpaceType";
  private getProfileUrl =
    environment.apiUrl + 'api/v1/admin/profile';
  private getUserProfileUrl =
    environment.apiUrl + 'api/v1/admin/viewUserProfile/';
  private getHostProfileUrl =
    environment.apiUrl + 'api/v1/admin/getHostProfileDataById/'
  private hostProfileUrl =
    environment.apiUrl + 'api/v1/admin/updateHostProfile';
  private adminProfileUrl =
    environment.apiUrl + 'api/v1/admin/updateProfile';
  private resetProfilePasswordUrl =
    environment.apiUrl + 'api/host/changeUserPassword'
  private filterbookingReportUrl =
    environment.apiUrl + 'api/v1/admin/getBookingReport'

  private userEmailSource = new BehaviorSubject<string>('');
  currentUserEmail = this.userEmailSource.asObservable();

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  private _options = {
    headers: this.headers,
    withCredentials: true,
  };

  getAll(search_string): Promise<any> {
    let search_condition = search_string ? `?search=${search_string}` : ``;

    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return this.http
      .get(this.getAllUrl + search_condition, {
        withCredentials: true,
        headers: headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  
  getFilteredBookingReport(id:number,month:any,year:any): Promise<any> {
    return this.http
      .get(`${this.filterbookingReportUrl}?hostId=${id}&month=${month}&year=${year}`, this._options)
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }


  getSpacesByCityIdForProposals(params): Promise<any> {
    return this.http
      .post(this.getSpacesByCityIdForProposalsUrl, JSON.stringify(params), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  updateCoverImage(cover_image, space_id): Promise<any> {
    return this.http
      .post(
        this.updateCoverImageUrl,
        JSON.stringify({ cover_image, space_id }),
        { headers: this.headers, withCredentials: true }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  sendMailForLead(send_proposal): Promise<any> {

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return this.http
      .post(this.sendMailForLeadsUrl, send_proposal, {
        withCredentials: true,
        headers: headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpacesByAreaForProposals(params): Promise<any> {
    let data = {
      lat: params.area_lat,
      longi: params.area_long,
      resource_type: params.resource_type,
    };
    return this.http
      .post(this.ggetSpacesByAreaForProposalsUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getAllActiveSpaceType(formData:any){
    return this.http.post(this.getActiveSpacesType ,{"hostId":formData}, this.options);
  }

  update(space_id, data): Promise<any> {
    return this.http
      .put(this.updateUrl + "/" + space_id, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }
  
  ActiveDeactiveSpace(space_id, data): Promise<any> {
    return this.http
      .put(this.SpaceActiveDeactive + "/" + space_id, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  delete(space_id): Promise<any> {
    return this.http
      .delete(this.deleteUrl + "/" + space_id, {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpaceDetails(space_id): Promise<any> {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return this.http
      .get(this.getSpaceDetailsUrl + "/" + space_id, {
        withCredentials: true,
        headers: headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }
  
  getViewSpaceDetails(space_id): Promise<any> {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return this.http
      .get(this.getViewSpaceDetailsUrl + "/" + space_id, {
        withCredentials: true,
        headers: headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  add(data): Promise<any> {
    return this.http
      .post(this.addUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  deleteDetailsImage(space_id, img): Promise<any> {
    return this.http
      .post(
        this.deleteDetailsImageUrl + "/" + img,
        JSON.stringify({ space_id }),
        { headers: this.headers, withCredentials: true }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  uploadImages(space_id, formData): Promise<any> {

    return this.http
      .post(this.uploadImagesUrl + "/" + space_id, formData, {
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  deleteImages(space_id, images): Promise<any> {
    return this.http
      .post(
        this.deleteImagesUrl + "/" + space_id,
        { images },
        { withCredentials: true }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getAllSpacesCities(): Observable<any> {
    return this.http.get(
      environment.apiUrl + "api/v1/spaces/getAllSpacesCities"
    );
  }

  getSpacesByCity(cityId): Observable<any> {
    return this.http.get(
      environment.apiUrl + "api/v1/spaces/getSpacesByCityId/" + cityId
    );
  }

  getNearBySpacesByCityName(data): Observable<any> {
    return this.http.post(
      environment.apiUrl + "api/v1/spaces/getNearBySpacesByCityId",data
    );
  }

  getNearBySpacesByCityNameFaq(cityName:string): Observable<any> {
    return this.http.get(
      environment.apiUrl + `api/v1/spaces/getNearBySpacesByCityIdFaq/${cityName}`
    );
  }

  getSpacesByCityAndLocation(city_id, location_name): Observable<any> {
    return this.http.post(
      environment.apiUrl + "api/v1/spaces/getSpacesByCityAndLocation",
      { city_id, location_name }
    );
  }

  getAllCountry() {
    return this.http.get(this.getAllCountries);
  }

  getAllState(cityId: any) {
    let search_condition = `?countryId=${cityId}`;
    return this.http.get(this.getAllStates + search_condition);
  }

  getAllCities(stateId: any) {
    let search_condition = `?stateId=${stateId}`;
    return this.http.get(this.getAllCity + search_condition);
  }

  filterSpace(
    country_search: any,
    category_search: any,
    state_search: any,
    city_search: any
  ) {
    return this.http.get(this.filterSpaces, {
      headers: this.headers,
      withCredentials: true,
      params: {
        country_search: country_search,
        category_search: category_search,
        state_search: state_search,
        city_search: city_search,
      },
    });
  }

  filterSpace2(
    country_search: any,
    category_search: any,
    state_search: any,
    city_search: any
  ) {
    return this.http.get(this.filterSpaces2, {
      headers: this.headers,
      withCredentials: true,
      params: {
        country_search: country_search,
        category_search: category_search,
        state_search: state_search,
        city_search: city_search,
      },
    });
  }

  getFlexible(): Observable<any> {
    return this.http.get(this.getFlexibleUrl);
  }

  getModerate(): Observable<any> {
    return this.http.get(this.getModerateUrl, this.options);
  }

  getStrict(): Observable<any> {
    return this.http.get(this.getStrictUrl, this.options);
  }

  getHost(): Observable<any> {
    return this.http.get(this.getHostUrl, this.options);
  }

  getAllReviewBySpaceId(spaceId: any, data: any): Promise<any> {
    return this.http
      .post(
        environment.apiUrl + `api/v1/admin/spaces/${spaceId}/reviews`,
        data,
        this.options
      )
      .toPromise()
      .then((res) => 
        res
      )
      .catch(this.handleError);
  }

  getReviewStatus(id: any, data:any, remark?:any): Promise<any> {
    return this.http
      .put(
        environment.apiUrl + `api/v1/admin/ratings/approval`,
        {
          'approved':data,
          'id': id,
          'remark': remark
        },
        this.options
      )
      .toPromise()
      .then((res) => 
        res
      )
      .catch(this.handleError);
  } 
  
  spaceCreateApproveRequest(id: any, data:any, remark?:any): Promise<any> {
    if(data == 0){
      this.statusRemark = "Rejected"
    }else{
      this.statusRemark = "Approved"
    }
    return this.http
      .post(
        environment.apiUrl + `api/superAdmin/spaceCreateApproveRequest`,
        {
          'spaceCreateRequest':data,
          'SpaceRequestId': id,
          'reason': remark,
          'status': this.statusRemark
        },
        this.options
      )
      .toPromise()
      .then((res) => 
        res
      )
      .catch(this.handleError);
  }
  
  spaceDeleteApproveRequest(id: any, data:any, remark?:any): Promise<any> {
    if(data == 0){
      this.statusRemark = "Rejected"
    }else{
      this.statusRemark = "Approved"
    }
    return this.http
      .post(
        environment.apiUrl + `api/superAdmin/spaceDeleteApproveRequest`,
        {
          'spaceCreateRequest':data,
          'SpaceRequestId': id,
          'reason': remark,
          'status': this.statusRemark
        },
        this.options
      )
      .toPromise()
      .then((res) => 
        res
      )
      .catch(this.handleError);
  }

  spaceEditApproveRequest(id: any, data:any, remark?:any): Promise<any> {
    if(data == 0){
      this.statusRemark = "Rejected"
    }else{
      this.statusRemark = "Approved"
    }
    return this.http
      .post(
        environment.apiUrl + `api/superAdmin/spaceUpdateApproveRequest`,
        {
          'spaceCreateRequest':data,
          'SpaceRequestId': id,
          'reason': remark,
          'status': this.statusRemark
        },
        this.options
      )
      .toPromise()
      .then((res) => 
        res
      )
      .catch(this.handleError);
  }

  getAllReviews(data:any){
    return this.http.post(environment.apiUrl + `api/v1/admin/getAllReviews`, data, this.options).toPromise().then((res) => res).catch(this.handleError);
  }
  
  spaceDeleteRequest(data:any){
    return this.http.post(environment.apiUrl + `api/superAdmin/spaceDeleteRequestList`, data, this.options).toPromise().then((res) => res).catch(this.handleError);
  }
  
  spaceUpdateRequest(data:any){
    return this.http.post(environment.apiUrl + `api/superAdmin/spaceUpdateRequestList`, data, this.options).toPromise().then((res) => res).catch(this.handleError);
  }
  
  spaceCreateRequest(data:any){
    return this.http.post(environment.apiUrl + `api/superAdmin/spaceCreateRequestList`, data, this.options).toPromise().then((res) => res).catch(this.handleError);
  }

  getAllSpaceNameByHostId(){
    return this.http.get(this.getAllSpaceNameByHostIdUrl, this.options);
  }

  getAllHostSpaces(data:any){
      return this.http.post(this.getAllHostSpacesUrl,data, this.options);
  }

  getHostSpaceDetail(id:any){
    return this.http.get(this.getHostSpaceDetailsUrl+`/${id}`, this.options);
  }

  getHostVisit(data:any){
    return this.http.post(this.getHostVisitUrl,data, {
      headers: this.headers,
      withCredentials: true,});
  }

  handleImageError(event:any){
    const imgElement=event.target as HTMLImageElement
    imgElement.src = 'assets/images/details_placeholder_image.jpg';
    imgElement.alt = 'Failed to Load Image';
  }

  postAmenities(data:any){
    return this.http.post(this.postAmenitiesUrl, data, this.options);
  }

  getAmenities(){
    return this.http.get(this.getAmenitiesUrl, this.options);
  }
  
  getAllHosts(){
    return this.http.get(this.getAllHostUrl, this.options);
  }
  
  getAllUsers(){
    return this.http.get(this.getAllUserUrl, this.options);
  }

  activeDeactiveHost(payload:any){
    return this.http.post(this.activeDeactiveHostUrl,payload, this.options);
  }
  
  activeDeactiveUser(payload:any){
    return this.http.post(this.activeDeactiveuserUrl,payload, this.options);
  }

  getAmenityById(id:any){
    return this.http.get(this.getAmenityByIdUrl + id, this.options);
  }

  updateAmenity(id:any, data:any){
    return this.http.put(this.updateAmenityUrl + id, data, this.options);
  }

  deleteAmenity(id:any){
    return this.http.delete(this.deleteAmenityUrl + id, this.options);
  }

  getCancellationPolicy(){
    return this.http.get(this.getCancellationPolicyUrl, this.options);
  }

  getCancellationByIdPolicy(id:any){
    return this.http.get(this.getCancellationPolicyByIdUrl + id, this.options);
  }

  updateCancellationPolicy(data:any){
    return this.http.post(this.updateCancellationPolicyUrl, data, this.options);
  }

  getAllCancellationPolicy(){
    return this.http.get(this.getAllCancellationPolicyUrl, this.options);
  }

  getAllReviewById(id:any){
    return this.http.get(this.getAllReviewByIdUrl + id, this.options);
  }

  getOneReviewById(id:any){
    return this.http.get(this.getReviewById+id, this.options);
  }

  updateMyReview(id:any,spaceId:any,data:any){
    return this.http.post(this.updateReview+id+'/'+spaceId, data, this.options);
  }

  getProfile(): Observable<any> {
    return this.http.get(this.getProfileUrl, this.options);
  }

  getAdminProfile(id:any){
    return this.http.get(this.getUserProfileUrl + id, this.options);
  }

  getHostProfile(id:any):Observable<any>{
    return this.http.get(this.getHostProfileUrl + id, this.options);
  }

  updateHostProfile(formData:any): Observable<any> {
    return this.http.put(this.hostProfileUrl, formData, this.options);
  }

  updateAdminProfile(formData:any): Observable<any> {
    return this.http.post(this.adminProfileUrl, formData, this.options);
  }

  setUserEmail(email: string) {
    this.userEmailSource.next(email);
  }

  resetPassword(email: string, resetFormData: any): Observable<any> {
    const url = `${this.resetProfilePasswordUrl}/${email}`;
    return this.http.post(url, resetFormData, this.options);
  }
}
