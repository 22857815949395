<!--Dashboard breadcrumb ends-->

<!-- Dashboard Content starts-->
<div class="row dash-content-wrap2" style="padding: 20px 5px;">
  <div class="col-md-12">
    <div class="dash-breadcrumb-content">
      <div class="dash-breadcrumb-left">
        <div class="breadcrumb-menu text-right sm-left">
          <p class="list-address">Showing <b>{{teamcabin_data?.length}}</b> results</p>
        </div>
      </div>
      <button (click)="openEditTeamcabinDialog(null, 1)" class="btn v3"><i class="ion-plus-round"></i>Add Team Cabin
      </button>
    </div>
  </div>
</div>
<div class="row dash-content-wrap2">
  <div class="col-md-12">
    <div class="recent-activity my-listing">
      <div class="act-title">
        <h5><i class="ion-social-buffer-outline"></i>Team Cabins</h5>
        <div class="row">
          <!-- <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px;">
              <mat-label>Search space name</mat-label>
              <input type="text" matInput [(ngModel)]="search_text" placeholder="Search teamcabin name">
              <mat-icon *ngIf="search_text" matSuffix style="cursor: pointer;" (click)="clearSearchInput()">
                cancel
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6" style="align-self: center;">
            <button class="btn v8" [disabled]="!search_text" (click)="applyFilter()">Search</button>
          </div> -->
        </div>
      </div>
      <div class="viewd-item-wrap" *ngFor="let element of teamcabin_data">
        <div class="most-viewed-item">
          <!--  <div class="most-viewed-img">
            <a href="#"><img src="images/single-listing/gallery-6.jpg" alt="..."></a>
          </div> -->
          <div class="most-viewed-detail">
            <!-- <a class="category" href="#"><span class="list-bg aqua"><i class="icofont-hotel"></i></span>Hotel</a> -->
            <!-- <div style="align-self: center;" [ngStyle]="{'color':'green'}">
                                                <i class="material-icons">verified</i>
                                              </div> -->
            <h3>
              {{element.name}}<span class="book-cancel" *ngIf="!element.is_active">In-Active</span><span
                class="book-approved" *ngIf="element.is_active">Active</span>
            </h3>
            <!-- <p class="list-address"><i class="icofont-google-map"></i>{{element.city_name}}</p> -->
            <div class="views">Price : <span>{{element.price}}</span></div>
            <div class="views">Capacity : <span>{{element.capacity}}</span></div>
          </div>
          <div class="listing-button">
            <button (click)="openEditTeamcabinDialog(element, 2)" class="btn v2"><i class="ion-edit"></i> Edit</button>
            <button (click)="openDeleteTeamcabinDialog(element)" class="btn v5"><i class="ion-android-delete"></i>
              Delete</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<!-- Dashboard Content ends-->