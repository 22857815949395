import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-box',
  templateUrl: './confirm-dialog-box.component.html',
  styleUrls: ['./confirm-dialog-box.component.css']
})
export class ConfirmDialogBoxComponent {
  message: any;
  status: any;
  show:any

  constructor(public dialogRef: MatDialogRef<ConfirmDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}

    ngOnInit(): void {
      this.message = this.data?.message;
      this.status = this.data?.status;
      this.show = this.data?.show;
    }

  
    onYesClick(): void {
      // Close the dialog and pass 'true' as the result
      this.dialogRef.close(true);
    }
  
    onNoClick(): void {
      // Close the dialog and pass 'false' as the result
      this.dialogRef.close(false);
    }
}
