import { Component } from '@angular/core';
import { fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})


export class AppComponent {
  // title = 'flexo-admin';
  
  ngOnInit() {
    this.loadGoogleMapsScript();
    fromEvent(window, 'load').subscribe(() => document.querySelector('#glb-loader')?.classList.remove('loaderShow'));
  }

  loadGoogleMapsScript(): void {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapKey}&callback=initMap&libraries=places`;
    script.defer = true;
    script.async = true;

    // Append the script to the body of the document
    document.head.appendChild(script);
  }
}
