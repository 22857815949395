import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-space-view-more',
  templateUrl: './add-space-view-more.component.html',
  styleUrls: ['./add-space-view-more.component.css'],
  providers:[MatDialog]
})
export class AddSpaceViewMoreComponent {

  dynamicImages:any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public dynamicData: any,public dialog: MatDialog) {}

  ngOnInit(): void {
    this.dynamicImages = this.dynamicData;
    console.log(this.dynamicImages)
  }
  
  closeModal(){
    this.dialog.closeAll();
  }

}
