import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-lead-price',
  templateUrl: './lead-price.component.html',
  styleUrls: ['./lead-price.component.css']
})
export class LeadPriceComponent implements OnInit {
  leadPriceForm: FormGroup | any;

  constructor(private fb: FormBuilder, private queryService: QueriesService) { }

  ngOnInit(): void {
    this.leadPriceForm = this.fb.group({
      value: ['', Validators.required],
    });
    this.getLeadPrice()
  }



  getLeadPrice() {
    this.queryService.getLeadPrice().then((res: any) => {
      if (res.success) {
        this.leadPriceForm.patchValue(res.data)
      }
    })
  }

  submit() {
    if (this.queryService.hasPermission('Lead Price', 'edit')) {
      this.leadPriceForm.markAllAsTouched()
      if (this.leadPriceForm.valid) {
        this.queryService.addLeadPrice(this.leadPriceForm.value).then((res: any) => {
          this.queryService.openSnackBar(res.message)
        })
      }
    } else {
      this.queryService.accessDeniedAlert()
    }
  }



}
