<!-- <form [formGroup]="firstFormGroup"> -->
<!-- <ng-template class = "template" matStepLabel>Fill out Space name</ng-template> -->
<p style="text-align: right;"><i (click)="closeDialog(null)" style="cursor: pointer;" class="material-icons">
  close</i>
</p>
<mat-dialog-content>
<p style="font-size: 20px; text-align: center;">Select Spaces to be attached</p><br>
<div class="row">
  <div class="col-md-4">
    <div class="form-group">
      <label class="required">To</label>
      <input type="text" [(ngModel)]="send_proposal.to" name="to" class="form-control filter-input"
        placeholder="Enter Email">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label class="required">Subject</label>
      <input type="text" [(ngModel)]="send_proposal.subject" class="form-control filter-input"
        placeholder="Enter Subject">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label class="required">Heading</label>
      <input type="text" [(ngModel)]="send_proposal.heading" class="form-control filter-input"
        placeholder="Enter Heading">
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
      <label class="required">Description</label>
      <textarea type="text" [(ngModel)]="send_proposal.description" class="form-control filter-input"
        placeholder="Enter Description" rows="3"></textarea>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="act-title" *ngIf="send_proposal_options.length > 0">
      <h5><i class="ion-ios-copy-outline"></i> Options</h5>
    </div>
    <div class="db-booking-wrap" *ngFor="let option of send_proposal_options; let i = index">
      <div class="db-booking-item">
        <h4>{{ 'Option ' + (i+1).toString() }}</h4>
        <div class="booking-info" style="margin-top: 10px">
          <h6>ID:</h6>
          <span class="booking_list">
            {{ option.id }}
          </span>
        </div>
        <div class="booking-info">
          <h6>Actual Name:</h6>
          <span class="booking_list">
            {{option.actual_name}}
          </span>
        </div>
        <div class="booking-info">
          <h6>Mask Name:</h6>
          <span class="booking_list">
            <span>{{ option.mask_name }}</span>
          </span>
        </div>
        <div class="booking-info">
          <h6>Inclusions:</h6>
          <span class="booking_list">
            <mat-chip-list>
              <mat-chip *ngFor="let inclusion of option.inclusions">
                {{inclusion}}
              </mat-chip>
            </mat-chip-list>
          </span>
        </div>
        <div class="booking-info">
          <h6>Not Add Link:</h6>
          <span class="booking_list">
            {{ option.not_add_link }}
          </span>
        </div>
        <div class="booking-info">
          <h6>Space Type:</h6>
          <span class="booking_list">
            {{ option.space_type }}
          </span>
        </div>
        <div class="booking-info">
          <h6>Quoted Price Per Seat:</h6>
          <span class="booking_list">
            {{ (option.quoted_price_per_seat | currency) + '/- ' }} {{option.quoted_price_unit}}
          </span>
        </div>
        <div class="booking-info">
          <h6>Total Billable Seats:</h6>
          <span class="booking_list">
            {{option.total_billable_seats}}
          </span>
        </div>
        <div class="booking-info">
          <h6>Total:</h6>
          <span class="booking_list">
            {{(option.total | currency) + '/- '}} {{option.total_unit}}
          </span>
        </div>
        <div>
          <a (click)="updateOption(option)" class="btn v2"><i class="ion-edit"></i> Edit</a>
          <button (click)="removeOption(option)" class="btn v5"><i class="ion-android-delete"></i>
            Remove</button>
        </div>
        <!-- <div class="booking-info">
        <h6>Resource: </h6>
        <ul class="booking_list">
          <li><span>{{resource_type_names[element.resource_type]}}
              {{element.booking_date_time && (element.resource_type == 6 || element.resource_type == 7)? 'at '+element.booking_date_time: ''}}</span>
          </li>
        </ul>
      </div> -->
        <!-- <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 1)"
        *ngIf="element.city_name && element.location_name"><i class="ion-android-mail"></i> Send
        Proposal</a>
      <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 2)"
        *ngIf="element.city_name && !element.location_name"><i class="ion-android-mail"></i> Send
        Proposal</a> -->
      </div>
    </div>
  </div>
</div>
<div class="col-md-12 d-flex justify-content-end">
  <button type="button" *ngIf="!show_option_form && send_proposal_options.length <= 10" class="btn v2 my-3"
    (click)="show_option_form = true"><i class="ion-plus-round"></i> Add More</button>
</div>
<div class="d-flex justify-content-center" *ngIf="show_option_form">
  <div class="col-md-8">
    <form [formGroup]="optionsForm">
      <div class="row">
        <div class="col-md-6">
          <label>Select City</label>
          <mat-form-field class="w-100" (click)="filterCity()" appearance="outline">
            <input type="text" placeholder="Cities" aria-label="cities" matInput [formControl]="city_slected"
              [matAutocomplete]="autoCity">
            <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
              <mat-option (click)="selectCity(city)"
                *ngFor="let city of filtered_cities_list | async; let i = index;" [value]="city.name">
                {{city.id}} - {{city.name}}
              </mat-option>
            </mat-autocomplete>
            <button (click)="city_slected.patchValue(''); spaces_list = [];" mat-icon-button color="accent" matSuffix
              aria-label="Remove filter button">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <label>Select Location</label>
          <mat-form-field class="w-100" (click)="filterLocation()" appearance="outline">
            <input type="text" placeholder="Locations" aria-label="locations" matInput
              [formControl]="location_slected" [matAutocomplete]="autoLocation">
            <mat-autocomplete autoActiveFirstOption #autoLocation="matAutocomplete">
              <mat-option (click)="selectLocation(location)"
                *ngFor="let location of filtered_locations_list | async; let i = index;"
                [value]="location.location_name">
                {{location.location_name}}
              </mat-option>
            </mat-autocomplete>
            <button (click)="location_slected.patchValue(''); resetLocation();" mat-icon-button color="accent"
              matSuffix aria-label="Remove filter button">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="required">Add Space</label>
          <mat-form-field class="w-100" (click)="filterSpace()" appearance="outline">
            <input type="text" placeholder="Spaces" aria-label="Spaces" matInput [formControl]="space_selected"
              [matAutocomplete]="autoSpace">
            <mat-autocomplete autoActiveFirstOption #autoSpace="matAutocomplete">
              <mat-option (click)="getSpaceById(space)"
                *ngFor="let space of filtered_spaces_list | async; let i = index;"
                [value]="space.actual_name + ' - ' + space.location_name + ' - ' + space.id">
                {{space.actual_name}} - {{space.landmark}} - {{space.id}}
              </mat-option>
            </mat-autocomplete>
            <button (click)="space_selected.patchValue('');" mat-icon-button color="accent" matSuffix
              aria-label="Remove filter button">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
          <mat-slide-toggle color="#f76900" class="mt-2" formControlName="mask_name">Mask Name</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Inclusions</label>
            <div class="row">
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_1" class="form-control filter-input"
                  placeholder="Enter First Inclusion">
              </div>
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_2" class="form-control filter-input"
                  placeholder="Enter Second Inclusion">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_3" class="form-control filter-input"
                  placeholder="Enter Third Inclusion">
              </div>
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_4" class="form-control filter-input"
                  placeholder="Enter Fourth Inclusion">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_5" class="form-control filter-input"
                  placeholder="Enter Fifth Inclusion">
              </div>
              <div class="col-md-6">
                <input type="text" formControlName="inclusion_6" class="form-control filter-input"
                  placeholder="Enter Sixth Inclusion">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Space Type</label>
            <input type="text" formControlName="space_type" class="form-control filter-input"
              placeholder="Enter Space Type">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Quoted Price / Seat</label>
            <div class="d-flex">
              <input (input)="calculateTotal()" type="number" min="0" formControlName="quoted_price_per_seat"
                class="form-control toggle-inpt filter-input" placeholder="Enter Price">
              <mat-button-toggle-group formControlName="quoted_price_unit" class="toggle-btn w-50" name="fontStyle"
                aria-label="Font Style">
                <mat-button-toggle class="w-100" value="month">Month</mat-button-toggle>
                <mat-button-toggle class="w-100" value="day">Day</mat-button-toggle>
                <mat-button-toggle class="w-100" value="hour">Hour</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Total Billable Seats</label>
            <input (input)="calculateTotal()" type="number" min="0" formControlName="total_billable_seats"
              class="form-control filter-input" placeholder="Enter Total Billable Seats">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Total</label>
            <div class="d-flex">
              <input type="number" min="0" formControlName="total" class="form-control toggle-inpt filter-input"
                placeholder="Enter Total">
              <mat-button-toggle-group formControlName="total_unit" class="toggle-btn w-50" name="fontStyle"
                aria-label="Font Style">
                <mat-button-toggle class="w-100" value="month">Month</mat-button-toggle>
                <mat-button-toggle class="w-100" value="day">Day</mat-button-toggle>
                <mat-button-toggle class="w-100" value="hour">Hour</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
          <mat-slide-toggle color="#f76900" formControlName="negotiable"
            class="mt-2 mr-4">Negotiable</mat-slide-toggle>
          <mat-slide-toggle color="#f76900" formControlName="not_add_link" class="mt-2">Not Add
            Link</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-end mt-2">
            <button type="button" (click)="addOption()" class="btn v7 mar-top-10">{{btn_txt}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-12">
    <div class="d-flex justify-content-center">
      <div class="col-md-8">
        <div class="row mt-3">
          <div class="col-sm-6">
            <label class="mr-5">Steps Image</label>
            <input type="file" name="file" id="file" (change)="stepsFileChangeEvent($event)"
              accept="image/jpeg, image/png">
            <mat-slide-toggle color="#f76900" [(ngModel)]="send_proposal.hide_steps_image" class="mt-2">Hide
              Image</mat-slide-toggle>
          </div>
          <div class="col-sm-6">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <img class="mx-auto w-150px img-fluid" [src]="image_steps"><br>
                <button (click)="resetStepsImage()" class="mt-2 btn btn-secondary">Reset To Default</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-12">
    <div class="d-flex justify-content-center">
      <div class="col-md-8">
        <label>Advantage Text</label>
        <input [(ngModel)]="send_proposal.advantage_text" class="form-control filter-input"
          placeholder="Enter Advantage Text">
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-12">
    <div class="d-flex justify-content-center">
      <div class="col-md-8">
        <div class="row mt-3">
          <div class="col-sm-6">
            <label class="mr-5">Why Image</label>
            <input type="file" name="file" id="file" (change)="whyFileChangeEvent($event)"
              accept="image/jpeg, image/png">
            <mat-slide-toggle color="#f76900" [(ngModel)]="send_proposal.hide_why_image" class="mt-2">Hide
              Image</mat-slide-toggle>
          </div>
          <div class="col-sm-6">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <img class="mx-auto w-150px img-fluid" [src]="image_why"><br>
                <button (click)="resetWhyImage()" class="mt-2 btn btn-secondary">Reset To Default</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="d-flex justify-content-center mt-4">
      <div class="col-md-8">
        <label class="required">Text</label>
        <textarea type="text" [(ngModel)]="send_proposal.text" class="w-100 form-control filter-input"
          placeholder="Enter Description" rows="6"></textarea>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="is_admin == 1">
  <div class="col-md-12">
    <div class="d-flex justify-content-center">
      <div class="col-md-8">
        <div>
          <label class="required mt-4">Advisor</label>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Advisor</mat-label>
            <mat-select name="worker">
              <mat-option (click)="selectWorker(worker);" *ngFor="let worker of workers" [value]="worker.id">
                {{worker.id + ' - ' + worker.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="send_proposal.manager !== undefined" class="row">
          <div class="col-sm-4">
            <img [src]="send_proposal.manager.image" class="img-fluid height__120px">
          </div>
          <div class="col-sm-8">
            <div class="db-booking-item worker-info">
              <div class="booking-info">
                <h6>ID:</h6>
                <span class="booking_list">
                  {{ send_proposal.manager.id }}
                </span>
              </div>
              <div class="booking-info">
                <h6>Name:</h6>
                <span class="booking_list">
                  {{ send_proposal.manager.name }}
                </span>
              </div>
              <div class="booking-info">
                <h6>Email:</h6>
                <span class="booking_list">
                  {{ send_proposal.manager.email }}
                </span>
              </div>
              <div class="booking-info">
                <h6>Mobile:</h6>
                <span class="booking_list">
                  {{ send_proposal.manager.mobile }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- multiple form ends -->

<div class="col-md-12">
  <!-- <mat-form-field style="width: 400px;" appearance="outline">
  <mat-label>Spaces</mat-label>
  <mat-select placeholder="Select Spaces" [(ngModel)]="space_ids" name="space_id" required>
    <mat-option *ngFor="let space of spaces_list" (click)="createCustomPriceList(space)" [value]="space.id">
      {{space.actual_name}} - {{space.name}}
    </mat-option>
  </mat-select>
</mat-form-field> -->
  <!-- <div *ngFor="let element of spaces_list_with_custom_prices">
  <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px;">
    <mat-label>Enter custom price for {{element.actual_name}}</mat-label>
    <input type="number" matInput [(ngModel)]="element.custom_price">
  </mat-form-field>
</div> -->

  <div class="row">
    <div class="col-sm-12 d-flex justify-content-end">
      <mat-slide-toggle [(ngModel)]="send_proposal.attach_pdf_proposal" name="attach_pdf_proposal" color="#f76900"
        class="mt-4 mr-4">Attach PDF Proposal</mat-slide-toggle>
    </div>
  </div>
  <div class="row" *ngIf="send_proposal.attach_pdf_proposal">
    <div class="col-md-12">
      <div class="d-flex justify-content-center">
        <div class="col-md-8">
          <div>
            <label>Receiver Name</label>
            <input [(ngModel)]="send_proposal.receiver_name" class="form-control filter-input"
              placeholder="Enter Receive Name">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 d-flex justify-content-end">
      <button type="button" class="btn v2 mt-3 mb-1 mr-4" (click)="showPreview()">Preview</button>
      <button [disabled]="space_ids.length <= 0" class="btn v7 mar-top-10" (click)="sendMail()">Send</button>
    </div>
  </div>


  <!-- <button mat-button matStepperNext>Next</button> -->
</div>
</mat-dialog-content>