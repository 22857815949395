import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { QueriesService } from '../services/queries.service';
import { SpaceService } from '../services/space.service';

export class Manager {
   id: number
   name: string;
   email: string;
   mobile: string;
   status: number;
   space_id: number;
}

@Component({
   selector: 'delete-space',
   templateUrl: './delete-space-dialog.component.html',
   styleUrls: ['./delete-space-dialog.component.css'],
})

export class DeleteSpaceDialog implements OnInit {
   public ref;
   public selected_space;
   public message = '';
   confirm: string;
   spaceId: any;
   selected: any;
   paywallSelected: any;
   tierSelected: any;
   ribbonList: any;
   selection: boolean = true;
   paywallSelection: boolean = true;
   tierSelection: boolean = true;

   type: any;
   plansList: any[] = []


   constructor(
      public dialogRef: MatDialogRef<DeleteSpaceDialog>,
      private spaceService: SpaceService,
      private router: Router,
      public snackBar: MatSnackBar,
      private loaderService: LoaderService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private queryService: QueriesService
   ) {
      if (data) {
         this.spaceId = data.id;
         this.type = data.type
      }
   }

   ngOnInit(): void {
      if (this.spaceId) {
         this.getAllRibbon()
      }
      this.getTier();
      this.getActiveSubscriptionPlans()
   }


   getAllRibbon() {
      this.queryService.getAllActiveRibbon().then((res: any) => {
         this.ribbonList = res?.ribbons
      })
   }

   openSnackBar(message: string, action: string) {
      this.loaderService.displayLoader(false);
      this.snackBar.open(message, action, {
         duration: 5000,
      });
   }

   closeDialog(options) {
      this.ref.close(options);
   }


   assignRibbon() {
      const data = {
         spaceId: this.spaceId,
         ribbon: this.selected
      }
      if (this.selected) {
         this.queryService.assignRibbon(data).then((res: any) => {
            res.success && this.closeDialog({ success: true })
            this.queryService.openSnackBar(res.message)
         })
      } else {
         this.selection = false
      }
   }

   assignPaywall() {
      const data = {
         sortOrder: this.paywallSelected.sortOrder,
         planId: this.paywallSelected.id
      }
      if (this.paywallSelected) {
         this.queryService.assignPaywallPlans(this.spaceId, data).then((res: any) => {
            res.success && this.closeDialog({ success: true })
            this.queryService.openSnackBar(res.message)
         })
      } else {
         this.paywallSelection = false
      }
   }

   selectRibbon() {
      if (this.selected) {
         this.selection = true
      }
   }

   selectPaywall() {
      if (this.paywallSelected) {
         this.paywallSelection = true
      }
   }

   selectTier() {
      if (this.tierSelected) {
         this.tierSelection = true
      }
   }

   delete() {
      if (this.confirm == "DELETE") {
         this.spaceService.delete(this.selected_space)
            .then(data => {
               if (data.success) {
                  this.message = data.message;
                  this.closeDialog({ success: true, message: data.message });
                  this.openSnackBar(this.message, "Dismiss");
                  this.router.navigate(['/home/spaces']);
               }
            })
            .catch(error => {
               this.message = "Something went wrong";
               this.openSnackBar(this.message, "Dismiss");
               console.log(error);
            })
      } else {
         this.openSnackBar("Please enter DELETE to confirm", "Dismiss");
      }
   }

   getActiveSubscriptionPlans() {
      this.queryService.getSubscriptionPlans("Active")
         .then((res: any) => {
            if (res.success) {
               this.plansList = res.data?.map((pack: any) => {
                  return {
                     ...pack,
                     features: JSON.parse(pack.features).filter((val: any) => val != "<p></p>"),
                     showYearly: true
                  };
               });
               console.log(this.plansList)
            } else {
               this.queryService.openSnackBar(res.message)
            }
         })
   }

   assignTier() {
      const data = {
         spaceId: this.spaceId,
         space_tier: this.tierSelected
      }
      if (this.tierSelected && this.tierSelected != "0") {
         this.queryService.assignTierByAdmin(data).then((res: any) => {
            res.success && this.closeDialog({ success: true })
            this.queryService.openSnackBar(res.message)
         })
      } else {
         this.tierSelection = false
      }
   }

   getTier(){
      this.queryService.getTier(this.spaceId).then((res: any) => {
         if(res.success){
            this.tierSelected = res?.space_tier.toString()
         }
      })
   }

}