<div *ngIf="lead_data" class="review-popup p-2">
    <div class="d-flex justify-content-between">
        <label class="top-label">Lead Data</label>
        <button class="text-muted me-3 btn btn-white border-0" (click)="closeDialog(null)">
            X
        </button>
    </div>

    <div class="pt-5">
        <div class="space-form w-100">
            <div class="row p-5">
                <div class="col-2">Lead ID</div>
                <div class="col-4">{{lead_data.id}}</div>
                <div class="col-2">Tags</div>
                <div class="col-4">{{lead_data.tags?.join(", ") | titlecase}}</div>
                <div class="col-2">Date Updated</div>
                <div class="col-4">
                    {{lead_data.updatedAt}}
                </div>
                <div class="col-2">City</div>
                <div class="col-4">
                    {{lead_data.city?.join(", ") | titlecase}}
                </div>
                <div class="col-2">Location</div>
                <div class="col-4">
                    {{lead_data.location?.join(", ") | titlecase}}
                </div>
                <div class="col-2">No. of Seats</div>
                <div class="col-4">
                    {{lead_data.number_of_seats}}
                </div>
                <div class="col-2">Approx Start</div>
                <div class="col-4">
                    {{lead_data.approx_start}}
                </div>
                <div class="col-2">Space Type</div>
                <div class="col-4">
                    {{lead_data.space.join(", ") | titlecase}}
                </div>
                <div class="col-2">Tenure</div>
                <div class="col-4">
                    {{lead_data.tenure}}
                </div>
                <div class="col-2">Budget</div>
                <div class="col-4">
                    {{lead_data.budget}}<span *ngIf="lead_data.budgetMax">-</span>{{lead_data.budgetMax}}
                </div>
                <div class="col-2">Sold</div>
                <div class="col-4">{{lead_data?.sold}}</div>
                <div class="col-2">Verified By</div>
                <div class="col-4">
                    {{lead_data.verifiedBy}}
                </div>
                <div class="col-2">Description</div>
                <div class="col-10">
                    {{lead_data?.inquiryDescription}}
                </div>
                <div class="col-2">Lead Name</div>
                <div class="col-4">{{lead_data?.firstName}} {{lead_data?.lastName}}</div>
                <div class="col-2">Lead Email</div>
                <div class="col-4">{{lead_data?.userEmail}}</div>
                <div class="col-2">Lead Phone</div>
                <div class="col-4">{{lead_data.countryCode}} {{lead_data?.userMobile}}</div>
                <div class="col-2">Lead Company Name</div>
                <div class="col-4">{{lead_data?.company_name}}</div>
                <div class="col-2 mt-2">Revenue Opportunity</div>
                <div *ngIf="lead_data?.revenueOpportunity" class="col-4 p-0">
                    <span class="highlight_revenue p-2 px-3">
                    <svg style="scale:.25;margin-right: -12px;margin-left: -14px" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" width="42" height="60">
                            <path
                                d="M0 0 C13.86 0 27.72 0 42 0 C42 2.64 42 5.28 42 8 C38.04 8 34.08 8 30 8 C30.495 8.94875 30.99 9.8975 31.5 10.875 C33 14 33 14 33 16 C35.97 16 38.94 16 42 16 C42 18.97 42 21.94 42 25 C39.03 25 36.06 25 33 25 C32.6596875 26.5778125 32.6596875 26.5778125 32.3125 28.1875 C30.51419436 34.42497969 25.44689864 37.87093057 20 41 C18.68 41 17.36 41 16 41 C21.31429814 47.17252553 21.31429814 47.17252553 27 53 C25.70700306 55.88437779 24.34058637 57.87219421 22 60 C16.5852468 57.64346638 12.88182545 53.29027355 8.75 49.1875 C7.90953125 48.37990234 7.0690625 47.57230469 6.203125 46.74023438 C5.01203125 45.55977539 5.01203125 45.55977539 3.796875 44.35546875 C2.70391113 43.28268677 2.70391113 43.28268677 1.58886719 42.18823242 C-0.33321464 39.54108746 -0.49327943 38.20332474 0 35 C2.72728976 32.27271024 4.44519804 32.70583158 8.25 32.5625 C13.16548702 32.25290579 16.86582159 31.90509834 21 29 C21.66 28.01 22.32 27.02 23 26 C11.615 25.505 11.615 25.505 0 25 C0 22.03 0 19.06 0 16 C7.59 16 15.18 16 23 16 C18.57285488 10.4660686 17.1864717 9.37157976 10.44921875 8.5859375 C9.20785156 8.51632812 7.96648438 8.44671875 6.6875 8.375 C5.43324219 8.30023438 4.17898437 8.22546875 2.88671875 8.1484375 C1.93410156 8.09945313 0.98148437 8.05046875 0 8 C0 5.36 0 2.72 0 0 Z "
                                fill="#777777" transform="translate(0,0)"/>
                        </svg>{{lead_data?.revenueOpportunity}}</span></div>
                <div *ngIf="!lead_data?.revenueOpportunity" class="col-4"></div>
                <div class="col-2 mt-2">Lead Price</div>
                <div *ngIf="lead_data.lead_price" class="col-4 mt-2">
                    <span class="highlight_lead p-2 px-3">{{lead_data.lead_price}} credits</span>
                </div>
                <div *ngIf="!lead_data.lead_price" class="col-4">
                </div>
                <div class="col-2">Space Name</div>
                <div class="col-4">
                    {{lead_data.spaceName ? lead_data.spaceName : 'NA'}}
                </div>
                <div class="col-2">Lead Source</div>
                <div class="col-4">
                    {{lead_data.lead_source}}
                </div>
            </div>
        </div>
    </div>
</div>