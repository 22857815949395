<div class="confirmBx" style="min-width: 23vw;">
 <ng-container *ngIf="!data.show">
  <h3 mat-dialog-title>Confirmation</h3>
  <div *ngIf="status" mat-dialog-content>
    <p class="my-2 font-weight-bold text-dark">Are you sure you want to change Status?</p>
  </div>
  <div *ngIf="!status" mat-dialog-content style="overflow-y: scroll;max-height: 300px;height:100%">
    <p class="my-2 font-weight-bold text-dark">Are you sure you want to approve?</p>
    <p style="align-items: justify;">{{message}}</p>
  </div>
 </ng-container>
 <ng-container *ngIf="data.show">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <div mat-dialog-content>
    <table *ngIf="data.price" class="table table-bordered my-3">
      <tr>
        <td>Booking Amount</td>
        <td>{{data.price}}</td>
      </tr>
      <tr>
        <td>Cancellation Fee(10%)</td>
        <td>{{(data.price * .10).toFixed(2) }}</td>
      </tr>
      <tr>
        <td>Receivable Amount</td>
        <td>{{(data.price) - +(data.price * .10).toFixed(2)}}</td>
      </tr>
    </table>
    <p class="my-2 font-weight-bold text-dark">{{data.message}}</p>
  </div>
 </ng-container>
  <div mat-dialog-actions>
    <button mat-button (click)="onYesClick()">Yes</button>
    <button mat-button (click)="onNoClick()">No</button>
  </div>
</div>
