import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';
import { ConfirmDialogBoxComponent } from 'src/app/spaces/confirm-dialog-box/confirm-dialog-box.component';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css'],
  providers: [MatDialog],
})
export class SubscriptionPlansComponent {

  plansList: any[]=[];
  deactivePackagesList: any=[];

  colors = ["#fe846f", "#34a853", "#fbbc05", "#ea4335","blueviolet"]

  constructor(private renderer: Renderer2,private queryService: QueriesService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getActiveSubscriptionPlans()
    this.getDeactiveSubscriptionPackages()
  }

  getCardClass(index: number): string {
    const colorIndex = index % this.colors.length;
    return `card_${colorIndex}`;
  }


  navigateToList() {
    if (this.queryService.hasPermission('Paywall', 'view')) {
      this.router.navigate(['/home/create-plan'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  edit(planId: number) {
    if (this.queryService.hasPermission('Paywall', 'edit')) {
      this.router.navigate(['/home/update-plan'], { queryParams: { planId } })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  getActiveSubscriptionPlans() {
    this.queryService.getSubscriptionPlans("Active")
      .then((res: any) => {
        if (res.success) {
          this.plansList = res.data?.map((pack: any) => {
            return {
              ...pack,
              features: JSON.parse(pack.features).filter((val: any) => val != "<p></p>"),
              showYearly: true
            };
          });
        } else {
          this.queryService.openSnackBar(res.message)
        }
      })
  }



  getDeactiveSubscriptionPackages() {
    this.queryService.getSubscriptionPlans("De-Active")
      .then((res: any) => {
        if (res.success) {
          this.deactivePackagesList = res.data.map((pack: any) => {
            return {
              ...pack,
              features: JSON.parse(pack.features).filter((val: any) => val != "<p></p>"),
              showYearly: true
            };
          });
        } else {
          this.queryService.openSnackBar(res.message)
        }
      })
  }



  confirmation(e:any,list:any) {
    e.preventDefault()
    if (this.queryService.hasPermission('Paywall', 'edit')) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
        data: { show: false ,status:true}
      }
      );
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result)
        if (result) {
          this.changeStatus(list)
        }
      });
    }, 500);
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  changeStatus({ id, status }) {
    const data = {
      id,
      status: status === "De-Active" ? "Active" : "De-Active"
    }
    this.queryService.updateSubscriptionStatus(data).then((res: any) => {
      if (res.success) {
        this.getActiveSubscriptionPlans()
        this.getDeactiveSubscriptionPackages()
      }
      this.queryService.openSnackBar(res.message)
    })
  }

  colorClasses: string[] = ['#8d00c3','#2b955e','#4c6abe','#c5a068','#227277'];

  getColorClass(index: number): string {
    return this.colorClasses[index % this.colorClasses.length];
  }

  ngAfterViewInit() {
    const styles = document.createElement('style');
    let styleContent = '';

    // Iterate over colorArray and generate dynamic styles
    this.colorClasses.forEach((color, index) => {
      styleContent += `
        .subs_title.color-${index}::after {
          background-color: ${color} !important;
          content: '';
          position: absolute;
          width: 100%;
          height: 10px; /* Customize the size */
          bottom: 0;
          left: 0;
        }
      `;
    });

    styles.innerHTML = styleContent;
    this.renderer.appendChild(document.head, styles);
  }
}