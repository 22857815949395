<!-- <app-page-header title="Elements" active_item="My Bookings"></app-page-header>ROW-1 OPEN -->
<!-- ROW OPEN -->
<div class="">
    <div class="row mx-0">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header ps-2 border-0">
                    <h4 class="card-title">Filter Bookings</h4>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-md-4 form-group status">
                        <ng-select
                            [placeholder]="selectedHost ? '' : 'Select Host'"
                            class="form-select drop_down form-control pb-0 placeholder_stars select_box"
                            [multiple]="true" [(ngModel)]="selectedHost" (change)="hostValueChange($event)">
                            <ng-option *ngFor="let item of host" [value]="item?.id">{{ item?.companyName
                                }} - {{item?.mobile}}</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-md-4 form-group status">
                        <ng-select [placeholder]="selectedStatus ? '' : 'Select Booking Status'"
                            class="form-select drop_down form-control pb-0 placeholder_stars select_box"
                            [(ngModel)]="selectedStatus">
                            <ng-option value="confirmed">Confirmed</ng-option>
                            <ng-option value="cancelled">Cancelled</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-md-4 form-group status">
                        <ng-select
                            [placeholder]="selectedSpaceName.length == 0 ? 'Select Space Name' : ''"
                            class="form-select drop_down form-control pb-0 placeholder_stars select_box"
                            [multiple]="true" [(ngModel)]="selectedSpaceName">
                            <ng-option *ngFor="let item of spaceNames" [value]="item?.id">{{ item?.actual_name
                                }}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col">
                        <div class="form-group mt-lg-0 mt-md-0 mt-3">
                            <label class="form-label"></label>
                            <h5>Country</h5>
                            <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                            (change)="onCountryChange(countryData); stateData=''; cityData='';" [items]="countryList"
                            bindLabel="country_name" [(ngModel)]="countryData" [placeholder]="countryData ? '' : 'Select country'">
                            </ng-select>
                        </div>
                        </div>
            
                        <div class="col">
                        <div class="form-group mt-lg-0 mt-md-0 mt-3">
                            <label class="form-label"></label>
                            <h5>State</h5>
                            <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                            [placeholder]="stateData ? '' : 'Select state'" (change)="onStateChange(stateData)" [items]="stateList"
                            bindLabel="name" [(ngModel)]="stateData">
                            </ng-select>
                        </div>
                        </div>
            
                        <div class="col">
                        <div class="form-group mt-lg-0 mt-md-0 mt-3">
                            <label class="form-label"></label>
                            <h5>City</h5>
                            <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
                            [placeholder]="cityData ? '' : 'Select city'" [(ngModel)]="cityData" placeholder="Select City"
                            [items]="cityList" bindLabel="name" (change)="onCityChange(cityData)">
                            </ng-select>
                        </div>
                        </div>
                </div>
                <div class="row mx-0 mt-4"> 
                    <div class="col-md-8 form-group status">
                        <ng-select [multiple]="true" class="form-select drop_down form-control pb-0 placeholder_stars select_box" placeholder="Select Category" [(ngModel)]="spaceType" required>
                            <ng-container *ngFor="let item of workSpaceNames">
                                <ng-option>{{item}}</ng-option>
                            </ng-container>
                        </ng-select>
                    </div>

                    <div class="col-md-4 form-group status claned_input">
                        <mat-form-field appearance="fill" class=" calender w-100">
                            <mat-label>Enter a date</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input [(ngModel)]="startDate" (dateChange)="dateRangeChange();" (click)="picker.open()"
                                    matStartDate placeholder="Start date">
                                <input [(ngModel)]="endDate" (dateChange)="dateRangeChange();" (click)="picker.open()"
                                    matEndDate placeholder="End date">
                            </mat-date-range-input>
                            <i (click)="startDate = ''; endDate = ''"  class="fa fa-times" aria-hidden="true"></i>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-4 form-group status">
                        <input [(ngModel)]="bookingIdValueForSearch" type="text" class="form-control"
                        placeholder="Search By Booking ID...">
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-7 form-group status">
                        <div class="panel panel-primary">
                            <div class=" tab-menu-heading" style="border: none !important;">
                                <div class="tabs-menu1 ">
                                    <!-- <ng-select ngbNav #nav1="ngbNav" [activeId]="1"
                                        class="form-select drop_down form-control pb-0 placeholder_stars select_box p-2"
                                        [placeholder]="selectedBookingType ? '' : 'Select Booking Type'"
                                        [(ngModel)]="selectedBookingType">
                                        <ng-option [ngbNavItem]="1" [value]="'allBooking'">All Bookings</ng-option>
                                        <ng-option [ngbNavItem]="2" [value]="'past'">Past Bookings</ng-option>
                                        <ng-option [ngbNavItem]="3" [value]="'upcoming'">Upcoming Bookings</ng-option>
                                    </ng-select> -->

                                    
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="selectedBookingType" class="panel-tabs">
                                        <li class="me-2 mb-1" [ngbNavItem]="'allBooking'">
                                            <a ngbNavLink>All Bookings</a>
                                        </li>
                                        <li class="me-2 mb-1" [ngbNavItem]="'past'">
                                            <a ngbNavLink>Past Bookings</a>
                                        </li>
                                        <li class="me-2 mb-1" [ngbNavItem]="'upcoming'">
                                            <a ngbNavLink>Upcoming Bookings</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="col-md-5 text-end p-4">
                        <button (click)="getAllHostSpaces()" class="btn btn-primary" type="button">
                            <i class="fa-solid fa-magnifying-glass"></i> Filter</button>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card Relatedpost nested-media">
                <div class="card-header">
                    <div class="row align-items-center justify-content-between mx-0 w-100">
                        <div class="col-6">
                            <h4 class="card-title">My Bookings</h4>
                        </div> 
                        <div class="col-md-4 form-group mb-0"> 
                            <div class="d-flex align-items-center justify-content-end" style="height: 40px;">
                                <span class="inputs_texts">
                                    <input [(ngModel)]="bookingIdValueForSearch" type="text" class="form-control" [autocomplete]="true" placeholder="Search By Booking ID, User name, User email and User phone..." ngbTooltip="Search By Booking ID, User name, User email and User phone...">
                                    <!-- <div class="input_hover_text bg-white shadow position-relative" style="top: 35px;">Search By Booking ID, User name, User email and User phone...</div> -->
                                </span>
                                <span class="text-end p-4">
                                    <button (click)="getAllHostSpaces()" class="btn btn-primary" type="button"><i class="fa-solid fa-magnifying-glass"></i></button>
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="card-body d-flex justify-content-between align-items-center"
                    *ngFor="let booking of paginatedBookings">
                    <div class="media media-lg mt-0">
                        <img (error)="handleImageError($event)" class="me-3 mb-4 br-3 media-img" src="{{booking.images[0]}}"
                            alt="Generic placeholder image">
                        <div class="booking-all-list-single-img-tag">
                            <p>{{booking?.spaceType}}</p>
                        </div>
                        <div class="media-body">
                            <h4 class="mt-0">{{booking?.spaceName}}</h4>
                            <p class="booking-all-list-single-cont-location"><img
                                    src="assets/images/download.png" alt=""><span>{{booking?.location_name}}
                                </span></p>
                            <div class="booking-list-single-info booking-list-single-about-info mt-3">
                                <div *ngIf="booking?.firstName" class="booking-list-single-info-single"><img
                                        src="assets/images/user-icon-orng.png" alt="">
                                    <p>{{booking?.firstName + " " + booking?.lastName}}</p>
                                </div>
                                <div *ngIf="booking?.userEmail"
                                    class="booking-list-single-info-single ng-star-inserted"><img
                                        src="assets/images/emailicon.jpeg" alt="">
                                    <p>{{booking?.userEmail}}</p>
                                </div>

                                <div *ngIf="booking?.userMobile" class="booking-list-single-info-single"><img
                                        src="assets/images/mobileicon.png" alt="" class="sq-ft-icon">
                                    <p>{{booking?.userMobile}}</p>
                                </div>
                            </div>

                            <p class="booking-id" style="font-size: 15px !important;"> Booking ID : <span>
                                    {{booking?.bookingId}} </span></p>

                            <div class="booking-list-single-info booking-list-single-date-info mt-3">
                                <div class="booking-list-single-info-single"><img src="assets/images/date-icon-o.png"
                                        alt="" class="date-icon">
                                    <p>{{booking?.startDate | date : 'dd-MM-YYYY' }}</p>
                                </div>
                                <div class="watch-time booking-list-single-info-single">
                                    <p><span> {{booking?.ofDays|| booking?.dayCount ||booking?.bookingPeriods}}</span>
                                        Days</p>
                                </div>
                                <div class="sq-ft booking-list-single-info-single">
                                    <p> <span> {{booking?.spaceType == 'Coworking Space' ? booking?.noOfGuest || 1 :
                                            booking?.totalHours || 2}} </span> {{ booking?.spaceType == 'Coworking Space' ? 'Guest' : 'hrs'}}</p>
                                </div>
                            </div>
                            <div class="booking-list-single-info booking-list-single-confirm-info">
                                <div class="booking-list-single-info-single">
                                    <p>Booking Status : </p>
                                </div>
                                <div *ngIf="(booking?.bookingStatus=='confirmed' || booking?.bookingStatus=='confirm') && booking?.paymentSuccess==1" class="booking-list-single-info-single booking-confirm ng-star-inserted"><img
                                        src="assets/images/bc-con.png" alt="" class="bc-icon">
                                    <p>{{booking?.bookingStatus}}</p>
                                </div>
                                <div *ngIf="booking?.bookingStatus == 'rejected'" class="booking-list-single-info-single ng-star-inserted"><img
                                        src="assets/images/bc-c.png" alt="" class="bc-icon">
                                    <p style="color: #FF0000">{{booking?.bookingStatus}}</p>
                                </div>
                                <div class="booking-list-single-info-single booking-waiting"
                                    *ngIf="(booking?.bookingStatus=='pending' || (booking?.bookingStatus=='confirmed' && booking?.paymentSuccess==0))">
                                    <img src="assets/images/bc-w.png" alt="" class="bc-icon">
                                    <p style="color: #0085ff;font-weight: bold;font-size: 16px;" *ngIf="booking?.bookingStatus=='confirmed' && booking?.paymentSuccess==0">Pending
                                        Payment</p>
                                    <p style="color: #0085ff;font-weight: bold;font-size: 16px;" *ngIf="booking?.bookingStatus=='pending' && booking?.isInstant=='0'">Pending Host
                                        Confirmation</p>
                                    <p style="color: #0085ff;font-weight: bold;font-size: 16px;" *ngIf="booking?.bookingStatus=='pending' && booking?.isInstant=='1'">Waiting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a routerLink="/home/booking-details/{{booking?.id}}" target="_blank"><button
                                class="btn custum-btn">Booking Details <i aria-hidden="true"
                                    class="fa fa-chevron-right"></i></button></a>
                    </div>
                </div>
                <div class="text-center" *ngIf="allBookings === null">
                    <p class="text-warning">Loading bookings...</p>
                </div>
                <div class="text-center" *ngIf="allBookings?.length === 0 && allBookings !== null">
                    <p class="text-danger">Bookings not found..</p>
                </div>
            </div>
            <mat-paginator [length]="allBookings?.length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[25,50,75,100]"
                   (page)="onPageChange($event)">
            </mat-paginator>
        </div>
    </div>
</div>
