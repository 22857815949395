import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';

@Component({
  selector: 'app-add-credit-point',
  templateUrl: './add-credit-point.component.html',
  styleUrls: ['./add-credit-point.component.css']
})
export class AddCreditPointComponent implements OnInit {
  host: any[] = [];
  addCreditPointForm: FormGroup | any;

  constructor(private fb: FormBuilder, private queryService: QueriesService, private spaceService: SpaceService, private router:Router) { }
  ngOnInit(): void {
    this.getHost();
    this.addCreditPointForm = this.fb.group({
      hostId: [null, Validators.required],
      balance: ['', Validators.required],
      amount: ['', Validators.required],
    });
  }

  getHost() {
    this.spaceService.getHost().subscribe((res: any) => {
      for (let i = 0; i < res.data.host.length; i++) {
        this.host.push(res.data.host[i]);
      }
    })
  }

  disablePoint(e: any) {
    if (e.key == ".") {
      e.preventDefault()
    }

  }

  navigateToList(){
    if (this.queryService.hasSubmodulePermission('Credit Wallet', 'Add Point', 'view')) {
      this.router.navigate(['/home/package-list'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }



  submit() {
    this.addCreditPointForm.markAllAsTouched()
    if (this.addCreditPointForm.valid) {
      this.queryService.addCreditPoint(this.addCreditPointForm.value).then((res: any) => {
        if (res.success) {
          this.addCreditPointForm.reset()
          this.queryService.openSnackBar(res.message)
        } else {
          this.queryService.openSnackBar(res.message)
        }
      })
    }
  } 
  }



