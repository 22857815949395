import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgbNavChangeEvent, NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';
import { MyErrorStateMatcher } from './error-state-matcher';

export class PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.css'],
  providers: [ErrorStateMatcher, MatDialog]
})
export class AmenitiesComponent {

  activeTab = 0;
  @ViewChild(NgbNavItem) navItem!: NgbNavItem;
  errorMatcher = new MyErrorStateMatcher();
  cancellationForm: FormGroup;
  amenitiesForm: FormGroup;
  iconCss: any;
  fallbackIcon = '';
  name: any;
  icon: any = '';
  id: any;
  is_id_exist: boolean = false;
  submitted: boolean = false;

  constructor(private spaceService: SpaceService, private fb: FormBuilder, private snackBar: MatSnackBar, public dialog: MatDialog, private route: ActivatedRoute, private queryService: QueriesService) {

    this.cancellationForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      status: ['inactive', Validators.required]
    })

    this.amenitiesForm = fb.group({
      name2: ['', Validators.required],
      icon: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.id = params.get('id');
        if (this.id) {
          this.is_id_exist = true;
          this.spaceService.getAmenityById(this.id).subscribe((res: any) => {
            console.log(res);
            this.name = res.amenities;
            this.icon = res.icon;
          })
        }
      }
    )
  }

  onTabChange(event: NgbNavChangeEvent) {
    event.preventDefault();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getCancelFormValues() {
    if (this.cancellationForm.valid) {
      console.log(this.cancellationForm.controls.name.value);
      console.log(this.cancellationForm.controls.description.value);
      console.log(this.cancellationForm.controls.status.value);
    } else {
      this.cancellationForm.markAllAsTouched();
    }
  }

  onIconPickerSelect(icon: string) {
    this.icon = icon;
  }

  getName(event: any) {
    this.name = event.target.value;
  }

  submit() {
    let data = {
      'amenities': this.name,
      'icon': this.icon
    }
    if (this.id) {
      this.update(data)
    } else {
      this.add(data)
    }
  }

  add(data: any) {
    if (this.queryService.hasPermission('Amenities', 'add')) {
      if (this.amenitiesForm.valid) {
        this.spaceService.postAmenities(data).subscribe((res: any) => {
          this.openSnackBar(res.message, "Dismiss");
          this.icon = '';
          this.name = '';
          this.submitted = true;
        })
      } else {
        this.amenitiesForm.markAllAsTouched();
      }
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  update(data: any) {
    if (this.queryService.hasPermission('Amenities', 'edit')) {
      if (this.amenitiesForm.valid) {
        this.spaceService.updateAmenity(this.id, data).subscribe((res: any) => {
          this.openSnackBar(res.message, "Dismiss");
        })
      } else {
        this.amenitiesForm.markAllAsTouched();
      }
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


}
