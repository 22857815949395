import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WorkerService } from '../services/worker.service';
import { LoaderService } from '../services/loader.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export class Worker {
   name: string;
   email: string;
   mobile: number;
   free_trial: number;
   credits: number;
   visits: number;
}

@Component({
   selector: 'update-worker',
   templateUrl: './update-worker-dialog.component.html',
   styleUrls: ['./update-worker-dialog.component.css']
})

export class UpdateWorkerDialog {
   public worker: Worker;
   public ref;
   public selected_worker;
   public message = '';

   constructor(
      public dialogRef: MatDialogRef<any>,
      private workerService: WorkerService,
      public snackBar: MatSnackBar,
      private loaderService: LoaderService
   ) {
      this.worker = new Worker;
   }

   ngOnInit() {
      this.worker = Object.assign({}, this.selected_worker);
   }
   openSnackBar(message: string, action: string) {
      this.loaderService.displayLoader(false);
      this.snackBar.open(message, action, {
         duration: 5000,
      });
   }
   update() {
      let final_data = Object.assign({}, this.worker);
      this.workerService.update(this.selected_worker.id, final_data)
         .then(data => {
            if (data.success) {
               this.message = data.message;
               this.closeDialog({ success: true, message: data.message });
               this.openSnackBar(this.message, "Dismiss");
            }
         })
         .catch(error => {
            this.message = "Something went wrong";
            this.openSnackBar(this.message, "Dismiss");
            console.log(error);
         })
   }
   closeDialog(options) {
      this.ref.close(options);
   }
}