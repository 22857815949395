import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';

@Component({
  selector: 'app-add-ribbon',
  templateUrl: './add-ribbon.component.html',
  styleUrls: ['./add-ribbon.component.css']
})
export class AddRibbonComponent implements OnInit {

  addRibbonForm: FormGroup | any;
  id: any;

  constructor(private fb: FormBuilder, private queryService: QueriesService, private spaceService: SpaceService, private route: ActivatedRoute, private router: Router) { }
  ngOnInit(): void {
    this.addRibbonForm = this.fb.group({
      id: [0],
      title: [null, Validators.required],
      color: ['', Validators.required],
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');

      if (this.id) {
        this.queryService.getRibbonById(this.id).then((res: any) => {
          if(res.success){
          this.addRibbonForm.patchValue({
            title: res.ribbon.title,
            color: res.ribbon.color,
            id: res.ribbon.id
          }) 
        }  else {
          this.queryService.openSnackBar(res.message)
        }
        })
      }
    });
  }


  submit() {
    if (this.queryService.hasPermission('Ribbon', 'add') && this.addRibbonForm.value.id === 0) {
      this.create('add')
    } else if (this.queryService.hasPermission('Ribbon', 'edit') && this.addRibbonForm.value.id !== 0) {
      this.create('update')
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  create(type:string){
    this.addRibbonForm.markAllAsTouched()
    if (this.addRibbonForm.valid) {
      this.queryService.addRibbon(this.addRibbonForm.value).then((res: any) => {
        if (res.success) {
          this.addRibbonForm.reset()
          if(type === 'update'){
            this.router.navigate([`/home/ribbon-list`]); 
          }
        }
        this.queryService.openSnackBar(res.message);
      })
    }
  }



}
