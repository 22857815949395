<div class="confirmBx">
    <h3 mat-dialog-title>Confirmation</h3>
    <div mat-dialog-content>
        <p class="my-2 font-weight-bold text-dark">Are you sure you want to reject?</p>

        <p>{{message}}</p>

        <form class="example-form">
            <mat-label class="mb-1 text-dark">Reason for rejecting</mat-label>
            <input class="w-100" #reason type="text" matInput #matInput placeholder="Enter your reason here" (input)="onReasonInput(reason.value)" required>
            <mat-error *ngIf="showError">Reason is required</mat-error>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onYesClick(reason.value)">Yes</button>
        <button mat-button (click)="onNoClick()">No</button>
    </div>
</div>