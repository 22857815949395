import { DatePipe } from "@angular/common";
import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatSort } from "@angular/material/sort";
import { NgSelectComponent } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { CsvDownloadFileService } from "../services/csv-download.service";
import { LoaderService } from "../services/loader.service";
import { MemberService } from "../services/member.service";
import { QueriesService } from "../services/queries.service";
import { AddLeadDialog } from "./add-lead-dialog.component";
import { ApproveLeadComponent } from "./approve-lead/approve-lead.component";
import { AssignLeadComponent } from "./assign-lead/assign-lead.component";
import { DeleteLeadComponent } from "./delete-lead/delete-lead.component";
import { SendLeadComponent } from "./send-lead/send-lead.component";
import { ViewLeadComponent } from "./view-lead/view-lead.component";

@Component({
  selector: 'app-co-working-leads',
  templateUrl: './co-working-leads.component.html',
  styleUrls: ['./co-working-leads.component.css'],
  providers: [MatDialog],
})
export class CoWorkingLeadsComponent {

  public leads_data = [];
  pendingLeadDataSource: [] = [];
  confirmedLeadDataSource!: MatTableDataSource<any>;
  leads_dialogRef: MatDialogRef<any>;
  lead_view_dialogRef: MatDialogRef<any>;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['serial','id', 'updatedAt', 'firstName', 'lastName', 'userEmail', 'userMobile', 'location_name', 'city_name', 'number_of_seats','actions'];
  confirmedColumns: string[] = ['serial','id', 'updatedAt', 'spaceName', 'location_name', 'city_name', 'number_of_seats', 'spaceType', 'approx_start', 'tenure', 'budget', 'lead_source', 'lead_price', 'actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize =25;
  spaceType: any;
  status = "0";
  pendingLeads: boolean = true;
  confirmedLeads: boolean = true;
  startDate: any;
  endDate: any;
  startMax: Date | null = null;
  endMin: Date | null = null;
  city_id: any;
  pendingCount: any=0;
  confirmCount: any;
  no_of_seats: any;
  budget: any;
  maxBudgetAmount: { label: string; }[];
  budgetData: { label: string; }[];
  budgetMax: any;
  city: any;
  
  constructor(
    public viewContainerRef: ViewContainerRef,
    private loaderService: LoaderService,
    public leads_dialog: MatDialog,
    public downloadService: CsvDownloadFileService,
    public snackBar: MatSnackBar,
    private _queriesservice: QueriesService,
    private datePipe: DatePipe,
    private memberService: MemberService,
  ) {}

   
  ngOnInit() {
    this.populateConfirmedLeads()
    this.getLocation()
    this.budgetData = [
      ...Array.from({ length: (50000 - 1500) / 500 + 1 }, (_, i) => {
        const amount = 500 + i * 500;
        return { label: `${amount}` };
      }),
      { label: '50000 +' }
    ];
  }


  populatePendingLeads(data?: any) {
    this._queriesservice
      .getAllLeads('pending', data).then((res) => {
        console.log(res);
        if (res.success) {
          res.leads.forEach(lead => {
            lead.spaceType = JSON.parse(lead.spaceType);
            lead.city_name = JSON.parse(lead.city_name);
            lead.location_name = JSON.parse(lead.location_name);
            lead.tags = JSON.parse(lead.tags);
          });
          this.pendingCount = `Pending (${res.leads?.length})`

          this.dataSource = new MatTableDataSource(res.leads);
          // this.dataSource.sort = this.sort;
          this.leads_data = res.leads;

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, 500);
        } else {
          this.pendingLeads = false;
          this.pendingCount = `Pending (0)`
          this.dataSource = new MatTableDataSource([]);
        }
      })
      .catch((error) => {
        this.pendingCount = `Pending (0)`
        this.dataSource = new MatTableDataSource([]);
      });
  }

  populateConfirmedLeads(data?: any) {
    this._queriesservice
      .getAllLeads('approved', data).then((res) => {
        console.log(res)
        if (res.success) {
          res.leads.forEach(lead => {
            lead.spaceType = JSON.parse(lead.spaceType);
            lead.city_name = JSON.parse(lead.city_name);
            lead.location_name = JSON.parse(lead.location_name);
            lead.tags = JSON.parse(lead.tags);
          });
          this.confirmCount = `Confirmed (${res.leads?.length})`
          this.confirmedLeadDataSource = new MatTableDataSource(res.leads);

          this.leads_data = res.leads;

          setTimeout(() => {
            this.confirmedLeadDataSource.paginator = this.paginator;
            this.confirmedLeadDataSource.sort = this.sort;
          }, 500);
        } else {
          this.confirmedLeads = false;
          this.confirmCount = `Confirmed (0)`
          this.confirmedLeadDataSource = new MatTableDataSource([]);
        }
      })
      .catch((error) => {
        this.confirmCount = `Confirmed (0)`
        this.confirmedLeadDataSource = new MatTableDataSource([]);
      });
      if(this.pendingCount === 0){
        this.populatePendingLeads();
      }
  }

  startDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.endMin || date <= this.endMin;
  };

  endDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.startMax || date >= this.startMax;
  };

  onStartDateChange(event: any): void {
    this.startMax = this.startDate;
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
  }

  onEndDateChange(event: any): void {
    this.endMin = this.endDate;
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  }


  getSeats(event: any) {
    this.no_of_seats = event;
  }

  getMaxBudget() {
    const start = Number(this.budget) + 500;
    if (start >= 50000) {
      this.maxBudgetAmount = [{ label: '50000 +' }];
      return;
    }
    const end = 49500;
    this.maxBudgetAmount = [
      ...Array.from({ length: (end - start) / 500 + 1 }, (_, i) => {
        const amount = start + i * 500;
        return { label: `${amount}` };
      }),
      { label: '50000 +' }
    ];
  }

  getBudget(event:any){
    if (event === undefined) {
      this.budgetSelect2?.clearModel();
      this.maxBudgetAmount = [];
      this.budget = undefined;
    } else{
      this.budget = event;
      this.getMaxBudget();
    }
  }

  getMaxBudgetVal(event:any){
    if (event === undefined) {
      this.budgetMax = undefined;
    } else {
      this.budgetMax = event;
    }
  }

  cityNames: string[] = [];

  getLocation() {
      this.memberService.getAllCity().subscribe((res: any) => {
        this.cityNames = res.data.map(val => val.name)
      })
  }

  getCity(event: any) {
    this.city = event;
  }  

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");

    let data = {
      startDate: this.startDate,
      endDate: this.endDate,
      no_of_seats: this.no_of_seats,
      budget: this.budget,
      budgetMax: this.budgetMax,
      city: this.city?.toLowerCase()
    }    

    if (this.status == '0') {
      this.populatePendingLeads(data);
    } else if (this.status == '1') {
      this.populateConfirmedLeads(data);
    }
  }

  deleteDate(status: any) {
    if (status == '1') {
      this.startDate = ''
    } else {
      this.endDate = ''
    }
  }

  applyFilter(value: string) {
    if (this.status == '0') {
      value = value.trim().toLowerCase();
      this.dataSource.filter = value;
    } else if (this.status == '1') {
      value = value.trim().toLowerCase();
      this.confirmedLeadDataSource.filter = value;
    }
  }

  @ViewChild('citySelect') citySelect: NgSelectComponent | undefined;
  @ViewChild('seatSelect') seatSelect: NgSelectComponent | undefined;
  @ViewChild('budgetSelect') budgetSelect: NgSelectComponent | undefined;
  @ViewChild('budgetSelect2') budgetSelect2: NgSelectComponent | undefined;

  public changeStatusCoworking(event: any) {
    this.resetFilterValues();

    let status = event.tab.textLabel;
    if (status.startsWith("Pending")) {
      this.status = "0";
      this.populatePendingLeads();
    } else if (status.startsWith("Confirmed")) {
      this.status = "1";
      this.populateConfirmedLeads();
    }
  }

  resetFilterValues(){
    this.startDate = '';
    this.endDate = '';

    if (this.citySelect) {
      this.citySelect.clearModel();
      this.city = '';
    }

    if (this.seatSelect) {
      this.seatSelect.clearModel();
      this.no_of_seats = '';
    }

    if (this.budgetSelect) {
      this.budgetSelect.clearModel();
      this.budget = '';
    }

    if (this.budgetSelect2) {
      this.budgetSelect2.clearModel();
      this.budgetMax = '';
    }
  }

  public downloadCSV() {
    const data: any[] = [];
    let name = this.status === "0" ? 'Pending' : 'Confirmed';
    let length = this.leads_data.length;
    let leadData = this.leads_data
    if (length) {
      for (let i = 0; i < length; i++) {
        data.push({
          'Id': i + 1,
          'Tags': leadData[i].tags?.join(', '),
          'Date & Time': new Date(leadData[i].updatedAt).toLocaleString(),
          'Location': leadData[i].location_name?.join(', '),
          'City': leadData[i].city_name?.join(', '),
          'No. of Seats': leadData[i].number_of_seats,
          'Space Type': leadData[i].spaceType?.join(', '),
          'Approx Start': leadData[i].approx_start,
          'Tenure': leadData[i].tenure,
          'Budget (Per Seat)': `${leadData[i].budget}-${leadData[i].budgetMax}`,
          'Lead Price (Credits)': leadData[i].lead_price,
          'Sold': leadData[i].sold,
          'Verified By': leadData[i].verifiedBy,
          'Description': leadData[i].inquiryDescription,
          'Lead Name': `${leadData[i].firstName} ${leadData[i].lastName}`,
          'Lead Email': leadData[i].userEmail,
          'Lead Phone': leadData[i].userMobile,
          'Lead Company Name': leadData[i].company_name,
          'Revenue Opportunity': leadData[i].revenueOpportunity
        })
      }
      this.downloadService.ExportData(data, `${name} Leads`, `${name} Leads.csv`,)
    } else {
      this._openSnackBar("No data found.", "Dismiss")
    }
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  addLeadDialog() {
    if (this._queriesservice.hasSubmodulePermission('Leads', 'Co Working', 'add')) {
    let config = new MatDialogConfig();
    config.panelClass = 'addleadModal';
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;

    this.leads_dialogRef = this.leads_dialog.open(AddLeadDialog, config);
    this.leads_dialogRef.componentInstance.ref = this.leads_dialogRef;
    this.leads_dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.populatePendingLeads();
        this.resetFilterValues();
      }
      this.leads_dialogRef = null;
    });
    } else {
      this._queriesservice.accessDeniedAlert()
    }
  }

  viewLeadDataDialog(id: any) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;

    config.data = { id: id };

    this.lead_view_dialogRef = this.leads_dialog.open(ViewLeadComponent, config);
    this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
    this.lead_view_dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.populatePendingLeads();
      }
      this.lead_view_dialogRef = null;
    });
  }

  deleteLeadDataDialog(id: any) {
    if (this._queriesservice.hasSubmodulePermission('Leads', 'Co Working', 'delete')) {
      let config = new MatDialogConfig();
      config.viewContainerRef = this.viewContainerRef;
      config.disableClose = true;

      config.data = { id: id };

      this.lead_view_dialogRef = this.leads_dialog.open(DeleteLeadComponent, config);
      this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
      this.lead_view_dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.populatePendingLeads();
          this.resetFilterValues();
        }
        this.lead_view_dialogRef = null;
      });
    } else {
      this._queriesservice.accessDeniedAlert()
    }
  }

  approveLeadDataDialog(list: any) {
    if (this._queriesservice.hasSubmodulePermission('Leads', 'Co Working', 'edit')) {
    let arr = [
      "spaceType",
      "location",
      "number_of_seats",
      "approx_start",
      "budget",
      "tenure",
      "lead_price",
      "firstName",
      "lastName",
      "userEmail",
      "userMobile",
    ]
    let missingFields = arr.filter((key) => list[key] == null || list[key] === '');
    if (missingFields.length > 0) {
      Swal.fire({
        text: "Please fill all mandatory fields.",
        icon: "warning"
      });
      return false;
    } else {
      let config = new MatDialogConfig();
      config.viewContainerRef = this.viewContainerRef;
      config.disableClose = true;

      config.data = { id: list.id, cityName: list.city_name };


      this.lead_view_dialogRef = this.leads_dialog.open(ApproveLeadComponent, config);
      this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
      this.lead_view_dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.populatePendingLeads();
          this.populateConfirmedLeads();
        }
        this.lead_view_dialogRef = null;
      });
    }
  } else {
    this._queriesservice.accessDeniedAlert()
  }
  }

  sendLeadDialog(id: any, city: any) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;

    config.data = { id: id, city: city };

    this.lead_view_dialogRef = this.leads_dialog.open(SendLeadComponent, config);
    this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
    this.lead_view_dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
        this.populateConfirmedLeads();
      }
      this.lead_view_dialogRef = null;
    });
  }

  assignLeadDialog(id: any, city: any) {
    if (this._queriesservice.hasSubmodulePermission('Leads', 'Co Working', 'edit')) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;

    config.data = { id: id, city_name: city };

    this.lead_view_dialogRef = this.leads_dialog.open(AssignLeadComponent, config);
    this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
    this.lead_view_dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.populatePendingLeads();
      } else {
        return;
      }
      this.lead_view_dialogRef = null;
    });
  }else {
    this._queriesservice.accessDeniedAlert()
  }
  }

  editLeadDataDialog(id: any, type: string) {
    if (this._queriesservice.hasSubmodulePermission('Leads', 'Co Working', 'edit')) {
      let config = new MatDialogConfig();
      config.viewContainerRef = this.viewContainerRef;
      config.disableClose = true;

      config.data = { id: id };

      this.lead_view_dialogRef = this.leads_dialog.open(AddLeadDialog, config);
      this.lead_view_dialogRef.componentInstance.ref = this.lead_view_dialogRef;
      this.lead_view_dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (type === 'confirmed') {
            this.populateConfirmedLeads()
            this.resetFilterValues();
          } else {
            this.populatePendingLeads();
            this.resetFilterValues();
          }
        }
        this.lead_view_dialogRef = null;
      });
    } else {
      this._queriesservice.accessDeniedAlert()
    }
  }
}
