import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { QueriesService } from '../services/queries.service';
import { SpaceService } from '../services/space.service';
import { ConfirmDialogBoxComponent } from '../spaces/confirm-dialog-box/confirm-dialog-box.component';

@Component({
  selector: 'app-userview',
  templateUrl: './userview.component.html',
  styleUrls: ['./userview.component.css'],
  providers: [MatDialog]
})
export class UserviewComponent {

  activeTab = 0;
  @ViewChild(NgbNavItem) navItem!: NgbNavItem;
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator: MatPaginator; 
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  cancellationForm:FormGroup;
  amenitiesForm:FormGroup;
  iconCss: any;
  fallbackIcon = '';
  name:any;
  icon:any = '';
  displayedColumns: string[] = ['position','addedAt', 'name', 'company_name', 'phone_number', 'email','registrationType','status', 'actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  status: string;
  filterValue: string;

  constructor(private spaceService: SpaceService, private snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private queryService:QueriesService) {}

  ngOnInit(){
    this.getAmenities();
  }

  getAmenities(){
    this.spaceService.getAllUsers().subscribe((res:any)=>{
      this.dataSource = new MatTableDataSource(res.data);
      setTimeout(() => { 
        this.dataSource.paginator = this.paginator; 
        if(this.filterValue) this.applyFilter(this.filterValue)
      }, 200);
    })
  }
   
  changeStatus(item:any){
    let data = {
    userId: item.id,
      status: item.status === 'Active' ? 'De-Active' : 'Active'
    }
    this.spaceService.activeDeactiveUser(data).subscribe((res:any)=>{
      if (res.success) {
        this.getAmenities();
      }
      this.spaceService.openSnackBar(res.message || "Something went wrong, try again!")
    })
  }

  
  applyFilter(filterValue: string) {
    this.filterValue = filterValue
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }


  updateAdminProfile(id:any){
    if (this.queryService.hasPermission('User View', 'edit')) {
    this.router.navigate(['home/edit-user-profile', id]);
    } else{
      this.queryService.accessDeniedAlert()
    }
  }


  openConfirmationDialog(item: any, event: any) {
    event.preventDefault()
    if (this.queryService.hasPermission('User View', 'edit')) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
        data: { status: "status Change" }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.changeStatus(item)
        }
      });
    }, 500);
  } else {
this.queryService.accessDeniedAlert()
  }
  }

}
