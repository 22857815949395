<div style="padding: 20px 5px">
      <form class="row" [formGroup]="filtersForm">
        <div class="col-3">
          <div class="form-group mt-lg-0 mt-md-0 mt-3">
            <h5>Country</h5>
            <ng-select [clearable]="false" class="form-select drop_down form-control mt-3" style="background-color: transparent;" formControlName="countryName" (change)="onCountryChange($event,'search')" >
               <ng-option *ngFor="let country of countryList" [value]="country.country_name">{{country.country_name}}</ng-option>
            </ng-select>
          </div>
        </div>
        
        <div class="col-3">
          <div class="form-group mt-lg-0 mt-md-0 mt-3">
            <h5>State</h5>
            <ng-select [clearable]="false" class="form-select drop_down form-control mt-3" style="background-color: transparent;" [placeholder]="filtersForm.value.stateName ? '' : 'Select state' " formControlName="stateName"
                (change)="onStateChange($event,'search')">
              <ng-option *ngFor="let state of stateList" [value]="state.name">{{state.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mt-lg-0 mt-md-0 mt-3">
            <h5>City</h5>
            <ng-select [clearable]="false" class="form-select drop_down form-control mt-3" style="background-color: transparent;" [placeholder]="filtersForm.value.city_id ? '' : 'Select city' " formControlName="city_id"
                (change)="onCityChange($event)">
              <ng-option *ngFor="let city of cityList" [value]="city.id">{{city.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mt-lg-0 mt-md-0 mt-3">
            <h5>Location</h5>
            <ng-select [clearable]="false" class="form-select drop_down form-control mt-3" style="background-color: transparent;" [placeholder]="filtersForm.value.location_id ? '' : 'Select location' " formControlName="location_id" (change)="getAllQuestions()"
                >
              <ng-option *ngFor="let location of locationList" [value]="location.location_name">{{location.location_name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mt-lg-0 mt-md-0 mt-3">
            <h5>Space</h5>
            <ng-select [clearable]="false" class="form-select drop_down form-control mt-3" style="background-color: transparent;" [placeholder]="filtersForm.value.space_id ? '' : 'Select space' " formControlName="space_id"
                (change)="getAllQuestions()">
              <ng-option *ngFor="let space of spaceList" [value]="space.id">{{space.actual_name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <!-- <mat-form-field appearance="outline" class="filter">
          <mat-label>City</mat-label> 
          <mat-select
            formControlName="city_id"
            (selectionChange)="onFilterChange($event, 'city')"
          >
            <mat-select-filter
              [array]="cities"
              [displayMember]="'name'"
              (filteredReturn)="citiesTemp = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let city of citiesTemp" [value]="city?.id">
              {{ city?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="filter">
          <mat-label>Location</mat-label>
          <mat-select
            formControlName="location_id"
            (selectionChange)="onFilterChange($event, 'location')"
          >
            <mat-select-filter
              [array]="locations"
              [displayMember]="'location_name'"
              (filteredReturn)="locationsTempList = $event"
            ></mat-select-filter>
            <mat-option
              *ngFor="let location of locationsTempList"
              [value]="location?.location_name"
            >
              {{ location?.location_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="filter">
          <mat-label>Space</mat-label>
          <mat-select
            formControlName="space_id"
            (selectionChange)="onFilterChange($event, 'space')"
          >
            <mat-select-filter
              [array]="spaces"
              [displayMember]="'actual_name'"
              (filteredReturn)="spacesTemp = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let space of spacesTemp" [value]="space?.id">
              {{ space?.actual_name }} ({{ space?.location_name }})
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="col d-flex mt-5 gap-1">
          <div>
            <button class="btn v3" (click)="resetFiltersForm()">Reset</button>
          </div>
          <div><button class="btn v3" (click)="openShortQuestionDialog()">
            <i class="ion-plus-round"></i>FAQ
          </button></div>
          <div><button class="btn v3" (click)="openBriefQuestionDialog()">
            <i class="ion-plus-round"></i>Overview
          </button></div>
        </div>
      </form>
    </div>
<div class="row dash-content-wrap2 mt-3">
  <div class="col-md-12">
    <div class="recent-activity my-listing">
      <div class="act-title">
        <h5><i class="ionicons ion-help"></i>Frequently Asked Questions</h5>
      </div>
    </div>
  </div>
</div>
<div class="row dash-content-wrap2" style="padding-bottom: 10px">
  <div class="col-md-12">
    <mat-tab-group>
      <mat-tab label="FAQ">
        <div class="mt-4">
          <mat-accordion>
            <div class="d-flex flex-column" style="grid-gap: 5px">
              <mat-expansion-panel
                class="question-panel"
                *ngFor="let question of shortQuestions"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="question">
                    {{ question?.question }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p class="answer" [innerHTML]="question?.answer"></p>
                <mat-action-row>
                  <button
                    mat-button
                    color="warn"
                    (click)="onDeleteShortQuestion(question)"
                  >
                    Delete
                  </button>
                  <button
                    mat-button
                    color="primary"
                    (click)="onEditShortQuestion(question)"
                  >
                    Edit
                  </button>
                </mat-action-row>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab label="Overview">
        <div class="mt-4">
          <mat-accordion>
            <div class="d-flex flex-column" style="grid-gap: 5px">
              <mat-expansion-panel
                class="question-panel"
                *ngFor="let question of briefQuestions"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="question">
                    {{ question?.question }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p
                  class="question-new-brief strong"
                  style="margin-bottom: 30px"
                  [innerHtml]="question?.question"
                ></p>

                <p class="question-new" [innerHtml]="question?.answer"></p>
                <mat-action-row>
                  <button
                    mat-button
                    color="warn"
                    (click)="onDeleteBriefQuestion(question)"
                  >
                    Delete
                  </button>
                  <button
                    mat-button
                    color="primary"
                    (click)="onEditBriefQuestion(question)"
                  >
                    Edit
                  </button>
                </mat-action-row>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #shortQuestionDialog>
  <button
    mat-icon-button
    class="close-button"
    [mat-dialog-close]="true"
    matTooltip="Presss Esc"
  >
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>
    {{ shortQuestionSubmissionMode ? "Add" : "Update" }} Short Question
  </h1>
  <div class="row" style="grid-gap: 10px" mat-dialog-content>
    <form [formGroup]="shortQuestionForm">
      <div class="col d-flex flex-row" style="grid-gap: 10px">
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>City</mat-label>
          <mat-select
            formControlName="city_id"
           
          >
            <mat-select-filter
              [array]="cities"
              [displayMember]="'name'"
              (filteredReturn)="citiesTemp = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let city of citiesTemp" [value]="city?.id">
              {{ city?.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="briefQuestionForm.hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>Location</mat-label>
          <mat-select
            formControlName="location_name"
            (selectionChange)="onLocationSelectionChange($event)"
          >
            <mat-select-filter
              [array]="locations"
              [displayMember]="'location_name'"
              (filteredReturn)="locationsTempList = $event"
            ></mat-select-filter>
            <!-- <mat-option value="0" >All</mat-option> -->
            <mat-option
              *ngFor="let location of locationsTempList"
              [value]="location?.location_name"
            >
              {{ location?.location_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>Space</mat-label>
          <mat-select formControlName="space_id">
            <mat-select-filter
              [array]="spaces"
              [displayMember]="'actual_name'"
              (filteredReturn)="spacesTemp = $event"
            ></mat-select-filter>
            <!-- <mat-option value="0" >All</mat-option> -->
            <mat-option *ngFor="let space of spacesTemp" [value]="space?.id">
              {{ space?.actual_name }} ({{ space?.location_name }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col d-flex flex-column" style="grid-gap: 10px">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Question</mat-label>
          <textarea
            matInput
            placeholder="Ask a question here"
            formControlName="question"
          ></textarea>
          <mat-error *ngIf="shortQuestionForm.hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
        <ckeditor
          [editor]="editor"
          [data]="data"
          class="w-100"
          style="margin-bottom: 10px"
          formControlName="answer"
        ></ckeditor>
        <!-- <mat-form-field appearance="outline" class="w-100">
          <mat-label>Answer</mat-label>
          <textarea
            matInput
            placeholder="Brief the question here"
            formControlName="answer"
          ></textarea>
          <mat-error *ngIf="shortQuestionForm.hasError('required')">
            Required
          </mat-error>
        </mat-form-field> -->
      </div>
    </form>
  </div>
  <div class="d-flex ml-3" style="grid-gap: 5px">
    <button
      mat-raised-button
      color="accent"
      (click)="
        shortQuestionSubmissionMode
          ? onSubmitShortQuestion()
          : onUpdateShortQuestion()
      "
    >
      {{ shortQuestionSubmissionMode ? "Submit" : "Update" }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="shortQuestionForm.reset()"
      matTooltip="Reset entire form"
    >
      Reset
    </button>
    <button
      mat-raised-button
      style="background-color: #28a745"
      (click)="resetShortQuestion()"
      matTooltip="Reset question and answer field only"
    >
      Reset Question
    </button>
    <button mat-raised-button [mat-dialog-close]="true">Close</button>
  </div>
  <div style="margin-top: 50px; padding: 20px">
    <!-- <mat-expansion-panel class="question-panel">
      <mat-expansion-panel-header>
        <mat-panel-title class="question">
          {{ shortQuestionForm?.value?.question }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="answer">
        {{ shortQuestionForm?.value?.answer }}
      </p>
    </mat-expansion-panel> -->
    <p
      class="question-new strong"
      *ngIf="shortQuestionForm?.value?.question"
      [innerHtml]="'Q- ' + shortQuestionForm?.value?.question"
    ></p>

    <div
      class="d-flex justify-content-start"
      *ngIf="shortQuestionForm?.value?.answer"
    >
      <p>Ans-</p>
      <p
        class="question-new"
        [innerHtml]="shortQuestionForm?.value?.answer"
      ></p>
    </div>
  </div>
</ng-template>

<ng-template #briefQuestionDialog>
  <button
    mat-icon-button
    class="close-button"
    [mat-dialog-close]="true"
    matTooltip="Presss Esc"
  >
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>
    {{ briefQuestionSubmissionMode ? "Add" : "Update" }} Brief Question
  </h1>
  <div class="row" style="grid-gap: 10px" mat-dialog-content>
    <form [formGroup]="briefQuestionForm">
      <div class="col d-flex flex-row" style="grid-gap: 10px">
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>City</mat-label>
          <mat-select
            formControlName="city_id"
          >
            <mat-select-filter
              [array]="cities"
              [displayMember]="'name'"
              (filteredReturn)="citiesTemp = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let city of citiesTemp" [value]="city?.id">
              {{ city?.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="briefQuestionForm.hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>Location</mat-label>
          <mat-select
            formControlName="location_name"
            (selectionChange)="onLocationFilterChange($event)"
          >
            <mat-select-filter
              [array]="locations"
              [displayMember]="'location_name'"
              (filteredReturn)="locationsTempList = $event"
            ></mat-select-filter>
            <!-- <mat-option value="0" >All</mat-option> -->
            <mat-option
              *ngFor="let location of locationsTempList"
              [value]="location?.location_name"
            >
              {{ location?.location_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>Space</mat-label>
          <mat-select formControlName="space_id">
            <mat-select-filter
              [array]="spaces"
              [displayMember]="'actual_name'"
              (filteredReturn)="spacesTemp = $event"
            ></mat-select-filter>
            <!-- <mat-option value="0" >All</mat-option> -->
            <mat-option *ngFor="let space of spacesTemp" [value]="space?.id">
              {{ space?.actual_name }} ({{ space?.location_name }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col d-flex flex-column" style="grid-gap: 10px">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Question</mat-label>
          <textarea
            matInput
            placeholder="Ask a question here"
            formControlName="question"
          ></textarea>
          <mat-error *ngIf="briefQuestionForm.hasError('required')">
            Required
          </mat-error>
        </mat-form-field>

        <ckeditor
          [editor]="editor"
          [data]="data"
          formControlName="answer"
          class="w-100"
          style="margin-bottom: 10px"
        ></ckeditor>
        <mat-error *ngIf="briefQuestionForm.hasError('required')">
          Required
        </mat-error>
      </div>
    </form>
  </div>
  <div class="d-flex ml-3" style="grid-gap: 5px">
    <button
      mat-raised-button
      color="accent"
      (click)="
        briefQuestionSubmissionMode
          ? onSubmitBriefQuestion()
          : onUpdateBriefQuestion()
      "
    >
      {{ briefQuestionSubmissionMode ? "Submit" : "Update" }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="briefQuestionForm.reset()"
      matTooltip="Reset entire form"
    >
      Reset
    </button>
    <button
      mat-raised-button
      style="background-color: #28a745"
      (click)="resetBriefQuestion()"
      matTooltip="Reset question and answer field only"
    >
      Reset Question
    </button>
    <button mat-raised-button [mat-dialog-close]="true">Close</button>
  </div>
  <div style="margin-top: 50px; padding: 20px">
    <p
      class="question-new-brief strong"
      style="margin-bottom: 30px"
      *ngIf="briefQuestionForm?.value?.question"
      [innerHtml]="briefQuestionForm?.value?.question"
    ></p>

    <p class="question-new" [innerHtml]="briefQuestionForm?.value?.answer"></p>
  </div>
</ng-template>

