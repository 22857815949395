import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { CsvDownloadFileService } from "../services/csv-download.service";
import { LoaderService } from "../services/loader.service";
import { QueriesService } from "../services/queries.service";

@Component({
  selector: "app-managers",
  templateUrl: "./leads.component.html",
  styleUrls: ["./leads.component.css"],
  providers: [MatDialog]
})
export class LeadsComponent implements OnInit {
  public leads_data = [];
  public search_string;


  constructor(
    public viewContainerRef: ViewContainerRef,
    private loaderService: LoaderService,
    public leads_dialog: MatDialog,
    public downloadService: CsvDownloadFileService,
    public snackBar: MatSnackBar,
    private _queriesservice: QueriesService,
    private datePipe: DatePipe
  ) { }

  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  displayedColumns: string[] = ['serial','id', 'createdAt', 'spaceName', 'hostName', 'first_name', 'last_name', 'email', 'mobile','location','city'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  startDate: any;
  endDate: any;
  startMax: Date | null = null;
  endMin: Date | null = null;

  ngOnInit() {
    this.populateLeads();
  }

  public populateLeads(date?: any) {
    this._queriesservice
      .getLongTermLeads(date).then((res) => {
        if (res.success) {
          this.dataSource = new MatTableDataSource(res.leads);
          this.dataSource.sort = this.sort;

          this.leads_data = res.leads;

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 200);
        } else {
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  applyFilter(value: string) {
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
  }

  startDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.endMin || date <= this.endMin;
  };

  endDateFilter = (date: Date | null): boolean => {
    if (!date) return true;
    return !this.startMax || date >= this.startMax;
  };

  onStartDateChange(event: any): void {
    this.startMax = this.startDate;
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
  }

  onEndDateChange(event: any): void {
    this.endMin = this.endDate;
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");

    let date = {
      startDate: this.startDate,
      endDate: this.endDate
    }

    this.populateLeads(date);
  }

  deleteDate(status: any) {
    if (status == '1') {
      this.startDate = ''
    } else {
      this.endDate = ''
    }
  }

  public downloadCSV() {
    const data: any[] = [];
    let length = this.leads_data.length;
    let leadData = this.leads_data
    if (length) {
      for (let i = 0; i < length; i++) {
        data.push({
          'Id': leadData[i].id,
          'Date & Time': leadData[i].createdAt,
          'Space Name': leadData[i].spaceName,
          'Host Name': leadData[i].hostName,
          'User Name': `${leadData[i].firstName} ${leadData[i].lastName}`,
          'User Email': leadData[i].userEmail,
          'User Phone': leadData[i].userMobile,
        })
      }
      this.downloadService.ExportData(data, 'Long-Term Leads', 'Long-Term Leads.xlsx')
    } else {
      this._openSnackBar("No data found.", "Dismiss")
    }
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  sendMessageDialog(lead_obj) {
    // let config = new MatDialogConfig();
    // let locality_type;
    // if (lead_obj.city_name && lead_obj.location_name) {
    //   locality_type = 1;
    // }
    // if (lead_obj.city_name && !lead_obj.location_name) {
    //   locality_type = 2;
    // }
    // if (!lead_obj.city_name && !lead_obj.location_name) {
    //   locality_type = 3;
    // }
    // config.viewContainerRef = this.leads_viewContainerRef;
    // config.disableClose = true;
    // config.minWidth = '95vw';
    // config.minHeight = '95vh';
    // config.data = lead_obj;

    // this.leads_dialogRef = this.leads_dialog.open(SendMessageDialog, config);
    // this.leads_dialogRef.componentInstance.ref = this.leads_dialogRef;
    // this.leads_dialogRef.componentInstance.selected_lead = lead_obj;
    // this.leads_dialogRef.componentInstance.locality_type = locality_type;
    // this.leads_dialogRef.afterClosed().subscribe((result) => {
    //   if (result && result.success) {
    //     this.populateLeads();
    //   }
    //   this.leads_dialogRef = null;
    // });
  }
}
