import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { SpaceService } from '../.././services/space.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css'],
  providers: [DatePipe]
})
export class MyBookingsComponent {

  workSpace = [
    { workSpaceName: 'Coworking Space', typeOfSpace: "Coworking" },
    { workSpaceName: 'Managed Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Private Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Shared Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Virtual Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Coworking Café/Restaurant', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Shoot Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Recording Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Podcast Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Activity Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Turf', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Venue', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Party Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Banquet Hall', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Gallery', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Classroom', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Private Cabin', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Meeting Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Training Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Event Space', typeOfSpace: "Short-Term" },
  ]
  workSpaceNames: any = [];
  startDate: any;
  endDate: any;
  spaceType: any;
  allBookings: any[] | null = null;
  bookingType!: string;
  selectedSpaceName: string[] = [];
  selectedBookingType: string = 'allBooking';
  selectedStatus: any;
  bookingIdValueForSearch: any;
  hostSpaceName: any = [];
  spaceNames: any = [];
  host: any[] = [];
  selectedHost: any;
  selectHostValue: any;
  pageSize = 25; // Default page size
  currentPage = 0;
  paginatedBookings = [];
  countryData: any = "India";
  countryList: any;
  stateList: any;
  cityList: any;
  stateData: any;
  cityData: any;
  country: any = "India";
  state: any;
  city: any;
  spaceTypeValueForFilter: any;


  constructor(private datePipe: DatePipe, private spaceService: SpaceService) { }

  ngOnInit(): void {
    this.getCountry();
    this.getState(this.countryData);
    this.getAllSpaceByHostId();
    this.getAllHostSpaces();
    this.getHost();
  }

  getHost() {
    this.spaceService.getHost().subscribe((res: any) => {
      for (let i = 0; i < res.data.host.length; i++) {
        this.host.push(res.data.host[i]);
      }
    })
  }

  hostValueChange(event: any) {
    this.selectHostValue = event;
    this.getWorkSpace();
    console.log(this.selectHostValue);
  }

  getWorkSpace() {
    this.spaceService.getAllActiveSpaceType(this.selectHostValue).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        this.workSpaceNames.push(res[i]);
      }
    });
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    if (this.startDate && this.endDate || !this.startDate && !this.endDate) {
      this.getAllHostSpaces();
    }
  }

  getAllSpaceByHostId() {
    this.spaceService.getAllSpaceNameByHostId().subscribe((res: any) => {
      this.hostSpaceName = res.data?.spaces;
      for (let i = 0; i < this.hostSpaceName.length; i++) {
        this.spaceNames.push(this.hostSpaceName[i]);
      }
    })
  }

  handleImageError(event: any) {
    const imgElement = event.target as HTMLImageElement
    imgElement.src = 'assets/images/details_placeholder_image.jpg';
    imgElement.alt = 'Failed to Load Image';
  }

  getAllHostSpaces() {
    console.log(this.spaceType)
    if (this.spaceType && this.spaceType.length > 0) {
      let data = this.spaceType[0].$ngOptionLabel;
      this.spaceTypeValueForFilter = data;
    }else{
      this.spaceTypeValueForFilter = ""
    }
    let data = {
      // userId: this.userId,
      bookingType: this.selectedBookingType,
      spaceType: this.spaceTypeValueForFilter,
      startDate: this.startDate,
      endDate: this.endDate,
      bookingStatus: this.selectedStatus,
      bookingId: this.bookingIdValueForSearch,
      spaceId: this.selectedSpaceName,
      hostId: this.selectedHost,
      countryName: this.countryData,
      stateName: this.stateData?.name ?? '',
      cityName: this.cityData?.name ?? '',
    };
    this.spaceService.getAllHostSpaces(data).subscribe((data: any) => {
      this.allBookings = data?.bookings;
      this.updatePaginatedBookings();
    })
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.allBookings.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }

  getCountry() {
    this.spaceService.getAllCountry().subscribe((res: any) => {
      this.countryList = res;
    });
  }

  onCountryChange(country: any) {
    this.country = country.country_name;
    var countryId = this.countryData?.country_name ?? "India";
    this.getState(countryId);
  }

  getState(id: any) {
    this.spaceService.getAllState(id).subscribe((res: any) => {
      this.stateList = res
    })
  }

  onStateChange(state: any) {
    if (state) {
      this.state = state.name;
    } else {
      this.state = ""
    }
    var stateId = this.stateData?.name;
    this.getAllCities(stateId);
  }

  getAllCities(id: any) {
    this.spaceService.getAllCities(id).subscribe((res: any) => {
      this.cityList = res
    })
  }

  onCityChange(city: any) {
    if (city) {
      this.city = city.name;
    } else {
      this.city = "";
    }
  }

}
