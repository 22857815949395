<div class="row main_row">
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Add Permission</h3>
        <a routerLink="/home/permission" class="btn v3"><i class="fa fa-list"></i>Permission List
        </a>
      </div>
      <div class="card-body">
        <div class="row g-3 panel-body tabs-menu-body">
          <div class="col-12">
            <label class="required form-label">Enter Department<span class="text-danger">*</span></label>
            <div class="filter-checkbox">
              <input matInput placeholder="Enter department" type="text" class="form-control filter-input"
                [(ngModel)]="dept" name="dept" (blur)="notValid = true">
              <mat-error *ngIf="!dept && notValid">
                Department is required
              </mat-error>
            </div>
          </div>
          <div class="col-12 mt-5">
            <div class="row">
              <label class="form-label col-3">All Permissions</label>
              <mat-checkbox class="col"  [(ngModel)]="globalPermissions.add" (change)="toggleGlobalPermission('add')"
                name="GlobalAdd">Add</mat-checkbox>
              <mat-checkbox class="col" [(ngModel)]="globalPermissions.view" (change)="toggleGlobalPermission('view')"
                name="globalView">View</mat-checkbox>
              <mat-checkbox class="col" [(ngModel)]="globalPermissions.edit" (change)="toggleGlobalPermission('edit')"
                name="globalEdit">Edit</mat-checkbox>
              <mat-checkbox class="col" [(ngModel)]="globalPermissions.delete"
                (change)="toggleGlobalPermission('delete')" name="globalDelete">Delete</mat-checkbox>
            </div>

            <div *ngFor="let part of module;let i =index">
              <h2 class="px-3 py-1 bg-primary text-white my-3" style="border-radius: .5rem;">{{ part.title }}</h2>

              <div class="row">
                <div class="col-3"></div>
                <mat-checkbox class="col" [(ngModel)]="part.permissions.add"
                  (change)="togglePartPermission(part, 'add')" [indeterminate]="disable('add',part)" [disabled]="disable('add',part)"
                  name="partAdd">Add</mat-checkbox>
                <mat-checkbox class="col" [(ngModel)]="part.permissions.view"
                  (change)="togglePartPermission(part, 'view')" name="partView"
                  >View</mat-checkbox>
                <mat-checkbox class="col" [(ngModel)]="part.permissions.edit"
                  (change)="togglePartPermission(part, 'edit')" name="partEdit"
                  [disabled]="disable('edit',part)" [indeterminate]="disable('edit',part)">
                  {{part.title === 'Review'  || part.title === 'Space Request'  ? 'Approve/Reject' : 
                  part.title === 'Paywall' ? 'Edit/Activate/Deactivate' : 'Edit' }}</mat-checkbox>
                <mat-checkbox class="col" [(ngModel)]="part.permissions.delete"
                  (change)="togglePartPermission(part, 'delete')" name="partDelete"
                  [disabled]="disable('delete',part)" [indeterminate]="disable('delete',part)">Delete</mat-checkbox>
              </div>

              <div *ngFor="let subpart of part?.subModule">
                <div class="row">
                  <h3 class="col-3">{{ subpart.title }}</h3>

                  <mat-checkbox class="col" [(ngModel)]="subpart.permissions.add" (change)="updateSubpartState(part)"
                    name="subAdd" [disabled]="disableSub('add',subpart)" [indeterminate]="disableSub('add',subpart)">Add</mat-checkbox>
                    <mat-checkbox class="col" [(ngModel)]="subpart.permissions.view" (change)="updateSubpartState(part)"
                      name="subView">View</mat-checkbox>
                    <mat-checkbox class="col" [(ngModel)]="subpart.permissions.edit" (change)="updateSubpartState(part)"
                      name="subEdit" [disabled]="disableSub('edit',subpart)" [indeterminate]="disableSub('edit',subpart)">{{subpart.title === 'Package' ? 'Edit/Activate/Deactivate': 'Edit' }}</mat-checkbox>
                    <mat-checkbox class="col" [(ngModel)]="subpart.permissions.delete" (change)="updateSubpartState(part)"
                      name="subDelete" [disabled]="disableSub('delete',subpart)" [indeterminate]="disableSub('delete',subpart)">Delete</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-list mt-4">
            <a class="btn btn-primary-light" style="float: right;" (click)="submit()">
              Submit
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>