import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-delete-lead',
  templateUrl: './delete-lead.component.html',
  styleUrls: ['./delete-lead.component.css']
})
export class DeleteLeadComponent {

  id:any;

  constructor(public dialogRef: MatDialogRef<DeleteLeadComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private queriesService:QueriesService, private loaderService: LoaderService, public snackBar: MatSnackBar){
    this.id = this.data.id;
  }

  ngOnInit(){}

  onYesClick(): void {
    this.queriesService.deleteLeadById(this.id).then((res:any) => {
      if (res.success) {
        this.dialogRef.close(true);
        this.openSnackBar(res.message, 'Dismiss');
      } else{
        this.dialogRef.close(false);
        this.openSnackBar(res.message, 'Dismiss');
      }
    })

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
