<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Tell Users about your space</h3>
            </div>
            <div class="card-body">
                <div class="row align-items-start">
                    <div class="col-lg-3 col-md-4 panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu radius-tab">
                                <ul (navChange)="onTabChange($event)" ngbNav #nav3="ngbNav" [activeId]="activeTab"
                                    class="mb-md-0 mb-5 nav panel-tabs panel-info">
                                    <!-- <li [ngbNavItem]="0">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>Select your Host</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Host <span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <p>{{selectedHostNameAndEmail}}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="btn-list">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li> -->
                                    <!-- <li [ngbNavItem]="1">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>Select your category</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Category<span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <p>{{createSpace?.spaceType}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="btn-list">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li> -->
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>General
                                            Information/Location</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.actual_name !== oldSpaceData.actual_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt" for="actual_name">Name of your
                                                            space <span class="text-danger">*</span></label>

                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.actual_name !== oldSpaceData.actual_name)">
                                                            <p> Old value: {{oldSpaceData.actual_name}} </p>
                                                            <p>New value: {{createSpace.actual_name}}</p>
                                                        </span>

                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.actual_name ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.yourWebsite !== oldSpaceData.yourWebsite)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your website URL<span
                                                                style="font-weight: 100;font-size: 12px;">
                                                                (company.com)</span><span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.yourWebsite !== oldSpaceData.yourWebsite)">
                                                            <p>Old value: {{oldSpaceData.yourWebsite}} </p>
                                                            <p>New value: {{createSpace.yourWebsite}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.yourWebsite ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.country !== oldSpaceData.country)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Country <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.country !== oldSpaceData.country)">
                                                            <p>Old value: {{oldSpaceData.country}} </p>
                                                            <p>New value: {{createSpace.country}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.country}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.state !== oldSpaceData.state)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">State <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.state !== oldSpaceData.state)">
                                                            <p>Old value: {{oldSpaceData.state}} </p>
                                                            <p>New value: {{createSpace.state}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.state}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contact_city_name !== oldSpaceData.contact_city_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">City <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.contact_city_name !== oldSpaceData.contact_city_name)">
                                                            <p>Old value: {{oldSpaceData.contact_city_name}} </p>
                                                            <p>New value: {{createSpace.contact_city_name}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.contact_city_name}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.location_name !== oldSpaceData.location_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt"> Location Name <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.location_name !== oldSpaceData.location_name)">
                                                            <p>Old value: {{oldSpaceData.location_name}} </p>
                                                            <p>New value: {{createSpace.location_name}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.location_name}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.buildingName !== oldSpaceData.buildingName)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your building name</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.buildingName !== oldSpaceData.buildingName)">
                                                            <p>Old value: {{oldSpaceData.buildingName}} </p>
                                                            <p>New value: {{createSpace.buildingName}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.buildingName}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.floorNumber !== oldSpaceData.floorNumber)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your unit/floor no.</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.floorNumber !== oldSpaceData.floorNumber)">
                                                            <p>Old value: {{oldSpaceData.floorNumber}} </p>
                                                            <p>New value: {{createSpace.floorNumber}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.floorNumber}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.address !== oldSpaceData.address)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter Address line 1 <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.address !== oldSpaceData.address)">
                                                            <p>Old value: {{oldSpaceData.address}} </p>
                                                            <p>New value: {{createSpace.address}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.address}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.address2 !== oldSpaceData.address2)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter Address line 2</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.address2 !== oldSpaceData.address2)">
                                                            <p>Old value: {{oldSpaceData.address2}} </p>
                                                            <p>New value: {{createSpace.address2}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.address2}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.zipcode !== oldSpaceData.zipcode)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Zip/Postal code <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.zipcode !== oldSpaceData.zipcode)">
                                                            <p>Old value: {{oldSpaceData.zipcode}} </p>
                                                            <p>New value: {{createSpace.zipcode}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.zipcode}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isSameBillingAddress !== oldSpaceData.isSameBillingAddress)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt" for="exampleInputEmail1">Is the
                                                            Billing address the same as
                                                            the communication address?</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.isSameBillingAddress !== oldSpaceData.isSameBillingAddress)">
                                                            <p>Old value: {{oldSpaceData.isSameBillingAddress}} </p>
                                                            <p>New value: {{createSpace.isSameBillingAddress}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.isSameBillingAddress ? 'Yes' : 'No'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_country !== oldSpaceData.billing_country)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Billing Country <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_country !== oldSpaceData.billing_country)">
                                                            <p>Old value: {{oldSpaceData.billing_country}} </p>
                                                            <p>New value: {{createSpace.billing_country}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_country}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_state !== oldSpaceData.billing_state)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Billing state <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_state !== oldSpaceData.billing_state)">
                                                            <p>Old value: {{oldSpaceData.billing_state}} </p>
                                                            <p>New value: {{createSpace.billing_state}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_state}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_contact_city_name !== oldSpaceData.billing_contact_city_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Billing city <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_contact_city_name !== oldSpaceData.billing_contact_city_name)">
                                                            <p>Old value: {{oldSpaceData.billing_contact_city_name}}
                                                            </p>
                                                            <p>New value: {{createSpace.billing_contact_city_name}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_contact_city_name}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_location_name !== oldSpaceData.billing_location_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Location Name <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_location_name !== oldSpaceData.billing_location_name)">
                                                            <p>Old value: {{oldSpaceData.billing_location_name}} </p>
                                                            <p>New value: {{createSpace.billing_location_name}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_location_name}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_buildingName !== oldSpaceData.billing_buildingName)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your building name</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_buildingName !== oldSpaceData.billing_buildingName)">
                                                            <p>Old value: {{oldSpaceData.billing_buildingName}} </p>
                                                            <p>New value: {{createSpace.billing_buildingName}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_buildingName}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_floorNumber !== oldSpaceData.billing_floorNumber)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your unit/floor no.</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billing_floorNumber !== oldSpaceData.billing_floorNumber)">
                                                            <p>Old value: {{oldSpaceData.billing_floorNumber}} </p>
                                                            <p>New value: {{createSpace.billing_floorNumber}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billing_floorNumber}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billingaddress !== oldSpaceData.billingaddress)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter billing address line 1 <span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.billingaddress !== oldSpaceData.billingaddress)">
                                                            <p>Old value: {{oldSpaceData.billingaddress}} </p>
                                                            <p>New value: {{createSpace.billingaddress}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.billingaddress}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_address2 !== oldSpaceData.billing_address2)">
                                                <div class="form-group">
                                                    <label class="head_label_txt">Enter billing address line 2</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.billing_address2 !== oldSpaceData.billing_address2)">
                                                        <p>Old value: {{oldSpaceData.billing_address2}} </p>
                                                        <p>New value: {{createSpace.billing_address2}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.billing_address2}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_zip_code !== oldSpaceData.billing_zip_code)">
                                                <div class="form-group">
                                                    <label class="head_label_txt">Enter billing zip/postal code <span
                                                            class="text-danger">*</span></label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.billing_zip_code !== oldSpaceData.billing_zip_code)">
                                                        <p>Old value: {{oldSpaceData.billing_zip_code}} </p>
                                                        <p>New value: {{createSpace.billing_zip_code}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.billing_zip_code}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row col-md-12"
                                                *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.location !== oldSpaceData.location)">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Enter your space location<span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.location !== oldSpaceData.location)">
                                                            <p>Old value: {{oldSpaceData.location}} </p>
                                                            <p>New value: {{createSpace.location}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.location}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <!-- <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a> -->
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> About the space:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceTitle !== oldSpaceData.spaceTitle)">
                                                    <div class="form-group">
                                                        <label class="required head_label_txt">Enter your space title
                                                            <span class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.spaceTitle !== oldSpaceData.spaceTitle)">
                                                            <p>Old value: {{oldSpaceData.spaceTitle}} </p>
                                                            <p>New value: {{createSpace.spaceTitle}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.spaceTitle ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.hostRulesDescription !== oldSpaceData.hostRulesDescription)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">What are you host rules
                                                    </label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.hostRulesDescription !== oldSpaceData.hostRulesDescription)">
                                                        <p>Old value: {{oldSpaceData.hostRulesDescription}} </p>
                                                        <p>New value: {{createSpace.hostRulesDescription}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.hostRulesDescription ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.parkingOptionsValue != oldParkingOptionsvalue)"
                                                class="col-md-6">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Parking options</label>
                                                    <span *ngIf="(viewItem=='Edit' && compareParkingoptions)">
                                                        <h4>Old value:<p class="form-control"
                                                                *ngFor="let oldParking of oldParkingOptionsvalue">
                                                                {{oldParking}}</p>
                                                        </h4>
                                                        <h4>New value:<p class="form-control"
                                                                *ngFor="let newParking of createSpace.parkingOptionsValue">
                                                                {{newParking}}</p>
                                                        </h4>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.parkingOptionsValue ??
                                                            'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.about !== oldSpaceData.about)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Add a description of your
                                                        space</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.about !== oldSpaceData.about)">
                                                        <p>Old value: {{oldSpaceData.about}} </p>
                                                        <p>New value: {{createSpace.about}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.about ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.parkingDescription !== oldSpaceData.parkingDescription)"
                                                class="col-md-6">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Describe the parking
                                                        options</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.parkingDescription !== oldSpaceData.parkingDescription)">
                                                        <p>Old value: {{oldSpaceData.parkingDescription}} </p>
                                                        <p>New value: {{createSpace.parkingDescription}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.parkingDescription ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.lightingDescription !== oldSpaceData.lightingDescription)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Describe the lighting
                                                        options</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.lightingDescription !== oldSpaceData.lightingDescription)">
                                                        <p>Old value: {{oldSpaceData.lightingDescription}} </p>
                                                        <p>New value: {{createSpace.lightingDescription}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.lightingDescription ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.soundDescription !== oldSpaceData.soundDescription)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Describe the sound
                                                        options</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.soundDescription !== oldSpaceData.soundDescription)">
                                                        <p>Old value: {{oldSpaceData.soundDescription}} </p>
                                                        <p>New value: {{createSpace.soundDescription}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.soundDescription ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.arrivalInstructions !== oldSpaceData.arrivalInstructions)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">Provide arrival
                                                        instructions</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.arrivalInstructions !== oldSpaceData.arrivalInstructions)">
                                                        <p>Old value: {{oldSpaceData.arrivalInstructions}} </p>
                                                        <p>New value: {{createSpace.arrivalInstructions}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.arrivalInstructions ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.wifiName !== oldSpaceData.wifiName)">
                                                    <div class="form-group">
                                                        <label class="required head_label_txt">What is your Wi-fi Name </label>
                                                        <span *ngIf="(viewItem=='Edit' && createSpace.wifiName !== oldSpaceData.wifiName)">
                                                            <p>Old value: {{oldSpaceData.wifiName}} </p>
                                                            <p>New value: {{createSpace.wifiName}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.wifiName ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.wifiPassword !== oldSpaceData.wifiPassword)">
                                                    <div class="form-group">
                                                        <label class="required head_label_txt">What is your Wi-fi Password</label>
                                                        <span *ngIf="(viewItem=='Edit' && createSpace.wifiPassword !== oldSpaceData.wifiPassword)">
                                                            <p>Old value: {{oldSpaceData.wifiPassword}} </p>
                                                            <p>New value: {{createSpace.wifiPassword}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.wifiPassword ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div> -->
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue == 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceStatus !== oldSpaceData.spaceStatus)">
                                                <div class="form-group">
                                                    <label class="required head_label_txt">What is the status of your
                                                        space? (Bare
                                                        shell, warm shell, semi-furnished, furnished)</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.spaceStatus !== oldSpaceData.spaceStatus)">
                                                        <p>Old value: {{oldSpaceData.spaceStatus}} </p>
                                                        <p>New value: {{createSpace.spaceStatus}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.spaceStatus ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="4">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> Size/Capacity:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spacesqft !== oldSpaceData.spacesqft)">
                                                    <label class="head_label_txt"
                                                        *ngIf="createSpace?.spacetypeValue == 'Long-Term'">How big is
                                                        the
                                                        space ? (carpet area sq ft)</label>
                                                    <label class="head_label_txt"
                                                        *ngIf="createSpace?.spacetypeValue == 'Short-Term'">How big is
                                                        the space ? (Square Feet Area)</label>
                                                    <label class="head_label_txt"
                                                        *ngIf="createSpace?.spacetypeValue == 'Coworking'">What is the
                                                        size of your space in sqft ? </label>

                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.spacesqft !== oldSpaceData.spacesqft)">
                                                        <p>Old value: {{oldSpaceData.spacesqft}} </p>
                                                        <p>New value: {{createSpace.spacesqft}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.spacesqft ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6"
                                                *ngIf="createSpace?.spacetypeValue == 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceBuiltUpArea !== oldSpaceData.spaceBuiltUpArea)">
                                                <label class="head_label_txt">How big is the space (built-up area sq
                                                    ft)</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.spaceBuiltUpArea !== oldSpaceData.spaceBuiltUpArea)">
                                                    <p>Old value: {{oldSpaceData.spaceBuiltUpArea}} </p>
                                                    <p>New value: {{createSpace.spaceBuiltUpArea}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p>{{createSpace.spaceBuiltUpArea ?? 'N/A'}}</p>
                                                </span>
                                            </div>
                                            <div class="row"
                                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.howManyPeopleInYourSpace !== oldSpaceData.howManyPeopleInYourSpace)">
                                                <div class="col-md-6">
                                                    <label class="head_label_txt">How many people can be accomodated in
                                                        your space</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.howManyPeopleInYourSpace !== oldSpaceData.howManyPeopleInYourSpace)">
                                                        <p>Old value: {{oldSpaceData.howManyPeopleInYourSpace}} </p>
                                                        <p>New value: {{createSpace.howManyPeopleInYourSpace}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.howManyPeopleInYourSpace ?? 'N/A'}}</p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="btn-list" style="margin-top:20px">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="5">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>
                                            Amenities/Equipment/Parking:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <ng-container>
                                                    <div class="col-md-12"
                                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.facilities !== oldFacilities)">
                                                        <div class="form-group">
                                                            <label class="required head_label_txt">Amenities<span
                                                                    class="text-danger">*</span> </label>
                                                            <div class="d-block"
                                                                *ngIf="viewItem == 'Edit' && createSpace.facilities !== oldFacilities">
                                                                <p>Old value:
                                                                    <span *ngFor="let facility of oldFacilities">
                                                                        {{ getFacilityName(facility) }}{{$last ? '' : ',
                                                                        '}}
                                                                    </span>
                                                                </p>
                                                                <p>New value:
                                                                    <span
                                                                        *ngFor="let facility of createSpace.facilities">
                                                                        {{ getFacilityName(facility) }}{{$last ? '' : ',
                                                                        '}}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div class="d-block" *ngIf="(viewItem!='Edit')">
                                                                <p style="margin-right: 10px;margin-top: 15px;display: inline-flex;align-items: center;"
                                                                    *ngFor="let facility of amenitiesName; let i = index">
                                                                    <span>{{i+1}}.&nbsp;</span> {{facility}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="createSpace?.spacetypeValue !== 'Short-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.howMenyParking !== oldSpaceData.howMenyParking)">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="head_label_txt">How many parkings are included
                                                                with your space</label>
                                                            <span
                                                                *ngIf="(viewItem=='Edit' && createSpace.howMenyParking !== oldSpaceData.howMenyParking)">
                                                                <p>Old value: {{oldSpaceData.howMenyParking}} </p>
                                                                <p>New value: {{createSpace.howMenyParking}}</p>
                                                            </span>
                                                            <span *ngIf="(viewItem!='Edit')">
                                                                <p>{{createSpace.howMenyParking ?? 'N/A'}}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- <ng-container
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.equipment !== oldSpaceData.equipment)">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="head_label_txt">List the equipment that are include with the booking</label>
                                                            <span *ngIf="(viewItem=='Edit' && createSpace.equipment !== oldSpaceData.equipment)">
                                                                <p>Old value: {{oldSpaceData.equipment}} </p>
                                                                <p>New value: {{createSpace.equipment}}</p>
                                                            </span>
                                                            <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.equipment ?? 'N/A'}}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                                <ng-container
                                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceSecurity !== oldSpaceData.spaceSecurity)">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="head_label_txt">Does your space have security
                                                                cameras or recording devices</label>
                                                            <span
                                                                *ngIf="(viewItem=='Edit' && createSpace.spaceSecurity !== oldSpaceData.spaceSecurity)">
                                                                <p>Old value: {{oldSpaceData.spaceSecurity == '1' ?
                                                                    'Yes' : 'No'}} </p>
                                                                <p>New value: {{createSpace.spaceSecurity == '1' ? 'Yes'
                                                                    : 'No'}}</p>
                                                            </span>
                                                            <span *ngIf="(viewItem!='Edit')">
                                                                <p>{{createSpace.spaceSecurity == '1' ? 'Yes' : 'No'}}
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="col-md-6">
                                                    <label style="color: red"
                                                        *ngIf="price_message">{{price_message}}</label>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="6" *ngIf="createSpace?.spacetypeValue !== 'Long-Term'">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>
                                            Operating hours:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.has_247_access_toggle !== oldSpaceData.has_247_access_toggle)">
                                                    <!-- <mat-checkbox [(ngModel)]="has_247_access_toggle"
                                                        [checked]="has_247_access_toggle">
                                                        Has 24x7 Access
                                                    </mat-checkbox> -->
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.has_247_access_toggle !== oldSpaceData.has_247_access_toggle)">
                                                        <p>Old value: {{oldSpaceData.has_247_access_toggle=='1' ? 'Yes'
                                                            : 'No'}} </p>
                                                        <p>New value: {{createSpace.has_247_access_toggle=='1' ? 'Yes' :
                                                            'No'}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>Has 24x7 Access {{createSpace.has_247_access ? 'Yes' : 'No'}}
                                                        </p>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li *ngFor="let time of createSpace?.working_time">
                                                        <strong>{{ time.day }}:</strong>
                                                        <div>
                                                            <span>
                                                                Opening Time:
                                                                {{ formatTime(time.openingTime) !== undefined &&
                                                                formatTime(time.openingTime) !== '' ?
                                                                formatTime(time.openingTime) : 'N/A' }},
                                                                Closing Time:
                                                                {{ formatTime(time.closingTime) !== undefined &&
                                                                formatTime(time.closingTime) !== '' ?
                                                                formatTime(time.closingTime) : 'N/A' }},
                                                                Status:
                                                                {{ time.isClosed ? 'Closed' : 'Open' }}
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <!-- <li [ngbNavItem]="7"
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> Activities:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row" *ngIf="spaceServiceDetailsArray.length > 0">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label  class="required head_label_txt">Which activity would you like to
                                                            host</label>
                                                        <input type="text" class="form-control filter-input"
                                                            placeholder="Enter activity would you like to host">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li> -->
                                    <li [ngbNavItem]="7">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> Pricing:</a>
                                        <ng-template ngbNavContent *ngIf="createSpace?.spacetypeValue == 'Short-Term'">
                                            <div class="row g-3 align-items-start">
                                                

                                                <div class="col-6 mb-3"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.minimum_hours !== oldSpaceData.minimum_hours)">
                                                    <div class="text-muted">Minimum Hours</div>
                                                    <div>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.minimum_hours !== oldSpaceData.minimum_hours)">
                                                            <p>Old value: {{oldSpaceData.minimum_hours/60}} </p>
                                                            <p>New value: {{createSpace.minimum_hours/60}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.minimum_hours/60}} Hours</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="text-muted"
                                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)">
                                                        Booking Type</div>
                                                    <div class="row">
                                                        <div class="col-md-6"
                                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)">
                                                            <div class="form-check ps-0 d-flex align-items-center">
                                                                <!-- <input (click)="getIsInstantValue($event)"
                                                                    [checked]="createSpace.isInstant == 0" value="0"
                                                                    class="form-check-input" type="radio"
                                                                    name="flexRadioDefault" id="flexRadioDefault1">
                                                                <label class="form-check-label" for="flexRadioDefault1">
                                                                    Request to Book
                                                                </label> -->
                                                                <span
                                                                    *ngIf="(viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)">
                                                                    <p>Old value: {{oldSpaceData.isInstant=='0' ?
                                                                        'Request to Book' : 'Instant Book'}} </p>
                                                                    <p>New value: {{createSpace.isInstant=='0' ?
                                                                        'Request to Book' : 'Instant Book'}}</p>
                                                                </span>
                                                                <span *ngIf="(viewItem!='Edit')">
                                                                    <p class="text-nowrap">{{createSpace.isInstant=='0' ? 'Request to Book'
                                                                        : 'Instant Book'}}</p>
                                                                </span>
                                                            </div>
                                                            <div *ngIf="(viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)"
                                                                class="my-3 border border-2 rounded p-3">
                                                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                                Officia, architecto!
                                                                <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea> -->
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-6">
                                                            <div class="form-check d-flex align-items-center">
                                                                <input (click)="getIsInstantValue($event)"
                                                                    [checked]="createSpace.isInstant == 1" value="1"
                                                                    class="form-check-input" type="radio"
                                                                    name="flexRadioDefault" id="flexRadioDefault2">
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    Instant Book
                                                                </label>
                                                            </div>
                                                            <div class="my-3 border border-2 rounded p-3">
                                                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                                Officia, architecto!
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                
                                                <div class="col-6 mb-3"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                    <div class="text-muted"> Duration </div>
                                                    <div
                                                        class="mt-2 text-muted px-5 py-2 bg-light rounded border border-2" style="width: fit-content;">
                                                        Hourly</div>
                                                </div>
                                                <div class="col-6 mb-3"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                    <div class="text-muted">Price</div>
                                                    <!-- <div class="row border border-2 mt-2 "> -->
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                        <p>Old value: {{oldSpaceData.price}} </p>
                                                        <p>New value: {{createSpace.price}}</p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p>{{createSpace.price}}</p>
                                                    </span>
                                                    <!-- </div> -->
                                                    <!-- <div *ngIf="price.invalid && (price.dirty || price.touched)"
                                                        class="text-danger">
                                                        <div *ngIf="price.errors && price.errors['required']">
                                                            Price field is required.</div>
                                                    </div> -->
                                                </div>

                                                <div class="btn-list">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbNavContent *ngIf="createSpace?.spacetypeValue == 'Coworking'">
                                            <div class="row">


                                                <ng-container *ngIf="createSpace?.spacetypeValue == 'Coworking'">
                                                    <div class="col-md-6" *ngFor="let resource_type of resource_types">
                                                        <div class="form-group" *ngIf="resource_type.id!=8">
                                                            <label class="head_label_txt"
                                                                *ngIf="createSpace?.[resource_type.column_name] !== oldSpaceData?.[resource_type.column_name]">{{resource_type.name}}
                                                                price starts at</label>
                                                            <span
                                                                *ngIf="(viewItem=='Edit' && createSpace?.[resource_type.column_name] !== oldSpaceData?.[resource_type.column_name])">
                                                                <p>Old value:
                                                                    {{oldSpaceData?.[resource_type.column_name] ?? 0}}
                                                                </p>
                                                                <p>New value: {{createSpace?.[resource_type.column_name]
                                                                    ?? 0}}</p>
                                                            </span>
                                                            <span *ngIf="(viewItem!='Edit')">
                                                                <p>{{createSpace?.[resource_type.column_name] ?? 'N/A'}}
                                                                </p>
                                                            </span>
                                                            <!-- <input type="tel" class="form-control filter-input"
                                                                [id]='"res-" + resource_type.id'
                                                                [(ngModel)]="createSpace[resource_type.column_name]"
                                                                placeholder='Enter starting price of {{resource_type.name}}'
                                                                [disabled]="!createSpace?.[resource_type.column_name] ?? ''"> -->
                                                        </div>
                                                        <div class="form-group" *ngIf="resource_type.id==8">
                                                            <div class="row mx-0">
                                                                <div class="w-25">
                                                                    <div class="text-muted head_label_txt"> Duration
                                                                    </div>
                                                                    <div
                                                                        class="mt-2 text-muted px-4 py-2 bg-light rounded border border-2">
                                                                        Daily</div>
                                                                </div>
                                                                <div class="col-lg-9">
                                                                    <div class="text-muted head_label_txt">Price (Per
                                                                        Guest)</div>
                                                                    <div class="row border border-2 mt-2 ">
                                                                        <div class="col-1">
                                                                            <label for="inputnumber"
                                                                                class="col-form-label fw-bold">INR</label>
                                                                        </div>
                                                                        <div class="col-11 pe-0">
                                                                            <p>{{createSpace?.[resource_type.column_name]
                                                                                ?? 'N/A'}}</p>
                                                                            <!-- <input [(ngModel)]="createSpace.price"
                                                                                #price="ngModel"
                                                                                [disabled]="!createSpace?.[resource_type.column_name] ?? ''"
                                                                                onkeypress="return /[0-9 ]/.test(event.key)"
                                                                                type="tel" [id]='"res-8"'
                                                                                class="form-control border-0"
                                                                                aria-describedby="passwordHelpInline"
                                                                                placeholder="eg12"> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="btn-list">
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light "
                                                        style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbNavContent *ngIf="createSpace?.spacetypeValue == 'Long-Term'">
                                            <div class="row">
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">What is your expected monthly
                                                            rental? <span class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                            <p>Old value: {{oldSpaceData.price}} </p>
                                                            <p>New value: {{createSpace.price}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace?.price}}</p>
                                                        </span>
                                                        <!-- <input maxlength="8" required [(ngModel)]="createSpace.price"
                                                            #price="ngModel" onkeypress="return /[0-9]/.test(event.key)"
                                                            (keyup)="calcualteSqftPrice()" type="text" name="priceconfig"
                                                            class="form-control filter-input"
                                                            placeholder="What is your expected monthly rental?"> -->

                                                        <!-- <div *ngIf="price.invalid && (price.dirty || price.touched)"
                                                            class="text-danger">
                                                            <div *ngIf="price.errors && price.errors['required']">
                                                                Price field is required.</div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.securityDeposit !== oldSpaceData.securityDeposit)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">How many months of security
                                                            deposit are you
                                                            expecting? <span class="text-danger">*</span> </label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.securityDeposit !== oldSpaceData.securityDeposit)">
                                                            <p>Old value: {{oldSpaceData.securityDeposit}} </p>
                                                            <p>New value: {{createSpace.securityDeposit}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace?.securityDeposit}}</p>
                                                        </span>

                                                        <!-- <input maxlength="2" required
                                                            [(ngModel)]="createSpace.securityDeposit"
                                                            #securityDeposit="ngModel"
                                                            onkeypress="return /[0-9 ]/i.test(event.key)" type="tel"
                                                            name="priceconfig" class="form-control filter-input"
                                                            placeholder="How many months of security deposit are you expecting?"> -->

                                                        <!-- <div *ngIf="securityDeposit.invalid && (securityDeposit.dirty || securityDeposit.touched)"
                                                            class="text-danger">
                                                            <div
                                                                *ngIf="securityDeposit.errors && securityDeposit.errors['required']">
                                                                Security deposit field is required.</div>
                                                        </div> -->

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Price / sqft (carpet)</label>
                                                        <p>{{createSpace.spaceCarpetAreaPrice}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Price / sqft (built-up)</label>
                                                        <p>{{createSpace.spaceBuiltUpAreaPrice}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="8"
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> Add on services:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h5>Add on services</h5>
                                                    <div class="form-group"></div>
                                                    <p
                                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.addOnServices !== oldSpaceData.addOnServices)">
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.addOnServices !== oldSpaceData.addOnServices)">
                                                            <div *ngIf="differences.length > 0" class="mt-4">
                                                                <!-- <h2>Differences</h2> -->
                                                                <ul>
                                                                    <li
                                                                        *ngFor="let diff of createSpace.spaceServiceDetailsArray">
                                                                        <div>
                                                                            <span>
                                                                                Old value - serviceName:
                                                                                {{ diff.serviceName !== undefined &&
                                                                                diff.serviceName !== '' ?
                                                                                diff.serviceName : 'N/A' }},
                                                                                servicePrice:
                                                                                {{ diff.servicePrice !== undefined &&
                                                                                diff.servicePrice !== '' ?
                                                                                diff.servicePrice : 'N/A' }},
                                                                                servicePriceType:
                                                                                {{ diff.servicePriceType }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                New value - serviceName:
                                                                                {{ diff.serviceName !== undefined &&
                                                                                diff.serviceName !== '' ?
                                                                                diff.serviceName : 'N/A' }},
                                                                                servicePrice:
                                                                                {{ diff.servicePrice !== undefined &&
                                                                                diff.servicePrice !== '' ?
                                                                                diff.servicePrice : 'N/A' }},
                                                                                servicePriceType:
                                                                                {{ diff.servicePriceType }}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <div *ngIf="createSpace.spaceServiceDetailsArray.length > 0"
                                                                class="mt-4">
                                                                <ul>
                                                                    <li
                                                                        *ngFor="let diff of createSpace.spaceServiceDetailsArray">
                                                                        <div>
                                                                            <span>
                                                                                serviceName:
                                                                                {{ diff.serviceName !== undefined &&
                                                                                diff.serviceName !== '' ?
                                                                                diff.serviceName : 'N/A' }},
                                                                                servicePrice:
                                                                                {{ diff.servicePrice !== undefined &&
                                                                                diff.servicePrice !== '' ?
                                                                                diff.servicePrice : 'N/A' }},
                                                                                servicePriceType:
                                                                                {{ diff.servicePriceType }}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="btn-list">
                                                <a href="javascript:void(0)" class="btn btn-primary-light"
                                                    (click)="onPreviousButtonClick()">Previous</a>
                                                <a href="javascript:void(0)" class="btn btn-primary-light "
                                                    style="float: right;" (click)="onNextButtonClick()">Next</a>
                                                <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onNextButtonClick()">Next</a> -->
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="9"
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking'">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i> Cancellation Policy:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h5>Select cancellation policy</h5>
                                                    <div class="form-group"></div>
                                                    <p
                                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.cancellationPolicy !== oldSpaceData.cancellationPolicy)">
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.cancellationPolicy !== oldSpaceData.cancellationPolicy)">

                                                            <p>Old value: {{oldSpaceData.cancellationPolicy}} </p>
                                                            <p
                                                                [innerHTML]="oldSpaceData.cancellationPolicyDescription ?? 'N/A'">
                                                                Old value description:</p>

                                                            <p>New value: {{createSpace.cancellationPolicy}}</p>
                                                            <p [innerHTML]="createSpace.cancellationPolicyDescription">
                                                                New value description:</p>

                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p [innerHTML]="createSpace.cancellationPolicy"></p>
                                                            <p [innerHTML]="createSpace.cancellationPolicyDescription"></p>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-primary-light"
                                                (click)="onPreviousButtonClick()">Previous</a>
                                            <a href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="10">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>Upload Photos:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <!-- <div class="form-group"> -->
                                                <!-- <form class="photo-upload"> -->
                                                <!-- <div class="form-group">
                                                            <div class="add-listing__input-file-box">
                                                                <input class="add-listing__input-file" type="file"
                                                                    name="file" id="file" multiple
                                                                    (change)="fileChangeEvent($event)"
                                                                    accept="image/jpeg, image/png" required>
                                                                <div class="add-listing__input-file-wrap">
                                                                    <i class="ion-ios-cloud-upload"></i>
                                                                    <p>Click here to upload your images</p>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                <!-- <div *ngIf="filesToUpload.length < 5">
                                                            <p class="text-danger">*Please upload at least 5 images.</p>
                                                        </div> -->
                                                <!-- </form> -->
                                                <!-- <ul>
                                                        <li class="file-list" *ngFor="let file of filesToUpload">
                                                            <span style="flex: 2">{{file.name}}</span><span
                                                                style="flex: 1">{{getSizeInMb(file.size)}}
                                                                MB</span><span class="ion-close"
                                                                (click)="removeFile(file)"></span>
                                                        </li>
                                                        <hr>
                                                    </ul> -->
                                                <!-- <div class="add-btn" *ngIf="filesToUpload && filesToUpload.length > 0">
                                                        <button (click)="uploadImages();"
                                                            [disabled]="is_add_button_disabled"
                                                            class="btn btn-primary mar-top-20">Add
                                                            Images</button>
                                                    </div> -->
                                                <!-- </div> -->
                                                <div *ngIf="createSpace?.images && createSpace.images.length > 0"
                                                    class="image-container">
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace?.images !== oldDataImages)">
                                                        <p>Old Images: </p>
                                                        <span class="row">
                                                            <span class="col-2 text-center"
                                                                *ngFor="let element of oldDataImages ;let i = index ">
                                                                <img class="item w-100" [src]="imageUrl+'/'+element"
                                                                    alt="Details image">
                                                                <strong *ngIf="i==0">Cover Image</strong>
                                                            </span>
                                                        </span>
                                                        <p>New Images: </p>
                                                        <span class="row">
                                                            <span class="col-2 text-center"
                                                                *ngFor="let element of createSpace.images;let i = index">
                                                                <img class="item w-100" [src]="imageUrl+'/'+element"
                                                                    alt="Details image">
                                                                <strong *ngIf="i==0">Cover Image</strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <span *ngFor="let element of createSpace.images">
                                                            <img class="item" [src]="imageUrl+'/'+element"
                                                                alt="Details image">
                                                        </span>
                                                    </span>
                                                </div>
                                                <div *ngIf="createSpace?.youtube_url" class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Youtube Url</label>
                                                        <input type="text" name="youtube_url"
                                                            [(ngModel)]="createSpace.youtube_url"
                                                            class="form-control filter-input"
                                                            placeholder="Enter youtube url">
                                                    </div>

                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-primary-light"
                                                (click)="onPreviousButtonClick()">Previous</a>
                                            <a href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onNextButtonClick()">Next</a>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="11">
                                        <a ngbNavLink class=""><i class="fe fe-home me-2"></i>Additional Contact
                                            Details:</a>
                                        <ng-template ngbNavContent>
                                            <div class="row content">
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.notificationemail !== oldSpaceData.notificationemail)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">When someone contacts you though
                                                            your page, which email
                                                            address(s) do you want your messages, sent to? <span
                                                                class="text-danger">*</span> </label>
                                                            <label class="head_label_txt">Notification Email</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.notificationemail !== oldSpaceData.notificationemail)">
                                                            <p>Old value: {{oldSpaceData.notificationemail}} </p>
                                                            <p>New value: {{createSpace.notificationemail}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.notificationemail}}</p>
                                                        </span>

                                                    </div>
                                                </div>
                                                <div class="col-md-12"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.notificationphone !== oldSpaceData.notificationphone)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Notification Mobile</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.notificationphone !== oldSpaceData.notificationphone)">
                                                            <p>Old value: {{oldSpaceData.notificationphone}} </p>
                                                            <p>New value: {{createSpace.notificationphone}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.notificationphone}}</p>
                                                        </span>

                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactName !== oldSpaceData.contactName)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Contact Person Name<span
                                                                class="text-danger">*</span></label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.contactName !== oldSpaceData.contactName)">
                                                            <p>Old value: {{oldSpaceData.contactName}} </p>
                                                            <p>New value: {{createSpace.contactName}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.contactName}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactemail !== oldSpaceData.contactemail)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Contact Person Email</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.contactemail !== oldSpaceData.contactemail)">
                                                            <p>Old value: {{oldSpaceData.contactemail}} </p>
                                                            <p>New value: {{createSpace.contactemail}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.contactemail}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactmobile !== oldSpaceData.contactmobile)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Contact Person Mobile</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.contactmobile !== oldSpaceData.contactmobile)">
                                                            <p>Old value: {{oldSpaceData.contactmobile}} </p>
                                                            <p>New value: {{createSpace.contactmobile}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.contactmobile}}</p>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_name !== oldSpaceData.alternate_contact_name)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Alternate Person Contact
                                                            Name</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_name !== oldSpaceData.alternate_contact_name)">
                                                            <p>Old value: {{oldSpaceData.alternate_contact_name}} </p>
                                                            <p>New value: {{createSpace.alternate_contact_name}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.alternate_contact_name ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_email !== oldSpaceData.alternate_contact_email)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Alternate Person Contact
                                                            Email</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_email !== oldSpaceData.alternate_contact_email)">
                                                            <p>Old value: {{oldSpaceData.alternate_contact_email}} </p>
                                                            <p>New value: {{createSpace.alternate_contact_email}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.alternate_contact_email ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_mobile !== oldSpaceData.alternate_contact_mobile)">
                                                    <div class="form-group">
                                                        <label class="head_label_txt">Alternate Person Contact
                                                            Mobile</label>
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_mobile !== oldSpaceData.alternate_contact_mobile)">
                                                            <p>Old value: {{oldSpaceData.alternate_contact_mobile}} </p>
                                                            <p>New value: {{createSpace.alternate_contact_mobile}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p>{{createSpace.alternate_contact_mobile ?? 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewItem == 'Create'">
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                </div>
                                                <div class="mt-3 d-flex justify-content-end">
                                                    <button
                                                        style="background-color: green;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewStatus()">
                                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                                        Approve
                                                    </button>
                                                    <button
                                                        style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewRejectStatus()">
                                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                                        Reject
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="viewItem == 'Delete'">
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                </div>
                                                <div class="mt-3 d-flex justify-content-end">
                                                    <button
                                                        style="background-color: green;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewStatus()">
                                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                                        Approve
                                                    </button>
                                                    <button
                                                        style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewRejectStatus()">
                                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                                        Reject
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="viewItem == 'Edit'">
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-primary-light"
                                                        (click)="onPreviousButtonClick()">Previous</a>
                                                </div>
                                                <div class="mt-3 d-flex justify-content-end">
                                                    <button
                                                        style="background-color: green; width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewStatus()">
                                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                                        Approve
                                                    </button>
                                                    <button
                                                        style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"
                                                        aria-label="Example icon button with a menu icon"
                                                        (click)="updateReviewRejectStatus()">
                                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                                        Reject
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light"
                                                (click)="onPreviousButtonClick()">Previous</a>
                                            <a *ngIf="!is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onSubmit()">Submit and Create Space</a>
                                            <a *ngIf="is_id_exist" href="javascript:void(0)" class="btn btn-primary-light "
                                                style="float: right;" (click)="onSubmit()">Update Space details</a> -->
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary-light "
                                            style="float: right;" (click)="onSubmit()">Update</a> -->
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8 panel-body tabs-menu-body ps-md-3 py-0">
                        <div [ngbNavOutlet]="nav3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>