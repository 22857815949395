import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-assign-lead',
  templateUrl: './assign-lead.component.html',
  styleUrls: ['./assign-lead.component.css']
})
export class AssignLeadComponent {

  id: any;
  hostList: any;
  public ref;
  selected: any;
  city_name:any;

  constructor(public dialogRef: MatDialogRef<AssignLeadComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private queriesService: QueriesService, private loaderService: LoaderService, public snackBar: MatSnackBar) {
    this.id = this.data.id;
    this.city_name = this.data.city_name;
  }


  ngOnInit() {
    this.getHostList();
  }


  getHostList() {
    let city = {
      city_name: this.city_name
    }
    this.queriesService.getHostList(city).then((res: any) => {
      this.hostList = res.data;
      this.getPreAssignedLeads()
    })
  }



  getPreAssignedLeads() {
    const data = {
      leadId: this.id,
    }
    this.queriesService.getPreAssignedLeads(data).then((res: any) => {
      this.selected = res?.data?.host;
      this.hostList= this.hostList.map(host => ({
        ...host,
        disabled: this.selected.includes(host.id)
      }));
    })
  }

 


  assignLead() {
    if (this.selected.length == 0) {
      this.openSnackBar('Please select at least 1 host', 'Dismiss')
    } else {
      let data = {
        hostId: this.selected,
        leadId: this.id,
        sendBy: "Assign",
        city_name: this.city_name
      }

      this.queriesService.assignLeads(data).then((res: any) => {
        console.log(res);
        if (res.data.success) {
          this.closeDialog(true);
          this.openSnackBar(res.data.message, 'Dismiss');
        } else {
          this.closeDialog(false);
          this.openSnackBar(res.data.message, 'Dismiss');
        }
      })
    }
  }


  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeDialog(options) {
    this.ref.close(options);
  }
}
