<!-- <app-page-header title="Elements" active_item="User Visits"></app-page-header>ROW-1 OPEN -->
<!-- ROW OPEN -->
<div class="">
    <div class="row mx-0">
        <div class="col-lg-12 px-0">
            <div class="card">
                <div class="card-header py-2 px-4 border-0">
                    <h3 class="card-title">Filter Visits</h3>
                </div>
                <div class="row mx-2 mt-4">
                    <!-- <div class="col-md-4 form-group status">
                        <ng-select placeholder="Select Booking Status" class="pb-0 placeholder_stars" [(ngModel)]="selectedStatus">
                        <ng-option value="confirmed">Confirmed</ng-option>
                        <ng-option value="cancelled">Cancel</ng-option>
                        </ng-select>
                    </div> -->
                      
                    <!-- <div class="col-md-4 form-group category mb-0 status">
                        <ng-select placeholder="Select Space Type" class="pb-0 placeholder_stars" [multiple]="true" [(ngModel)]="selectedSpaceName">
                            <ng-option *ngFor="let item of workSpaceNames" value="">{{ item }}</ng-option>
                        </ng-select>
                    </div>  -->
                    <!-- <div class="col-md-4 form-group"> 
                        <div class="">
                            <div class="row gutters-xs">
                                <div class="col">
                                    <input [(ngModel)]="bookingIdValueForSearch" type="text" class="form-control" placeholder="Search By Booking ID...">
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="card-body d-flex align-items-center justify-content-between py-2 ps-3 pe-0">
                                   
                    <div class="row w-100 mx-0 mb-2">
                        <div class="col-md-12">
                            <div class="booking-list-filter d-flex align-items-center">
                                <div class="row w-100">
                                    <div class="col-md-3 form-group status">
                                        <ng-select
                                            [placeholder]="selectedHost ? '' : 'Select Host'"
                                            class="form-select drop_down form-control pb-0 placeholder_stars select_box"
                                            [multiple]="true" [(ngModel)]="selectedHost">
                                            <ng-option *ngFor="let item of host" [value]="item?.id">{{ item?.companyName
                                                }} - {{item?.mobile}}</ng-option>
                                        </ng-select>
                                    </div>
    
                                    <div class="col-md-3 form-group">
                                        <ng-select class="form-select drop_down form-control pb-0 placeholder_stars select_box" [(ngModel)]="spaceType" [placeholder]="spaceType ? '' : 'Space Category'"
                                            [items]="workSpaceNames" required>
                                        </ng-select>
                                    </div>
    
                                    <div class="col-md-3 form-group category">
                                        <ng-select [placeholder]="selectedSpaceName.length == 0 ? 'Select Space Name' : ''" class="form-select drop_down form-control pb-0 placeholder_stars select_box" [multiple]="true" [(ngModel)]="selectedSpaceName">
                                            <ng-option *ngFor="let item of spaceNames" [value]="item?.id">{{ item?.actual_name }}</ng-option>
                                        </ng-select>
                                    </div> 
    
                                    <div class="col-md-3 form-group visit_date">
                                        <mat-form-field appearance="fill" class=" calender w-100">
                                            <mat-label>Enter a date</mat-label>
                                            <mat-date-range-input [rangePicker]="picker">
                                                <input [(ngModel)]="startDate" (dateChange)="dateRangeChange();" (click)="picker.open()" matStartDate
                                                    placeholder="Start date">
                                                <input [(ngModel)]="endDate" (dateChange)="dateRangeChange();" (click)="picker.open()" matEndDate placeholder="End date">
                                            </mat-date-range-input>
                                            <i (click)="startDate = ''; endDate = ''"  class="fa fa-times "  aria-hidden="true"></i>
                                            <mat-datepicker-toggle matSuffix [for]="picker" style="color: #f76900;"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>
                                        </mat-form-field> 
                                    </div>
                                    
                                    <div class="col-12 d-flex justify-content-end">
                                        <button (click)="getAllHostSpaces()" class="btn btn-primary" type="button"><i class="fa fa-search"></i> Search</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <!-- ROW CLOSED -->
    <div class="row">
        <div class="col-12">
            <div class="card Relatedpost nested-media">
                <div class="card-header w-100">
                    <div class="row align-items-center justify-content-between mx-0 w-100"> 
                        <div class="col-6">
                            <h4 class="card-title">User Visits</h4>
                        </div>
                        
                        <div class="col-4">
                            <input [(ngModel)]="user_search" type="text" class="form-control py-2" placeholder="Search By User....">
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex justify-content-between align-items-center" *ngFor="let booking of paginatedBookings">
                    <div class="media media-lg mt-0">
                        <img (error)="imageErrorHandler($event)" class="me-3 mb-4 br-3 media-img" src="{{booking?.images[0]}}" alt="Generic placeholder image">
                        <div class="booking-all-list-single-img-tag">
                            <p>{{booking?.spaceType}}</p>
                        </div>
                        <div class="media-body">
                            <h4 class="mt-0" style="text-align: left;">{{booking?.spaceName}}</h4>
                            <p class="booking-all-list-single-cont-location" style="text-align: left;"><img src="assets/images/download.png"
                                    alt=""><span>{{booking?.spaceLocation}} </span></p>
                            <div class="booking-list-single-info booking-list-single-about-info">
                                <div *ngIf="booking?.userName" class="booking-list-single-info-single"><img src="assets/images/user-icon-orng.png"
                                        alt="">
                                    <p>{{booking?.userName}}</p>
                                </div>
                                <div *ngIf="booking?.userEmail" class="booking-list-single-info-single ng-star-inserted"><img
                                        src="assets/images/emailicon.jpeg" alt="">
                                    <p>{{booking?.userEmail}}</p>
                                </div>
    
                                <div *ngIf="booking?.userMobile" class="booking-list-single-info-single"><img src="assets/images/mobileicon.png" alt=""
                                        class="sq-ft-icon">
                                    <p>{{booking?.userMobile}}</p>
                                </div>
                            </div>
                            <div class="booking-list-single-info booking-list-single-date-info">
                                <div class="booking-list-single-info-single"><img src="assets/images/date-icon-o.png" alt=""
                                        class="date-icon">
                                    <p>{{booking?.visitDate | date : 'dd-MM-YYYY' }}</p>
                                </div>
                                <div *ngIf="booking?.visitTime != null && booking?.visitTime != '00:00:00'" class="booking-list-single-info-single"><img src="assets/images/clock-icon-orng.png" alt=""
                                        class="date-icon">
                                        <p>{{booking?.visitTime ? booking?.visitTime.slice(0, 5) : ''}}</p>
                                </div>
                                <div *ngIf="booking?.visitSpaceType == 'coworking'" class="booking-list-single-info-single"><label for="" class="mb-0" style="font-size: 14px; ">Type of Space: &nbsp;</label> {{booking?.coworkingSpaceType}}</div>
                                <div *ngIf="booking?.visitSpaceType == 'coworking'" class="booking-list-single-info-single"><label for="" class="mb-0" style="font-size: 14px; ">No. Of People: &nbsp;</label> {{booking?.howManyPeople }}</div>
                                <!-- <div class="watch-time booking-list-single-info-single">
                                    <p><span> {{booking?.ofDays|| booking?.dayCount ||booking?.bookingPeriods}}</span> Days</p>
                                </div> -->
                                <!-- <div class="sq-ft booking-list-single-info-single">
                                    <p> <span> {{booking?.spaceType == 'Coworking Space' ? booking?.noOfGuest || 1 : booking?.totalHours || 2}} </span> {{ booking?.spaceType == 'Coworking Space' ? 'Guest' : 'hrs'}}</p>
                                </div> -->
                            </div>
                            <!-- <div class="booking-list-single-info booking-list-single-confirm-info">
                                <div class="booking-list-single-info-single">
                                    <p>Booking Status : </p>
                                </div>
                                <div class="booking-list-single-info-single booking-confirm ng-star-inserted"><img
                                        src="assets/images/bc-con.png" alt="" class="bc-icon">
                                    <p>{{booking?.bookingStatus}}</p>
                                </div>
                            </div>  -->
                        </div>
                    </div>
                    <!-- <div>
                        <a href="spaces/bookings_detail/{{booking?.id}}" target="_blank"><button class="btn custum-btn">Detail <i aria-hidden="true" class="fa fa-chevron-right"></i></button></a>
                    </div> -->
                </div>
                <div class="text-center" *ngIf="allBookings?.length == 0">
                    <p class="text-danger">Visits not found..</p>
                </div>
            </div>
            <mat-paginator [length]="allBookings.length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[25,50,75,100]"
                   (page)="onPageChange($event)">
            </mat-paginator>
        </div><!-- COL-END -->
    </div>
</div>