<div class="row main_row" >
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Amenities</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading" style="padding: 0; border: none;">
                        <div class="tabs-menu radius-tab">
                            <ul (navChange)="onTabChange($event)" ngbNav #nav3="ngbNav" [activeId]="activeTab"
                                class="nav panel-tabs panel-info">
                                <li [ngbNavItem]="0">
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="amenitiesForm">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Enter Amenity Title <span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <input matInput [errorStateMatcher]="errorMatcher" formControlName="name2" (change)="getName($event)" [placeholder]="name ? '' : 'Enter amenity title'" [(ngModel)]="name" type="text"
                                                                class="form-control filter-input" [required]="!submitted">
                                                            <mat-error *ngIf="!submitted && amenitiesForm.get('name2').invalid && amenitiesForm.get('name2').touched">
                                                                Title is required
                                                            </mat-error>
                                                        </div>
                                                    </div>
    
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Select icon<span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <input matInput [errorStateMatcher]="errorMatcher" formControlName="icon" type="text" class="form-control filter-input" required
                                                            [iconPicker]="icon" [ipPosition]="'bottom'" [ipWidth]="'250px'" [ipIconSize]="'16px'"
                                                            [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'" [ipKeepSearchFilter]="'false'"
                                                            [placeholder]="icon ? '' : 'Choose icon'" [ipFallbackIcon]="fallbackIcon" (iconPickerSelect)="onIconPickerSelect($event)" [(ngModel)]="icon" />
                                                            <mat-error *ngIf="amenitiesForm.get('icon').invalid && amenitiesForm.get('icon').touched">
                                                                Icon is required
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="btn-list">
                                                    <a class="btn btn-primary-light" style="float: right;" (click)="submit()">
                                                        Submit
                                                    </a>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-body tabs-menu-body">
                    <div [ngbNavOutlet]="nav3"></div>

                </div>
            </div>
        </div>
    </div>
</div>
