import { DEFAULT_CURRENCY_CODE, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { navigatableComponents, routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
// import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { MatGridListModule } from "@angular/material/grid-list";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import {
    MatLegacyOptionModule as MatOptionModule,
} from "@angular/material/legacy-core";
import { DeleteImageDialog } from "./add-space/delete-image-dialog.component";
import { ShowImageDialog } from "./add-space/show-image-dialog.component";
import { AppGlobals } from "./services/app-globals";
import { AuthenticationService } from "./services/authentication.service";
import { CsvDownloadFileService } from "./services/csv-download.service";
import { LoaderInterceptorService } from "./services/loader-interceptor.service";
import { LoaderService } from "./services/loader.service";
import { ManagerService } from "./services/manager.service";
import { MemberService } from "./services/member.service";
import { QueriesService } from "./services/queries.service";
import { SpaceService } from "./services/space.service";
import { WorkerService } from "./services/worker.service";
import { DeleteSpaceDialog } from "./spaces/delete-space-dialog.component";
import { DeleteWorkerDialog } from "./workers/delete_worker.component";
import { UpdateWorkerDialog } from "./workers/update_worker.component";
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from "@angular/common";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_DATE_LOCALE, MatCommonModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxEditorModule } from "ngx-editor";
import { IconPickerModule } from "ngx-icon-picker";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AddSpaceViewMoreComponent } from "./add-space-view-more/add-space-view-more.component";
import { AddSpaceComponent } from "./add-space/add-space.component";
import { ConfirmImgDeleteComponent } from "./add-space/confirm-img-delete/confirm-img-delete.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqsComponent } from "./faqs/faqs.component";
import { EditHostProfileComponent } from "./hostview/edit-host-profile/edit-host-profile.component";
import { HostviewComponent } from "./hostview/hostview.component";
import { CreditWalletHistoryComponent } from './invoice-management/credit-wallet-history/credit-wallet-history.component';
import { EnterpriseEnquiryComponent } from "./invoice-management/enterprise-enquiry/enterprise-enquiry.component";
import { PaywallHistoryComponent } from './invoice-management/paywall-history/paywall-history.component';
import { AddLeadDialog } from "./leads/add-lead-dialog.component";
import { ApproveLeadComponent } from "./leads/approve-lead/approve-lead.component";
import { AssignLeadComponent } from "./leads/assign-lead/assign-lead.component";
import { CoWorkingLeadsComponent } from "./leads/co-working-leads.component";
import { EmailPreviewComponent } from "./leads/email-preview/email-preview.component";
import { LeadPriceComponent } from './leads/lead-price/lead-price.component';
import { LeadsComponent } from "./leads/leads.component";
import { SendMessageDialog } from "./leads/send-message-dialog.component";
import { UpdateLeadComponent } from "./leads/update-lead/update-lead.component";
import { ViewLeadComponent } from "./leads/view-lead/view-lead.component";
import { DeleteManagerDialog } from "./managers/delete-manager-dialog.component";
import { UpdateManagerDialog } from "./managers/update-manager-dialog.component";
import { MaterialModule } from "./material.module";
import { UpdateMeetingRoomDialog } from "./meeting_rooms/add-update-meetingroom-dialog.component";
import { DeleteMeetingRoomDialog } from "./meeting_rooms/delete-meetingroom.component";
import { AddSubscriptionPlanComponent } from './paywall-subscription/add-subscription-plan/add-subscription-plan.component';
import { SubscriptionPlansComponent } from './paywall-subscription/subscription-plans/subscription-plans.component';
import { AddPermissionComponent } from './permission/add-permission/add-permission.component';
import { PermissionListComponent } from './permission/permission-list/permission-list.component';
import { SanitizerUrlPipe } from "./pipes/img-pipe";
import { AddRibbonComponent } from './ribbon-management/add-ribbon/add-ribbon.component';
import { RibbonListComponent } from './ribbon-management/ribbon-list/ribbon-list.component';
import { FaqsService } from "./services/faqs.service";
import { InvoiceService } from "./services/invoice.service";
import { MeetingRoomService } from "./services/meetingRoom.service";
import { TeamcabinService } from "./services/teamcabin.service";
import { SelectFilterComponent } from "./shared/select-filter/select-filter.component";
import { AllSpacesReviewComponent } from "./spaces/all-spaces-review/all-spaces-review.component";
import { EditReviewComponent } from "./spaces/all-spaces-review/edit-review.component";
import { AmenitiesListComponent } from "./spaces/amenities-list/amenities-list.component";
import { AmenitiesComponent } from "./spaces/amenities/amenities.component";
import { BookingDetailsComponent } from "./spaces/booking-details/booking-details.component";
import { BookingReportComponent } from './spaces/booking-report/booking-report.component';
import { BookingsComponent } from './spaces/bookings/bookings.component';
import { CancellationPolicyComponent } from "./spaces/cancellation-policy/cancellation-policy.component";
import { ConfirmDeleteAmenityComponent } from "./spaces/confirm-delete-amenity/confirm-delete-amenity.component";
import { ConfirmDialogBoxComponent } from "./spaces/confirm-dialog-box/confirm-dialog-box.component";
import { ConfirmRejectDialogBoxComponent } from "./spaces/confirm-reject-dialog-box/confirm-reject-dialog-box.component";
import { MyBookingsComponent } from "./spaces/my-bookings/my-bookings.component";
import { SpaceRequestCreateComponent } from "./spaces/space-request-create/space-request-create.component";
import { SpaceRequestDeleteComponent } from "./spaces/space-request-delete/space-request-delete.component";
import { SpaceRequestEditComponent } from "./spaces/space-request-edit/space-request-edit.component";
import { SpaceReviewComponent } from "./spaces/space-review/space-review.component";
import { UpdateCancellationPolicyComponent } from "./spaces/update-cancellation-policy/update-cancellation-policy.component";
import { ViewSpaceEditComponent } from "./spaces/view-space-edit/view-space-edit.component";
import { ViewSpacesComponent } from "./spaces/view-spaces/view-spaces.component";
import { VisitScheduleComponent } from "./spaces/visit-schedule/visit-schedule.component";
import { SubscriptionInquiryComponent } from './subscription-inquiry/subscription-inquiry.component';
import { UpdateTeamcabinDialog } from "./teamcabins/add-update-teamcabin-dialog.component";
import { DeleteTeamcabinDialog } from "./teamcabins/delete-teamcabin.component";
import { EditUserProfileComponent } from "./userview/edit-user-profile/edit-user-profile.component";
import { UserviewComponent } from "./userview/userview.component";
import { AddCreditPlansComponent } from "./wallet/add-credit-plans/add-credit-plans.component";
import { AddCreditPointComponent } from './wallet/add-credit-point/add-credit-point.component';
import { CreditWalletPlansComponent } from "./wallet/credit-wallet-plans/credit-wallet-plans.component";

@NgModule({
    declarations: [
        SanitizerUrlPipe,
        AppComponent,
        SendMessageDialog,
        AddLeadDialog,
        UpdateManagerDialog,
        UpdateWorkerDialog,
        DeleteTeamcabinDialog,
        UpdateTeamcabinDialog,
        DeleteWorkerDialog,
        ShowImageDialog,
        // UploadImageDialog,
        DeleteMeetingRoomDialog,
        UserviewComponent,
        EditUserProfileComponent,
        DeleteSpaceDialog,
        DeleteImageDialog,
        UpdateMeetingRoomDialog,
        DeleteManagerDialog,
        ...navigatableComponents,
        FaqsComponent,
        EmailPreviewComponent,
        AddSpaceComponent,
        SpaceReviewComponent,
        HostviewComponent,
        EditHostProfileComponent,
        AllSpacesReviewComponent,
        EditReviewComponent,
        ConfirmDialogBoxComponent,
        ConfirmRejectDialogBoxComponent,
        MyBookingsComponent,
        BookingDetailsComponent,
        AmenitiesComponent,
        VisitScheduleComponent,
        SpaceRequestCreateComponent,
        SpaceRequestDeleteComponent,
        SpaceRequestEditComponent,
        ViewSpacesComponent,
        AmenitiesComponent,
        AmenitiesListComponent,
        CancellationPolicyComponent,
        UpdateCancellationPolicyComponent,
        ConfirmDeleteAmenityComponent,
        ViewSpaceEditComponent,
        AddSpaceViewMoreComponent,
        ViewLeadComponent,
        LeadsComponent,
        UpdateLeadComponent,
        AddCreditPlansComponent,
        CreditWalletPlansComponent,
        EnterpriseEnquiryComponent,
        CoWorkingLeadsComponent,
        AssignLeadComponent,
        ApproveLeadComponent,
        AddCreditPointComponent,
        SubscriptionPlansComponent,
        AddSubscriptionPlanComponent,
        PaywallHistoryComponent,
        CreditWalletHistoryComponent,
        AddRibbonComponent,
        RibbonListComponent,
        LeadPriceComponent,
        ConfirmImgDeleteComponent,
        PermissionListComponent,
        AddPermissionComponent,
        BookingReportComponent,
        DashboardComponent,
        BookingsComponent,
        SubscriptionInquiryComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        HttpClientModule,
        SelectFilterComponent,
        GoogleMapsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatChipsModule,
        MatTabsModule,
        CKEditorModule,
        // UpdateManagerDialog,
        // DeleteManagerDialog,
        MatProgressBarModule,
        FormsModule,
        MatRadioModule,
        MatButtonModule,
        MatListModule,
        MaterialModule,
        MatDialogModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatGridListModule,
        CdkAccordionModule,
        MatExpansionModule,
        MatButtonToggleModule,
        RouterModule.forRoot(routes, {}),
        NgbModule,
        NgSelectModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatCommonModule,
        IconPickerModule,
        TimePickerModule,
        DragDropModule,
        MatIconModule,
        NgxIntlTelInputModule,
        BsDropdownModule,
        NgxEditorModule,
        MatTooltipModule
    ],
    providers: [
        DatePipe,
        FaqsService,
        AuthenticationService,
        LoaderService,
        MemberService,
        CsvDownloadFileService,
        MeetingRoomService,
        DeleteMeetingRoomDialog,
        SpaceService,
        AuthenticationService,
        LoaderService,
        // CompanyService,
        TeamcabinService,
        MemberService,
        WorkerService,
        // RatingService,
        // MembershipService,
        // PerformaInvoiceService,
        QueriesService,
        InvoiceService,
        // RecurringInvoiceService,
        // TransactionService,
        // SpaceService,
        // BookingService,
        // ReceiptService,
        AppGlobals,
        // OverviewService,
        // VisitorService,
        // HelpdeskService,
        // ConversationService,
        // ResourceService,
        // PlanService,
        ManagerService,
        // MeetingRoomService,
        // RevenueService,
        // ContractService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true,
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'Rs. '
        },
        {
            provide: MAT_DIALOG_SCROLL_STRATEGY,
            useFactory: scrollFactory,
            deps: [Overlay]
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function scrollFactory(overlay: Overlay): () => any {
    return () => overlay.scrollStrategies.reposition();
}