import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.css']
})
export class AddPermissionComponent implements OnInit {

  dept!: any;
  notValid = false;
  departmentId!: number;
  module: ({ title: string; permissions: { add: boolean; view: boolean; edit: boolean; delete: boolean; }; subModule?: undefined; } | { title: string; permissions: { add: boolean; view: boolean; edit: boolean; delete: boolean; }; subModule: { title: string; permissions: { add: boolean; view: boolean; edit: boolean; delete: boolean; }; }[]; })[];
  globalPermissions: { add: boolean; view: boolean; edit: boolean; delete: boolean; };


  constructor(private queryService: QueriesService, private route: ActivatedRoute, private router: Router) {
    this.departmentId = this.route.snapshot.queryParams['departmentId']
  }
  ngOnInit(): void {
    if (this.departmentId) {
      this.getDepartmentById()
    }
    this.globalPermissions = {
      add: false,
      view: false,
      edit: false,
      delete: false
    };

    this.module = [
      {
        title: 'Space',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
      },
      {
        title: 'Review',
        permissions: {
          add: null,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Bookings',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
        subModule: [
          {
            title: 'List',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
          {
            title: 'Report',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
        ]
      },
      {
        title: 'Host View',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'User View',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Amenities',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Visit Schedule',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Cancellation Policy',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Space Request',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Managers',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Workers',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Credit Wallet',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
        subModule: [
          {
            title: 'Add Point',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
          {
            title: 'Package',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
        ]
      },
      {
        title: 'Paywall',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Invoice Management',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
        subModule: [
          {
            title: 'Paywall',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
          {
            title: 'Credit Wallet',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          }
        ]
      },
      {
        title: 'Ribbon',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Leads',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
        subModule: [
          {
            title: 'Co Working',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          },
          {
            title: 'Long Term',
            permissions: {
              add: false,
              view: false,
              edit: false,
              delete: false
            }
          }
        ]
      },
      {
        title: 'Lead Price',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Send Proposal',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Queries',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'Department',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
      {
        title: 'FAQs',
        permissions: {
          add: false,
          view: false,
          edit: false,
          delete: false
        },
      },
    ];
  }


  addTitles = ['Review', 'Bookings', 'Host View', 'User View', 'Visit Schedule', 'Space Request', 'Invoice Management', 'Lead Price','Leads', 'Cancellation Policy','Queries'];
  editTitles = ['Bookings','Visit Schedule', 'Invoice Management', 'Leads', 'Send Proposal','Queries'];
  deleteTitles = ['Bookings', 'Host View', 'User View', 'Visit Schedule', 'Space Request', 'Invoice Management', 'Leads', 'Department', 'Ribbon', 'Lead Price', 'Send Proposal', 'Review', 'Flexo Credits', 'Queries', 'Paywall'];
  addSubTitles = ['List', 'Report', 'Paywall', 'Flexo Credits','Long Term'];
  editSubTitles = ['List','Add Point', 'Paywall', 'Flexo Credits', 'Long Term','Report'];
  deleteSubTitles = ['List', 'Report', 'Add Point', 'Paywall', 'Flexo Credits', 'Co Working', 'Long Term', 'Package'];

  disable(permission: string, module: any): boolean {
    return this[`${permission}Titles`].includes(module.title);
  }
  disableSub(permission: string, subModule: any): boolean {
    return this[`${permission}SubTitles`].includes(subModule.title);
  }
  

  getDepartmentById() {
    this.queryService.getDepartmentById(this.departmentId)
      .then(res => {
        if (res.success) {
          const { main, module } = JSON.parse(res.data.data)
          this.globalPermissions = main
          this.module = module
          this.dept = res.data.departmentName
        }
      })
      .catch(error => {
        console.log(error);
      })
  }




  submit() {
    if (this.dept) {
      const permission = {
        departmentName: this.dept?.trim(),
        data: {
          main: this.globalPermissions,
          module: this.module,
        }
      }
      if (this.departmentId) {
        this.updateDepartment(permission)
      } else {
        this.addDepartment(permission)
      }
    } else {
      this.notValid = true
    }
  }


  updateDepartment(permission: any) {
    if (this.queryService.hasPermission('Department', 'edit')) {
      this.queryService.updateDepartmentById(this.departmentId, permission).then((res: any) => {
        if (res.success) {
          this.router.navigate(["/home/permission"])
        }
        this.queryService.openSnackBar(res.message)
      })
    }
    else {
      this.queryService.accessDeniedAlert()
    }
  }

  addDepartment(permission: any) {
    if (this.queryService.hasPermission('Department', 'add')) {
      this.queryService.createDepartment(permission).then((res: any) => {
        if (res.success) {
          for (let permission in this.globalPermissions) {
            this.globalPermissions[permission] = false
            this.toggleGlobalPermission(permission)
          }
          this.dept = " "
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }




  toggleGlobalPermission(permissionType: string): void {
    const isChecked = this.globalPermissions[permissionType];
    this.module.forEach(part => {
      part.permissions[permissionType] = isChecked;
      part?.subModule?.forEach(subpart => {
        subpart.permissions[permissionType] = isChecked;
      });
    });
  }

  togglePartPermission(part: any, permissionType: string): void {
    const isChecked = part.permissions[permissionType];
    part?.subModule?.forEach(subpart => {
      subpart.permissions[permissionType] = isChecked;
    });
    this.updateGlobalPermissions(permissionType);
  }

  updateSubpartState(part: any): void {
    const permissions = ['add', 'view', 'edit', 'delete'];
    permissions.forEach(permissionType => {
      const allSelected = part?.subModule?.every((subpart: any) => subpart.permissions[permissionType]);
      part.permissions[permissionType] = allSelected;
    });
    this.updateGlobalPermissions();
  }

  updateGlobalPermissions(permissionType?: string): void {
    if (permissionType) {
      const allPartsSelected = this.module.every(part => part.permissions[permissionType]);
      this.globalPermissions[permissionType] = allPartsSelected;
    } else {
      const permissions = ['add', 'view', 'edit', 'delete'];
      permissions.forEach(permissionType => {
        const allPartsSelected = this.module.every(part => part.permissions[permissionType]);
        this.globalPermissions[permissionType] = allPartsSelected;
      });
    }
  }


}
