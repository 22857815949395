import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';
import { ConfirmDialogBoxComponent } from 'src/app/spaces/confirm-dialog-box/confirm-dialog-box.component';

@Component({
  selector: 'app-credit-wallet-plans',
  templateUrl: './credit-wallet-plans.component.html',
  styleUrls: ['./credit-wallet-plans.component.css'],
  providers: [MatDialog],
})
export class CreditWalletPlansComponent implements OnInit {

  packagesList: any[]=[];
  colors = ['#00b451', '#ff0000', '#ffff00', '#0000ff', '#ffa500'];
  deactivePackagesList: any=[];


  constructor(private queryService: QueriesService, private router: Router, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getActiveCreditPackages()
    this.getDeactiveCreditPackages()
  }

  getCardClass(index: number): string {
    const colorIndex = index % this.colors.length;
    return `border-${colorIndex}`;
  }

  navigateToAdd() {
    if (this.queryService.hasSubmodulePermission('Credit Wallet', 'Package', 'add')) {
      this.router.navigate(['/home/create-package'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  edit(packageId: number) {
    if (this.queryService.hasSubmodulePermission('Credit Wallet', 'Package', 'edit')) {
      this.router.navigate(['/home/update-package'], { queryParams: { packageId } })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }



  getActiveCreditPackages() {
    this.queryService.getCreditPackages("Active")
      .then((res: any) => {
        if (res.success) {
          this.packagesList = res.data.map((pack: any) => {
            return {
              ...pack,
              discription: JSON.parse(pack.discription).filter((val: any) => val != "<p></p>")
            };
          });
        } else {
          this.queryService.openSnackBar(res.message)
        }
      })
  }

  getDeactiveCreditPackages() {
    this.queryService.getCreditPackages("De-Active")
      .then((res: any) => {
        if (res.success) {
          this.deactivePackagesList = res.data.map((pack: any) => {
            return {
              ...pack,
              discription: JSON.parse(pack.discription).filter((val: any) => val != "<p></p>")
            };
          });
        } else {
          this.queryService.openSnackBar(res.message)
        }
      })
  }



  confirmation(e: any, list: any) {
    e.preventDefault()
    if (this.queryService.hasSubmodulePermission('Flexo Credits', 'Package', 'edit')) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
        data: { show: false, status: true }
      }
      );
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result)
        if (result) {
          this.changeStatus(list)
        }
      });
    }, 500);
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  changeStatus({ id, status }) {
    const data = {
      id,
      status: status === "De-Active" ? "Active" : "De-Active"
    }
    this.queryService.updateCreditStatus(data).then((res: any) => {
      if (res.success) {
        this.getActiveCreditPackages()
        this.getDeactiveCreditPackages()
      }
      this.queryService.openSnackBar(res.message)
    })
  }

}