<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Cancellation Policies</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading" style="padding: 0; border: none;">
                        <div class="tabs-menu radius-tab">
                            <ul ngbNav #nav3="ngbNav" [activeId]="activeTab" class="nav panel-tabs panel-info">
                                <li [ngbNavItem]="0">
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <ng-container *ngFor="let element of policies; let i = index">
                                                <div class="col-md-12 mt-3">
                                                    <div class="viewd-item-wrap">
                                                        <div class="most-viewed-item">
                                                            <div class="most-viewed-detail">
                                                                <h3>Policy Name:<a>{{element.policyName}}</a>
                                                                </h3>
                                                                <div class="list-content-data">
                                                                    <p class="list-address">Prior Description: {{element.before7}}
                                                                    </p>
                                                                    <p class="list-address">After Description: {{element.after7}}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                            <div class="listing-button position-relative-con mt-3">
                                                                <a (click)="updateDetails(element.id)"
                                                                    class="btn v2"><i class="ion-edit"></i> Edit</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-body tabs-menu-body">
                    <div [ngbNavOutlet]="nav3"></div>

                </div>
            </div>
        </div>
    </div>
</div>