import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { map, startWith } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { MemberService } from '../services/member.service';
import { QueriesService } from '../services/queries.service';

declare var google: any;
@Component({
  selector: 'add-lead',
  templateUrl: './add-lead-dialog.component.html',
  styleUrls: ['./add-lead-dialog.component.css']
})

export class AddLeadDialog {
  @ViewChild('autocomplete', { static: true }) autocompleteInput!: ElementRef;
  private autocomplete: any;
  public ref;
  public lat: any;
  public longi: any;
  public spaceForm: FormGroup;
  selectedLocations: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  location: any[] = [];
  locationObj: any;
  getLocationObjForSearch: any;
  locations: string[] = [];
  control = new FormControl('');
  filteredPlaces: any;
  cityArray: any[] = [];
  tenure: any;
  amount: any;
  locationArray: any[] = [];
  searchTerm: string;
  lead_data: any;
  city: any;
  spaceTypeArray: any[];
  no_of_seats: any;
  tagsArray: any[];
  leadPoints: any;
  probability_factor: any;
  revenue: number = 0;
  maxBudgetAmount: { label: string; }[];
  cityList: any[]=[];
  filteredCities: any;
  cityFilterCtrl = new FormControl();
  countryCodes:any;
  selectedIndex: any = 75;
  placeholder: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private _memberService: MemberService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _queriesservice: QueriesService
  ) {
    this.spaceForm = this.fb.group({
      location: [[], Validators.required],
      number_of_seats: ['', Validators.required],
      spaceType: [[], Validators.required],
      approx_start: ['', Validators.required],
      tenure: ['', Validators.required],
      budget: ['', Validators.required],
      budgetMax: ['', Validators.required],
      lead_price: ['', Validators.required],
      lead_source: [''],
      tags: [[], this.validateMaxSelections(2)],
      inquiryDescription: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      countryCode: ['+91'],
      userMobile: ['', [Validators.required, Validators.minLength(10)]],
      company_name: [''],
      city_name: [[], Validators.required],
      verifiedBy: [''],
    })
  }
  ngOnInit() {
    this.getAllCity()
    this.countryCodes = this._queriesservice?.countryCodes;
    this.updatePlaceholder();
    this.filteredCities = this.cityFilterCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCities(value || ''))
    );
    

    this.filteredCities.subscribe(data => {
    this.filteredCities = data
    });

    this.tenure = [
      { label: 'month on month' },
      ...Array.from({ length: 60 }, (_, i) => ({
        label: `${i + 1} month${i + 1 > 1 ? 's' : ''}`,
      })),
      { label: '60 months +' }
    ];
    this.amount = [
      ...Array.from({ length: (49500 - 1500) / 500 + 1 }, (_, i) => {
        const amount = 500 + i * 500;
        return { label: `${amount}` };
      }),
      { label: '50000 +' }
    ];

    if (this?.data?.id) {
      this.getLeadData(this.data.id);
    }

    this.getProbabilityFactor();
  }

  onCountryCodeChange(country: any) {
		this.selectedIndex = this.countryCodes.findIndex((code:any) => code.dialcode === country);
		this.updatePlaceholder();
	}
	
	updatePlaceholder() {
		if (this.selectedIndex) {
			this.placeholder = Array.from({ length: this.countryCodes[this.selectedIndex]['number-of-digits-in-number'] }, (_, i) => i ).join('');
		}
    this.spaceForm.get('userMobile')?.patchValue('')
    this.updateMobileValidator(this.placeholder.length)
	}

  updateMobileValidator(minLength: number) {
    const userMobileControl = this.spaceForm.get('userMobile');
    userMobileControl?.setValidators([
      Validators.required,
      Validators.minLength(minLength)
    ]);
    userMobileControl?.updateValueAndValidity();
  }


  selectedCities: string[] = [];
 
  private filterCities(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    this.filteredCities = this.cityList.filter(city => this._normalizeValue(city).includes(filterValue));
    return this.filteredCities;
  }


  addCity(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.selectedCities.includes(value)) {
      this.selectedCities.push(value);
      this.spaceForm.get('city_name')?.setValue(this.selectedCities);
    }
    event.input.value = '';
    this.cityFilterCtrl.setValue(null);
  }

  removeCity(city: string): void {
    const index = this.selectedCities.indexOf(city);
    if (index >= 0) {
      this.selectedCities.splice(index, 1);
      this.spaceForm.get('city_name')?.setValue(this.selectedCities);
    }
  }

  
  selectCity(event: MatAutocompleteSelectedEvent): void {
    const city = event.option.value;
    if (!this.selectedCities.includes(city)) {
      this.selectedCities.push(city);
      this.spaceForm.get('city_name')?.setValue(this.selectedCities);
    }
    this.cityFilterCtrl.setValue(null);
  }

 

  getProbabilityFactor() {
    this._memberService.getProbabilityFactor().subscribe((res: any) => {
      this.probability_factor = res.data.value;
    })
  }

  onInputChange(value: string) {
    this.searchTerm = value.toLowerCase().trim().replace(/\s/g, '');
  }

 
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  getAllCity() {
    this._memberService.getAllCity().subscribe((res: any) => {
      this.cityList = res.data.map(val => val.name)
      this.filteredCities = this.cityList
    })
  }

  addLocation(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = '';
    }

    if (this.autocomplete.getPlace()) {
      const selectedPlace = this.autocomplete.getPlace();
      const selectedValue = selectedPlace.formatted_address;

      if (selectedValue && this.selectedLocations.indexOf(selectedValue) === -1) {
        if (!this.selectedLocations.includes(selectedValue)) {
          this.selectedLocations.push(selectedValue);
        }
        this.spaceForm.get('location').patchValue(this.selectedLocations);
      }
    }
  }

  removeLocation(location: string): void {
    const index = this.location.indexOf(location);
    if (index >= 0) {
      this.location.splice(index, 1);
      this.locationArray.splice(index, 1);
      this.cityArray.splice(index, 1);
    }
    console.log(this.spaceForm.value, "value");
  }

  validateMaxSelections(max: number) {
    return (control) => {
      if (control.value && control.value.length > max) {
        return { maxSelections: true };
      }
      return null;
    };
  }

  isOptionDisabled(value: string): boolean {
    const selectedTags = this.spaceForm.get('tags').value;
    return selectedTags.length >= 2 && !selectedTags.includes(value);
  }

  onSelectionChange() {
    const selectedTags = this.spaceForm.get('tags').value;
    if (selectedTags.length > 2) {
      this.spaceForm.get('tags').setValue(selectedTags.slice(0, 2), { emitEvent: false });
    }
  }

  get errorStateMatcher() {
    return {
      isErrorState: (control, form) => {
        const tagControl = this.spaceForm.get('tags');
        const isInvalid = tagControl.invalid && (tagControl.dirty || tagControl.touched);
        const isMaxSelectionsError = tagControl.errors?.maxSelections;
        return isInvalid || isMaxSelectionsError;
      }
    };
  }

  submitForm() {
    this.spaceForm.markAllAsTouched()
    if(this.spaceForm.valid){
      const data = {
        ...this.spaceForm.value,
        location_name: this.keywords,
        location: this.keywords,
        revenueOpportunity: this.revenue,
        // city_name: this.cityList
        //   .filter(city => this.spaceForm.value.city_id.includes(city.id))
        //   .map(city => city.name)
      }
      if (this?.data?.id) {
        this.updateLead(data)
      } else {
        this.addLead(data)
      }
    }
  }

  addLead(data) {
    this._memberService.addLead(data)
      .then(res => {
        if (res && res.success) {
          this.openSnackBar(res.message, 'Dismiss');
          this.closeDialog(res);
        }
      })
      .catch(error => {
        this.openSnackBar("Some error occured", "Error");
      })
  }

  updateLead(data) {
    this._memberService.updateLead(this?.data?.id, data)
      .then(res => {
        if (res && res.success) {
          this.openSnackBar(res.message, 'Dismiss');
          this.closeDialog(res);
        }
      })
      .catch(error => {
        this.openSnackBar("Some error occured", "Error");
        this.closeDialog(error);
      })
  }

  calculateLeadPoints() {
    let seat = this.spaceForm.value.number_of_seats
    let tenure = this.spaceForm.value.tenure
    let budget = this.spaceForm.value.budget
    if (seat && tenure && budget) {
      let seats: number;
      let no_of_seats = this.spaceForm.get('number_of_seats').value || 1;

      if (no_of_seats?.includes('-')) {
        const rangeParts = no_of_seats.split('-');
        seats = parseInt(rangeParts[0], 10);

      } else if (no_of_seats?.includes('+')) {
        seats = parseInt(no_of_seats, 10);

      } else {
        seats = parseInt(no_of_seats, 10);
      }

      let lead_budget = this.spaceForm.get('budget').value || 1;
      let budget = parseInt(lead_budget, 10);

      let tenure = this.spaceForm.get('tenure').value === "month on month" ? 3 : this.spaceForm.get('tenure').value || 1;
      const duration = parseInt(tenure, 10);

      this.leadPoints = seats * budget * duration * this.probability_factor
      console.log(this.leadPoints);
    }
  }


  getMaxBudget() {
    const start = Number(this.spaceForm.value.budget) + 500;
    if (start >= 50000) {
      this.maxBudgetAmount = [{ label: '50000 +' }];
      return;
    }
    const end = 49500;
    this.maxBudgetAmount = [
      ...Array.from({ length: (end - start) / 500 + 1 }, (_, i) => {
        const amount = start + i * 500;
        return { label: `${amount}` };
      }),
      { label: '50000 +' }
    ];
  }



  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  closeDialog(options) {
    this.ref.close(options);
  }


  keywords = [];
  removeKeyword(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);
      this.spaceForm.get('location')?.setValue(this.keywords);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.keywords.push(value);
      this.spaceForm.get('location')?.setValue(this.keywords); 
    }
    event.chipInput!.clear();

  }

  getRevenue() {
    let seat = this.spaceForm.value.number_of_seats
    let tenure = this.spaceForm.value.tenure
    let budget = this.spaceForm.value.budget
    if (seat && tenure && budget) {
      let seats;
      if (seat.includes('-')) {
        const rangeParts = seat.split('-');
        seats = parseInt(rangeParts[0], 10);

      } else if (seat.includes('+')) {
        seats = parseInt(seat, 10);

      } else {
        seats = parseInt(seat, 10);
      }

      let modifiedtenure = tenure?.toLowerCase() === "month on month" ? 3 : parseInt(tenure, 10);
      let modifiedbudget = parseInt(budget, 10);
      this.revenue = seats * modifiedtenure * modifiedbudget
    }
  }


  getLeadData(id: any) {
    this.getAllCity()
    this._queriesservice
      .getLeadById(id).then((res) => {
        if (res.success) {
          this.lead_data = res.leads;
          this.spaceForm.patchValue({
            ...res.leads,
            spaceType: JSON.parse(this.lead_data?.spaceType) || [],
            tags: JSON.parse(this.lead_data?.tags) || [],
            no_of_seats: (this.lead_data?.number_of_seats).toString(),
            city_name: JSON.parse(this.lead_data?.city_name) || [],
            countryCode: this.lead_data.countryCode ? this.lead_data.countryCode : "+91" 
          })
          this.placeholder = String(res.leads?.userMobile)
          this.updateMobileValidator(this.placeholder?.length)
          this.getMaxBudget()
          this.keywords = JSON.parse(this.lead_data?.location)?.join("") === "" ? [] : JSON.parse(this.lead_data?.location)
          this.revenue = this.lead_data?.revenueOpportunity
          this.selectedCities= JSON.parse(this.lead_data?.city_name) || []
        } else {
          this.openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
