<div>
  <div>
    <label *ngIf="action_type == 1" class="top-label">Add Meeting Room details</label>
    <label *ngIf="action_type == 2" class="top-label">Update Meeting Room details</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <form #spaceForm="ngForm" (ngSubmit)="addOrUpdate()" class="space-form">
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Name</mat-label>
      <input type="string" matInput placeholder="Name" name="name" [(ngModel)]="meetingroom.name" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Price</mat-label>
      <input type="number" matInput placeholder="Price" name="price" [(ngModel)]="meetingroom.price" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Capacity</mat-label>
      <input type="number" matInput placeholder="Capacity" name="capacity" [(ngModel)]="meetingroom.capacity" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Select Space</mat-label>
      <mat-select [(ngModel)]="meetingroom.space_id" name="space_id" required>
        <mat-option *ngFor="let space of spaces_data" [value]="space.id">
          {{space.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" type="submit" [disabled]="!spaceForm.form.valid">Submit</button>
    </div>
  </form>
</div>