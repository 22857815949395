<div *ngIf="!data?.title" class="confirmBx">
    <h3 mat-dialog-title>Confirmation</h3>
    <div mat-dialog-content>
        <p class="my-2 font-weight-bold text-dark">Are you sure you want to approve this lead?</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onYesClick()">Yes</button>
        <button mat-button (click)="onNoClick()">No</button>
    </div>
</div>

<div *ngIf="data?.title" class="confirmBx">
    <h3 mat-dialog-title>Confirmation</h3>
    <div mat-dialog-content>
        <p class="my-2 font-weight-bold text-dark">Are you sure you want to {{data.title}} this inquiry?</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialogRef.close(true)">Yes</button>
        <button mat-button (click)="dialogRef.close(false)">No</button>
    </div>
</div>