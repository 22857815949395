import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-ribbon-list',
  templateUrl: './ribbon-list.component.html',
  styleUrls: ['./ribbon-list.component.css']
})
export class RibbonListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['id', 'name', 'color', 'status', 'edit'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize!: MatPaginator;

  constructor(private queryService: QueriesService, private router: Router) { }


  ngOnInit(): void {
    this.getAllRibbon();
  }

  getAllRibbon() {
    this.queryService.getAllRibbon()
      .then((res: any) => {
        this.dataSource = new MatTableDataSource(res?.ribbons);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }, 200);
      })
  }


  applyFilter(value: any): void {
    value = value.value.trim().toLowerCase();
    this.dataSource.filter = value;
  }


  changeStatus(e:any,list: any): void {
    e.preventDefault()
    if (this.queryService.hasPermission('Ribbon', 'edit')) {
      const data = {
        id: list.id,
        status: list.status === "0" ? "1" : "0"
      }
      this.queryService.updateRibbonStatus(data).then((res: any) => {
        if (res.success) {
          this.getAllRibbon()
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  editRibbon(id: any) {
    if (id && this.queryService.hasPermission('Ribbon', 'edit')) {
      this.router.navigate([`/home/edit-ribbon`, id]);
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

}
