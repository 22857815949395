import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { LoaderService } from '../services/loader.service';
import { DeleteSpaceDialog } from './delete-space-dialog.component';
// import { MatOption } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { QueriesService } from '../services/queries.service';
import { SpaceService } from '../services/space.service';
import { environment } from 'src/environments/environment';
// import { GlobalVariables } from '../global/global-variables';
// import { UpdateSpaceDialog } from './update-space-dialog.component';
// import { DeleteSpaceDialog } from './delete-space-dialog.component';
// import { UploadImageDialog } from './upload-image-dialog.component';

// import {RatingModule} from "ngx-rating";

export class Space {
  id!: number
  actual_name!: string;
  buildingName!: String;
  spaceType!: any;
  spacetypeValue!: any;
  about!: string;
  facilities!: number[];
  images: [];
  delete_images: any[];
  location_name!: string;
  location!: string;
  sunday_closed!: Number;
  saturday_closed!: Number;
  isInstant: any = 0;
  securityDeposit!: any;
  spaceBuiltUpArea!: any;
  billing_location_name!: String;
  spacesqft!: any;
  billing_buildingName!: String;
  billingaddress!: String;
  cancellationPolicyDescription!: String;
  cancellationType!: String;
  notificationemail!: String;
  billing_floorNumber!: Number;
  howManyPeopleInSpace!: Number;
  yourSpaceDescription!: String;
  landmark!: string;
  howMenyParking!: Number;
  spaceSecurity!: String;
  howManyPeopleInYourSpace!: any;
  equipment!: String;
  wifiName!: String;
  wifiPassword!: String;
  spaceStatus!: String;
  howManyBigSpace!: String;
  showParkingOptions!: boolean;
  parkingOptionsValue!: string[];
  longitude!: number;
  latitude!: number;
  negociable_price!: boolean;
  minimum_hours!: number;
  status!: number;
  unitno!: String;
  notificationemail1!: String;
  notificationemail2!: String;
  notificationphone!: string;
  has_247_access!: number;
  max_capacity!: number;
  near_by_metro!: string;
  city_name!: string;
  rating!: number;
  near_by_railway_name!: string;
  metro_distance!: number;
  railway_distance!: number;
  mon_friday_opening_time!: string;
  mon_friday_closing_time!: string;
  mon_opening_time!: string;
  mon_closing_time!: string;
  tue_opening_time!: string;
  tue_closing_time!: string;
  wed_opening_time!: string;
  wed_closing_time!: string;
  thu_opening_time!: string;
  thu_closing_time!: string;
  fri_opening_time!: string;
  fri_closing_time!: string;
  working_time!: Array<any>;
  sunday_opening_time!: string;
  sunday_closing_time!: string;
  saturday_opening_time!: string;
  saturday_closing_time!: string;
  privatecabin_price!: any;
  desks_price!: any;
  customized_space_price!: any;
  flexible_desk_price!: any;
  virtual_office_price!: any;
  meeting_room_price!: any;
  training_room_price!: any;
  address!: string;
  address2!: string;
  contact_city_name!: string;
  state!: string;
  yourWebsite!: String;
  country: string = "India";
  billing_country: String = "India";
  billing_state!: string;
  billing_contact_city_name!: string;
  billing_address!: string;
  billing_address2!: string;
  billing_zip_code!: string;
  billing_contactName!: string;
  billing_contactmobile!: string;
  billing_contactemail!: string;
  zipcode!: string;
  contactName!: string;
  contactmobile!: string;
  floorNumber!: Number;
  contactemail!: string;
  spaceTitle!: String;
  parkingDescription!: String;
  lightingDescription!: String;
  soundDescription!: String;
  hostRulesDescription!: String;
  arrivalInstructions!: String;
  alternate_contact_name!: string;
  alternate_contact_email!: string;
  alternate_contact_mobile!: string;
  longi!: Number;
  lat!: Number;
  price!: any;
  hostId: any;
  spaceCarpetAreaPrice!: any;
  spaceBuiltUpAreaPrice!: any;
  youtube_url!: string;
  updated_at!: string;
  serviceName!: string;
  isSameBillingAddress: boolean = false;
  serviceDescription!: string;
  servicePrice!: number;
  property_sourced_by: string;
  sourcing_date: string = "00-00-0000";
  property_current_status: string;
  property_details_updated_on: string;
  property_details_updated_by: string;

  spaceServiceDetailsArray!: Array<{
    serviceName: string;
    serviceDescription: string;
    servicePrice: number;
  }>;
  cancellationPolicy!: string;
  [key: string]: any;
}


@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.css'],

})
export class SpacesComponent implements OnInit {

  public selected_type: number;
  public spaces_data: Space[];
  public selectedspaces_data: Space[];
  public list_space;
  public filtered_spaces_data: Space[];
  displayedSpaces: any[] = [];
  itemsPerPage = 6;
  startIndex = 0;
  public search_string: string;
  public date_display;
  public date;
  public space_details;
  public selected_status = 1;
  isOpen: false;
  public website_base_url: string = environment?.websiteUrl;
  countryData: any = "India";
  countryList: any;
  stateList: any;
  cityList: any;
  stateData: any;
  cityData: any;
  spacesArray: Space;
  totalItems = 0;
  pageSize = 25; // Default page size
  currentPage = 0;
  paginatedBookings = [];

  workSpace = [
    { workSpaceName: 'Coworking Space', typeOfSpace: "Coworking" },
    { workSpaceName: 'Managed Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Private Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Shared Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Virtual Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Coworking Café/Restaurant', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Shoot Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Recording Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Podcast Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Activity Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Turf', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Venue', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Party Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Banquet Hall', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Gallery', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Classroom', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Private Cabin', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Meeting Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Training Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Event Space', typeOfSpace: "Short-Term" },
  ]
  workSpaceNames: any = [];
  selectedWorkSpace: any;
  category: any;
  country: any = "India";
  state: any;
  city: any;
  status: number;

  constructor(
    private loaderService: LoaderService,
    private _spaceservice: SpaceService,
    private queryService: QueriesService,
    private spaceService: SpaceService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public space_dialog: MatDialog,
    public upload_dialog: MatDialog,
    public confirm_dialog: MatDialog,
    public confirm_viewContainerRef: ViewContainerRef,
    public space_viewContainerRef: ViewContainerRef,
    public upload_viewContainerRef: ViewContainerRef,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  dialogRef: MatDialogRef<any>
  confirm_dialogRef: MatDialogRef<any>
  space_dialogRef: MatDialogRef<any>
  upload_dialogRef: MatDialogRef<any>

  public spacesDataSource;
  public search_text: string;

  selection = new SelectionModel<Space>(true, []);
  spacesDisplayedColumns: string[] = ['id', 'name', 'status', 'area_name', 'about', 'address', 'lat', 'longi', 'cumulative_rating'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.selected_type = 0;
    this.populateSpaces();
    this.getWorkSpace();
    this.getCountry();
    this.getState('India');
  }

  public populateSpaces() {
    this._spaceservice.getAll(this.search_string)
      .then(res => {
        if (res.success) {
          this.filtered_spaces_data = [];
          this.spaces_data = [];
          // this.spaces_data = Object.assign([], res.data);
          // this.filtered_spaces_data = [...this.spaces_data];
          this.spaces_data = res.data;
          this.filtered_spaces_data = res.data;
          this.updatePaginatedBookings();
          this.loadMore();

          this.changeStatus(this.selected_status);
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  getWorkSpace() {
    for (let i = 0; i < this.workSpace.length; i++) {
      this.workSpaceNames.push(this.workSpace[i]?.workSpaceName);
    }
  }

  public changeStatus(status: number) {
    this.selectedWorkSpace = '';
    this.country = "India";
    this.stateData = '';
    this.cityData = '';
    this.cityList = ''
    this.selected_status = status;
    switch (status) {
      case 1:
        this.filtered_spaces_data = this.spaces_data.filter(t => t.status === 1);
        this.updatePaginatedBookings();
        break;
      case 0:
        this.filtered_spaces_data = this.spaces_data.filter(t => t.status === 0);
        this.updatePaginatedBookings();
        break;
      default:
        this.filtered_spaces_data = [...this.spaces_data];
    }
    // this.loadMore();
  }

  public changeCategory(category: number) {
    this.category = category;
  }

  getCountry() {
    this.spaceService.getAllCountry().subscribe((res: any) => {
      this.countryList = res;
    });
  }

  onCountryChange(country: any) {
    this.country = country.country_name;
    var countryId = this.countryData?.country_name ?? "India";
    this.getState(countryId);
  }

  getState(id: any) {
    this.spaceService.getAllState(id).subscribe((res: any) => {
      this.stateList = res
    })
  }

  onStateChange(state: any) {
    if (state) {
      this.state = state.name;
    } else {
      this.state = ""
    }
    var stateId = this.stateData?.name;
    this.getAllCities(stateId);
  }

  getAllCities(id: any) {
    this.spaceService.getAllCities(id).subscribe((res: any) => {
      this.cityList = res
    })
  }

  onCityChange(city: any) {
    if (city) {
      this.city = city.name;
    } else {
      this.city = "";
    }
  }

  getSpaceByFilter() {
    this._spaceservice.filterSpace2(this.country ?? '', this.category ?? '', this.state ?? '', this.city ?? '').subscribe((res: any) => {
      this.filtered_spaces_data = res.data;

      if(this.selected_status == 1){
        this.filtered_spaces_data = this.filtered_spaces_data.filter(t => t.status === 1);
      }
      this.updatePaginatedBookings();
      if(this.selectedWorkSpace == null){
        this.changeStatus(this.selected_status);
      }
      this.totalItems = this.filtered_spaces_data.length;
    });
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updateSpace(element) {
    let status = element.space_status_toggle ? 1 : 0
    this.spaceService.update(element.id, { status })
      .then(res => {
        if (res.success) {
          this._openSnackBar(res.message, "Dismiss");
          this.populateSpaces();
        }
      })
      .catch(error => {
        console.log(error)
        this._openSnackBar(`Something went wrong`, "Dismiss");
      })
  }

  
  navigateToAdd(){
    if (this.queryService.hasPermission('Space', 'add')) {
      this.router.navigate(['/home/add-space'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  ActiveDeactiveSpace(event: any, element) {
    event.preventDefault();
    this.status = element.status = element.status === 1 ? 0 : 1;
    if (this.queryService.hasPermission('Space', 'edit')) {
    this.spaceService.ActiveDeactiveSpace(element.id, { status: this.status })
      .then(res => {
        if (res.success) {
          this._openSnackBar(res.message, "Dismiss");
          this.populateSpaces();
        }
      })
      .catch(error => {
        console.log(error)
        this._openSnackBar(`Something went wrong`, "Dismiss");
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  applyFilter() {
    this.populateSpaces()
  }

  search(value) {
    this.filtered_spaces_data = this.filtered_spaces_data.filter(
      (val) => val['name'].includes(value))
  }

  public clearSearchInput() {
    this.search_string = '';
    this.changeStatus(this.selected_status);
    this.populateSpaces();
  }

  openDeleteSpaceDialog(space_obj) {
    console.log(space_obj)
    let config = new MatDialogConfig();
    config.viewContainerRef = this.space_viewContainerRef;
    config.disableClose = true;

    this.space_dialogRef = this.space_dialog.open(DeleteSpaceDialog, config);
    this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
    this.space_dialogRef.componentInstance.selected_space = space_obj;
    this.space_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateSpaces();
      }
      this.space_dialogRef = null;
    });
  }


  edit(id:number){
    if (this.queryService.hasPermission('Space', 'edit')) {
      this.router.navigate([`/home/update-space/${id}`])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  review(id:number){
    if (this.queryService.hasPermission('Review', 'edit')) {
      window.open(`/home/space-review/${id}`,'_blank')
    } else {
      this.queryService.accessDeniedAlert()
    }
  }



  openRibbonDialog(id,type) {
    if (this.queryService.hasPermission('Space', 'edit')){
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;

    config.data = { id , type: type };

    this.space_dialogRef = this.space_dialog.open(DeleteSpaceDialog, config);
    this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
    this.space_dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.populateSpaces();
      }
      this.space_dialogRef = null;
    });
  }else {
    this.queryService.accessDeniedAlert()
  }
  }

  loadMore() {
    // Calculate the end index based on itemsPerPage and startIndex
    const endIndex = Math.min(this.startIndex + this.itemsPerPage, this.filtered_spaces_data.length);

    // If no spaces are displayed yet, load initial set, else load more
    if (this.displayedSpaces.length === 0) {
      this.displayedSpaces = this.filtered_spaces_data.slice(0, endIndex);
    } else {
      // Append the next set of spaces to the displayedSpaces array
      this.displayedSpaces.push(...this.filtered_spaces_data.slice(this.startIndex, endIndex));
    }

    // Update startIndex for the next load
    this.startIndex = endIndex;
  }

  // openEditSpaceDialog(space_obj, action_type) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(UpdateSpaceDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space = space_obj;
  //   this.space_dialogRef.componentInstance.action_type = action_type;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.success) {
  //       this.populateSpaces();
  //       if (result.add_space) {
  //         this.openUploadImageDialog(result.space_obj)
  //       }
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }

  // openDeleteManagerDialog(space_obj) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(DeleteSpaceDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space = space_obj;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if(result && result.success){
  //       this.populateSpaces();
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }

  // openUploadImageDialog(space_obj) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(UploadImageDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_1 = space_obj.details_img_1;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_2 = space_obj.details_img_2;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_3 = space_obj.details_img_3;
  //   this.space_dialogRef.componentInstance.selected_space_banner_img = space_obj.banner_img;
  //   this.space_dialogRef.componentInstance.selected_space_id = space_obj.id;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.success) {
  //       this.populateSpaces();
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }

  imageErrorHandler(event: any) {
    this.spaceService.handleImageError(event);
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.filtered_spaces_data.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }

  formatUrl(value: string): string {
    return value?.trim()?.toLowerCase().replace(/\s+/g, '-');
  }
}
