import { Component, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {
  MatLegacyDialog as MatDialog
} from "@angular/material/legacy-dialog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { FaqsService } from "../services/faqs.service";
import { QueriesService } from "../services/queries.service";
import { SpaceService } from "../services/space.service";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  @ViewChild("shortQuestionDialog") shortQuestionDialog: TemplateRef<any>;
  @ViewChild("briefQuestionDialog") briefQuestionDialog: TemplateRef<any>;
  panelOpenState = false;
  selectedCity = 0;
  selectedSpace = 0;
  editor = ClassicEditor;
  data: any = `<p>Hello, world!</p>`;
  toppingList: string[] = [
    "Extra cheese",
    "Mushroom",
    "Onion",
    "Pepperoni",
    "Sausage",
    "Tomato",
  ];
  cities: any[] = [];
  citiesTemp: any[];
  spaces: any[] = [];
  spacesTemp: any[] = [];
  shortQuestions: any[] = [];
  briefQuestions: any[] = [];
  shortQuestionForm: UntypedFormGroup;
  briefQuestionForm: UntypedFormGroup;
  filtersForm: UntypedFormGroup;
  shortQuestionSubmissionMode: boolean = true;
  briefQuestionSubmissionMode: boolean = true;
  selectedQuestion: any;
  locations: any[] = [];
  locationsTempList: any[] = [];
  selectedLocation: any;
  countryList: any;
  stateList: any;
  cityList: any;
  locationList: any;
  spaceList: any;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private spaceService: SpaceService,
    private faqsService: FaqsService,
    private fb: UntypedFormBuilder,
    private queryService: QueriesService,
  ) { }

  ngOnInit() {
    this.getCountry()
    this.createForms();
    this.getAllQuestions();
  }


  getCountry() {
    this.spaceService.getAllCountry().subscribe((res: any) => {
      this.countryList = res;
      this.filtersForm.controls['countryName'].patchValue("India");
      this.getState('India');
    });
  }

  onCountryChange(country: any, type: string) {
    if (type = 'search') {
      this.filtersForm.controls['stateName'].patchValue(null);
      this.getState(country);
    }
  }

  getState(id: any) {
    this.spaceService.getAllState(id).subscribe((res: any) => {
      this.stateList = res
    })
  }

  onStateChange(state: any, type: string) {
    this.filtersForm.controls['city_id'].patchValue(null);
    this.getAllCities(state);
  }

  getAllCities(id: any) {
    this.spaceService.getAllCities(id).subscribe((res: any) => {
      this.cityList = res
    })
  }

  onCityChange(city: any) {
    this.getNearBySpacesByCityName(this.returnCityName(city))
    this.getSpacesByCity(city)
    this.getAllQuestions()
  }

  getNearBySpacesByCityName(cityName: string) {
    this.spaceService.getNearBySpacesByCityNameFaq(cityName).subscribe(
      (res) => {
        this.locationList = res;
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }

  getSpacesByCity(cityId: number) {
    this.spaceService.getSpacesByCity(cityId).subscribe(
      (res) => {
        if (res.length) {
          this.spaceList = res;
        }
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }

  resetFiltersForm() {
    this.filtersForm.patchValue({
      countryName: "India",
      stateName: null,
      city_id: null,
      location_id: '',
      space_id: null
    });
    this.cityList = []
    this.locationList = []
    this.spaceList = []
    this.getAllQuestions();
  }


  createForms() {
    this.filtersForm = this.fb.group({
      city_id: null,
      space_id: null,
      location_id: '',
      countryName: '',
      stateName: ''
    });
    this.shortQuestionForm = this.fb.group({
      city_id: [0, Validators.required],
      question: ["", [Validators.required]],
      answer: ["", [Validators.required]],

      location_name: [""],
      space_id: [""],
    });
    this.briefQuestionForm = this.fb.group({
      city_id: [0, Validators.required],
      question: ["", [Validators.required]],
      answer: ["", [Validators.required]],
      location_name: [""],
      space_id: [""],
    });
  }

  getAllSpacesCities() {
    this.spaceService.getAllSpacesCities().subscribe(
      (res) => {
        this.cities = res || [];
        this.citiesTemp = this.cities.slice();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }

  onCityFilterChange(e: any) {
    console.log(e);
    this.selectedCity = e?.value || 0;
    this.filtersForm.controls.space_id.setValue("");
    this.filtersForm.controls.location_id.setValue("");
    this.getSpacesByCity(this.selectedCity);
    // this.getNearBySpacesByCityName(this.returnCityName(this.selectedCity));
    this.getAllQuestions();
    // this.getAllQuestions();
  }
  onLocationFilterChange(e: any) {
    console.log(e);
    this.getSpacesByCityAndLocation(this.selectedCity, e.value);
  }
  getSpacesByCityAndLocation(cityID: number, locationName: any) {
    this.spaceService
      .getSpacesByCityAndLocation(this.selectedCity, locationName)
      .subscribe(
        (response) => {
          console.log(response);
          this.spaces = response || [];
          this.spacesTemp = this.spaces.slice();
        },
        ({ message }) => {
          console.log(message);
        },
      );
  }
  onSpaceFilterChange(e: { value: number; }) {
    this.selectedSpace = e?.value || 0;
    this.getAllQuestions();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 400 && !this.isLoading) {
      this.getAllQuestions()
    }
  }

  displayedRows: any[] = [];
  rowIncrement = 25;
  currentOffset = 0;
  isLoading = false;


  getAllQuestions() {
    this.isLoading = true;
    const data = {
      location_name: this.filtersForm.value.location_id || null,
      space_id: this.filtersForm.value.space_id || null,
      city_id: this.filtersForm.value.city_id || null,
      offset: this.currentOffset,
      limit: this.rowIncrement
    }
    this.faqsService
      .getAllQuestions(data)
      .subscribe(
        (response) => {
          this.currentOffset += response.length ? this.rowIncrement : 0;
            this.displayedRows = [...this.displayedRows, ...response];
            this.shortQuestions = this.displayedRows.filter((item) => item.type == 1);
            this.briefQuestions = this.displayedRows.filter((item) => item.type == 2);
            if (!response.length && (data.location_name || data.city_id || data.space_id)) {
            this.shortQuestions = []
            this.briefQuestions = []
          }
          this.isLoading = false;
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
          this.shortQuestions = []
          this.briefQuestions = []
          this.isLoading = false;
        },
      );
  }




  // onCitySelectionChange(e) {
  //   console.log(e);
  //   this.selectedCity = e?.value || 0;
  //   this.getSpacesByCity(e.value);
  //   this.getNearBySpacesByCityName(t(e.value));
  // }

  onLocationSelectionChange(e: any) {
    this.selectedLocation = e?.value;
    console.log(e);

    this.getSpacesByCityAndLocation(this.selectedCity, this.selectedLocation);
  }




  openShortQuestionDialog() {
    this.shortQuestionForm.reset();
    this.dialog.open(this.shortQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
  }
  openBriefQuestionDialog() {
    this.briefQuestionForm.reset();
    this.dialog.open(this.briefQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
  }

  onSubmitShortQuestion() {
    if (this.queryService.hasPermission('FAQs', 'add')) {
      console.log(this.shortQuestionForm.value);
      if (this.shortQuestionForm.invalid) {
        this.snackBar.open("Please fill all the fields", "Close", {
          duration: 2000,
        });
        return;
      }
      if (this.shortQuestionForm.value.space_id == "" || null) {
        this.shortQuestionForm.value.space_id = 0;
      }
      if (this.shortQuestionForm.value.location_name == "" || null) {
        this.shortQuestionForm.value.location_name = "";
      }
      this.faqsService
        .addQuestion({ type: 1, ...this.shortQuestionForm.value })
        .subscribe(
          (response) => {
            console.log(response);
            this.snackBar.open("Question added successfully", "Close", {
              duration: 2000,
            });
            // this.shortQuestionForm.reset();
            // this.dialog.closeAll();
            this.getAllQuestions();
          },
          ({ message }) => {
            this.snackBar.open(message, "Close", {
              duration: 2000,
            });
          },
        );
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  onSubmitBriefQuestion() {
    if (this.queryService.hasPermission('FAQs', 'add')) {
      console.log(this.briefQuestionForm.value);
      if (this.briefQuestionForm.invalid) {
        this.snackBar.open("Please fill all the fields", "Close", {
          duration: 2000,
        });
        return;
      }

      if (this.briefQuestionForm.value.space_id == "" || null) {
        this.briefQuestionForm.value.space_id = 0;
      }
      if (this.briefQuestionForm.value.location_name == "" || null) {
        this.briefQuestionForm.value.location_name = "";
      }
      this.faqsService
        .addQuestion({ type: 2, ...this.briefQuestionForm.value })
        .subscribe(
          (response) => {
            console.log(response);
            this.snackBar.open("Question added successfully", "Close", {
              duration: 2000,
            });
            // this.shortQuestionForm.reset();
            // this.dialog.closeAll();
            this.getAllQuestions();
          },
          ({ message }) => {
            this.snackBar.open(message, "Close", {
              duration: 2000,
            });
          },
        );
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  onUpdateShortQuestion() {
    if (this.shortQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }

    if (this.shortQuestionForm.value.space_id == "" || null) {
      this.shortQuestionForm.value.space_id = 0;
    }
    if (this.shortQuestionForm.value.location_name == "" || null) {
      this.shortQuestionForm.value.location_name = "";
    }
    this.faqsService
      .updateQuestion(
        { type: 1, ...this.shortQuestionForm.value },
        this.selectedQuestion.id,
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question updated successfully", "Close", {
            duration: 2000,
          });
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }

  onUpdateBriefQuestion() {
    if (this.briefQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }

    if (this.briefQuestionForm.value.space_id == "" || null) {
      this.briefQuestionForm.value.space_id = 0;
    }
    if (this.briefQuestionForm.value.location_name == "" || null) {
      this.briefQuestionForm.value.location_name = "";
    }
    this.faqsService
      .updateQuestion(
        { type: 2, ...this.briefQuestionForm.value },
        this.selectedQuestion.id,
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question updated successfully", "Close", {
            duration: 2000,
          });
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }
  onDeleteShortQuestion(question: { id: any; }) {
    this.faqsService.deleteQuestion(question.id).subscribe(
      (response) => {
        console.log(response);
        this.snackBar.open("Question deleted successfully", "Close", {
          duration: 1500,
        });
        this.getAllQuestions();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  onDeleteBriefQuestion(question: { id: any; }) {
    this.faqsService.deleteQuestion(question.id).subscribe(
      (response) => {
        console.log(response);
        this.snackBar.open("Question deleted successfully", "Close", {
          duration: 1500,
        });
        this.getAllQuestions();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  onEditShortQuestion(question: { [x: string]: any; city_id?: any; }) {
    console.log(question);
    this.selectedQuestion = question;
    this.shortQuestionSubmissionMode = false;
    this.getSpacesByCity(question.city_id);
    this.shortQuestionForm.patchValue(question);
    const dialog = this.dialog.open(this.shortQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
    dialog.afterClosed().subscribe(() => {
      this.shortQuestionForm.reset();
      this.shortQuestionSubmissionMode = true;
    });
  }
  onEditBriefQuestion(question: { [x: string]: any; city_id?: any; }) {
    this.selectedQuestion = question;

    this.briefQuestionSubmissionMode = false;
    this.getSpacesByCity(question.city_id);
    this.briefQuestionForm.patchValue(question);
    const dialog = this.dialog.open(this.briefQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
    dialog.afterClosed().subscribe(() => {
      this.briefQuestionForm.reset();
      this.briefQuestionSubmissionMode = true;
    });
  }

  onFilterChange(e: { value: null; }, type: string) {
    if (type == "city") {
      this.selectedCity = e?.value || null;
      this.filtersForm.controls.space_id.setValue("");
      this.filtersForm.controls.location_id.setValue("");
      this.getSpacesByCity(this.selectedCity);
      // this.getNearBySpacesByCityName(this.returnCityName(this.selectedCity));
    }
    if (type == "location") {
      this.selectedLocation = this.getLocationNameById(e?.value) || null;
      this.getSpacesByCityAndLocation(this.selectedCity, this.selectedLocation);
    }
    if (type == "space") {
      this.selectedSpace = e?.value || null;
    }
    this.getAllQuestions();
  }
  resetShortQuestion() {
    this.shortQuestionForm.controls.question.reset();
    this.shortQuestionForm.controls.answer.reset();
  }
  resetBriefQuestion() {
    this.briefQuestionForm.controls.question.reset();
    this.briefQuestionForm.controls.answer.reset();
  }



  returnCityName(cityId: any) {
    return this.cityList.find((city: { id: any; }) => city.id == cityId).name;
  }

  getLocationNameById(locationId: any) {
    return this.locations.find((location) => location.id == locationId)
      .location_name;
  }


 
}

