<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Tell Users about your space</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading">
                        <div class="tabs-menu radius-tab">

                            <div class="mb-5 pt-5">
                                <a class="main-labels"><i class="fe fe-home me-2"></i>Select your category</a>
                                <div class="row mx-0 mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="required mb-2">Category<span class="text-danger">*</span></label>
                                            <div class="filter-checkbox">
                                                <p class="form-control" class="form-control">{{createSpace?.spaceType}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5" *ngIf="showGenInfo">
                                <a class="main-labels"><i class="fe fe-home me-2"></i>General
                                    Information/Location</a>
                                <div class="row mx-0 mt-4">
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.actual_name !== oldSpaceData.actual_name)">
                                        <div class="form-group">
                                            <label class="mb-2" for="actual_name">Name of your space <span
                                                    class="text-danger">*</span></label>

                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.actual_name !== oldSpaceData.actual_name)">
                                                <p class="form-control" class="form-control"> Old value: {{oldSpaceData.actual_name}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.actual_name}}</p>
                                            </span>

                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.actual_name}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.yourWebsite !== oldSpaceData.yourWebsite)">
                                        <div class="form-group">
                                            <label>Enter your website URL<span
                                                    style="font-weight: 100;font-size: 12px;">
                                                    (company.com)</span><span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.yourWebsite !== oldSpaceData.yourWebsite)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.yourWebsite}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.yourWebsite}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.yourWebsite}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.country !== oldSpaceData.country)">
                                        <div class="form-group">
                                            <label>Country <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.country !== oldSpaceData.country)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.country}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.country}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.country}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.state !== oldSpaceData.state)">
                                        <div class="form-group">
                                            <label>State <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.state !== oldSpaceData.state)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.state}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.state}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.state}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contact_city_name !== oldSpaceData.contact_city_name)">
                                        <div class="form-group">
                                            <label>City <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.contact_city_name !== oldSpaceData.contact_city_name)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.contact_city_name}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.contact_city_name}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.contact_city_name}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.location_name !== oldSpaceData.location_name)">
                                        <div class="form-group">
                                            <label> Location Name <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.location_name !== oldSpaceData.location_name)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.location_name}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.location_name}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.location_name}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.buildingName !== oldSpaceData.buildingName)">
                                        <div class="form-group">
                                            <label>Enter your building name</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.buildingName !== oldSpaceData.buildingName)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.buildingName}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.buildingName}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.buildingName}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.floorNumber !== oldSpaceData.floorNumber)">
                                        <div class="form-group">
                                            <label>Enter your unit/floor no.</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.floorNumber !== oldSpaceData.floorNumber)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.floorNumber}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.floorNumber}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.floorNumber}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.unitno !== oldSpaceData.unitno)">
                                        <div class="form-group">
                                            <label>Unit no.</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.unitno !== oldSpaceData.unitno)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.unitno}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.unitno}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.unitno}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.address !== oldSpaceData.address)">
                                        <div class="form-group">
                                            <label>Enter Address line 1 <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.address !== oldSpaceData.address)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.address}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.address}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.address}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.address2 !== oldSpaceData.address2)">
                                        <div class="form-group">
                                            <label>Enter Address line 2</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.address2 !== oldSpaceData.address2)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.address2}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.address2}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.address2}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.zipcode !== oldSpaceData.zipcode)">
                                        <div class="form-group">
                                            <label>Zip/Postal code <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.zipcode !== oldSpaceData.zipcode)">
                                                <p class="form-control" class="form-control">Old value: {{oldSpaceData.zipcode}} </p>
                                                <p class="form-control" class="form-control">New value: {{createSpace.zipcode}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control" class="form-control">{{createSpace.zipcode}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isSameBillingAddress !== oldSpaceData.isSameBillingAddress)">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Is the Billing address the same as
                                                the communication address?</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.isSameBillingAddress !== oldSpaceData.isSameBillingAddress)">
                                                <p class="form-control">Old value: {{oldSpaceData.isSameBillingAddress}} </p>
                                                <p class="form-control">New value: {{createSpace.isSameBillingAddress}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.isSameBillingAddress ? 'Yes' : 'No'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_country !== oldSpaceData.billing_country)">
                                        <div class="form-group">
                                            <label>Billing Country <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_country !== oldSpaceData.billing_country)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_country}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_country}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_country}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_state !== oldSpaceData.billing_state)">
                                        <div class="form-group">
                                            <label>Billing state <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_state !== oldSpaceData.billing_state)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_state}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_state}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_state}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_contact_city_name !== oldSpaceData.billing_contact_city_name)">
                                        <div class="form-group">
                                            <label>Billing city <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_contact_city_name !== oldSpaceData.billing_contact_city_name)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_contact_city_name}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_contact_city_name}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_contact_city_name}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_location_name !== oldSpaceData.billing_location_name)">
                                        <div class="form-group">
                                            <label>Location Name <span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_location_name !== oldSpaceData.billing_location_name)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_location_name}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_location_name}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_location_name}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_buildingName !== oldSpaceData.billing_buildingName)">
                                        <div class="form-group">
                                            <label>Enter your building name</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_buildingName !== oldSpaceData.billing_buildingName)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_buildingName}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_buildingName}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_buildingName}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_floorNumber !== oldSpaceData.billing_floorNumber)">
                                        <div class="form-group">
                                            <label>Enter your unit/floor no.</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billing_floorNumber !== oldSpaceData.billing_floorNumber)">
                                                <p class="form-control">Old value: {{oldSpaceData.billing_floorNumber}} </p>
                                                <p class="form-control">New value: {{createSpace.billing_floorNumber}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billing_floorNumber}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billingaddress !== oldSpaceData.billingaddress)">
                                        <div class="form-group">
                                            <label>Enter billing address line 1 <span
                                                    class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.billingaddress !== oldSpaceData.billingaddress)">
                                                <p class="form-control">Old value: {{oldSpaceData.billingaddress}} </p>
                                                <p class="form-control">New value: {{createSpace.billingaddress}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.billingaddress}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.near_by_metro !== oldSpaceData.near_by_metro || createSpace.metro_distance !== oldSpaceData.metro_distance || createSpace.near_by_railway_name !== oldSpaceData.near_by_railway_name || createSpace.railway_distance !== oldSpaceData.railway_distance)">
                                    <div class="form-group">
                                        <label *ngIf="(viewItem=='Edit' && createSpace.near_by_metro !== oldSpaceData.near_by_metro)" class="required">Nearest metro</label>
                                        <span *ngIf="(viewItem=='Edit' && createSpace.near_by_metro !== oldSpaceData.near_by_metro)">
                                            <p class="form-control">Old value: {{oldSpaceData.near_by_metro}} </p>
                                            <p class="form-control">New value: {{createSpace.near_by_metro}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.near_by_metro ?? 'N/A'}}</p>
                                        </span>

                                        <label *ngIf="(viewItem=='Edit' && createSpace.metro_distance !== oldSpaceData.metro_distance)" class="required">Metro distance</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.metro_distance !== oldSpaceData.metro_distance)">
                                            <p class="form-control">Old value: {{oldSpaceData.metro_distance}} </p>
                                            <p class="form-control">New value: {{createSpace.metro_distance}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.metro_distance ?? 'N/A'}}</p>
                                        </span>

                                        <label *ngIf="(viewItem=='Edit' && createSpace.near_by_railway_name !== oldSpaceData.near_by_railway_name)" class="required">Nearest railway</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.near_by_railway_name !== oldSpaceData.near_by_railway_name)">
                                            <p class="form-control">Old value: {{oldSpaceData.near_by_railway_name}} </p>
                                            <p class="form-control">New value: {{createSpace.near_by_railway_name}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.near_by_railway_name ?? 'N/A'}}</p>
                                        </span>

                                        <label *ngIf="(viewItem=='Edit' && createSpace.railway_distance !== oldSpaceData.railway_distance)" class="required">Railway distance</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.railway_distance !== oldSpaceData.railway_distance)">
                                            <p class="form-control">Old value: {{oldSpaceData.railway_distance}} </p>
                                            <p class="form-control">New value: {{createSpace.railway_distance}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.railway_distance ?? 'N/A'}}</p>
                                        </span>
                                    </div>

                                </div>
                                <div class="col-md-6"
                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_address2 !== oldSpaceData.billing_address2)">
                                    <div class="form-group">
                                        <label>Enter billing address line 2</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.billing_address2 !== oldSpaceData.billing_address2)">
                                            <p class="form-control">Old value: {{oldSpaceData.billing_address2}} </p>
                                            <p class="form-control">New value: {{createSpace.billing_address2}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.billing_address2}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="!createSpace.isSameBillingAddress && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.billing_zip_code !== oldSpaceData.billing_zip_code)">
                                    <div class="form-group">
                                        <label>Enter billing zip/postal code <span class="text-danger">*</span></label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.billing_zip_code !== oldSpaceData.billing_zip_code)">
                                            <p class="form-control">Old value: {{oldSpaceData.billing_zip_code}} </p>
                                            <p class="form-control">New value: {{createSpace.billing_zip_code}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.billing_zip_code}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-md-12"
                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.location !== oldSpaceData.location)">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Enter your space location<span class="text-danger">*</span></label>
                                            <span *ngIf="(viewItem=='Edit' && createSpace.location !== oldSpaceData.location)">
                                                <p class="form-control">Old value: {{oldSpaceData.location}} </p>
                                                <p class="form-control">New value: {{createSpace.location}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.location}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5 pt-5" *ngIf="showAbtSpace">
                                <a class="main-labels mb-2"><i class="fe fe-home me-2"></i> About the space:</a>
                                <div class="row mt-4">
                                    <div class="col-md-12"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceTitle !== oldSpaceData.spaceTitle)">
                                        <div class="form-group">
                                            <label class="required">Enter your space title <span
                                                    class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.spaceTitle !== oldSpaceData.spaceTitle)">
                                                <p class="form-control h-100">Old value: {{oldSpaceData.spaceTitle}} </p>
                                                <p class="form-control h-100 mt-4">New value: {{createSpace.spaceTitle}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.spaceTitle ?? 'N/A'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.hostRulesDescription !== oldSpaceData.hostRulesDescription)">
                                    <div class="form-group">
                                        <label class="required">What are you host rules
                                        </label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.hostRulesDescription !== oldSpaceData.hostRulesDescription)">
                                            <p class="form-control">Old value: {{oldSpaceData.hostRulesDescription}} </p>
                                            <p class="form-control">New value: {{createSpace.hostRulesDescription}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.hostRulesDescription ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12"
                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.about !== oldSpaceData.about)">
                                    <div class="form-group">
                                        <label class="required">Add a description of your space</label>
                                        <span *ngIf="(viewItem=='Edit' && createSpace.about !== oldSpaceData.about)">
                                            <p class="form-control h-100">Old value: {{oldSpaceData.about}} </p>
                                            <p class="form-control h-100 mt-4">New value: {{createSpace.about}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.about ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.parkingDescription !== oldSpaceData.parkingDescription)"
                                    class="col-md-12">
                                    <div class="form-group">
                                        <label class="required">Describe the parking options</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.parkingDescription !== oldSpaceData.parkingDescription)">
                                            <p class="form-control h-100">Old value: {{oldSpaceData.parkingDescription}} </p>
                                            <p class="form-control h-100 mt-4">New value: {{createSpace.parkingDescription}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.parkingDescription ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && compareParkingoptions)"
                                    class="col-md-6">
                                    <div class="form-group">
                                        <label class="required">Parking options</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && compareParkingoptions)">
                                            <h4>Old value:<p class="form-control" *ngFor="let oldParking of oldParkingOptionsvalue">{{oldParking}}</p></h4>
                                            <h4>New value:<p class="form-control" *ngFor="let newParking of createSpace.parkingOptionsValue">{{newParking}}</p></h4>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.parkingOptionsValue ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.lightingDescription !== oldSpaceData.lightingDescription)">
                                    <div class="form-group">
                                        <label class="required">Describe the lighting options</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.lightingDescription !== oldSpaceData.lightingDescription)">
                                            <p class="form-control">Old value: {{oldSpaceData.lightingDescription}} </p>
                                            <p class="form-control">New value: {{createSpace.lightingDescription}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.lightingDescription ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.soundDescription !== oldSpaceData.soundDescription)">
                                    <div class="form-group">
                                        <label class="required">Describe the sound options</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.soundDescription !== oldSpaceData.soundDescription)">
                                            <p class="form-control">Old value: {{oldSpaceData.soundDescription}} </p>
                                            <p class="form-control">New value: {{createSpace.soundDescription}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.soundDescription ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.arrivalInstructions !== oldSpaceData.arrivalInstructions)">
                                    <div class="form-group">
                                        <label class="required">Provide arrival instructions</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.arrivalInstructions !== oldSpaceData.arrivalInstructions)">
                                            <p class="form-control">Old value: {{oldSpaceData.arrivalInstructions}} </p>
                                            <p class="form-control">New value: {{createSpace.arrivalInstructions}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.arrivalInstructions ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.wifiName !== oldSpaceData.wifiName)">
                                    <div class="form-group">
                                        <label class="required">What is your Wi-fi Name </label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.wifiName !== oldSpaceData.wifiName)">
                                            <p class="form-control">Old value: {{oldSpaceData.wifiName}} </p>
                                            <p class="form-control">New value: {{createSpace.wifiName}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.wifiName ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.wifiPassword !== oldSpaceData.wifiPassword)">
                                    <div class="form-group">
                                        <label class="required">What is your Wi-fi Password</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.wifiPassword !== oldSpaceData.wifiPassword)">
                                            <p class="form-control">Old value: {{oldSpaceData.wifiPassword}} </p>
                                            <p class="form-control">New value: {{createSpace.wifiPassword}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.wifiPassword ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div> -->
                                <div class="col-md-6"
                                    *ngIf="createSpace?.spacetypeValue == 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceStatus !== oldSpaceData.spaceStatus)">
                                    <div class="form-group">
                                        <label class="required">What is the status of your space? (Bare
                                            shell, warm shell, semi-furnished, furnished)</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.spaceStatus !== oldSpaceData.spaceStatus)">
                                            <p class="form-control">Old value: {{oldSpaceData.spaceStatus}} </p>
                                            <p class="form-control">New value: {{createSpace.spaceStatus}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.spaceStatus ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showSizeCap">
                                <a class="main-labels"><i class="fe fe-home me-2"></i> Size/Capacity:</a>
                                <div class="row mt-4">
                                    <div class="col-md-6 mt-5"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spacesqft !== oldSpaceData.spacesqft)">
                                        <label *ngIf="createSpace?.spacetypeValue == 'Long-Term'">How big is the
                                            space ? (carpet area sq ft)</label>
                                        <label *ngIf="createSpace?.spacetypeValue == 'Short-Term'">How big is
                                            the space ? (Square Feet Area)</label>
                                        <label *ngIf="createSpace?.spacetypeValue == 'Coworking'">What is the
                                            size of your space in sqft ? </label>

                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.spacesqft !== oldSpaceData.spacesqft)">
                                            <p class="form-control">Old value: {{oldSpaceData.spacesqft}} </p>
                                            <p class="form-control">New value: {{createSpace.spacesqft}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.spacesqft ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5"
                                    *ngIf="createSpace?.spacetypeValue == 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceBuiltUpArea !== oldSpaceData.spaceBuiltUpArea)">
                                    <label>How big is the space (built-up area sq ft)</label>
                                    <span
                                        *ngIf="(viewItem=='Edit' && createSpace.spaceBuiltUpArea !== oldSpaceData.spaceBuiltUpArea)">
                                        <p class="form-control">Old value: {{oldSpaceData.spaceBuiltUpArea}} </p>
                                        <p class="form-control">New value: {{createSpace.spaceBuiltUpArea}}</p>
                                    </span>
                                    <span *ngIf="(viewItem!='Edit')">
                                        <p class="form-control">{{createSpace.spaceBuiltUpArea ?? 'N/A'}}</p>
                                    </span>
                                </div>
                                <div class="row"
                                    *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.howManyPeopleInYourSpace !== oldSpaceData.howManyPeopleInYourSpace)">
                                    <div class="col-md-6">
                                        <label>How many people can be accomodated in your space</label>
                                        <span
                                            *ngIf="(viewItem=='Edit' && createSpace.howManyPeopleInYourSpace !== oldSpaceData.howManyPeopleInYourSpace)">
                                            <p class="form-control">Old value: {{oldSpaceData.howManyPeopleInYourSpace}} </p>
                                            <p class="form-control">New value: {{createSpace.howManyPeopleInYourSpace}}</p>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <p class="form-control">{{createSpace.howManyPeopleInYourSpace ?? 'N/A'}}</p>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="showAmenities" class="mt-4">
                                <a class="main-labels"><i class="fe fe-home me-2"></i>
                                    Amenities/Equipment/Parking:</a>
                                <div class="row mx-0 mt-4">
                                    <ng-container>
                                        <div class="col-md-12"
                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' &&  compareAmenities)">
                                            <div class="form-group">
                                                <label class="required mb-2">Amenities<span class="text-danger">*</span>
                                                </label>
                                                <span *ngIf="viewItem == 'Edit' && compareAmenities">
                                                    <!-- Old Facilities Table -->
                                                    <h4>Old Values</h4>
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Old Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let facility of oldFacilities; let i = index">
                                                                <td>{{ i + 1 }}</td>
                                                                <td>{{ getFacilityName(facility) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                
                                                    <!-- New Facilities Table -->
                                                    <h4>New Values</h4>
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>New Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let facility of createSpace.facilities; let i = index">
                                                                <td>{{ i + 1 }}</td>
                                                                <td>{{ getFacilityName(facility) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control" style="margin-right: 10px;margin-top: 15px;display: inline-flex;align-items: center;"
                                                        *ngFor="let facility of amenitiesName; let i = index">
                                                        <span>{{i+1}}.&nbsp;</span> {{facility}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="createSpace?.spacetypeValue !== 'Short-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.howMenyParking !== oldSpaceData.howMenyParking)">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>How many parkings are included with your space</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.howMenyParking !== oldSpaceData.howMenyParking)">
                                                    <p class="form-control">Old value: {{oldSpaceData.howMenyParking}} </p>
                                                    <p class="form-control">New value: {{createSpace.howMenyParking}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.howMenyParking ?? 'N/A'}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.equipment !== oldSpaceData.equipment)">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>List the equipment that are include with the booking</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.equipment !== oldSpaceData.equipment)">
                                                    <p class="form-control">Old value: {{oldSpaceData.equipment}} </p>
                                                    <p class="form-control">New value: {{createSpace.equipment}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.equipment ?? 'N/A'}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceSecurity !== oldSpaceData.spaceSecurity)">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Does your space have security cameras or recording
                                                    devices</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.spaceSecurity !== oldSpaceData.spaceSecurity)">
                                                    <p class="form-control">Old value: {{oldSpaceData.spaceSecurity}} </p>
                                                    <p class="form-control">New value: {{createSpace.spaceSecurity}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.spaceSecurity ?? 'N/A'}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-md-6">
                                        <label style="color: red" *ngIf="price_message">{{price_message}}</label>
                                    </div>
                                </div>

                            </div>
                            <div class="mb-5 pt-5" *ngIf="differences.length > 0 || createSpace.has_247_access !== oldSpaceData.has_247_access">
                                <a class="main-labels"><i class="fe fe-home me-2"></i>
                                    Operating hours:</a>
                                <h6>Has 24X7 Access</h6>
                                <div class="col-md-12" *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.has_247_access !== oldSpaceData.has_247_access)">
                                    <!-- <mat-checkbox [(ngModel)]="has_247_access_toggle"
                                        [checked]="has_247_access_toggle">
                                        Has 24x7 Access
                                    </mat-checkbox> -->
                                    <span *ngIf="(viewItem=='Edit' && createSpace.has_247_access !== oldSpaceData.has_247_access)">
                                        <p>Old value: {{oldSpaceData.has_247_access ? 'Yes' : 'No'}} </p>
                                        <p>New value: {{createSpace.has_247_access ? 'Yes' : 'No'}}</p>
                                    </span>
                                    <span *ngIf="(viewItem!='Edit')">
                                        <p>Has 24x7 Access {{createSpace.has_247_access ? 'Yes' : 'No'}}</p>
                                    </span>
                                </div>
                                <h6>Operating hours</h6>
                                <div *ngIf="differences.length > 0" class="mt-4">
                                    <!-- <h2>Differences</h2> -->
                                     
                                    <ul>
                                        <li *ngFor="let diff of differences">
                                        <strong>{{ diff.day }}:</strong>
                                        <div>
                                            <span>
                                                Old value - Opening Time:
                                                {{ formatTime(diff.old.openingTime) !== undefined && formatTime(diff.old.openingTime) !== '' ? formatTime(diff.old.openingTime) : 'N/A' }},
                                                Closing Time:
                                                {{ formatTime(diff.old.closingTime) !== undefined && formatTime(diff.old.closingTime) !== '' ? formatTime(diff.old.closingTime) : 'N/A' }},
                                                Status:
                                                {{ diff.old.isClosed ? 'Closed' : 'Open' }}
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                New value - Opening Time:
                                                {{ formatTime(diff.new.openingTime) !== undefined && formatTime(diff.new.openingTime) !== '' ? formatTime(diff.new.openingTime) : 'N/A' }},
                                                Closing Time:
                                                {{ formatTime(diff.new.closingTime) !== undefined && formatTime(diff.new.closingTime) !== '' ? formatTime(diff.new.closingTime) : 'N/A' }},
                                                Status:
                                                {{ diff.new.isClosed ? 'Closed' : 'Open' }}
                                            </span>
                                        </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="mb-5 pt-5"
                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && showActivities">
                                <a class="main-labels"><i class="fe fe-home me-2"></i> Activities:</a>

                                <div class="row mx-0 mt-4" *ngIf="spaceServiceDetailsArray.length > 0">
                                    <div class="col-md-12 mb-3">
                                        <div class="form-group">
                                            <label class="required mb-2">Which activity would you like to
                                                host</label>
                                            <input type="text" class="form-control filter-input"
                                                placeholder="Enter activity would you like to host">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="mb-5 pt-5" *ngIf="showPricing">
                                <a class="main-labels"><i class="fe fe-home me-2"></i> Pricing:</a>
                                <!-- <ng-template *ngIf="createSpace?.spacetypeValue == 'Short-Term'" class="mt-4"> -->
                                    <div *ngIf="createSpace?.spacetypeValue == 'Short-Term'" class="row mx-0 mt-5 g-3 align-items-start">
                                        <div class="col-4" *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.minimum_hours !== oldSpaceData.minimum_hours)">
                                            <div class="text-muted">Minimum Hours</div>
                                                <span *ngIf="(viewItem=='Edit' && createSpace.minimum_hours !== oldSpaceData.minimum_hours)">
                                                    <p>Old value: {{oldSpaceData.minimum_hours/60}} </p>
                                                    <p>New value: {{createSpace.minimum_hours/60}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                <p>{{createSpace.minimum_hours/60}} Hours</p>
                                                </span>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-muted"
                                                *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)">
                                                Booking Type</div>
                                            <div class="row">
                                                <div class="col-md-6"
                                                    *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)">
                                                    <div class="form-check d-flex align-items-center pl-0">
                                                        <span
                                                            *ngIf="(viewItem=='Edit' && createSpace.c !== oldSpaceData.isInstant)">
                                                            <p class="form-control h-100">Old value: {{oldSpaceData.isInstant=='0' ? 'Request to Book' : 'Instant Book'}} </p>
                                                            <p class="form-control h-100 mt-4">New value: {{createSpace.isInstant=='0' ? 'Request to Book' : 'Instant Book'}}</p>
                                                        </span>
                                                        <span *ngIf="(viewItem!='Edit')">
                                                            <p class="form-control">{{createSpace.isInstant=='0' ? 'Request to Book' :
                                                                'Instant Book'}}</p>
                                                        </span>
                                                    </div>
                                                    <!-- <div *ngIf="(viewItem=='Edit' && createSpace.isInstant !== oldSpaceData.isInstant)"
                                                        class="my-3 border border-2 rounded p-3">
                                                        <p class="form-control h-100">Old value: {{oldSpaceData.isInstant=='0' ? 'Select Request to Book option for spaces where real-time availability cannot be guaranteed and requires host approval. Guests will need to submit a booking request, which you will review and approve before finalizing the reservation.' : 'Choose Instant Book option for spaces that can be booked instantly without needing host approval. Guests can secure their booking immediately once they complete the reservation process.'}} </p>
                                                        <p class="form-control h-100 mt-4">New value: {{createSpace.isInstant=='0' ? 'Select Request to Book option for spaces where real-time availability cannot be guaranteed and requires host approval. Guests will need to submit a booking request, which you will review and approve before finalizing the reservation.' : 'Choose Instant Book option for spaces that can be booked instantly without needing host approval. Guests can secure their booking immediately once they complete the reservation process.'}}</p>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2"
                                            *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                            <div class="text-muted"> Duration </div>
                                            <div class="mt-2 text-muted px-5 py-2 bg-light rounded border border-2">
                                                Hourly</div>
                                        </div>
                                        <div class="col-4"
                                            *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                            <div class="text-muted">Price</div>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                <p class="form-control h-100">Old value: {{oldSpaceData.price}} </p>
                                                <p class="form-control h-100 mt-4">New value: {{createSpace.price}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.price}}</p>
                                            </span>
                                        </div>


                                    </div>
                                <!-- </ng-template> -->
                                <!-- <ng-template *ngIf="createSpace?.spacetypeValue == 'Coworking'" class="mt-4"> -->
                                    <div *ngIf="createSpace?.spacetypeValue == 'Coworking'" class="row">
                                        <ng-container *ngIf="createSpace?.spacetypeValue == 'Coworking'">
                                            <div class="col-md-6" *ngFor="let resource_type of resource_types">
                                                <div class="form-group" *ngIf="resource_type.id!=8">
                                                    <label
                                                        *ngIf="createSpace?.[resource_type.column_name] !== oldSpaceData?.[resource_type.column_name]">{{resource_type.name}}
                                                        price starts at</label>
                                                    <span
                                                        *ngIf="(viewItem=='Edit' && createSpace?.[resource_type.column_name] !== oldSpaceData?.[resource_type.column_name])">
                                                        <p class="form-control h-100">Old value: {{oldSpaceData?.[resource_type.column_name] ?? 0}}
                                                        </p>
                                                        <p class="form-control h-100 mt-4">New value: {{createSpace?.[resource_type.column_name] ?? 0}}
                                                        </p>
                                                    </span>
                                                    <span *ngIf="(viewItem!='Edit')">
                                                        <p class="form-control">{{createSpace?.[resource_type.column_name]}}</p>
                                                    </span>
                                                </div>
                                                <div class="form-group" *ngIf="this.createSpace.price !== oldSpaceData.price && resource_type.id==8">
                                                    <div class="row mx-0">
                                                        <div class="col-12">
                                                            <div class="text-muted">Day Pass Price</div>
                                                            <span
                                                                *ngIf="(viewItem=='Edit' && createSpace?.[resource_type.column_name] !== oldSpaceData?.[resource_type.column_name])">
                                                                <p class="form-control h-100">Old value: {{oldSpaceData?.[resource_type.column_name] ?? 0}}
                                                                </p>
                                                                <p class="form-control h-100 mt-4">New value: {{createSpace?.[resource_type.column_name] ?? 0}}
                                                                </p>
                                                            </span>
                                                            <span *ngIf="(viewItem!='Edit')">
                                                                <p class="form-control h-100">{{createSpace?.[resource_type.column_name]}}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                <!-- </ng-template> -->
                                <!-- <ng-template *ngIf="createSpace?.spacetypeValue == 'Long-Term'" class="mt-4"> -->
                                    <div *ngIf="createSpace?.spacetypeValue == 'Long-Term'" class="row">
                                        <div class="col-md-12"
                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                            <div class="form-group">
                                                <label>What is your expected monthly rental? <span
                                                        class="text-danger">*</span></label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.price !== oldSpaceData.price)">
                                                    <p class="form-control">Old value: {{oldSpaceData.price}} </p>
                                                    <p class="form-control">New value: {{createSpace.price}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace?.price}}</p>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12"
                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.securityDeposit !== oldSpaceData.securityDeposit)">
                                            <div class="form-group">
                                                <label>How many months of security deposit are you
                                                    expecting? <span class="text-danger">*</span> </label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.securityDeposit !== oldSpaceData.securityDeposit)">
                                                    <p class="form-control">Old value: {{oldSpaceData.securityDeposit}} </p>
                                                    <p class="form-control">New value: {{createSpace.securityDeposit}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace?.securityDeposit}}</p>
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-md-12"
                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.negociable_price !== oldSpaceData.negociable_price)">
                                            <div class="form-group">
                                                <label>Negotiable</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.negociable_price !== oldSpaceData.negociable_price)">
                                                    <p class="form-control">Old value: {{oldSpaceData.negociable_price}} </p>
                                                    <p class="form-control">New value: {{createSpace.negociable_price}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace?.negociable_price}}</p>
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceCarpetAreaPrice !== oldSpaceData.spaceCarpetAreaPrice)">
                                            <div class="form-group">
                                                <label>Price / sqft (carpet)</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.spaceCarpetAreaPrice !== oldSpaceData.spaceCarpetAreaPrice)">
                                                    <p class="form-control">Old value: {{oldSpaceData.spaceCarpetAreaPrice}} </p>
                                                    <p class="form-control">New value: {{createSpace.spaceCarpetAreaPrice}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace?.spaceCarpetAreaPrice}}</p>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.spaceCarpetAreaPrice !== oldSpaceData.spaceCarpetAreaPrice)">
                                            <div class="form-group">
                                                <label>Price / sqft (built-up)</label>
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.spaceBuiltUpAreaPrice !== oldSpaceData.spaceBuiltUpAreaPrice)">
                                                    <p class="form-control">Old value: {{oldSpaceData.spaceBuiltUpAreaPrice}} </p>
                                                    <p class="form-control">New value: {{createSpace.spaceBuiltUpAreaPrice}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace?.spaceBuiltUpAreaPrice}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                <!-- </ng-template> -->
                            </div>
                            <div class="mb-5 pt-5"
                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && newSpaceServiceArray!=oldSpaceServiceArray">
                                <a class="main-labels"><i class="fe fe-home me-2"></i> Add on services:</a>
                                <div *ngIf="viewItem == 'Edit' && newSpaceServiceArray != oldSpaceServiceArray" class="mt-4">
                                    <div class="row mx-0">
                                        <div class="col-md-12">
                                            <h3 class="mt-4">Old Values:</h3>
                                            <table>
                                                <tr>
                                                    <th>S.No:</th>
                                                    <th>Service Name:</th>
                                                    <th>Service Price:</th>
                                                    <th>Service Type:</th>
                                                </tr>
                                                <tr *ngFor="let details of oldSpaceServiceArrayByLoop.slice(0); let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{details?.serviceName}}</td>
                                                    <td>{{details?.servicePrice}}</td>
                                                    <td>{{details?.servicePriceType}}</td>
                                                </tr> 
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="viewItem == 'Edit' && newSpaceServiceArray != oldSpaceServiceArray" class="mt-4">
                                    <div class="row mx-0">
                                        <div class="col-md-12">
                                            <h3 class="mt-4">New Values:</h3>
                                            <table>
                                                <tr>
                                                    <th>S.No:</th>
                                                    <th>Service Name:</th>
                                                    <th>Service Price:</th>
                                                    <th>Service Type:</th>
                                                </tr>
                                                <tr *ngFor="let details of newSpaceServiceArrayByLoop.slice(0); let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{details?.serviceName}}</td>
                                                    <td>{{details?.servicePrice}}</td>
                                                    <td>{{details?.servicePriceType}}</td>
                                                </tr> 
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="mb-5 pt-5"
                                *ngIf="createSpace?.spacetypeValue !== 'Long-Term' && createSpace?.spacetypeValue !== 'Coworking' && showCancellation">
                                <a class="main-labels"><i class="fe fe-home me-2"></i> Cancellation Policy:</a>
                                <div class="row mx-0 mt-4">
                                    <div class="col-md-12">
                                        <h5 class="mb-2">Select cancellation policy</h5>
                                        <div class="form-group"></div>
                                        <p class="form-control"
                                            *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.cancellationPolicy !== oldSpaceData.cancellationPolicy)">
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.cancellationPolicy !== oldSpaceData.cancellationPolicy)">

                                                <p class="form-control">Old value: {{oldSpaceData.cancellationPolicy}} </p>
                                                <p class="form-control" [innerHTML]="oldSpaceData.cancellationPolicyDescription ?? 'N/A'">Old
                                                    value description:</p>

                                                <p class="form-control">New value: {{createSpace.cancellationPolicy}}</p>
                                                <p class="form-control" [innerHTML]="createSpace.cancellationPolicyDescription">New value
                                                    description:</p>

                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.cancellationPolicy}}</p>
                                                <p class="form-control">{{createSpace.cancellationPolicyDescription}}</p>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5 pt-5" *ngIf="this.createSpace?.images && this.createSpace.images.length > 0 && (this.viewItem=='Edit' && imageDifference.length>0 || imageIndexingDifference)">
                                <a class="main-labels mb-5"><i class="fe fe-home me-2"></i>Upload Photos:</a>
                                <div class="row mt-4 mx-0">
                                    <div class="mb-5 pt-5" *ngIf="createSpace?.images && createSpace.images.length > 0"
                                        class="image-container">
                                        <span *ngIf="(viewItem=='Edit' && imageDifference.length>0 || imageIndexingDifference)">
                                            <p class="pt-3">Old Images: </p>
                                            <span class="row align-items-baseline">
                                            <span class="col-2 text-center" *ngFor="let element of oldDataImages ;let i = index ">
                                                <img class="item w-100" [src]="imageUrl+'/'+element" alt="Details image">
                                                <strong *ngIf="i==0">Cover Image</strong>
                                            </span>
                                            </span>
                                            <p class="pt-3">New Images: </p>
                                            <span class="row align-items-baseline">
                                            <span class="col-2 text-center" *ngFor="let element of createSpace.images;let i = index">
                                                <img class="item w-100" [src]="imageUrl+'/'+element" alt="Details image">
                                                <strong *ngIf="i==0">Cover Image</strong>
                                            </span>
                                            </span>
                                        </span>
                                        <span *ngIf="(viewItem!='Edit')">
                                            <span *ngFor="let element of createSpace.images">
                                                <img class="item" [src]="imageUrl+'/'+element" alt="Details image">
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <div *ngIf="createSpace?.youtube_url" class="col-md-12">
                                        <div class="form-group">
                                            <label>Youtube Url</label>
                                            <input type="text" name="youtube_url" [(ngModel)]="createSpace.youtube_url"
                                                class="form-control filter-input" placeholder="Enter youtube url">
                                        </div>

                                    </div> -->
                                </div>

                            </div>
                            <div class="mb-5 pt-5" *ngIf="showContactDetails">
                                <a class="main-labels"><i class="fe fe-home me-2"></i>Additional Contact
                                    Details:</a>
                                <div class="row mx-0 mt-4 content">
                                    <div class="col-md-12"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.notificationemail !== oldSpaceData.notificationemail)">
                                        <div class="form-group">
                                            <label class="mb-2">When someone contacts you though your page, which email
                                                address(s) do you want your messages, sent to? <span
                                                    class="text-danger">*</span> </label>

                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.notificationemail !== oldSpaceData.notificationemail)">
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.notificationemail !== oldSpaceData.notificationemail)">
                                                    <p class="form-control">Old value: {{oldSpaceData.notificationemail}} </p>
                                                    <p class="form-control">New value: {{createSpace.notificationemail}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.notificationemail}}</p>
                                                </span>
                                            </span>

                                        </div>
                                    </div>
                                    <div class="col-md-12"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.notificationemail2 !== oldSpaceData.notificationemail2)">
                                        <div class="form-group">
                                            <label class="mb-2">Notification Email 2</label>

                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.notificationemail2 !== oldSpaceData.notificationemail2)">
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.notificationemail2 !== oldSpaceData.notificationemail2)">
                                                    <p class="form-control">Old value: {{oldSpaceData.notificationemail2}} </p>
                                                    <p class="form-control">New value: {{createSpace.notificationemail2}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.notificationemail2}}</p>
                                                </span>
                                            </span>

                                        </div>
                                    </div>
                                    <div class="col-md-12"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.notificationphone !== oldSpaceData.notificationphone)">
                                        <div class="form-group">
                                            <label class="mb-2">Notification Phone</label>

                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.notificationphone !== oldSpaceData.notificationphone)">
                                                <span
                                                    *ngIf="(viewItem=='Edit' && createSpace.notificationphone !== oldSpaceData.notificationphone)">
                                                    <p class="form-control">Old value: {{oldSpaceData.notificationphone}} </p>
                                                    <p class="form-control">New value: {{createSpace.notificationphone}}</p>
                                                </span>
                                                <span *ngIf="(viewItem!='Edit')">
                                                    <p class="form-control">{{createSpace.notificationphone}}</p>
                                                </span>
                                            </span>

                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactName !== oldSpaceData.contactName)">
                                        <div class="form-group">
                                            <label>Contact Person Name<span class="text-danger">*</span></label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.contactName !== oldSpaceData.contactName)">
                                                <p class="form-control">Old value: {{oldSpaceData.contactName}} </p>
                                                <p class="form-control">New value: {{createSpace.contactName}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.contactName}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactemail !== oldSpaceData.contactemail)">
                                        <div class="form-group">
                                            <label>Contact Person Email</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.contactemail !== oldSpaceData.contactemail)">
                                                <p class="form-control">Old value: {{oldSpaceData.contactemail}} </p>
                                                <p class="form-control">New value: {{createSpace.contactemail}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.contactemail}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.contactmobile !== oldSpaceData.contactmobile)">
                                        <div class="form-group">
                                            <label>Contact Person Mobile</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.contactmobile !== oldSpaceData.contactmobile)">
                                                <p class="form-control">Old value: {{oldSpaceData.contactmobile}} </p>
                                                <p class="form-control">New value: {{createSpace.contactmobile}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.contactmobile}}</p>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_name !== oldSpaceData.alternate_contact_name)">
                                        <div class="form-group">
                                            <label>Alternate Person Contact Name</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_name !== oldSpaceData.alternate_contact_name)">
                                                <p class="form-control">Old value: {{oldSpaceData.alternate_contact_name}} </p>
                                                <p class="form-control">New value: {{createSpace.alternate_contact_name}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.alternate_contact_name ?? 'N/A'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_email !== oldSpaceData.alternate_contact_email)">
                                        <div class="form-group">
                                            <label>Alternate Person Contact Email</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_email !== oldSpaceData.alternate_contact_email)">
                                                <p class="form-control">Old value: {{oldSpaceData.alternate_contact_email}} </p>
                                                <p class="form-control">New value: {{createSpace.alternate_contact_email}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.alternate_contact_email ?? 'N/A'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="viewItem != 'Edit' || (viewItem=='Edit' && createSpace.alternate_contact_mobile !== oldSpaceData.alternate_contact_mobile)">
                                        <div class="form-group">
                                            <label>Alternate Person Contact Mobile</label>
                                            <span
                                                *ngIf="(viewItem=='Edit' && createSpace.alternate_contact_mobile !== oldSpaceData.alternate_contact_mobile)">
                                                <p class="form-control">Old value: {{oldSpaceData.alternate_contact_mobile}} </p>
                                                <p class="form-control">New value: {{createSpace.alternate_contact_mobile}}</p>
                                            </span>
                                            <span *ngIf="(viewItem!='Edit')">
                                                <p class="form-control">{{createSpace.alternate_contact_mobile ?? 'N/A'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="div">
                                <div *ngIf="viewItem == 'Create'" class="d-flex align-items-center">
                                    <button style="background-color: green;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;" 
                                        aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewStatus()">
                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                   Approve
                                    </button>
                                    <button style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"  aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewRejectStatus()">
                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                   Reject
                                    </button>
                                </div>
                                <div *ngIf="viewItem == 'Delete'" class="d-flex align-items-center">
                                    <button style="background-color: green;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;" 
                                        aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewStatus()">
                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                        Approve
                                    </button>
                                    <button style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"  aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewRejectStatus()">
                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                   Reject
                                    </button>
                                </div>
                                <div *ngIf="viewItem == 'Edit'" class="d-flex align-items-center"> 
                                   
                                    <button style="background-color: green;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;" 
                                        aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewStatus()">
                                        <i class="me-2 fa-solid fa-check text-white"></i>
                                        Approve
                                    </button>
                                    <button style="margin-left: 15px;background-color: red;width: 130px;padding: 12px 10px;display: flex;align-items: center;justify-content:center; color: #fff; border-radius: 5px; border:none;"  aria-label="Example icon button with a menu icon"
                                        (click)="updateReviewRejectStatus()">
                                        <i class="me-2 fa-solid fa-xmark text-white"></i>
                                    Reject
                                    </button>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>