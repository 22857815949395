<div>
  <div>
    <label *ngIf="action_type == 1" class="top-label">Add Manager details</label>
    <label *ngIf="action_type == 2" class="top-label">Update Manager details</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="d-flex justify-content-center">
    <img class="mx-auto w-200 img-fluid" [src]="final_url">
  </div>
  <form #spaceForm="ngForm" (ngSubmit)="addOrUpdate()" class="space-form">
    <div class="d-flex mt-3">
      <label class="mr-5">Profile Photo</label>
      <input type="file" name="file" id="file" (change)="fileChangeEvent($event)" accept="image/jpeg, image/png">
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Name</mat-label>
          <input type="string" matInput placeholder="Name" name="name" [(ngModel)]="manager.name">
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Email</mat-label>
          <input 
            type="email" 
            matInput 
            placeholder="Email" 
            name="email" 
            [(ngModel)]="manager.email"
            #emailInput="ngModel"
            required
            email
          >
          <!-- Error Messages -->
        </mat-form-field>
        <mat-error *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
          <span *ngIf="emailInput.errors?.required">Email is required</span>
          <span *ngIf="emailInput.errors?.email">Please enter a valid email address</span>
        </mat-error>
      </div>
      
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Mobile</mat-label>
          <input 
            type="text" 
            matInput 
            placeholder="Mobile" 
            name="mobile" 
            [(ngModel)]="manager.mobile"
            #mobileInput="ngModel"
            required
            minlength="10"
            maxlength="10"
            pattern="^[0-9]*$"
            onkeypress="return /[0-9 ]/i.test(event.key)"
          >
          <!-- Error Messages -->
        </mat-form-field>
        <mat-error *ngIf="mobileInput.invalid && (mobileInput.dirty || mobileInput.touched)">
          <span *ngIf="mobileInput.errors?.required">Mobile number is required</span>
          <span *ngIf="mobileInput.errors?.minlength">Mobile number must be 10 digits</span>
          <span *ngIf="mobileInput.errors?.maxlength">Mobile number must be 10 digits</span>
          <span *ngIf="mobileInput.errors?.pattern">Mobile number must be numeric</span>
        </mat-error>
      </div>
      
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Select Department</mat-label>
          <mat-select [(ngModel)]="manager.departmentId" name="dept_id">
            <mat-option *ngFor="let dept of department" [value]="dept.id">
              {{dept.departmentName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>



    <div class="row"></div>
    <mat-form-field class="space-small-form-inputs" appearance="outline" *ngIf="action_type == 2">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="manager.status" name="status">
        <mat-option *ngFor="let status of statusArray" [value]="status.id">
          {{status.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="text-right">
      <button class="btn btn-primary-light" type="submit" [disabled]="!spaceForm.form.valid">Submit</button>
    </div>
  </form>
</div>