import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';
import { ConfirmDialogBoxComponent } from '../confirm-dialog-box/confirm-dialog-box.component';
import { ConfirmRejectDialogBoxComponent } from '../confirm-reject-dialog-box/confirm-reject-dialog-box.component';

@Component({
  selector: 'app-space-request-edit',
  templateUrl: './space-request-edit.component.html',
  styleUrls: ['./space-request-edit.component.css'],
  providers: [DatePipe, MatDialog]
})
export class SpaceRequestEditComponent {

  displayedColumns = [
    // "checkbox",
    "Serial_no",
    "createdAt",
    "space_name",
    "user_name",
    // "rating",
    // "Review",
    "status",
    "rejection_reason",
    "rejection_date",
    "approved_date"
  ];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator: MatPaginator; 
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  startDate: any;
  endDate: any;
  selectedStars = [];
  space = [];
  spaceId: any;
  spaceName: any;
  transactionHistory: any[] = [];
  status = "pending";
  country: string;
  category: string;
  state: string;
  city: string;
  ids: any[] = [];
  allIds: any[] = [];
  allComplete: boolean = false;
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  viewItem:String = 'Edit';

  constructor(
    private datePipe: DatePipe,
    private _spaceService: SpaceService,
    private queryService: QueriesService,
    private router: Router,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAllReviews();
    this.getAllSpaces();
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
  }

  getStar(event: any) {
    this.selectedStars = event;
  }

  getSpace(event: any) {
    this.spaceId = event;
  }

  getAllSpaces() {
    this._spaceService
      .filterSpace(
        this.country ?? "",
        this.category ?? "",
        this.state ?? "",
        this.city ?? ""
      )
      .subscribe((res: any) => {
        this.space = res.data;
      });
  }

  getAllReviews() {
    let data = {
      spaceId: this.spaceId,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      // selectedStars: this.selectedStars,
    };

    this._spaceService.spaceUpdateRequest(data).then(
      (res: any) => {
        this.transactionHistory = [];
        this.transactionHistory = res?.data?.data;
        console.log(this.transactionHistory)
        for (let i = 0; i < this.transactionHistory.length; i++) {
          this.transactionHistory[i].completed = false;
        }
        this.dataSource = new MatTableDataSource(this.transactionHistory);
        this.dataSource.paginator = this.paginator;
        this.cd.detectChanges();
      },
      (error) => {
        if (error.status === 500) {
          this.router.navigate(["/auth/login"]);
        }
      }
    );
  }

  public changeStatus(event: any) {
    let status = event.tab.textLabel;
    if (status == "Approved") {
      this.status = "approved";
    } else if (status == "Rejected") {
      this.status = "rejected";
    } else if (status == "Pending") {
      this.status = "pending";
    }
    this.getAllReviews();
  } 

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator = this.paginatorPageSize;
  } 

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updateReviewStatus(id: any) {   
    alert(1)
    const dialogRef = this.dialog.open(ConfirmDialogBoxComponent,{data:{show:false}});
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.getReviewStatus([id], "1");
      }
    });
  }

  redirectViewSpaces(id:any){
    if (this.queryService.hasPermission('Space Request', 'edit')) {
      this.router.navigateByUrl("/home/view-update-space/"+this.viewItem+"/"+id);
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  updateReviewRejectStatus(id: any) { 
    const dialogRef = this.dialog.open(ConfirmRejectDialogBoxComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.getReviewStatus([id], "0", result);
      }
    });
  }

  getReviewStatus(id: any[], value: any, reason?:any) {
    this._spaceService.spaceEditApproveRequest(id, value, reason).then(
      (res: any) => {
        this.openSnackBar(res?.data?.message, "Dismiss");
        this.getAllReviews();
        this.ids = []
      },
      (error) => {
        if (error.status === 500) {
          this.router.navigate(["/auth/login"]);
        }
      }
    );
  }

  updateAllComplete(name) {
    const index = this.ids.indexOf(name);
    if (index !== -1) {
      this.ids.splice(index, 1); 
    } else {
      this.ids.push(name);
    }
    
    if(this.transactionHistory?.length === this.ids?.length){
      this.allComplete = true;
    }else{
        this.allComplete = false;
    }
  }

  setAll(completed: boolean) {
    console.log(completed);
    if(completed){
      this.ids = [];
      this.allComplete = completed;
      if (this.transactionHistory == null) {
        return;
      }
      // this.transactionHistory.forEach(t => (t.completed = completed));
      // this.transactionHistory.forEach(item => {
      //   this.ids.push(item.id)
      //   item.checked = true;
      // });

      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = Math.min(startIndex + this.paginator.pageSize, this.dataSource.data.length);

      for (let i = startIndex; i < endIndex; i++) {
        const item = this.dataSource.data[i];
        item.completed = completed;
        this.ids.push(item.id);
        item.checked = true;
      }
      
      console.log(this.ids);

    } else {
      this.ids = [];
      this.allComplete = completed;

      // this.transactionHistory.forEach(t => (t.completed = completed));
      // this.transactionHistory.forEach(item => {
      //   item.checked = false;
      // });

      for (let i = this.paginator.pageIndex * this.paginator.pageSize;
        i < Math.min((this.paginator.pageIndex + 1) * this.paginator.pageSize, this.dataSource.data.length);
        i++) {
        
        const item = this.dataSource.data[i];
        item.completed = completed;
        item.checked = false;
      }
    }
  }
  // updateSingleComplete(name): void {
  //   const index = this.ids.indexOf(name);
  //   if (index !== -1) {
  //     this.ids.splice(index, 1);
  //     this.allComplete = false;
  //   } else {
  //     this.ids.push(name);
  //   }
  //   console.log(this.ids);
  // }

  // setAllComplete() {
  //   this.ids = [];
  //   for (let i = 0; i < this.transactionHistory.length; i++) {
  //     if (this.transactionHistory[i].completed == false) {
  //       this.ids.push(this.transactionHistory[i].spaceName+i);
  //       this.transactionHistory[i].completed = true;
  //       this.allComplete = false;
  //     } else {
  //       this.allComplete = true;
  //       this.ids = [];
  //       this.transactionHistory[i].completed = false;
  //     }
  //   }
  //   console.log(this.ids);
  // }

  approveWithCheckbox() {
    for (let i = 0; i < this.transactionHistory.length; i++) {
      if (this.transactionHistory[i].completed == true) {
        this.ids.push(this.transactionHistory[i].id)
      } 
    } 
    const uniqueArray = Array.from(new Set(this.ids));

    console.log(uniqueArray);
    if(this.ids.length == 0){
      this.openSnackBar('Please select atleast one review', "Dismiss");
    } else{
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent,{data:{show:false}});
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.allComplete = false;
          this.getReviewStatus(this.ids, "1");
        }
      });
    }
  }

  rejectWithCheckbox() {
    for (let i = 0; i < this.transactionHistory.length; i++) {
      if (this.transactionHistory[i].completed == true) {
        this.ids.push(this.transactionHistory[i].id)
      } 
    }
    const uniqueArray = Array.from(new Set(this.ids));
    console.log(uniqueArray)

    if(this.ids.length == 0){
      this.openSnackBar('Please select atleast one review', "Dismiss");
    } else{
      const dialogRef = this.dialog.open(ConfirmRejectDialogBoxComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.allComplete = false;
          this.getReviewStatus(this.ids, "0", result);
        }
      });
    }
  }

}
