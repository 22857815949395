<div *ngIf="type == 'delete'">
  <div style="margin-bottom: 15px">
    <label class="top-label">Delete Space</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div>
    Are you sure you want to delete Space.
    <br><br>
    <p>Write "DELETE" to confirm</p>
    <input class="form-control" type="text" [(ngModel)]="confirm" [ngModelOptions]="{standalone: true}">
    <div class="text-end mt-5">
    <button mat-raised-button color="primary" class="me-3" (click)="delete()">Confirm</button>
    <button mat-raised-button (click)="closeDialog(null)">Cancel</button>
    </div>
  </div>
</div>

<ng-container *ngIf="type == 'ribbon'">
  <div style="margin-bottom: 15px;width: 500px;">
    <label class="top-label">Select Ribbon</label>
    <i class="cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="space_dropdown">
    <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
      [(ngModel)]="selected" [placeholder]="selected ? '' : 'Select ribbon'" (change)="selectRibbon()">
      <ng-option *ngFor="let ribbon of ribbonList" [value]="ribbon.id">{{ribbon.title}}</ng-option>
    </ng-select>
    <mat-error *ngIf="!selection">
      Ribbon selection is required
    </mat-error>
  </div>
  <div class="text-end mt-4"><button type="submit" class="btn btn-primary-light"
      (click)="assignRibbon()">Assign</button></div>
</ng-container>

<ng-container *ngIf="type == 'paywall'">
  <div style="margin-bottom: 15px;width: 500px;">
    <label class="top-label">Select paywall</label>
    <i class="cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="space_dropdown">
    <ng-select class="form-select drop_down form-control mt-3" style="background-color: transparent;"
      [(ngModel)]="paywallSelected" [placeholder]="paywallSelected ? '' : 'Select paywall plan'" (change)="selectPaywall()">
      <ng-option *ngFor="let plans of plansList" [value]="plans">{{plans.name}}</ng-option>
    </ng-select>
    <mat-error *ngIf="!paywallSelection">
      Plan selection is required
    </mat-error>
  </div>
  <div class="text-end mt-4"><button type="submit" class="btn btn-primary-light"
      (click)="assignPaywall()">Assign</button></div>
</ng-container>

<ng-container *ngIf="type == 'tier'">
  <div style="margin-bottom: 15px;width: 500px;">
    <label class="top-label">Select Tier</label>
    <i class="cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="space_dropdown">
    <ng-select (change)="selectTier()" [(ngModel)]="tierSelected" [placeholder]="tierSelected ? '' : 'Select Tier'" class="form-select drop_down form-control mt-3" style="background-color: transparent;">
      <ng-option value="99" disabled="true">Select Tier</ng-option>
      <ng-option value="1">Tier 1</ng-option>
      <ng-option value="2">Tier 2</ng-option>
      <ng-option value="3">Tier 3</ng-option>
    </ng-select>
    <mat-error *ngIf="!tierSelection">
      Tier selection is required
    </mat-error>
  </div>
  <div class="text-end mt-4"><button type="submit" class="btn btn-primary-light" (click)="assignTier()">Assign</button></div>
</ng-container>