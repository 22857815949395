<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">User List</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading" style="padding: 0; border: none;">
                        <div class="tabs-menu radius-tab">
                            <ul ngbNav #nav3="ngbNav" [activeId]="activeTab" class="nav panel-tabs panel-info">
                                <li [ngbNavItem]="0">
                                    <ng-template ngbNavContent>
                                        <div class="row ">
                                            <div class="search_meta" style="display: flex; 
                                            justify-content: flex-end; 
                                            align-items: center; 
                                            width: 100%; 
                                            padding: 10px; 
                                            box-sizing: border-box;">

                                                <mat-form-field appearance="outline">
                                                    <mat-label>Search</mat-label>
                                                    <input matInput (keyup)="applyFilter($event.target.value)"
                                                        placeholder="Enter your search term"
                                                        style="border: none; padding: 8px; box-sizing: border-box;">
                                                </mat-form-field>
                                            </div>

                                            <div class="col-md-12">

                                                <table mat-table [dataSource]="dataSource" matSort="sort"
                                                    class="tableContainer w-100">

                                                    <ng-container matColumnDef="position">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> S. No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 +
                                                            (paginator?.pageIndex
                                                            *
                                                            paginator?.pageSize) }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="addedAt">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date &
                                                            Time
                                                        </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.added_at | date:
                                                            'dd-MM-yyyy'}}
                                                            {{row.added_at | date: 'hh:mma'}} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name
                                                        </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.firstName }} {{
                                                            row.lastName }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="company_name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company
                                                            Name </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.companyName ?? 'N/A'
                                                            }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="phone_number">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone
                                                            Number </th>
                                                        <td mat-cell *matCellDef="let row">{{row.mobile ?? 'N/A'}}</td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">{{row.email ?? 'N/A'}}</td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="registrationType">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Registration Type
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">{{row.regType | titlecase}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="status">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                                        </th>
                                                        <td mat-cell *matCellDef="let row"><mat-slide-toggle
                                                                color="#f76900" [checked]="row.status == 'Active'"
                                                                (click)="openConfirmationDialog(row,$event)"></mat-slide-toggle>
                                                        </td>
                                                    </ng-container>


                                                    <ng-container matColumnDef="actions">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <button mat-mini-fab color="primary"
                                                                (click)="updateAdminProfile(row.id)"><i
                                                                    class="fa fa-edit" style="color: white;"
                                                                    aria-hidden="true"></i>
                                                            </button> &nbsp;
                                                            <!-- <button style="background-color: red;" mat-mini-fab (click)="delete(row.id)"><mat-icon><i style="color: white;" class="fa-solid fa-xmark"></i></mat-icon></button> -->
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                                    <tr class="mat-row" *matNoDataRow>
                                                        <td class="mat-cell" colspan="4">No data matching the filter "
                                                        </td>
                                                    </tr>

                                                </table>

                                                <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                    [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                    [length]="dataSource?.data.length">
                                                </mat-paginator>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-body tabs-menu-body">
                    <div [ngbNavOutlet]="nav3"></div>

                </div>
            </div>
        </div>
    </div>
</div>