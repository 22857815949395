<div class="container-fluid">

    <!-- <div class="container">
        <div class="form-container">
            <h2>Cancellation Form</h2>

            <form [formGroup]="cancellationForm" (submit)="getCancelFormValues()">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" [errorStateMatcher]="errorMatcher">
                    <mat-error *ngIf="cancellationForm.get('name').invalid && cancellationForm.get('name').touched">
                        Name is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Textarea</mat-label>
                    <textarea matInput formControlName="description" [errorStateMatcher]="errorMatcher"></textarea>
                    <mat-error
                        *ngIf="cancellationForm.get('description').invalid && cancellationForm.get('description').touched">
                        Description is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" [errorStateMatcher]="errorMatcher">
                        <mat-option value="inactive">Inactive</mat-option>
                        <mat-option value="active">Active</mat-option>
                    </mat-select>
                    <mat-error *ngIf="cancellationForm.get('status').invalid && cancellationForm.get('status').touched">
                        Status is required
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit">Basic</button>
            </form>
        </div>
    </div> -->
</div>


<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Amenities List</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading" style="padding: 0; border: none;">
                        <div class="tabs-menu radius-tab">
                            <ul ngbNav #nav3="ngbNav" [activeId]="activeTab"
                                class="nav panel-tabs panel-info">
                                <li [ngbNavItem]="0">
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <div class="search_meta" style="display: flex; 
                                            justify-content: flex-end; 
                                            align-items: center; 
                                            width: 100%; 
                                            padding: 10px; 
                                            box-sizing: border-box;">

                                                <mat-form-field appearance="outline">
                                                    <mat-label>Search</mat-label>
                                                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Enter your search term" style="border: none; padding: 8px; box-sizing: border-box;">
                                                </mat-form-field>
                                            </div>

                                            <div class="col-md-12">

                                                <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">

                                                    <ng-container matColumnDef="position">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial No. </th>
                                                        <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 + (paginator?.pageIndex
                                                            *
                                                            paginator?.pageSize) }} </td>
                                                    </ng-container>
                    
                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.amenities }} </td>
                                                    </ng-container>
                    
                                                    <ng-container matColumnDef="icon_name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Icon </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.icon }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="icon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Display </th>
                                                        <td mat-cell *matCellDef="let row"> <i class={{row.icon}}></i> </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="actions">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <button (click)="updateDetails(row.id)"  mat-mini-fab color="primary"><i class="fa-solid fa-pen" style="color: white;"></i></button> &nbsp;

                                                            <button style="background-color: red;" mat-mini-fab (click)="delete(row.id)"><mat-icon><i style="color: white;" class="fa-solid fa-xmark"></i></mat-icon></button>
                                                        </td>
                                                    </ng-container>
                    
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                                                    <tr class="mat-row" *matNoDataRow>
                                                        <td class="mat-cell" colspan="4">No data matching the filter "</td>
                                                    </tr>
                    
                                                </table>
                    
                                                <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                                    [showFirstLastButtons]="true" [length]="dataSource.data.length">
                                                </mat-paginator>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-body tabs-menu-body">
                    <div [ngbNavOutlet]="nav3"></div>

                </div>
            </div>
        </div>
    </div>
</div>
