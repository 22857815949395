import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { MemberService } from 'src/app/services/member.service';
import { QueriesService } from 'src/app/services/queries.service';
declare var google: any;
@Component({
  selector: 'app-update-lead',
  templateUrl: './update-lead.component.html',
  styleUrls: ['./update-lead.component.css']
})
export class UpdateLeadComponent implements OnInit, AfterViewInit {

  @ViewChild('autocomplete', { static: true }) autocompleteInput!: ElementRef;
  private autocomplete: any;
  id: any;
  public ref;
  public spaceForm: FormGroup;
  public no_of_persons = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21-50',
    '51-100',
    '100+',
  ];
  no_of_seats: any;
  lead_data: any;
  city: any;
  spaceTypeArray: any[];
  spaceTypeOptions = [
    'Private cabin',
    'Dedicated desk',
    'Flexi desk',
    'Private office',
    'Managed office',
    'Virtual office',
    'Hybrid work',
    'Day pass',
    'Customized space'
  ];
  tagsArray: any[];
  tagsOptions = [
    'Hot lead',
    'Urgent requirement',
    'High value',
    'Good budget',
    'Immediate closure',
    'Wants to visit ASAP',
    'Immediate start',
    'Option to leave blank'
  ];
  cityArray: any[];
  cityOptions = [
    'A',
    'B',
    'C'
  ];
  selectedLocations: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public dialogRef: MatDialogRef<any>, public snackBar: MatSnackBar, private loaderService: LoaderService, private _memberService: MemberService, private fb: FormBuilder, private _queriesservice: QueriesService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.id = this.data.id;
    // console.log(this.id)
    // if (this.id !== '00') {
    //   this.getLeadData(this.id);

    // } 

    this.getLeadData(this.id);
  }

  initializeAutocomplete() {
    const input = this.autocompleteInput.nativeElement;
    this.autocomplete = new google.maps.places.Autocomplete(input);
    this.autocomplete.addListener('place_changed', () => {
      const place: any = this.autocomplete?.getPlace();
      if (place.geometry) {
        console.log('Place:', place);
        this.spaceForm?.get('location').patchValue(place.formatted_address);
      } else {
        console.log('No details available for input: ' + place.name);
      }
    });
  }

  ngAfterViewInit() {
    this.initializeAutocomplete();
  }

  addLocation(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = '';
    }

    if (this.autocomplete.getPlace()) {
      const selectedPlace = this.autocomplete.getPlace();
      const selectedValue = selectedPlace.formatted_address;

      if (selectedValue && this.selectedLocations.indexOf(selectedValue) === -1) {
        this.selectedLocations.push(selectedValue);
        this.spaceForm.get('location').patchValue(this.selectedLocations);
        console.log(this.selectedLocations);
      }
    }
  }

  removeLocation(location: string): void {
    const index = this.selectedLocations.indexOf(location);

    if (index >= 0) {
      this.selectedLocations.splice(index, 1);
      this.spaceForm.get('location').value.splice(index, 1)
    }

    if (this.selectedLocations.length === 0) {
      this.spaceForm.get('location').setErrors({ 'required': true });
    }
  }

  getLeadData(id: any) {
    this._queriesservice
      .getLeadById(id).then((res) => {
        if (res.success) {
          this.lead_data = res.leads;

          this.spaceTypeArray = JSON.parse(this.lead_data?.spaceType) || [];
          this.tagsArray = JSON.parse(this.lead_data?.tags) || [];
          this.cityArray = JSON.parse(this.lead_data?.city_name) || [];
          this.no_of_seats = (this.lead_data?.number_of_seats).toString();
          this.initForm();

        } else {
          this.openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  initForm() {
    this.spaceForm = this.fb.group({
      location: ['', Validators.required],
      number_of_seats: ['', Validators.required],
      spaceType: [this.spaceTypeArray, Validators.required],
      approx_start: ['', Validators.required],
      tenure: ['', Validators.required],
      budget: ['', Validators.required],
      lead_price: ['', Validators.required],
      lead_source: ['', Validators.required],
      tags: [this.tagsArray, this.validateMaxSelections(2)],
      inquiryDescription: ['', Validators.required],
      city_name: [this.cityArray, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      userMobile: ['', [Validators.required, Validators.minLength(10)]],
      company_name: ['', Validators.required]
    })
  }

  submitForm() {
    console.log(this.spaceForm.value);
    this._memberService.updateLead(this.id, this.spaceForm.value)
      .then(res => {
        if (res && res.success) {
          this.openSnackBar('Lead Added Successfully', 'Dismiss');
          this.closeDialog(res);
        }
      })
      .catch(error => {
        this.openSnackBar("Some error occured", "Error");
        this.closeDialog(error);
      })
  }

  validateMaxSelections(max: number) {
    return (control) => {
      if (control.value && control.value.length > max) {
        return { maxSelections: true };
      }
      return null;
    };
  }

  isOptionDisabled(value: string): boolean {
    const selectedTags = this.spaceForm?.get('tags').value;
    return selectedTags.length >= 2 && !selectedTags.includes(value);
  }


  onSelectionChange() {
    const selectedTags = this.spaceForm?.get('tags').value;
    if (selectedTags.length > 2) {
      this.spaceForm?.get('tags').setValue(selectedTags.slice(0, 2), { emitEvent: false });
    }
  }

  get errorStateMatcher() {
    return {
      isErrorState: (control, form) => {
        const tagControl = this.spaceForm?.get('tags');
        const isInvalid = tagControl.invalid && (tagControl.dirty || tagControl.touched);
        const isMaxSelectionsError = tagControl.errors?.maxSelections;
        return isInvalid || isMaxSelectionsError;
      }
    };
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeDialog(options) {
    this.ref.close(options);
  }
}
