<div class="page-wrapper">
    <!--Sidebar Menu Starts-->

    <!--Sidebar Menu ends-->

    <!--Dashboard content Wrapper starts-->
    <div class="dash-content-wrap">
        <!--Dashboard breadcrumb starts-->
        <div class="dash-breadcrumb" style="padding: 25px 0 10px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dash-breadcrumb-content">
                            <div class="dash-breadcrumb-left">
                                <div class="breadcrumb-menu text-right sm-left">
                                    <!-- <p class="list-address">
                      Showing <b>{{ leads_data.length }}</b> results
                    </p> -->
                                </div>
                            </div>
                            <div>
                                <button class="btn v3" (click)="downloadCSV()">
                                    <i class="ion-plus-round"></i>Export to CSV</button>&nbsp;&nbsp;
                                <button class="btn v3" (click)="addLeadDialog()">
                                    <i class="ion-plus-round"></i>Add Lead
                                </button>
                            </div>
                            <!-- <a class="btn v3" href="add-listing.html"><i class="ion-plus-round"></i>Add Listing </a> -->
                        </div>
                        <!-- <div style="display: flex; justify-content: flex-end;">
  
              </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin: 8px;">
            <div class="col-lg-12">
                <div class="card tabs-menu-body">
                    <div class="card-header ps-2">
                      <h3 class="card-title">Filter Leads</h3>
                    </div>
                    <div class="row px-3 my-3">
                        <div class="col-md-3 ">
                            <ng-select [placeholder]="no_of_seats ? '' :  'Select No. of Seats'" #seatSelect class="form-select drop_down form-control mt-3 seats_select" (change)="getSeats($event)" [multiple]="true">
                                <ng-option value="1-5">1-5</ng-option>
                                <ng-option value="6-10">6-10</ng-option>
                                <ng-option value="11-20">11-20</ng-option>
                                <ng-option value="21-50">21-50</ng-option>
                                <ng-option value="51-100">51-100</ng-option>
                                <ng-option value="100+">100+</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <ng-select #budgetSelect [placeholder]="!budget ? 'Select Min Budget' : '' " class="form-select drop_down form-control mt-3"
                                (change)="getBudget($event)">
                                <ng-option *ngFor="let item of budgetData" [value]="item?.label">{{ item?.label }}</ng-option>
                            </ng-select>
                        </div>
                        
                        <div class="col-md-3">
                            <ng-select class="form-select drop_down form-control mt-3" [ngClass]="{ 'disabled-select': budget == '' }" #budgetSelect2 [placeholder]="!budgetMax ? 'Select Max Budget' : ''"
                              (change)="getMaxBudgetVal($event)">
                                <ng-option *ngFor="let item of maxBudgetAmount" [value]="item?.label">{{ item?.label }}</ng-option>
                            </ng-select>
                        </div>
                        
                        <div class="col-md-3">
                            <ng-select #citySelect [placeholder]="!city ? 'Select City' : ''" class="form-select drop_down form-control mt-3"
                                (change)="getCity($event)">
                                <ng-option *ngFor="let item of cityNames" [value]="item">{{ item }}</ng-option>
                            </ng-select>
                        </div>
                    
                      <div class="col-md-3 mt-3">
                        <mat-form-field class="example-full-width" appearance="fill" class=" calender w-100">
                          <input class="place_txt" a matInput [matDatepickerFilter]="startDateFilter" [(ngModel)]="startDate"
                            [matDatepicker]="start" placeholder="Start Date" (dateChange)="onStartDateChange($event)" class="date_input">
                            <i *ngIf="startDate" class="fa fa-times" aria-hidden="true" (click)="deleteDate(1)"></i> &nbsp;
                          <mat-datepicker-toggle matIconSuffix [for]="start" style="color: #f76900;"></mat-datepicker-toggle>
                          <mat-datepicker #start></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3 mt-3">
                        <mat-form-field appearance="fill" class="w-100">
                          <input matInput [matDatepickerFilter]="endDateFilter" [matDatepicker]="end" [(ngModel)]="endDate"
                            placeholder="End Date" (dateChange)="onEndDateChange($event)" class="date_input">
                            <i *ngIf="endDate" class="fa fa-times" aria-hidden="true" (click)="deleteDate(2)"></i> &nbsp;
                          <mat-datepicker-toggle matIconSuffix [for]="end" style="color: #f76900;"></mat-datepicker-toggle>
                          <mat-datepicker #end></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <span class="col-md-2 mt-3">
                        <button (click)="dateRangeChange()" class="btn btn-primary" type="button"><i class="fe fe-search"></i>
                          Filter</button>
                      </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="dash-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dash-bookings">
                            <div class="act-title col-md-12" style="display: flex; justify-content: space-between;">
                                <h5><i class="ion-ios-copy-outline"></i> Leads</h5>
                            </div>

                            <mat-tab-group (selectedTabChange)="changeStatusCoworking($event)">

                                <mat-tab [label]="pendingCount">
                                    <div class="div" style="display: flex; justify-content: end;">
                                        <input type="text" matInput (input)="applyFilter($event.target.value)"
                                            placeholder="Type to filter">
                                    </div>
                                    <div *ngIf="status == '0'">
                                        <div class="table-wrapper table_center">
                                            <table mat-table [dataSource]="dataSource" matSort
                                                class="tableContainer" style="width: 100% !important;">

                                                <ng-container matColumnDef="serial">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                                                </ng-container>                                                

                                                <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        style="width: 5%;"> Lead Id
                                                         </th>
                                                    <td style="width: 5%;" mat-cell
                                                        *matCellDef="let row"> {{ row.id
                                                        }}
                                                        <span *ngIf="row?.tags?.length" class="text-success">
                                                            <br>
                                                            {{row?.tags?.length ? row?.tags[0] : ''}}
                                                            <br>
                                                            {{row?.tags?.length ? row?.tags[1] : ''}}
                                                        </span>

                                                    </td>
                                                </ng-container>
                                                
                                                <ng-container matColumnDef="updatedAt">
                                                    <th style="width: 15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Date & Time
                                                    </th>
                                                    <td style="width: 15%;"  mat-cell *matCellDef="let row"> {{ row.updatedAt }} <br> <span
                                                            class="text-success">{{row.verifiedBy}}</span> </td>
                                                </ng-container>

                                                <ng-container matColumnDef="firstName">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > First Name
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.firstName}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="lastName">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Last Name
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.lastName}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="userEmail">
                                                    <th style="width:8%;" mat-header-cell *matHeaderCellDef mat-sort-header > User Email
                                                    </th>
                                                    <td style="width:8%;" mat-cell *matCellDef="let row" > {{row.userEmail}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="userMobile">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header > User Mobile
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row" > {{row.countryCode}} {{row.userMobile}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="location_name">
                                                    <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Location
                                                    </th>
                                                    <td style="width: 10%;text-transform: capitalize;" mat-cell *matCellDef="let row">
                                                        <span [innerHTML]="row.location_name?.join(', ')"></span>
                                                    </td>
                                                </ng-container>
                                                
                                                <ng-container matColumnDef="city_name">
                                                    <th style="width: 8%;" mat-header-cell *matHeaderCellDef mat-sort-header> City
                                                    </th>
                                                    <td  style="text-transform: capitalize;width: 8%;" mat-cell *matCellDef="let row">
                                                        <span [innerHTML]="row.city_name?.join(', ')"></span>
                                                    </td>
                                                </ng-container>
                                                
                                                <ng-container matColumnDef="number_of_seats">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header> No. of seats
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row"> {{row.number_of_seats}} </td>
                                                </ng-container>


                                                <ng-container matColumnDef="actions">
                                                    <th style="width: 32%;text-align: center;" mat-header-cell *matHeaderCellDef> Actions </th>
                                                    <td mat-cell *matCellDef="let row"
                                                        style="width: 32%;">
                                                        <div class="action_btn">
                                                        <button class="lead-btns" style="background-color: yellow;"
                                                            mat-mini-fab (click)="viewLeadDataDialog(row.id)">
                                                            <i class="fa fa-eye"></i> View</button>
                                                        <button class="lead-btns text-light"
                                                            style="background-color: red;" mat-mini-fab
                                                            (click)="deleteLeadDataDialog(row.id)"><i
                                                                class="fa fa-trash"></i> Delete</button>
                                                        
                                                        <button class="lead-btns" mat-mini-fab color="primary"
                                                            (click)="editLeadDataDialog(row.id,'pending')"><i
                                                                class="fa fa-edit"></i> Edit</button>
                                                        <button class="lead-btns text-light"
                                                            style="background-color: green;" mat-mini-fab
                                                            (click)="approveLeadDataDialog(row)"><i
                                                                class="fa fa-thumbs-up"></i> Approve</button>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4" style="text-align: center;">No data
                                                        found</td>
                                                </tr>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                </tr>

                                            </table>

                                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                [length]="dataSource?.data.length">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </mat-tab>

                                <mat-tab [label]="confirmCount">
                                    <div class="div" style="display: flex; justify-content: end;">
                                        <input type="text" matInput (input)="applyFilter($event.target.value)"
                                            placeholder="Type to filter">
                                    </div>
                                    <div *ngIf="status == '1'">
                                        <div class="table-wrapper table_center">
                                            <table mat-table [dataSource]="confirmedLeadDataSource" matSort="sort"
                                                class="tableContainer" style="width: 100% !important;">

                                                <ng-container matColumnDef="serial">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="id">
                                                    <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header
                                                        > Lead Id
                                                 </th>
                                                    <td style="width: 10%;" mat-cell
                                                        *matCellDef="let row"> {{ row.id }}
                                                        <span *ngIf="row?.tags?.length" class="text-success">
                                                            <br>
                                                            {{row?.tags?.length ? row?.tags[0] : ''}}
                                                            <br>
                                                            {{row?.tags?.length ? row?.tags[1] : ''}}
                                                        </span>
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="updatedAt">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> Date & Time
                                                    </th>
                                                    <td style="width: 7%;" mat-cell *matCellDef="let row"> {{ row.updatedAt }} <br> <span class="text-success">{{row.verifiedBy}}</span> </td>
                                                </ng-container>

                                                <ng-container matColumnDef="spaceName">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Space Name
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.spaceName}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="location_name">
                                                    <th style="width: 8%;"  mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Location
                                                    </th>
                                                    <td style="text-transform: capitalize;width:8%" mat-cell
                                                        *matCellDef="let row">
                                                        <span [innerHTML]="row.location_name?.join(', ')"></span>
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="city_name">
                                                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> City
                                                    </th>
                                                    <td style="text-transform: capitalize;width: 7%;" mat-cell
                                                        *matCellDef="let row">
                                                        <span [innerHTML]="row.city_name?.join(', ')"></span>
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="number_of_seats">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header> No. of seats
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row"> {{row.number_of_seats}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="spaceType">
                                                    <th style="width: 8%;" mat-header-cell *matHeaderCellDef
                                                        mat-sort-header> Space Type
                                                    </th>
                                                    <td style="width: 8%;" mat-cell *matCellDef="let row">
                                                        {{row.spaceType}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="approx_start">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Approx Start
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.approx_start}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="tenure">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Tenure </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.tenure}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="budget">
                                                    <th  style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Budget
                                                        (min-max) </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > 
                                                        {{row?.budget}}-{{row?.budgetMax}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="lead_source">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Lead Source
                                                    </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.lead_source}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="lead_price">
                                                    <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header > Lead Price
                                                     </th>
                                                    <td style="width: 5%;" mat-cell *matCellDef="let row" > {{row.lead_price}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="actions">
                                                    <th style="width:25%;text-align: center;" mat-header-cell *matHeaderCellDef > Actions </th>
                                                    <td  mat-cell *matCellDef="let row"
                                                        style="width:25%">
                                                        <div class="action_btn">
                                                        <button class="lead-btns" style="background-color: yellow;"
                                                            mat-mini-fab (click)="viewLeadDataDialog(row.id)"><i
                                                                class="fa fa-eye"></i> VIEW</button>
                                                        <!-- <button class="lead-btns text-light"
                                                            style="background-color: red;" mat-mini-fab
                                                            (click)="sendLeadDialog(row.id, row.city_name)">
                                                            <i class="fa fa-paper-plane text-white"></i>
                                                            SEND</button>
                                                        &nbsp; -->
                                                        <button class="lead-btns" mat-mini-fab color="primary" (click)="editLeadDataDialog(row.id,'confirmed')"><i class="fa fa-edit"></i>
                                                            Edit</button> 
                                                        <button class="lead-btns text-white" mat-mini-fab style="background-color: green;"
                                                            (click)="assignLeadDialog(row.id, row.city_name)"> <i
                                                                class="fa fa-clipboard-check text-white"></i>
                                                             ASSIGN</button>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4" style="text-align: center;">No data
                                                        found</td>
                                                </tr>
                                                <tr mat-header-row *matHeaderRowDef="confirmedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: confirmedColumns;">
                                                </tr>
                                            </table>

                                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
                                                [pageSize]="pageSize" [showFirstLastButtons]="true"
                                                [length]="confirmedLeadDataSource?.data.length">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Dashboard content ends-->
    <!--Dashboard footer ends-->
</div>
<!--Dashboard content Wrapper ends-->