<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Add lead Price</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="leadPriceForm">
          <div class="row g-3 panel-body tabs-menu-body">
            <div class="col-12">
              <label class="required form-label">Enter Probability Factor<span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input  formControlName="value" placeholder="Enter Probability Factor" type="number"
                  class="form-control" onwheel="this.blur()">
                <mat-error *ngIf="leadPriceForm.get('value').invalid && leadPriceForm.get('value').touched">
                  Probability factor is required
                </mat-error>
              </div>
            </div>
            <div class="btn-list mt-4">
              <a class="btn btn-primary-light" style="float: right;" (click)="submit()">
                Submit
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>