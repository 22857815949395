import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { QueriesService } from './services/queries.service';

export const permissionGuard: CanActivateFn = (route) => {
  const queryService = inject(QueriesService)
  const router = inject(Router)

  const moduleTitle = route.data['Title'];
  const type = route.data['Type'] || 'view';
  const hasViewPermission = queryService.hasPermission(moduleTitle, type);

  if (hasViewPermission) {
    return true;
  } else {
    queryService.accessDeniedAlert()
    router.navigate(['/home/dashboard'])
    return false;
  }
}


export const submodulePermissionGuard: CanActivateFn = (route) => {
  const queryService = inject(QueriesService);
  const router = inject(Router)

  const moduleTitle = route.data['Title'];
  const submoduleTitle = route.data['subTitle'];
  const type = route.data['Type'] || 'view';
  const hasViewPermission = queryService.hasSubmodulePermission(moduleTitle, submoduleTitle, type);

  if (hasViewPermission) {
    return true;
  } else {
    queryService.accessDeniedAlert()
    router.navigate(['/home/dashboard'])
    return false;
  }
};


