<!-- <app-page-header title="Wallet" active_item="{{spaceName}}"></app-page-header> -->

<!-- <div class="row row-sm">
    <div class="col-lg-12">
        <div class="card custom-card p-4">
            <h3>Filter your space rating</h3>
            <div class="booking-list-filter">
                <div class="row align-items-end">
                    <div class="col-md-3 form-group category mb-0">
                        <p class="mg-b-10" style="font-size: 15px;font-weight: 700;">Select type</p>
                        <ng-select class="form-select drop_down form-control pb-0 placeholder_stars select_box"
                            [placeholder]="spaceId ? '' : 'Select space'" [(ngModel)]="spaceId"
                            (change)="getSpace($event)">
                            <ng-option *ngFor="let item of space" [value]="item.id">
                                {{item.actual_name}} ({{item.hostEmail}})
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="col-md-3">
                        <p class="mg-b-10" style="font-size: 15px;font-weight: 700;">Select Date</p>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Enter a date</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input [(ngModel)]="startDate" (dateChange)="dateRangeChange();" (click)="picker.open()"
                                    matStartDate placeholder="Start date">
                                <input [(ngModel)]="endDate" (click)="picker.open()" (dateChange)="dateRangeChange();"
                                    matEndDate placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 form-group category mb-0">
                        <p class="mg-b-10">Select stars</p>
                        <ng-select [placeholder]="selectedStars?.length > 0 ? '' : 'Select stars'"
                            class="pb-0 placeholder_stars" [multiple]="true" (change)="getStar($event)">
                            <ng-option value="1"><i class="fa fa-star text-warning"></i></ng-option>
                            <ng-option value="2"><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i></ng-option>
                            <ng-option value="3"><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i></ng-option>
                            <ng-option value="4"><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i></ng-option>
                            <ng-option value="5"><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i><i class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i><i
                                    class="fa fa-star text-warning"></i></ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3">
                        <button (click)="getAllReviews()" class="btn btn-primary search-btn">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row row-sm">
    <div class="col-lg-12">
        <div class="card custom-card edit-card p-4">

            <div class="d-flex justify-content-between align-items-center">
                <div class="waletBalance">
                    <!-- <h2 class="pb-2" style="border-bottom: 1px solid #0000001f;">Reviews Listing of <b>{{spaceName}}</b>
                    </h2> -->
                </div>
                <div class="d-flex align-items-center">
                    <!-- <ul class="product-rating-c d-flex">
                        <li class="text-warning">
                            <i style="cursor: context-menu;"
                                [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 1, 'fa-star-half-o': spaceRatingAvg > 0 && spaceRatingAvg < 1, 'fa-star-o': spaceRatingAvg <= 0}"
                                aria-hidden="true"></i>
                        </li>
                        <li class="text-warning">
                            <i style="cursor: context-menu;"
                                [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 2, 'fa-star-half-o': spaceRatingAvg > 1 && spaceRatingAvg < 2, 'fa-star-o': spaceRatingAvg <= 1}"
                                aria-hidden="true"></i>
                        </li>
                        <li class="text-warning">
                            <i style="cursor: context-menu;"
                                [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 3, 'fa-star-half-o': spaceRatingAvg > 2 && spaceRatingAvg < 3, 'fa-star-o': spaceRatingAvg <= 2}"
                                aria-hidden="true"></i>
                        </li>
                        <li class="text-warning">
                            <i style="cursor: context-menu;"
                                [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 4, 'fa-star-half-o': spaceRatingAvg > 3 && spaceRatingAvg < 4, 'fa-star-o': spaceRatingAvg <= 3}"
                                aria-hidden="true"></i>
                        </li>
                        <li class="text-warning">
                            <i style="cursor: context-menu;"
                                [ngClass]="{'fa': true, 'fa-star': spaceRatingAvg >= 5, 'fa-star-half-o': spaceRatingAvg > 4 && spaceRatingAvg < 5, 'fa-star-o': spaceRatingAvg <= 4}"
                                aria-hidden="true"></i>
                        </li>
                    </ul> -->
                    <!-- <p class="rating mb-0">{{spaceRatingAvg}}</p> -->
                    <p class="review mb-0">Total reviews : {{transactionHistory?.length}}</p>
                </div>
            </div>
            <div class="card-body ">
                <!-- <div class="d-flex">
                    <button *ngIf="status == 'pending'" style="background-color: green;" mat-mini-fab color="primary"
                        aria-label="Example icon button with a menu icon" (click)="approveWithCheckbox()">
                        <mat-icon><i class="fa-solid fa-check"></i></mat-icon>
                    </button>
                    <button *ngIf="status == 'pending'" style="margin-left: 15px;background-color: red;" mat-mini-fab
                        color="primary" aria-label="Example icon button with a menu icon" (click)="rejectWithCheckbox()">
                        <mat-icon><i class="fa-solid fa-xmark"></i></mat-icon>
                    </button>
                </div> -->
                <mat-tab-group (selectedTabChange)="changeStatus($event)">
                    <mat-tab label="Pending">
                        <div *ngIf="status == 'pending'">

                            <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">

                                <!-- <ng-container matColumnDef="checkbox">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            (change)="setAll($event.checked)">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index">
                                        <mat-checkbox [ngModel]="row.completed" [color]="row.color"
                                            (ngModelChange)="updateAllComplete(row.id)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container> -->

                                <ng-container matColumnDef="Serial_no">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial no. </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 + (paginator?.pageIndex
                                        *
                                        paginator?.pageSize) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="createdAt">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Name </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="space_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Email </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostEmail }} </td>
                                </ng-container>

                                <ng-container matColumnDef="user_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row.spaceName || 'N/A'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="rejection_reason">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <ng-container matColumnDef="rejection_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <ng-container matColumnDef="approved_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="rating">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
                                    <td mat-cell *matCellDef="let row">
                                        <ul class="product-rating-c d-flex">
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 1, 'fa-star-half-o': row?.rating > 0 && row?.rating < 1, 'fa-star-o': row?.rating <= 0}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 2, 'fa-star-half-o': row?.rating > 1 && row?.rating < 2, 'fa-star-o': row?.rating <= 1}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 3, 'fa-star-half-o': row?.rating > 2 && row?.rating < 3, 'fa-star-o': row?.rating <= 2}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 4, 'fa-star-half-o': row?.rating > 3 && row?.rating < 4, 'fa-star-o': row?.rating <= 3}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 5, 'fa-star-half-o': row?.rating > 4 && row?.rating < 5, 'fa-star-o': row?.rating <= 4}"
                                                    aria-hidden="true"></i>
                                            </li>
                                        </ul>
                                    </td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="Review">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Reviews </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.Review }} </td>
                                </ng-container> -->

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Actions </th>
                                    <td class="" mat-cell *matCellDef="let row">
                                        <!-- <button style="background-color: green;" mat-mini-fab color="primary"
                                            aria-label="Example icon button with a menu icon"
                                            (click)="updateReviewStatus(row.id)">
                                            <mat-icon><i class="fa-solid fa-check"></i></mat-icon>
                                        </button>
                                        <button style="margin-left: 15px;background-color: red;" mat-mini-fab
                                            color="primary" aria-label="Example icon button with a menu icon"
                                            (click)="updateReviewRejectStatus(row.id)">
                                            <mat-icon><i class="fa-solid fa-xmark"></i></mat-icon>
                                        </button> -->
                                        <button style="margin-left: 15px;background-color: yellow;" mat-mini-fab
                                            color="primary" aria-label="Example icon button with a menu icon"
                                            (click)="redirectViewSpaces(row.id)">
                                            <mat-icon><i class="fa-solid fa-eye"></i></mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="reason">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container> -->

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data matching the filter "</td>
                                </tr>

                            </table>

                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                [showFirstLastButtons]="true" [length]="dataSource?.data.length">
                            </mat-paginator>
                        </div>
                    </mat-tab>

                    <mat-tab label="Approved">
                        <div *ngIf="status == 'approved'">
                            <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">

                                <ng-container matColumnDef="checkbox">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row; let i = index"></td>
                                </ng-container>

                                <ng-container matColumnDef="Serial_no">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial no. </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 + (paginator?.pageIndex
                                        *
                                        paginator?.pageSize) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="createdAt">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Name </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="space_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Email </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostEmail }} </td>
                                </ng-container>

                                <ng-container matColumnDef="user_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row?.spaceName|| 'N/A'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="rejection_reason">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <ng-container matColumnDef="rejection_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <ng-container matColumnDef="approved_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Approved Date & Time </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.updatedAt | date: 'dd-MM-yyyy hh:mm:ss'
                                        }} </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="rating">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
                                    <td mat-cell *matCellDef="let row">
                                        <ul class="product-rating-c d-flex">
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 1, 'fa-star-half-o': row?.rating > 0 && row?.rating < 1, 'fa-star-o': row?.rating <= 0}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 2, 'fa-star-half-o': row?.rating > 1 && row?.rating < 2, 'fa-star-o': row?.rating <= 1}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 3, 'fa-star-half-o': row?.rating > 2 && row?.rating < 3, 'fa-star-o': row?.rating <= 2}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 4, 'fa-star-half-o': row?.rating > 3 && row?.rating < 4, 'fa-star-o': row?.rating <= 3}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 5, 'fa-star-half-o': row?.rating > 4 && row?.rating < 5, 'fa-star-o': row?.rating <= 4}"
                                                    aria-hidden="true"></i>
                                            </li>
                                        </ul>
                                    </td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="Review">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Reviews </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.Review }} </td>
                                </ng-container> -->

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Approval Date </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.action_date | date: 'dd-MM-yyyy hh:mm:ss'
                                        }} </td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="reason">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"></th>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container> -->

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data matching the filter "</td>
                                </tr>

                            </table>

                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                [showFirstLastButtons]="true" [length]="dataSource?.data.length">
                            </mat-paginator>
                        </div>
                    </mat-tab>

                    <mat-tab label="Rejected">
                        <div *ngIf="status == 'rejected'">
                            <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">

                                <ng-container matColumnDef="checkbox">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row; let i = index"></td>
                                </ng-container>

                                <ng-container matColumnDef="Serial_no">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial no. </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 + (paginator?.pageIndex
                                        *
                                        paginator?.pageSize) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="createdAt">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Name </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="space_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Host Email </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.hostEmail }} </td>
                                </ng-container>

                                <ng-container matColumnDef="user_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row?.spaceName || 'N/A'}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="rejection_reason">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reason </th>
                                    <td mat-cell *matCellDef="let row"> {{row?.reason || 'N/A'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="rejection_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Rejection Date & Time </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.updatedAt | date: 'dd-MM-yyyy hh:mm:ss'
                                        }} </td>
                                </ng-container>

                                <ng-container matColumnDef="approved_date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> </th>
                                    <td mat-cell *matCellDef="let row"> </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="rating">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
                                    <td mat-cell *matCellDef="let row">
                                        <ul class="product-rating-c d-flex">
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 1, 'fa-star-half-o': row?.rating > 0 && row?.rating < 1, 'fa-star-o': row?.rating <= 0}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 2, 'fa-star-half-o': row?.rating > 1 && row?.rating < 2, 'fa-star-o': row?.rating <= 1}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 3, 'fa-star-half-o': row?.rating > 2 && row?.rating < 3, 'fa-star-o': row?.rating <= 2}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 4, 'fa-star-half-o': row?.rating > 3 && row?.rating < 4, 'fa-star-o': row?.rating <= 3}"
                                                    aria-hidden="true"></i>
                                            </li>
                                            <li class="text-warning">
                                                <i style="cursor: context-menu;"
                                                    [ngClass]="{'fa': true, 'fa-star': row?.rating >= 5, 'fa-star-half-o': row?.rating > 4 && row?.rating < 5, 'fa-star-o': row?.rating <= 4}"
                                                    aria-hidden="true"></i>
                                            </li>
                                        </ul>
                                    </td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="Review">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Reviews </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.Review }} </td>
                                </ng-container> -->

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="date">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Rejection Date </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.action_date | date: 'dd-MM-yyyy hh:mm:ss'
                                        }} </td>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="reason">
                                    <th class="mat-column-Review" mat-header-cell *matHeaderCellDef
                                        mat-sort-header="false"> Rejection Remark </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.remark }} </td>
                                </ng-container> -->

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data matching the filter "</td>
                                </tr>

                            </table>

                            <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                [showFirstLastButtons]="true" [length]="dataSource?.data.length">
                            </mat-paginator>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>