<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Monthly Report </h3>
        <button class="btn v3" (click)="downloadCSV()">
          <i class="ion-plus-round"></i>Export to CSV</button>
      </div>
      <div class="card-body">
        <div class="card tabs-menu-body">
          <div class="card-header ps-2">
            <h3 class="card-title">Filter Report</h3>
          </div>
          <div class="row mx-0 align-items-center py-4 gy-md-1 gy-2">
            <div class="col-md-3">
              <div>
                <div class="filter-checkbox">
                  <ng-select class="form-select drop_down form-control" [(ngModel)]="hostId"
                    [placeholder]="hostId  ? '' :'Select Host'">
                    <ng-option *ngFor="let host of host" [value]="host.id">
                      {{host.companyName}} - {{host.mobile}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div id="datePicker" class="col-md-3 position-relative">
              <mat-form-field appearance="legacy">
                <input matInput  [matDatepicker]="dp" [formControl]="date">
                <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, dp)"
                panelClass="example-month-picker">
              </mat-datepicker>
              <i class="fa-solid fa-xmark text-dark cursor-pointer position-absolute" style="right:20%;"
                (click)="resetMonth()"></i>
              </mat-form-field>
            </div>
            <span class="col-md-2">
              <button (click)="getFilteredData()" class="btn btn-primary" type="button"><i class="fe fe-search"></i>
                Filter</button>
            </span>
          </div>

        </div>
        <div class="card tabs-menu-body">
          <div class="card-header ps-2 d-flex justify-content-between">
            <div class="card-title">Monthly Report</div>
            <div class="card-title">Total Earnings : {{totalAmount?.toFixed(2)}}</div>
          </div>
          <div class="card-body">
            <div>
              <div class="col-md-12">
                <div class="div align-items-center" style="display: flex; justify-content: end;gap:30px">
                  <label class="form-label">Search</label>
                  <input type="text" matInput (input)="applyFilter($event.target)">
                </div>
              </div>
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">
                  <ng-container matColumnDef="serial">
                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                    </th>
                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                  </ng-container>
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr. No. </th>
                    <td mat-cell *matCellDef="let row; let i = index">{{ (paginator.pageIndex *
                      paginator.pageSize) + (i + 1) }} </td>
                  </ng-container>
                  <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date and Time </th>
                    <td mat-cell *matCellDef="let row"> {{row.dateTime }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bookingId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking Id</th>
                    <td mat-cell *matCellDef="let row"> {{row.bookingId}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="hostName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Host Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.hostName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="spaceCategory">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Category </th>
                    <td mat-cell *matCellDef="let row"> {{row.spaceCategory}} </td>
                  </ng-container>
                  <ng-container matColumnDef="space">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.spaceName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount 
                      &nbsp; <i matTooltip="Net of Platform Fees. Excl GST" matTooltipPosition="above" class="fa-solid fa-lg fa-circle-info"></i>
                       </th>
                    <td mat-cell *matCellDef="let row"> {{row.amount}}  </td>
                  </ng-container>
                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row" style="padding-block: 1rem;">
                      <a routerLink="/home/booking-details/{{row.id}}" target="_blank"><button class="btn custum-btn my-2">Booking
                          Details <i aria-hidden="true" class="fa fa-chevron-right"></i></button></a>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row text-center" *matNoDataRow>
                    <td class="mat-cell" colspan="10">No data matching the filter</td>
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator pageIndex="0" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                [showFirstLastButtons]="true" [length]="dataSource?.data?.length">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>