<div class="page-wrapper">
  <!--Sidebar Menu Starts-->

  <!--Sidebar Menu ends-->

  <!--Dashboard content Wrapper starts-->
  <div class="dash-content-wrap">
    <!--Dashboard breadcrumb starts-->
    <div class="dash-breadcrumb">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-breadcrumb-content">
              <div class="dash-breadcrumb-left">
                <div class="breadcrumb-menu text-right sm-left">
                  <!-- <p class="list-address">
                    Showing <b>{{ leads_data.length }}</b> results
                  </p> -->
                </div>
              </div>
              <div>
                <button class="btn v3" (click)="downloadCSV()">
                  <i class="ion-plus-round"></i>Export to CSV</button>&nbsp;&nbsp;
                <!-- <button class="btn v3" (click)="addLeadDialog()">
                  <i class="ion-plus-round"></i>Add Lead
                </button> -->
              </div>
              <!-- <a class="btn v3" href="add-listing.html"><i class="ion-plus-round"></i>Add Listing </a> -->
            </div>
            <!-- <div style="display: flex; justify-content: flex-end;">

            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard breadcrumb ends-->
    <!--Dashboard content starts-->
    <div class="dash-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-bookings" style="overflow-x: auto;">
              <div class="act-title">
                <h5><i class="ion-ios-copy-outline"></i>Long Term Leads</h5>

                <input type="text" matInput (input)="applyFilter($event.target.value)" placeholder="Type to Search">
              </div>

              <div class="table-wrapper">
                <table mat-table [dataSource]="dataSource" matSort="sort" class="w-100">

                  <ng-container matColumnDef="serial">
                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                    </th>
                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                  </ng-container>

                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap" style="width:5%;"> Lead Id </th>
                    <td mat-cell *matCellDef="let row; let i = index" style="width: 5%;padding-left: 24px !important;"> {{ row.id }} </td>
                  </ng-container>

                  <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 15%"> Lead date & time </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%"> {{ row.createdAt }} </td>
                  </ng-container>
                  <ng-container matColumnDef="spaceName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap" style="width: 20%"> Space Name </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%"> {{ row.spaceName }} </td>
                  </ng-container>
                  <ng-container matColumnDef="hostName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap" style="width: 10%"> Host Name </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%"> {{ row.hostName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap" style="width: 10%"> First Name </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%"> {{ row.firstName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap" style="width: 10%"> Last Name </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%"> {{ row.lastName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Email </th>
                    <td mat-cell *matCellDef="let row" style="width: 15%"> {{row.userEmail}} </td>
                  </ng-container>

                  <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Mobile </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%">{{row.countryCode}} {{row.userMobile}} </td>
                  </ng-container>
                  <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> location </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%">{{row.location_name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> city </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%">{{row.city_name}}</td>
                  </ng-container>



                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter</td>
                  </tr>
                </table>

                <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                  [showFirstLastButtons]="true" [length]="dataSource?.data.length">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard content ends-->
    <!--Dashboard footer ends-->
  </div>
  <!--Dashboard content Wrapper ends-->
</div>