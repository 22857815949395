import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-approve-lead',
  templateUrl: './approve-lead.component.html',
  styleUrls: ['./approve-lead.component.css']
})
export class ApproveLeadComponent {

  id: any;
  city: any;
  hostId: any[] = [];
  title: any;

  constructor(public dialogRef: MatDialogRef<ApproveLeadComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private queriesService:QueriesService, private loaderService: LoaderService, public snackBar: MatSnackBar){
    this.id = this.data?.id;
    this.city = this.data?.cityName;
    this.title = this.data?.title
  }

  ngOnInit(){
    if(this.city.length){
      this.getHostList()
    }
  }


  getHostList() {
    let city = {
      city_name: this.city
    }
    this.queriesService.getHostList(city).then((res: any) => {
      let length = res.data.length
      if(length){
        for (let i = 0; i < length; i++) {
          this.hostId.push(res.data[i].id);
        }
      }
    })
    
  }

  onYesClick(): void {
    this.queriesService.approveLeadById({"leadId":this.id}).then((res:any) => {
      if(res.success){
        this.send()
      }
        this.openSnackBar(res.message, 'Dismiss');
    })
  }


  send(){
    let data = {
      hostId: this.hostId,
      leadId: this.id,
      sendBy: "Send"
    }
    this.queriesService.assignLeads(data).then((res: any) => {
      if (res.data.success) {
        this.dialogRef.close(true);
        this.openSnackBar(res.data.message, 'Dismiss');
      } else {
        this.dialogRef.close(false);
        this.openSnackBar(res.data.message, 'Dismiss');
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  yes(){

  }
}
