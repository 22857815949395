import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';
import { ConfirmDeleteAmenityComponent } from '../confirm-delete-amenity/confirm-delete-amenity.component';

@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.css'],
  providers: [ErrorStateMatcher, MatDialog]
})
export class CancellationPolicyComponent {

  activeTab = 0;
  @ViewChild(NgbNavItem) navItem!: NgbNavItem;
  policies:any[] = [];
  cancellationForm:FormGroup;
  amenitiesForm:FormGroup;
  iconCss: any;
  fallbackIcon = '';
  name:any;
  icon:any = '';
  displayedColumns: string[] = ['position', 'title', 'desc1', 'desc2', 'update'];
  pageSizeOptions = [5, 10, 25, 100];
  pageSize = 5;

  constructor(private spaceService: SpaceService, private snackBar: MatSnackBar, public dialog: MatDialog, private queryService: QueriesService, private router:Router) { }

  ngOnInit(){
    this.getPolicies();
  }

  getPolicies(){
    this.spaceService.getCancellationPolicy().subscribe((res:any)=>{
      this.policies = res.cancellationPolicy;
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updateDetails(id:number){
    if (this.queryService.hasPermission('Cancellation Policy', 'edit')) {
      this.router.navigate([`/home/update-cancellation-policy/${id}`])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }


  delete(id:any){
    const dialogRef = this.dialog.open(ConfirmDeleteAmenityComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.spaceService.deleteAmenity(id).subscribe((res:any)=>{
          this.openSnackBar(res.message, "Dismiss");
          this.getPolicies();
        })
      }
    });
  }
}
