import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { QueriesService } from "src/app/services/queries.service";
import { SpaceService } from "src/app/services/space.service";
import { ConfirmDialogBoxComponent } from "../confirm-dialog-box/confirm-dialog-box.component";
import { ConfirmRejectDialogBoxComponent } from "../confirm-reject-dialog-box/confirm-reject-dialog-box.component";
import { EditReviewComponent } from "./edit-review.component";

@Component({
  selector: "app-all-spaces-review",
  templateUrl: "./all-spaces-review.component.html",
  styleUrls: ["./all-spaces-review.component.css"],
  providers: [DatePipe, MatDialog],
})
export class AllSpacesReviewComponent {
  pendingColumns = [
    "checkbox",
    "id",
    "createdAt",
    "spaceName",
    "userName",
    "rating",
    "status",
  ];
  approvedColumns = [
    "serial",
    "createdAt",
    "spaceName",
    "userName",
    "rating",
    "status",
    "action_date",
  ];
  rejectedColumns = [
    "serial",
    "createdAt",
    "spaceName",
    "userName",
    "rating",
    "status",
    "action_date",
    "remark",
  ];
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator; 
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  startDate: any;
  endDate: any;
  selectedStars = [];
  space = [];
  spaceId: any;
  spaceName: any;
  transactionHistory: any[] = [];
  status = "0";
  country: string;
  category: string;
  state: string;
  city: string;
  ids: any[] = [];
  allIds: any[] = [];
  allComplete: boolean = false;
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  dynamicString: any;
  leads_dialogRef: MatDialogRef<any>;

  constructor(
    private datePipe: DatePipe,
    private _spaceService: SpaceService,
    private router: Router,
    public snackBar: MatSnackBar,
    private queryService: QueriesService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public leads_dialog: MatDialog,
    public leads_viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.getAllReviews();
    this.getAllSpaces();
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
  }

  getStar(event: any) {
    this.selectedStars = event;
  }

  getSpace(event: any) {
    this.spaceId = event;
  }

  getAllSpaces() {
    this._spaceService
      .filterSpace(
        this.country ?? "",
        this.category ?? "",
        this.state ?? "",
        this.city ?? ""
      )
      .subscribe((res: any) => {
        this.space = res.data;
      });
  }

  getAllReviews() {
    let data = {
      spaceId: this.spaceId,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      selectedStars: this.selectedStars,
    };

    this._spaceService.getAllReviews(data).then(
      (res: any) => {
        this.transactionHistory = [];
        this.transactionHistory = res?.data;
        for (let i = 0; i < this.transactionHistory.length; i++) {
          this.transactionHistory[i].completed = false;
        }
        this.dataSource = new MatTableDataSource(this.transactionHistory);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 1000);
        // this.cd.detectChanges();
      },
      (error) => {
        if (error.status === 500) {
          this.router.navigate(["/auth/login"]);
        }
      }
    );
  }

  public changeStatus(event: any) {
    let status = event.tab.textLabel;
    if (status == "Approved") {
      this.status = "1";
    } else if (status == "Rejected") {
      this.status = "2";
    } else if (status == "Pending") {
      this.status = "0";
    }
    this.getAllReviews();
  } 

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.paginator = this.paginatorPageSize;
  // } 

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  

  updateReviewStatus(id: any) {   
    if (this.queryService.hasPermission('Review', 'edit')) {
     this.getReviewbyId(id);
     setTimeout(() => {
       const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
         data: { message: this.dynamicString }
       });
       dialogRef.afterClosed().subscribe((result) => {
         console.log(result)
         if(result){
           this.getReviewStatus([id], "1");
         }
       });
     }, 500);
   } else {
    this.queryService.accessDeniedAlert()
   }
  }

  getReviewbyId(id: any) {
    this._spaceService.getAllReviewById(id).subscribe(
      (res: any) => {
        this.dynamicString = res?.data?.Review;
      },
      (error) => {
        this.openSnackBar(error.error.message, "Dismiss");
      }
    );
  }
  updateReviewRejectStatus(id: any) {
    if (this.queryService.hasPermission('Review', 'edit')) {
      this.getReviewbyId(id);
      setTimeout(() => {
        const dialogRef = this.dialog.open(ConfirmRejectDialogBoxComponent, {
          data: { message: this.dynamicString }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if(result){
            this.getReviewStatus([id], "2", result);
          }
        });
      }, 500);
  } else {
    this.queryService.accessDeniedAlert()
  }
  }

  getReviewStatus(id: any[], value: any, remark?:any) {
    this._spaceService.getReviewStatus(id, value, remark).then(
      (res: any) => {
        console.log(res);
        this.openSnackBar(res.message, "Dismiss");
        this.getAllReviews();
        this.ids = []
      },
      (error) => {
        if (error.status === 500) {
          this.router.navigate(["/auth/login"]);
        }
      }
    );
  }

  updateAllComplete(name) {
    const index = this.ids.indexOf(name);
    if (index !== -1) {
      this.ids.splice(index, 1); 
    } else {
      this.ids.push(name);
    }
    
    if(this.transactionHistory?.length === this.ids?.length){
      this.allComplete = true;
    }else{
        this.allComplete = false;
    }
  }

  setAll(completed: boolean) {
    console.log(completed);
    if(completed){
      this.ids = [];
      this.allComplete = completed;
      if (this.transactionHistory == null) {
        return;
      }
      // this.transactionHistory.forEach(t => (t.completed = completed));
      // this.transactionHistory.forEach(item => {
      //   this.ids.push(item.id)
      //   item.checked = true;
      // });

      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = Math.min(startIndex + this.paginator.pageSize, this.dataSource.data.length);

      for (let i = startIndex; i < endIndex; i++) {
        const item = this.dataSource.data[i];
        item.completed = completed;
        this.ids.push(item.id);
        item.checked = true;
      }
      
      console.log(this.ids);

    } else {
      this.ids = [];
      this.allComplete = completed;

      // this.transactionHistory.forEach(t => (t.completed = completed));
      // this.transactionHistory.forEach(item => {
      //   item.checked = false;
      // });

      for (let i = this.paginator.pageIndex * this.paginator.pageSize;
        i < Math.min((this.paginator.pageIndex + 1) * this.paginator.pageSize, this.dataSource.data.length);
        i++) {
        
        const item = this.dataSource.data[i];
        item.completed = completed;
        item.checked = false;
      }
    }
  }
  // updateSingleComplete(name): void {
  //   const index = this.ids.indexOf(name);
  //   if (index !== -1) {
  //     this.ids.splice(index, 1);
  //     this.allComplete = false;
  //   } else {
  //     this.ids.push(name);
  //   }
  //   console.log(this.ids);
  // }

  // setAllComplete() {
  //   this.ids = [];
  //   for (let i = 0; i < this.transactionHistory.length; i++) {
  //     if (this.transactionHistory[i].completed == false) {
  //       this.ids.push(this.transactionHistory[i].spaceName+i);
  //       this.transactionHistory[i].completed = true;
  //       this.allComplete = false;
  //     } else {
  //       this.allComplete = true;
  //       this.ids = [];
  //       this.transactionHistory[i].completed = false;
  //     }
  //   }
  //   console.log(this.ids);
  // }

  approveWithCheckbox() {
   if (this.queryService.hasPermission('Review', 'edit')) {
    for (let i = 0; i < this.transactionHistory.length; i++) {
      if (this.transactionHistory[i].completed == true) {
        this.ids.push(this.transactionHistory[i].id)
      } 
    } 
    const uniqueArray = Array.from(new Set(this.ids));

    console.log(uniqueArray);
    if(this.ids.length == 0){
      this.openSnackBar('Please select atleast one review', "Dismiss");
    } else{
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.allComplete = false;
          this.getReviewStatus(this.ids, "1");
        }
      });
    }
  } else {
this.queryService.accessDeniedAlert()
  }
  }

  rejectWithCheckbox() {
    if (this.queryService.hasPermission('Review', 'edit')) {
      for (let i = 0; i < this.transactionHistory.length; i++) {
        if (this.transactionHistory[i].completed == true) {
          this.ids.push(this.transactionHistory[i].id)
        } 
      }
      const uniqueArray = Array.from(new Set(this.ids));
      console.log(uniqueArray)
  
      if(this.ids.length == 0){
        this.openSnackBar('Please select atleast one review', "Dismiss");
      } else{
        const dialogRef = this.dialog.open(ConfirmRejectDialogBoxComponent);
        dialogRef.afterClosed().subscribe((result) => {
          if(result){
            this.allComplete = false;
            this.getReviewStatus(this.ids, "2", result);
          }
        });
      }
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  editReview(id:any) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.leads_viewContainerRef;
    config.disableClose = true;

    config.data = { id: id };

    this.leads_dialogRef = this.leads_dialog.open(EditReviewComponent, config);
    this.leads_dialogRef.componentInstance.ref = this.leads_dialogRef;
    this.leads_dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getAllReviews();
      }
      this.leads_dialogRef = null;
    });
  }
}
