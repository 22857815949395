import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/toPromise';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class QueriesService {

  public selectedLocationId;
  permission: any[] = [];

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals,
    private snackBar: MatSnackBar,
    private location: Location
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);
    this.permission = JSON.parse(localStorage.getItem("permission")) || []
  }

  private base_url = 'api/v1/admin';
  private getAllUrl = environment.apiUrl + this.base_url + '/allQueries';
  private getAllLeadsUrl = environment.apiUrl + this.base_url + '/allLeads';
  private getLongTermLeadsUrl = environment.apiUrl + this.base_url + '/longtermLeads';
  private getSingleLead = environment.apiUrl + this.base_url + '/getLeadById/';
  private deleteLead = environment.apiUrl + this.base_url + '/delete_lead/';
  private approveLead = environment.apiUrl + this.base_url + '/updateLeadStatus'
  private getHost = environment.apiUrl + this.base_url + '/getAllCoworkingHost';
  private assign = environment.apiUrl + this.base_url + '/assign-leads';
  private createPlan = environment.apiUrl + this.base_url + '/create-paywall';
  private updatePlan = environment.apiUrl + this.base_url + '/paywall-update/';
  private getAllPlans = environment.apiUrl + this.base_url + '/viewAll-paywall';
  private getTierBySpaceId = environment.apiUrl + this.base_url + '/getSpaceTierBySpaceId';
  private getPlanById = environment.apiUrl + this.base_url + '/paywall-view/';
  private preAssigned = environment.apiUrl + this.base_url + '/getHostAssignLeads';
  private createRibbon = environment.apiUrl + this.base_url + '/createRibbon';
  private assignSpaceRibbon = environment.apiUrl + this.base_url + '/addRibbonBySpaceId';
  private assignPaywallPlan = environment.apiUrl + this.base_url + '/updateSpaceTier/';
  private assignTiesByAdmin = environment.apiUrl + this.base_url + '/updateSpaceTierByAdmin';
  private changeRibbonStatus = environment.apiUrl + this.base_url + '/updateRibbonStatus';
  private getRibbon = environment.apiUrl + this.base_url + '/ribbonList';
  private addPrice = environment.apiUrl + this.base_url + '/addLeadPrice';
  private getPrice = environment.apiUrl + this.base_url + '/getLeadPrice';
  private getRibbonByIdUrl = environment.apiUrl + this.base_url + '/getRibbonById';
  private addDepartment = environment.apiUrl + this.base_url + '/addDepartment';
  private getDepartment = environment.apiUrl + this.base_url + '/departmentList';
  private getActiveDepartment = environment.apiUrl + this.base_url + '/getAllActiveDepartments';
  private getDepartmentWithId = environment.apiUrl + this.base_url + '/getDepartmentById';
  private changeDepartmentStatus = environment.apiUrl + this.base_url + '/changeDepartmentStatus';
  private updateDepartment = environment.apiUrl + this.base_url + '/updateDepartment';
  private creditInquiryUrl = environment.apiUrl + this.base_url + '/getCreditInquiry';
  private paywallInquiryUrl = environment.apiUrl + this.base_url + '/getPaywallInquiry';
  private updateCreditInquiryUrl = environment.apiUrl + this.base_url + '/changeCreditInquiryStatus';
  private updatePaywallInquiryUrl = environment.apiUrl + this.base_url + '/changePaywallInquiryStatus';
  private changeSubscriptionStatus = environment.apiUrl + this.base_url + '/change-paywall-status';
  private changeCreditStatus = environment.apiUrl + 'api/creditplan/credit/Plan/changeStatus';


  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")},`,
  });

  private _options = {
    headers: this.headers,
    withCredentials: true
  };


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  getAll(space_ids): Promise<any> {
    let data = {
      space_ids
    }
    return this.http.post(this.getAllUrl, JSON.stringify(data), { headers: this.headers, withCredentials: true })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllLeads(status: any, data?: any): Promise<any> {
    
    const queryParams: string[] = [];

    if (data?.startDate) {
      queryParams.push(`startDate=${encodeURIComponent(data.startDate)}`);
    }
    if (data?.endDate) {
      queryParams.push(`endDate=${encodeURIComponent(data.endDate)}`);
    }
    if (data?.spaceName) {
      queryParams.push(`spaceName=${encodeURIComponent(data.spaceName)}`);
    }
    if (data?.budget) {
      queryParams.push(`budget=${encodeURIComponent(data.budget)}`);
    }
    if (data?.budgetMax) {
      queryParams.push(`budgetMax=${encodeURIComponent(data.budgetMax)}`);
    }
    if (data?.tenure) {
      queryParams.push(`tenure=${encodeURIComponent(data.tenure)}`);
    }
    if (data?.lead_price) {
      queryParams.push(`leadPrice=${encodeURIComponent(data.lead_price)}`);
    }
    if (data?.city) {
      queryParams.push(`city=${encodeURIComponent(data.city)}`);
    }

    let seats = data?.no_of_seats !== undefined ? data?.no_of_seats : [];
    const seats_data:any ={seats:seats}
    const queryString = queryParams.join('&');

    const url = `${this.getAllLeadsUrl}/${status}${queryString ? '?' + queryString : ''}`;
    
    return this.http.post(url, seats_data,this._options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  getLongTermLeads(data?: any): Promise<any> {
    const startDate = data?.startDate ? `startDate=${data.startDate}` : '';
    const endDate = data?.endDate ? `endDate=${data.endDate}` : '';

    const url = `${this.getLongTermLeadsUrl}?${startDate}&${endDate}`;

    return this.http.get(url, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getLeadById(id: any): Promise<any> {
    return this.http.get(this.getSingleLead + id, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  deleteLeadById(id: any): Promise<any> {
    return this.http.delete(this.deleteLead + id, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  approveLeadById(id: any): Promise<any> {
    return this.http.post(this.approveLead, id, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getHostList(data: any) {
    return this.http.post(this.getHost, data, this._options).toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getPreAssignedLeads(data: any) {
    return this.http.post(this.preAssigned, data, this._options).toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  assignLeads(data: any) {
    return this.http.post(this.assign, data, this._options).toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addCreditPoint(data: any): Promise<any> {
    return this.http.post(`${environment.apiUrl}api/v1/admin/createHostBalance`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addCreditPackage(data: any): Promise<any> {
    return this.http.post(`${environment.apiUrl}api/Creditplan/credit/Plan/create`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getCreditPackages(status: string): Promise<any> {
    return this.http.get(`${environment.apiUrl}api/Creditplan/credit/Plan/viewAll?status=${status}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateCreditStatus(data: any): Promise<any> {
    return this.http.post(`${this.changeCreditStatus}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getCreditPackageByID(id: number): Promise<any> {
    return this.http.get(`${environment.apiUrl}api/Creditplan/credit/Plan/view/${id}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateCreditPackage(id: number, data: any): Promise<any> {
    return this.http.put(`${environment.apiUrl}api/Creditplan/credit/Plan/update/${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  addSubscriptionPlan(data: any): Promise<any> {
    return this.http.post(this.createPlan, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getSubscriptionPlans(status: string): Promise<any> {
    return this.http.get(`${this.getAllPlans}?status=${status}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getTier(spaceid:any){
	return this.http.get(`${this.getTierBySpaceId}/${spaceid}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  updateSubscriptionStatus(data: any): Promise<any> {
    return this.http.post(`${this.changeSubscriptionStatus}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getSubscriptionPlanByID(id: number): Promise<any> {
    return this.http.get(`${this.getPlanById}${id}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateSubscriptionPlan(id: number, data: any): Promise<any> {
    return this.http.put(`${this.updatePlan}${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllRibbon(): Promise<any> {
    return this.http.get(this.getRibbon, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getRibbonById(id: any): Promise<any> {
    return this.http.get(`${this.getRibbonByIdUrl}/${id}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllActiveRibbon(): Promise<any> {
    return this.http.get(`${this.getRibbon}?type=1`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  assignRibbon(data: any): Promise<any> {
    return this.http.post(this.assignSpaceRibbon, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  assignPaywallPlans(spaceId,data): Promise<any> {
    return this.http.post(this.assignPaywallPlan+spaceId,data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  assignTierByAdmin(data): Promise<any> {
    return this.http.post(this.assignTiesByAdmin,data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addRibbon(data: any): Promise<any> {
    return this.http.post(this.createRibbon, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateRibbonStatus(data: any): Promise<any> {
    return this.http.post(this.changeRibbonStatus, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addLeadPrice(data: any): Promise<any> {
    return this.http.post(this.addPrice, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getLeadPrice(): Promise<any> {
    return this.http.get(this.getPrice, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  createDepartment(data: any): Promise<any> {
    return this.http.post(this.addDepartment, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllDepartment(): Promise<any> {
    return this.http.get(this.getDepartment, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllActiveDepartment(): Promise<any> {
    return this.http.get(this.getActiveDepartment, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getDepartmentById(id: number): Promise<any> {
    return this.http.get(`${this.getDepartmentWithId}/${id}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  updateDepartmentStatus(id: number, data: any): Promise<any> {
    return this.http.post(`${this.changeDepartmentStatus}/${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateDepartmentById(id: number, data: any): Promise<any> {
    return this.http.post(`${this.updateDepartment}/${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  getCreditInquiry(type: any): Promise<any> {
    return this.http.get(`${this.creditInquiryUrl}/${type}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getSubscriptionInquiry(type: any): Promise<any> {
    return this.http.get(`${this.paywallInquiryUrl}/${type}`, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateCreditInquiryById(id: number, data: any): Promise<any> {
    return this.http.post(`${this.updateCreditInquiryUrl}/${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updatePaywallInquiryById(id: number, data: any): Promise<any> {
    return this.http.post(`${this.updatePaywallInquiryUrl}/${id}`, data, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }


  openSnackBar(message: string, action = "Dismiss") {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }


  hasPermission(moduleTitle: string, action: string): boolean {
    if (this.permission.length) {
      const module = this.permission?.find(mod => mod.title === moduleTitle);
      return module ? module.permissions[action] : false;
    } else {
      return true
    }
  }

  hasSubmodulePermission(moduleTitle: string, submoduleTitle: string, action: string): boolean {
    if (this.permission.length) {
      const module = this.permission.find(mod => mod.title === moduleTitle);
      const submodule = module?.subModule?.find((sub:any) => sub.title === submoduleTitle);
      return submodule ? submodule.permissions[action] : false;
    } else {
      return true
    }
  }


  accessDeniedAlert() {
    Swal.fire({
      text: "Access denied! You don't have permission to access this.",
      icon: "info"
    });
  }

	

  countryCodes =[
		{
		  "name": "Afghanistan",
		  "dialcode": "+93",
		  "flag": "https://flagcdn.com/w320/af.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Albania",
		  "dialcode": "+355",
		  "flag": "https://flagcdn.com/w320/al.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Algeria",
		  "dialcode": "+213",
		  "flag": "https://flagcdn.com/w320/dz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Andorra",
		  "dialcode": "+376",
		  "flag": "https://flagcdn.com/w320/ad.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Angola",
		  "dialcode": "+244",
		  "flag": "https://flagcdn.com/w320/ao.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Antigua and Barbuda",
		  "dialcode": "+1-268",
		  "flag": "https://flagcdn.com/w320/ag.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Argentina",
		  "dialcode": "+54",
		  "flag": "https://flagcdn.com/w320/ar.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Armenia",
		  "dialcode": "+374",
		  "flag": "https://flagcdn.com/w320/am.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Australia",
		  "dialcode": "+61",
		  "flag": "https://flagcdn.com/w320/au.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Austria",
		  "dialcode": "+43",
		  "flag": "https://flagcdn.com/w320/at.png",
		  "number-of-digits-in-number": 4
		},
		{
		  "name": "Azerbaijan",
		  "dialcode": "+994",
		  "flag": "https://flagcdn.com/w320/az.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Bahamas",
		  "dialcode": "+1-242",
		  "flag": "https://flagcdn.com/w320/bs.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Bahrain",
		  "dialcode": "+973",
		  "flag": "https://flagcdn.com/w320/bh.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Bangladesh",
		  "dialcode": "+880",
		  "flag": "https://flagcdn.com/w320/bd.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Barbados",
		  "dialcode": "+1-246",
		  "flag": "https://flagcdn.com/w320/bb.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Belarus",
		  "dialcode": "+375",
		  "flag": "https://flagcdn.com/w320/by.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Belgium",
		  "dialcode": "+32",
		  "flag": "https://flagcdn.com/w320/be.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Belize",
		  "dialcode": "+501",
		  "flag": "https://flagcdn.com/w320/bz.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Benin",
		  "dialcode": "+229",
		  "flag": "https://flagcdn.com/w320/bj.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Bhutan",
		  "dialcode": "+975",
		  "flag": "https://flagcdn.com/w320/bt.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Bolivia",
		  "dialcode": "+591",
		  "flag": "https://flagcdn.com/w320/bo.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Bosnia and Herzegovina",
		  "dialcode": "+387",
		  "flag": "https://flagcdn.com/w320/ba.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Botswana",
		  "dialcode": "+267",
		  "flag": "https://flagcdn.com/w320/bw.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Brazil",
		  "dialcode": "+55",
		  "flag": "https://flagcdn.com/w320/br.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Brunei",
		  "dialcode": "+673",
		  "flag": "https://flagcdn.com/w320/bn.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Bulgaria",
		  "dialcode": "+359",
		  "flag": "https://flagcdn.com/w320/bg.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Burkina Faso",
		  "dialcode": "+226",
		  "flag": "https://flagcdn.com/w320/bf.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Burundi",
		  "dialcode": "+257",
		  "flag": "https://flagcdn.com/w320/bi.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Cabo Verde",
		  "dialcode": "+238",
		  "flag": "https://flagcdn.com/w320/cv.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Cambodia",
		  "dialcode": "+855",
		  "flag": "https://flagcdn.com/w320/kh.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Cameroon",
		  "dialcode": "+237",
		  "flag": "https://flagcdn.com/w320/cm.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Canada",
		  "dialcode": "+1",
		  "flag": "https://flagcdn.com/w320/ca.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Central African Republic",
		  "dialcode": "+236",
		  "flag": "https://flagcdn.com/w320/cf.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Chad",
		  "dialcode": "+235",
		  "flag": "https://flagcdn.com/w320/td.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Chile",
		  "dialcode": "+56",
		  "flag": "https://flagcdn.com/w320/cl.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "China",
		  "dialcode": "+86",
		  "flag": "https://flagcdn.com/w320/cn.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Colombia",
		  "dialcode": "+57",
		  "flag": "https://flagcdn.com/w320/co.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Comoros",
		  "dialcode": "+269",
		  "flag": "https://flagcdn.com/w320/km.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Congo",
		  "dialcode": "+242",
		  "flag": "https://flagcdn.com/w320/cg.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Congo, Democratic Republic of the",
		  "dialcode": "+243",
		  "flag": "https://flagcdn.com/w320/cd.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Costa Rica",
		  "dialcode": "+506",
		  "flag": "https://flagcdn.com/w320/cr.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Croatia",
		  "dialcode": "+385",
		  "flag": "https://flagcdn.com/w320/hr.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Cuba",
		  "dialcode": "+53",
		  "flag": "https://flagcdn.com/w320/cu.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Cyprus",
		  "dialcode": "+357",
		  "flag": "https://flagcdn.com/w320/cy.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Czech Republic",
		  "dialcode": "+420",
		  "flag": "https://flagcdn.com/w320/cz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Denmark",
		  "dialcode": "+45",
		  "flag": "https://flagcdn.com/w320/dk.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Djibouti",
		  "dialcode": "+253",
		  "flag": "https://flagcdn.com/w320/dj.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Dominica",
		  "dialcode": "+1-767",
		  "flag": "https://flagcdn.com/w320/dm.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Dominican Republic",
		  "dialcode": "+1-809",
		  "flag": "https://flagcdn.com/w320/do.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Ecuador",
		  "dialcode": "+593",
		  "flag": "https://flagcdn.com/w320/ec.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Egypt",
		  "dialcode": "+20",
		  "flag": "https://flagcdn.com/w320/eg.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "El Salvador",
		  "dialcode": "+503",
		  "flag": "https://flagcdn.com/w320/sv.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Equatorial Guinea",
		  "dialcode": "+240",
		  "flag": "https://flagcdn.com/w320/gq.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Eritrea",
		  "dialcode": "+291",
		  "flag": "https://flagcdn.com/w320/er.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Estonia",
		  "dialcode": "+372",
		  "flag": "https://flagcdn.com/w320/ee.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Eswatini",
		  "dialcode": "+268",
		  "flag": "https://flagcdn.com/w320/sz.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Ethiopia",
		  "dialcode": "+251",
		  "flag": "https://flagcdn.com/w320/et.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Fiji",
		  "dialcode": "+679",
		  "flag": "https://flagcdn.com/w320/fj.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Finland",
		  "dialcode": "+358",
		  "flag": "https://flagcdn.com/w320/fi.png",
		  "number-of-digits-in-number": 5
		},
		{
		  "name": "France",
		  "dialcode": "+33",
		  "flag": "https://flagcdn.com/w320/fr.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Gabon",
		  "dialcode": "+241",
		  "flag": "https://flagcdn.com/w320/ga.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Gambia",
		  "dialcode": "+220",
		  "flag": "https://flagcdn.com/w320/gm.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Georgia",
		  "dialcode": "+995",
		  "flag": "https://flagcdn.com/w320/ge.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Germany",
		  "dialcode": "+49",
		  "flag": "https://flagcdn.com/w320/de.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Ghana",
		  "dialcode": "+233",
		  "flag": "https://flagcdn.com/w320/gh.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Greece",
		  "dialcode": "+30",
		  "flag": "https://flagcdn.com/w320/gr.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Grenada",
		  "dialcode": "+1-473",
		  "flag": "https://flagcdn.com/w320/gd.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Guatemala",
		  "dialcode": "+502",
		  "flag": "https://flagcdn.com/w320/gt.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Guinea",
		  "dialcode": "+224",
		  "flag": "https://flagcdn.com/w320/gn.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Guinea-Bissau",
		  "dialcode": "+245",
		  "flag": "https://flagcdn.com/w320/gw.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Guyana",
		  "dialcode": "+592",
		  "flag": "https://flagcdn.com/w320/gy.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Haiti",
		  "dialcode": "+509",
		  "flag": "https://flagcdn.com/w320/ht.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Honduras",
		  "dialcode": "+504",
		  "flag": "https://flagcdn.com/w320/hn.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Hungary",
		  "dialcode": "+36",
		  "flag": "https://flagcdn.com/w320/hu.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Iceland",
		  "dialcode": "+354",
		  "flag": "https://flagcdn.com/w320/is.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "India",
		  "dialcode": "+91",
		  "flag": "https://flagcdn.com/w320/in.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Indonesia",
		  "dialcode": "+62",
		  "flag": "https://flagcdn.com/w320/id.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Iran",
		  "dialcode": "+98",
		  "flag": "https://flagcdn.com/w320/ir.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Iraq",
		  "dialcode": "+964",
		  "flag": "https://flagcdn.com/w320/iq.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Ireland",
		  "dialcode": "+353",
		  "flag": "https://flagcdn.com/w320/ie.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Israel",
		  "dialcode": "+972",
		  "flag": "https://flagcdn.com/w320/il.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Italy",
		  "dialcode": "+39",
		  "flag": "https://flagcdn.com/w320/it.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Jamaica",
		  "dialcode": "+1-876",
		  "flag": "https://flagcdn.com/w320/jm.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Japan",
		  "dialcode": "+81",
		  "flag": "https://flagcdn.com/w320/jp.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Jordan",
		  "dialcode": "+962",
		  "flag": "https://flagcdn.com/w320/jo.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Kazakhstan",
		  "dialcode": "+7",
		  "flag": "https://flagcdn.com/w320/kz.png",
		  "number-of-digits-in-number": 11
		},
		{
		  "name": "Kenya",
		  "dialcode": "+254",
		  "flag": "https://flagcdn.com/w320/ke.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Kiribati",
		  "dialcode": "+686",
		  "flag": "https://flagcdn.com/w320/ki.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Korea, North",
		  "dialcode": "+850",
		  "flag": "https://flagcdn.com/w320/kp.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Korea, South",
		  "dialcode": "+82",
		  "flag": "https://flagcdn.com/w320/kr.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Kuwait",
		  "dialcode": "+965",
		  "flag": "https://flagcdn.com/w320/kw.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Kyrgyzstan",
		  "dialcode": "+996",
		  "flag": "https://flagcdn.com/w320/kg.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Laos",
		  "dialcode": "+856",
		  "flag": "https://flagcdn.com/w320/la.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Latvia",
		  "dialcode": "+371",
		  "flag": "https://flagcdn.com/w320/lv.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Lebanon",
		  "dialcode": "+961",
		  "flag": "https://flagcdn.com/w320/lb.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Lesotho",
		  "dialcode": "+266",
		  "flag": "https://flagcdn.com/w320/ls.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Liberia",
		  "dialcode": "+231",
		  "flag": "https://flagcdn.com/w320/lr.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Libya",
		  "dialcode": "+218",
		  "flag": "https://flagcdn.com/w320/ly.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Liechtenstein",
		  "dialcode": "+423",
		  "flag": "https://flagcdn.com/w320/li.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Lithuania",
		  "dialcode": "+370",
		  "flag": "https://flagcdn.com/w320/lt.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Luxembourg",
		  "dialcode": "+352",
		  "flag": "https://flagcdn.com/w320/lu.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Madagascar",
		  "dialcode": "+261",
		  "flag": "https://flagcdn.com/w320/mg.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Malawi",
		  "dialcode": "+265",
		  "flag": "https://flagcdn.com/w320/mw.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Malaysia",
		  "dialcode": "+60",
		  "flag": "https://flagcdn.com/w320/my.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Maldives",
		  "dialcode": "+960",
		  "flag": "https://flagcdn.com/w320/mv.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Mali",
		  "dialcode": "+223",
		  "flag": "https://flagcdn.com/w320/ml.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Malta",
		  "dialcode": "+356",
		  "flag": "https://flagcdn.com/w320/mt.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Marshall Islands",
		  "dialcode": "+692",
		  "flag": "https://flagcdn.com/w320/mh.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Mauritania",
		  "dialcode": "+222",
		  "flag": "https://flagcdn.com/w320/mr.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Mauritius",
		  "dialcode": "+230",
		  "flag": "https://flagcdn.com/w320/mu.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Mexico",
		  "dialcode": "+52",
		  "flag": "https://flagcdn.com/w320/mx.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Micronesia",
		  "dialcode": "+691",
		  "flag": "https://flagcdn.com/w320/fm.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Moldova",
		  "dialcode": "+373",
		  "flag": "https://flagcdn.com/w320/md.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Monaco",
		  "dialcode": "+377",
		  "flag": "https://flagcdn.com/w320/mc.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Mongolia",
		  "dialcode": "+976",
		  "flag": "https://flagcdn.com/w320/mn.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Montenegro",
		  "dialcode": "+382",
		  "flag": "https://flagcdn.com/w320/me.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Morocco",
		  "dialcode": "+212",
		  "flag": "https://flagcdn.com/w320/ma.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Mozambique",
		  "dialcode": "+258",
		  "flag": "https://flagcdn.com/w320/mz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Myanmar",
		  "dialcode": "+95",
		  "flag": "https://flagcdn.com/w320/mm.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Namibia",
		  "dialcode": "+264",
		  "flag": "https://flagcdn.com/w320/na.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Nauru",
		  "dialcode": "+674",
		  "flag": "https://flagcdn.com/w320/nr.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Nepal",
		  "dialcode": "+977",
		  "flag": "https://flagcdn.com/w320/np.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Netherlands",
		  "dialcode": "+31",
		  "flag": "https://flagcdn.com/w320/nl.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "New Zealand",
		  "dialcode": "+64",
		  "flag": "https://flagcdn.com/w320/nz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Nicaragua",
		  "dialcode": "+505",
		  "flag": "https://flagcdn.com/w320/ni.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Niger",
		  "dialcode": "+227",
		  "flag": "https://flagcdn.com/w320/ne.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Nigeria",
		  "dialcode": "+234",
		  "flag": "https://flagcdn.com/w320/ng.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "North Macedonia",
		  "dialcode": "+389",
		  "flag": "https://flagcdn.com/w320/mk.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Norway",
		  "dialcode": "+47",
		  "flag": "https://flagcdn.com/w320/no.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Oman",
		  "dialcode": "+968",
		  "flag": "https://flagcdn.com/w320/om.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Pakistan",
		  "dialcode": "+92",
		  "flag": "https://flagcdn.com/w320/pk.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Palau",
		  "dialcode": "+680",
		  "flag": "https://flagcdn.com/w320/pw.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Palestine",
		  "dialcode": "+970",
		  "flag": "https://flagcdn.com/w320/ps.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Panama",
		  "dialcode": "+507",
		  "flag": "https://flagcdn.com/w320/pa.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Papua New Guinea",
		  "dialcode": "+675",
		  "flag": "https://flagcdn.com/w320/pg.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Paraguay",
		  "dialcode": "+595",
		  "flag": "https://flagcdn.com/w320/py.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Peru",
		  "dialcode": "+51",
		  "flag": "https://flagcdn.com/w320/pe.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Philippines",
		  "dialcode": "+63",
		  "flag": "https://flagcdn.com/w320/ph.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Poland",
		  "dialcode": "+48",
		  "flag": "https://flagcdn.com/w320/pl.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Portugal",
		  "dialcode": "+351",
		  "flag": "https://flagcdn.com/w320/pt.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Qatar",
		  "dialcode": "+974",
		  "flag": "https://flagcdn.com/w320/qa.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Romania",
		  "dialcode": "+40",
		  "flag": "https://flagcdn.com/w320/ro.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Russia",
		  "dialcode": "+7",
		  "flag": "https://flagcdn.com/w320/ru.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Rwanda",
		  "dialcode": "+250",
		  "flag": "https://flagcdn.com/w320/rw.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Saint Kitts and Nevis",
		  "dialcode": "+1-869",
		  "flag": "https://flagcdn.com/w320/kn.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Saint Lucia",
		  "dialcode": "+1-758",
		  "flag": "https://flagcdn.com/w320/lc.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Saint Vincent and the Grenadines",
		  "dialcode": "+1-784",
		  "flag": "https://flagcdn.com/w320/vc.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Samoa",
		  "dialcode": "+685",
		  "flag": "https://flagcdn.com/w320/ws.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "San Marino",
		  "dialcode": "+378",
		  "flag": "https://flagcdn.com/w320/sm.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Sao Tome and Principe",
		  "dialcode": "+239",
		  "flag": "https://flagcdn.com/w320/st.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Saudi Arabia",
		  "dialcode": "+966",
		  "flag": "https://flagcdn.com/w320/sa.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Senegal",
		  "dialcode": "+221",
		  "flag": "https://flagcdn.com/w320/sn.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Serbia",
		  "dialcode": "+381",
		  "flag": "https://flagcdn.com/w320/rs.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Seychelles",
		  "dialcode": "+248",
		  "flag": "https://flagcdn.com/w320/sc.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Sierra Leone",
		  "dialcode": "+232",
		  "flag": "https://flagcdn.com/w320/sl.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Singapore",
		  "dialcode": "+65",
		  "flag": "https://flagcdn.com/w320/sg.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Sint Maarten",
		  "dialcode": "+1-721",
		  "flag": "https://flagcdn.com/w320/sx.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Slovakia",
		  "dialcode": "+421",
		  "flag": "https://flagcdn.com/w320/sk.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Slovenia",
		  "dialcode": "+386",
		  "flag": "https://flagcdn.com/w320/si.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Solomon Islands",
		  "dialcode": "+677",
		  "flag": "https://flagcdn.com/w320/sb.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Somalia",
		  "dialcode": "+252",
		  "flag": "https://flagcdn.com/w320/so.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "South Africa",
		  "dialcode": "+27",
		  "flag": "https://flagcdn.com/w320/za.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "South Sudan",
		  "dialcode": "+211",
		  "flag": "https://flagcdn.com/w320/ss.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Spain",
		  "dialcode": "+34",
		  "flag": "https://flagcdn.com/w320/es.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Sri Lanka",
		  "dialcode": "+94",
		  "flag": "https://flagcdn.com/w320/lk.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Sudan",
		  "dialcode": "+249",
		  "flag": "https://flagcdn.com/w320/sd.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Suriname",
		  "dialcode": "+597",
		  "flag": "https://flagcdn.com/w320/sr.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Sweden",
		  "dialcode": "+46",
		  "flag": "https://flagcdn.com/w320/se.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Switzerland",
		  "dialcode": "+41",
		  "flag": "https://flagcdn.com/w320/ch.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Syria",
		  "dialcode": "+963",
		  "flag": "https://flagcdn.com/w320/sy.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Taiwan",
		  "dialcode": "+886",
		  "flag": "https://flagcdn.com/w320/tw.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Tajikistan",
		  "dialcode": "+992",
		  "flag": "https://flagcdn.com/w320/tj.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Tanzania",
		  "dialcode": "+255",
		  "flag": "https://flagcdn.com/w320/tz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Thailand",
		  "dialcode": "+66",
		  "flag": "https://flagcdn.com/w320/th.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Timor-Leste",
		  "dialcode": "+670",
		  "flag": "https://flagcdn.com/w320/tl.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Togo",
		  "dialcode": "+228",
		  "flag": "https://flagcdn.com/w320/tg.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Tonga",
		  "dialcode": "+676",
		  "flag": "https://flagcdn.com/w320/to.png",
		  "number-of-digits-in-number": 5
		},
		{
		  "name": "Trinidad and Tobago",
		  "dialcode": "+1-868",
		  "flag": "https://flagcdn.com/w320/tt.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Tunisia",
		  "dialcode": "+216",
		  "flag": "https://flagcdn.com/w320/tn.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Turkey",
		  "dialcode": "+90",
		  "flag": "https://flagcdn.com/w320/tr.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Turkmenistan",
		  "dialcode": "+993",
		  "flag": "https://flagcdn.com/w320/tm.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Tuvalu",
		  "dialcode": "+688",
		  "flag": "https://flagcdn.com/w320/tv.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Uganda",
		  "dialcode": "+256",
		  "flag": "https://flagcdn.com/w320/ug.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Ukraine",
		  "dialcode": "+380",
		  "flag": "https://flagcdn.com/w320/ua.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "United Arab Emirates",
		  "dialcode": "+971",
		  "flag": "https://flagcdn.com/w320/ae.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "United Kingdom",
		  "dialcode": "+44",
		  "flag": "https://flagcdn.com/w320/gb.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "United States",
		  "dialcode": "+1",
		  "flag": "https://flagcdn.com/w320/us.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Uruguay",
		  "dialcode": "+598",
		  "flag": "https://flagcdn.com/w320/uy.png",
		  "number-of-digits-in-number": 8
		},
		{
		  "name": "Uzbekistan",
		  "dialcode": "+998",
		  "flag": "https://flagcdn.com/w320/uz.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Vanuatu",
		  "dialcode": "+678",
		  "flag": "https://flagcdn.com/w320/vu.png",
		  "number-of-digits-in-number": 7
		},
		{
		  "name": "Vatican City",
		  "dialcode": "+379",
		  "flag": "https://flagcdn.com/w320/vt.png",
		  "number-of-digits-in-number": 6
		},
		{
		  "name": "Venezuela",
		  "dialcode": "+58",
		  "flag": "https://flagcdn.com/w320/ve.png",
		  "number-of-digits-in-number": 10
		},
		{
		  "name": "Vietnam",
		  "dialcode": "+84",
		  "flag": "https://flagcdn.com/w320/vn.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Yemen",
		  "dialcode": "+967",
		  "flag": "https://flagcdn.com/w320/ye.png",
		  "number-of-digits-in-number": 9
		},
		{
		  "name": "Zambia",
		  "dialcode": "+260",
		  "flag": "https://flagcdn.com/w320/zm.png",
		  "number-of-digits-in-number": 13
		},
		{
		  "name": "Zimbabwe",
		  "dialcode": "+263",
		  "flag": "https://flagcdn.com/w320/zw.png",
		  "number-of-digits-in-number": 9
		}
	];

}
