import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FaqsService {
  constructor(private http: HttpClient) {}

  addQuestion(data): Observable<any> {
    return this.http.post(environment.apiUrl + "api/v1/faqs/addQuestion", data);
  }

  deleteQuestion(id): Observable<any> {
    return this.http.delete(
      environment.apiUrl + "api/v1/faqs/deleteQuestion/" + id,
    );
  }

  updateQuestion(data, id): Observable<any> {
    return this.http.put(
      environment.apiUrl + "api/v1/faqs/updateQuestion/" + id,
      data,
    );
  }

  getAllQuestions(data): Observable<any> {
    return this.http.post(
      environment.apiUrl + "api/v1/faqs/getAllQuestions",
      data,
    );
  }
}
