
<div class="">
    <div class="row mx-0">
        <div class="col-md-8">
            <div class="booking-details-confirmation ng-star-inserted" *ngIf="spaceDetails?.bookingStatus == 'confirmed'">
                <div class="booking-details-confirmation-img"><img class="overflow-hidden" src="assets/images/tick-white.png" alt=""></div>
                <div class="booking-details-confirmation-cont">
                    <h4 class="mb-0">Booking {{spaceDetails?.bookingStatus}} !</h4>
                    <!-- <p>You will received a confirmation mail on your registered email !</p> -->
                </div>
            </div>
            
            <div class="booking-all-list-single position-relative">
                <div class="booking-all-list-single-img-cont d-flex align-items-center justify-content-between ">
                    <div class="booking-all-list-single-img">
                        <img style="width: 200px;
                            height: 150px;
                            object-fit: cover;
                            border-radius: 10px;" (error)="handleImageError($event)" src="{{spaceDetails?.images[0] ? spaceDetails?.images[0] : 'assets/images/details_placeholder_image.jpg'}}"
                        alt="">
                        <div class="booking-all-list-single-img-tag"><p>{{spaceDetails?.spaceType}}</p></div>
                    </div>
                    <div class="booking-all-list-single-cont ms-4">
                        <div class="booking-all-list-single-cont-od">
                            <h2 style="font-size: 22px">{{spaceDetails?.spaceName}}</h2>
                            <p class="booking-id"> Booking ID : <span> {{spaceDetails?.bookingId}} </span></p>
                            <p class="booking-all-list-single-cont-location"><img src="assets/images/download.png"
                                    alt=""><span>{{spaceDetails?.location_name}} </span></p>
                            <div class="booking-list-single-info booking-list-single-about-info">
                                <div class="booking-list-single-info-single"><img src="assets/images/user-icon-orng.png" alt="">
                                    <p>{{spaceDetails?.howManyPeopleInYourSpace || 0}} people</p>
                                </div>
                                <div *ngIf="spaceDetails?.spaceType !='Coworking Space'" class="booking-list-single-info-single ng-star-inserted"><img
                                        src="assets/images/clock-icon-orng.png" alt="">
                                    <p>{{(min_hrs == 0 || min_hrs == null) ? 2 :min_hrs/60}} hrs min</p>
                                </div>
                                <div class="booking-list-single-info-single"><img src="assets/images/scale-orng.png" alt=""
                                        class="sq-ft-icon">
                                    <p>{{spaceDetails?.spacesqft}} sqft</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="booking-details-booking">
                <h4>User Details</h4>
            </div>
            <div class="booking-list-single-info booking-list-single-about-info" *ngIf="spaceDetails?.userName || spaceDetails?.userEmail || spaceDetails?.userMobile">
                <div *ngIf="spaceDetails?.firstName" class="booking-list-single-info-single"><img src="assets/images/user-icon-orng.png" alt="">
                    <p>{{spaceDetails?.firstName + " " + spaceDetails?.lastName}}</p>
                </div>
                <div *ngIf="spaceDetails?.userEmail" class="booking-list-single-info-single ng-star-inserted"><img
                        src="assets/images/emailicon.jpeg" alt="">
                    <p>{{spaceDetails?.userEmail}}</p>
                </div>
                <div *ngIf="spaceDetails?.userMobile" class="booking-list-single-info-single"><img src="assets/images/mobileicon.png" alt=""
                        class="sq-ft-icon">
                    <p>{{spaceDetails?.userMobile}}</p>
                </div>
            </div>
            <div class="booking-details-booking">
                <h4>Booking Details</h4>
                <!-- <p class="booking-details-booking-cat">Space Category : <span> {{spaceDetails?.spaceType}} </span></p> -->
            </div>
            <div class=" booking-details-booking-information-single" *ngIf="spaceDetails?.spaceType == 'Coworking Space'">
                <div class="d-flex align-items-center">
                  <p>No of Days:</p>
                  <div class="ml-2 col-3">
                      <p style="font-weight: 600; color: #000000;">{{spaceDetails?.ofDays}}</p>
                  </div>
                </div>
          </div>
            <div class="booking-details-booking-information d-flex align-items-center" style="gap: 10px; flex-wrap: wrap; margin-top: 16px;" *ngIf="spaceDetails?.spaceType == 'Coworking Space'">
                <p>Date:</p>
                <div *ngFor="let bookings of formattedDates">
                <div class="booking-details-booking-information-single booking-admin-single">
                    <div class="booking-details-booking-information-single-cont booking-admin-btn">
                        <p>{{bookings}}</p>
                    </div>
                </div>
                <!-- <div class="booking-details-booking-information-single">
                    <p>Arrival Time</p>
                    <div class="booking-details-booking-information-single-cont">
                        <p>{{(spaceDetails?.estimateArrivalTime).includes(':') ?
                            (spaceDetails?.estimateArrivalTime) : (spaceDetails?.estimateArrivalTime) + ':00'}}
                        </p>
                    </div>
                </div>
                <div class="booking-details-booking-information-single">
                    <p>No of Days</p>
                    <div class="booking-details-booking-information-single-cont">
                        <p>{{spaceDetails?.ofDays}}</p>
                    </div>
                </div>
                <div class="booking-details-booking-information-single">
                    <p>No of Guest</p>
                    <div class="booking-details-booking-information-single-cont">
                        <p>{{spaceDetails?.noOfGuest || 1}}</p>
                    </div>
                </div> -->
                <!-- <div class="booking-details-booking-information-single">
                    <p>Quantity</p>
                    <div class="booking-details-booking-information-single-cont">
                        <p>1</p>
                    </div>
                </div> -->
            </div>
            </div>
            <div *ngIf="spaceDetails?.spaceType !== 'Coworking Space'">

                <div class="d-flex py-2 ng-star-inserted" *ngFor="let bookings of bookingPeriod">
                    <div class="booking-details-booking-information-single">
                        <p>Date</p>
                        <div class="booking-details-booking-information-single-cont">
                            <p>{{ bookings.startDate | date : 'dd-MM-YYYY' }}</p>
                        </div>
                    </div>
                    <div class="booking-details-booking-information-single">
                        <p>Start Time</p>
                        <div class="booking-details-booking-information-single-cont">
                            <p>{{ formatTime(bookings.startTime) }}</p>
                        </div>
                    </div>
                    <div class="booking-details-booking-information-single">
                        <p>End Time</p>
                        <div class="booking-details-booking-information-single-cont">
                            <p>{{ formatTime(bookings.endTime) }}</p>
                        </div>
                    </div>
                    <div class="booking-details-booking-information-single">
                        <p>No of Hours</p>
                        <div class="booking-details-booking-information-single-cont">
                            <p>{{calculateHourDifference(bookings.startTime, bookings.endTime)}}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="booking-details-booking-msg" *ngIf="spaceDetails?.message">
                <p>Custom Message</p>
                <div class="booking-details-booking-information-single-cont">
                    <p>{{spaceDetails?.message}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="booking-details-payment h-100 d-flex flex-column justify-content-between">
                <div class="booking-details-payment-cont">
                    <h4>Payment Summary</h4>
                    <div class="booking-details-payment-info">
                        <div class="booking-details-payment-info-single">
                            <div class="booking-details-payment-info-label">
                                <p>Base Price</p>
                            </div>
                            <div class="booking-details-payment-info-amount">
                                <p>{{spaceDetails?.subtotal}} INR</p>
                            </div>
                        </div>
                        <div class="booking-details-payment-info-single">
                            <div class="booking-details-payment-info-label">
                                <p>GST ( 18%)</p>
                            </div>
                            <div class="booking-details-payment-info-amount">
                                <p>{{spaceDetails?.gst}} INR</p>
                            </div>
                        </div>
                    </div>
                    <div class="booking-details-payment-info-single booking-details-payment-total">
                        <div class="booking-details-payment-info-label">
                            <p>Total</p>
                        </div>
                        <div class="booking-details-payment-info-amount">
                            <p>{{spaceDetails?.bookingPrice}} INR</p>
                        </div>
                    </div>
                    
                    <div class="booking-details-payment-info border-0 ng-star-inserted" *ngIf="paymentDetail">
                        <div class="booking-details-payment-info-single">
                            <div class="booking-details-payment-info-label">
                                <p>Payment Method</p>
                            </div>
                            <div class="booking-details-payment-info-amount">
                                <p style="text-transform: capitalize;">{{paymentDetail?.method}}</p>
                            </div>
                        </div>
                        <div class="booking-details-payment-info-single" *ngIf="paymentDetail?.transaction_id">
                            <div class="booking-details-payment-info-label">
                                <p>Payment Id</p>
                            </div>
                            <div class="booking-details-payment-info-amount">
                                <p>{{paymentDetail?.id}}</p>
                            </div>
                        </div><!--bindings={}-->
                        <div class="booking-details-payment-info-single">
                            <div class="booking-details-payment-info-label">
                                <p>Txn Date &amp; Time</p>
                            </div>
                            <div class="booking-details-payment-info-amount">
                                <p>{{formatTimestamp(paymentDetail?.created_at,'date')}}</p>
                                <p class="booking-details-payment-info-amount-time">{{formatTimestamp(paymentDetail?.created_at,'time')}}</p>
                            </div>
                        </div>
                    </div>
                </div> 
                <!-- <div class="text-end" *ngIf="spaceDetails?.bookingStatus !== 'confirmed'">
                    <button class="btn text-white me-3" style="background-color: green;"
                        (click)="openConfirmation('Approve')">
                        <i class="fa-solid fa-check ml-1"></i> APPROVE
                    </button>
                    <button class="btn text-white" style="background-color: red;"
                        (click)="openConfirmation('Reject')">
                        <i class="fa-solid fa-xmark ml-1"></i> REJECT
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>