import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/share';
// import 'rxjs/add/operator/startWith';
import { BehaviorSubject } from 'rxjs';
import { SpaceConfig } from '../spaceConfig';

export class Location {
  id: number;
  name: string;

} export class Spaces {
  id: number;
  name: string;
  type: number;
  actual_name: string;
}

export class Account {
  order_no: number;
  name: string;
}

@Injectable()
export class AppGlobals {

  // use this property for property binding
  public accountsArray: BehaviorSubject<Account[]> = new BehaviorSubject([]);

  public spaceConfig: BehaviorSubject<SpaceConfig> = new BehaviorSubject({
    name: "Flexo",
    open_time: "09:00",
    close_time: "21:00",
    color: "#4B159B",
    invoice_prefix: "INV",
    bhaifi_linked_id: 0,
    is_super_admin: false
  });

  public lastInvoiceNo: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public lastCashInvoiceNo: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public selectedLocationId: BehaviorSubject<number> = new BehaviorSubject<number>(localStorage.selectedLocationId);

  public allLocationsArray: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  public locationsArray: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  public spacesData: BehaviorSubject<Spaces[]> = new BehaviorSubject([]);
  public isAdmin: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public spaceIds: BehaviorSubject<number[]> = new BehaviorSubject([]);

  setLocationId(locationId) {
    this.selectedLocationId.next(locationId);
  };

  setLastInvoiceNo(invoiceNo) {
    this.lastInvoiceNo.next(invoiceNo);
  };

  setLastCashInvoiceNo(cashInvoiceNo) {
    this.lastCashInvoiceNo.next(cashInvoiceNo);
  };

  setLocationsArray(locations) {
    this.locationsArray.next(locations);
  };

  setSpaceIDS(space_ids) {
    this.spaceIds.next(space_ids);
  };

  setSpacesData(spaces_data) {
    this.spacesData.next(spaces_data);
  };

  setIsAdmin(is_admin) {
    this.isAdmin.next(is_admin);
  };

  setAllLocationsArray(locations) {
    this.allLocationsArray.next(locations);
  };

  setSpaceConfig(space_details) {
    this.spaceConfig.next(space_details);
  };

  setAccountsArray(accounts) {
    this.accountsArray.next(accounts);
  };
}
