<div class="page-wrapper">


  <!--Dashboard content Wrapper starts-->
  <div class="dash-content-wrap">
    <!--Dashboard breadcrumb starts-->
    <div class="dash-breadcrumb">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-breadcrumb-content">
              <div class="dash-breadcrumb-left">
                <div class="breadcrumb-menu text-right sm-left">
                  <p class="list-address">Showing <b>{{selected_managers_data?.length}}</b> results</p>
                </div>
              </div>
              <button class="btn v3" (click)="openEditManagerDialog(null, 1,'add')"><i class="ion-plus-round"></i>Add Manager
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard breadcrumb ends-->
    <!--Dashboard content starts-->
    <div class="dash-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="invoice-panel">
              <div class="act-title">
                <h5><i style="align-self: center;" class="material-icons">person</i> Managers</h5>
              </div>
              <div class="invoice-body">
                <div class="table-responsive">
                  <table mat-table [dataSource]="paginatedBookings" matSort class="member-table">
                    <ng-container matColumnDef="serial">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> S.no</th>
                      <td class="table-cell" mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                      <td class="table-cell" mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        {{element.name}} &nbsp; <i *ngIf="element.is_admin" class="material-icons"
                          style="color: green;">verified_user</i>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <i class="material-icons">settings</i>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="openEditManagerDialog(element, 2,'edit')" mat-menu-item>
                            &nbsp;<mat-icon class="material-icons">edit</mat-icon>
                            &nbsp;<span>Edit</span>
                          </button>
                          <button (click)="openDeleteManagerDialog(element)" mat-menu-item>
                            &nbsp;<mat-icon class="material-icons">delete</mat-icon>
                            &nbsp;<span>DELETE</span>
                          </button>
                        </mat-menu>
                        <div class="basic-details">
                          <mat-icon class="material-icons">
                            email_on
                          </mat-icon>{{element.email}}
                        </div>
                        <div class="basic-details">
                          <mat-icon class="material-icons">
                            phone_on
                          </mat-icon>{{element.mobile}}
                        </div>
                      </td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="space_name">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Department Name</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        {{element.departmentName}}
                      </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="email">
                                              <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                                              <td class="table-cell" mat-cell *matCellDef="let element"> {{element.email}} </td>
                                            </ng-container> -->
                    <ng-container matColumnDef="status">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        <div style="color: green" *ngIf="element.status"> Active</div>
                        <div style="color: red" *ngIf="!element.status"> In-Active</div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="admin">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Admin</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        <!-- <mat-chip [style.background]="element.is_admin == 1 ? 'green': 'grey'" style = "border-radius: 6px;" class="status" selected>
                                                    {{element.is_admin == 1 ? "Admin": "Not-Admin"}}
                                                  </mat-chip> -->
                        <span class="book-pending" *ngIf="element.is_admin == 0">Not-Admin</span>
                        <span class="book-approved" *ngIf="element.is_admin == 1">Admin</span>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="ManagersDisplayedColumns">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ManagersDisplayedColumns;"
                      (click)="selection.toggle(row)">
                    </tr>
                  </table>
                </div>
              </div>
              <mat-paginator [length]="managers_data.length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[25,50,75,100]"
                   (page)="onPageChange($event)">
            </mat-paginator>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <!--Dashboard content Wrapper ends-->
</div>