<div class="row main_row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Cancellation Policy</h3>
            </div>
            <div class="card-body">
                <div class="panel panel-primary">
                    <div class="tab-menu-heading" style="padding: 0; border: none;">
                        <div class="tabs-menu radius-tab">
                            <ul ngbNav #nav3="ngbNav" [activeId]="activeTab"
                                class="nav panel-tabs panel-info">
                                <li [ngbNavItem]="0">
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="cancellationForm">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Enter Policy Title <span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <input matInput [errorStateMatcher]="errorMatcher" formControlName="title" (change)="getTitle($event)" [placeholder]="title ? '' : 'Enter policy title'" [(ngModel)]="title" type="text"
                                                                class="form-control filter-input" [required]="!submitted">
                                                            <mat-error *ngIf="!submitted && cancellationForm.get('title').invalid && cancellationForm.get('title').touched">
                                                                Title is required
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">Prior Booking Time<span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <textarea matInput [errorStateMatcher]="errorMatcher" formControlName="descriptionbefore7" (change)="getDescBefore7($event)" [placeholder]="descbefore7 ? '' : 'Enter prior booking time'" [(ngModel)]="descbefore7" type="text"
                                                                class="form-control filter-input" [required]="!submitted">
                                                            </textarea>
                                                            <mat-error *ngIf="!submitted && cancellationForm.get('descriptionbefore7').invalid && cancellationForm.get('descriptionbefore7').touched">
                                                                Description is required
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="required">After Booking Time <span
                                                                class="text-danger">*</span></label>
                                                        <div class="filter-checkbox">
                                                            <textarea matInput [errorStateMatcher]="errorMatcher" formControlName="descriptionafter7" (change)="getDescAfter7($event)" [placeholder]="descafter7 ? '' : 'Enter after booking time'" [(ngModel)]="descafter7" type="text"
                                                                class="form-control filter-input" [required]="!submitted">
                                                            </textarea>
                                                            <mat-error *ngIf="!submitted && cancellationForm.get('descriptionafter7').invalid && cancellationForm.get('descriptionafter7').touched">
                                                                Description is required
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="btn-list">
                                                    <a if class="btn btn-primary-light" style="float: right;" (click)="submit()">
                                                        Update
                                                    </a>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-body tabs-menu-body">
                    <div [ngbNavOutlet]="nav3"></div>

                </div>
            </div>
        </div>
    </div>
</div>
