import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import * as json2csv from "json2csv";
import { utils, writeFile } from 'xlsx';
import { LoaderService } from './loader.service';

@Injectable()
export class CsvDownloadFileService {
  Json2csvParser = json2csv.Parser;
  constructor(
    private _loaderService: LoaderService
  ) { }


  ExportData(data: any, title: string, file: string) {
    let Heading = [Object.keys(data[0])];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, Heading);
    utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, title);
    writeFile(wb, file);
  }

  public downloadFile(data: any, fields: Array<string>, filename?: string) {
    this._loaderService.displayLoader(true);
    let csvData = this.convertToCSV(data, fields);
    let file = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(file, filename);
    this._loaderService.displayLoader(false);
  }

  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public capitalizeFirstLetterAndRemoveUnderscore(string) {
    let updated_string = this.capitalizeFirstLetter(string)
    return updated_string.split('_').join(' ');
  }

  public convertToCSV(objArray: any, fields?) {
    let array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
    let str = '';
    let row = '';

    fields.forEach(field => {
        row += this.capitalizeFirstLetterAndRemoveUnderscore(field) + ',';
    });
    row = row.slice(0, -1);
    str += row + '\r\n';

    array.forEach(item => {
        let line = '';
        fields.forEach(field => {
            line += (item[field] !== undefined && item[field] !== null ? item[field] : '') + ',';
        });
        str += line.slice(0, -1) + '\r\n';
    });

    return str;
}

}
