import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-add-subscription-plan',
  templateUrl: './add-subscription-plan.component.html',
  styleUrls: ['./add-subscription-plan.component.css'],
})
export class AddSubscriptionPlanComponent implements OnInit {
  planId: number;
  formData: any;
  addPackageForm: FormGroup | any;
  ribbonList: any;

  editors: Editor[] = [];
  toolbar: Toolbar = [
    ['bold'],
    ['strike']
  ];
  constructor(private fb: FormBuilder, private queryService: QueriesService, private route: ActivatedRoute, private router: Router) {
    this.planId = this.route.snapshot.queryParams['planId']
  }

  ngOnInit(): void {
    this.getAllRibbon()
    this.addPackageForm = this.fb.group({
      name: ['', Validators.required],
      amount: ['', Validators.required],
      yearlyPrice: ['', Validators.required],
      sortOrder: ['', Validators.required],
      ribbon: [null],
      features: this.fb.array([])
    });
    if (this.planId) {
      this.getSubscriptionPlanByID()
    } else {
      this.addField();
    }
    this.initializeEditors();
  }


  getAllRibbon() {
    this.queryService.getAllActiveRibbon().then((res: any) => {
      this.ribbonList = res?.ribbons
    })
  }

  get features(): FormArray {
    return this.addPackageForm.get('features') as FormArray;
  }

  addField(): void {
    this.features.push(this.fb.group({
      label: ['', Validators.required],
    }));
    this.initializeEditors();
  }

  
  removeField(index: number): void {
    this.features.removeAt(index);
    this.editors.splice(index, 1)
  }


  navigateToList() {
    if (this.queryService.hasPermission('Paywall', 'view')) {
      this.router.navigate(['/home/plans-list'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  submit(): void {
    this.addPackageForm.markAllAsTouched()
    this.formData = {
      ...this.addPackageForm.value,
      features: this.addPackageForm.value?.features.reduce((acc: any, val: any) => {
        acc.push(val.label);
        return acc;
      }, [])
    }
    if (this.addPackageForm.valid) {
      if (this.planId) {
        this.updatePlan()
      } else {
        this.addPlan()
      }
    }
  }

  addPlan(): void {
    if (this.queryService.hasPermission('Paywall', 'add')) {
      this.queryService.addSubscriptionPlan(this.formData).then((res: any) => {
        if (res.success) {
          this.addPackageForm.reset();
          this.router.navigate(["/home/plans-list"])
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  updatePlan(): void {
    if (this.queryService.hasPermission('Paywall', 'edit')) {
    this.queryService.updateSubscriptionPlan(this.planId, this.formData).then((res: any) => {
      if (res.success) {
        this.router.navigate(["/home/plans-list"])
      }
      this.queryService.openSnackBar(res.message)
    })
  } else {
    this.queryService.accessDeniedAlert()
  }
  }

  getSubscriptionPlanByID(): void {
    this.queryService.getSubscriptionPlanByID(this.planId).then((res: any) => {
      const data = {
        ...res.data,
        features: JSON.parse(res.data.features).map(val => {
          return { label: val }
        })
      }
      const length = data.features.length
      if (data) {
        for (let i = 0; i < length; i++) {
          this.addField();
        }
        this.addPackageForm.patchValue(data)
      }
    })
  }

  getEditor(index: number): Editor {
    return this.editors[index];
  }

  initializeEditors() {
    this.editors = this.features.controls.map(() => new Editor());
  }

  ngOnDestroy() {
    this.editors.forEach(editor => editor.destroy());
  }
}
