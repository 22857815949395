import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogBoxComponent } from '../confirm-dialog-box/confirm-dialog-box.component';

@Component({
  selector: 'app-confirm-delete-amenity',
  templateUrl: './confirm-delete-amenity.component.html',
  styleUrls: ['./confirm-delete-amenity.component.css']
})
export class ConfirmDeleteAmenityComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}

  
    onYesClick(): void {
      // Close the dialog and pass 'true' as the result
      this.dialogRef.close(true);
    }
  
    onNoClick(): void {
      // Close the dialog and pass 'false' as the result
      this.dialogRef.close(false);
    }
}
