import { Injectable } from '@angular/core';
// import { RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {

constructor (private http: HttpClient) {}

  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  private base_url = 'api/v1/user';
  private validateUserUrl = environment.apiUrl + this.base_url + '/adminLogin';
  private validateSessionUrl = environment.apiUrl + 'api/v1/admin/validateSession';
  private verifyOtpForadminUrl = environment.apiUrl + 'api/v1/user/verifyOtpForAdmin';
  private forgotPasswordUrl = environment.apiUrl + 'api/v1/user/adminForgotPassword';
  private resetPasswordUrl = environment.apiUrl + 'api/v1/app/user/resetPassword';
  private logoutUrl = environment.apiUrl + this.base_url + '/logout';
  private updateAdminPasswordUrl = environment.apiUrl + 'api/v1/user/updateAdminPassword';

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  };

  private options = {
    headers: this.headers, 
    withCredentials: true
  };

  login (mobile: string, password: string): Promise<any> {

    return  this.http.post(this.validateUserUrl, JSON.stringify ({'mobile': mobile, 'password': password}), this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  validateSession(): Promise<any> {
    const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
    
    // Set the headers with the token
    const headers = {
      'Authorization': `Bearer ${token}`, // Assuming it's a bearer token
      'Content-Type': 'application/json'
    };
  
    // Pass headers with the request options
    const requestOptions = { headers: headers };
  
    return this.http.get(this.validateSessionUrl, requestOptions)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }
  

  deletCookies() {
    return  this.http.put(this.logoutUrl, {}, this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  forgotPassword (mobile: string): Promise<any> {

    return  this.http.post(this.forgotPasswordUrl, JSON.stringify ({'mobile': mobile}), this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  verifyOtpForAdmin (mobile: string, otp: string): Promise<any> {
    return this.http.post(this.verifyOtpForadminUrl, JSON.stringify ({mobile, otp}), this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  updateAdminPassword (mobile: string, password: string): Promise<any> {
    return this.http.post(this.updateAdminPasswordUrl, JSON.stringify ({mobile, password}), this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  resetPassword (alternate_id: string, password: string): Promise<any> {
    return  this.http.put(this.resetPasswordUrl, JSON.stringify ({alternate_id, password, type: 1}), this.options)
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }
}
