import { Component, OnInit } from '@angular/core';
// import { MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, MatSort, MatTableDataSource, MatOption } from '@angular/material';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmedValidator } from '../confirmed.validator';
import { AuthenticationService } from '../services/authentication.service';
import { LoaderService } from '../services/loader.service';
import { User } from './user';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  user: User;
  form: UntypedFormGroup = new UntypedFormGroup({});
  constructor(
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public fb: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.user = new User;
    this.form = fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    })
  }
  get f() {
    return this.form.controls;
  }
  public show_forgot_password = false;
  public alert_message = '';
  public alert_success = '';
  public otp_sent = false;
  public otp_;
  public show_update_password_form = false;
  public is_logged_in: boolean = false;
  email = new UntypedFormControl('', [Validators.required, Validators.email])
  mobile = new UntypedFormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]);

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid Email Address' :
        '';
  }

  getMobileErrorMessage() {
    return this.mobile.hasError('required') ? 'You must enter a value' :
      this.mobile.hasError('pattern') ? 'Not a valid Mobile Number' :
        '';
  }
  password = new UntypedFormControl('', [Validators.required]);
  otp = new UntypedFormControl('', [Validators.required]);

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? 'You must enter a value' : '';
  }

  getOtpErrorMessage() {
    return this.otp.hasError('required') ? 'Enter otp' : '';
  }

  private _verifiySession() {
    this.authenticationService.validateSession()
      .then(data => {
        if (data.verified) {
          this.router.navigate(['home/dashboard']);
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  ngOnInit() {
    this._verifiySession();
  }

  validate() {
    this.authenticationService.login(this.user.mobile, this.user.password)
      .then(res => {
        console.log(res);
        
        localStorage.setItem('token', res.token);
        if (res.success) {
          localStorage.setItem('permission', JSON.stringify(res.permissions?.module));
          this.router.navigate(['home/dashboard'])
          .then(() => {
            window.location.reload();
          });
        } else {
          this.alert_message = res.message;
          this.is_logged_in = false;
        }
      })
      .catch(err => {
        console.log('err')
      })
  }

  public forgotPassword() {
    this.authenticationService.forgotPassword(this.user.mobile)
      .then(res => {
        if (res.success) {
          this.alert_success = 'success';
          this._openSnackBar(res.message, "Dismiss");
          this.otp_sent = true;
        } else {
          this.alert_success = 'warn';
          this._openSnackBar(res.message, "Dismiss");
        }
      })
      .catch(err => console.log('err'))
  }

  resendOtp(){
    this.otp_sent = false;
    this.otp.patchValue('');
  }

  public verifyOtp() {
    this.authenticationService.verifyOtpForAdmin(this.user.mobile, this.otp_)
      .then(res => {
        if (res.success) {
          this._openSnackBar(res.message, "Dismiss");
          this.show_update_password_form = true;
          this.show_forgot_password = false;
        } else {
          this._openSnackBar(res.message, "Dismiss");
        }
      })
  }

  public updatePassword() {
    this.authenticationService.updateAdminPassword(this.user.mobile, this.user.password)
      .then(res => {
        if (res.success) {
          this._openSnackBar(res.message, "Dismiss");
          this.show_update_password_form = false;
          this.otp_sent = false;
          this.otp_ = null;
          // this.user = null;
        }
      })
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 7000,
    });
  }

  otpClear(){
    this.otp.patchValue('');
  }
}
