import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { QueriesService } from 'src/app/services/queries.service';
import { SpaceService } from 'src/app/services/space.service';
import { MyErrorStateMatcher } from '../amenities/error-state-matcher';
@Component({
  selector: 'app-update-cancellation-policy',
  templateUrl: './update-cancellation-policy.component.html',
  styleUrls: ['./update-cancellation-policy.component.css'],
  providers: [ErrorStateMatcher, MatDialog]
})
export class UpdateCancellationPolicyComponent {

  activeTab = 0;
  @ViewChild(NgbNavItem) navItem!: NgbNavItem;
  errorMatcher = new MyErrorStateMatcher();
  cancellationForm:FormGroup;
  iconCss: any;
  fallbackIcon = '';
  title:any;
  descbefore7:any = '';
  descafter7:any = '';
  id:any;
  is_id_exist:boolean = false;
  submitted: boolean = false;

  constructor(private spaceService:SpaceService, private fb:FormBuilder, private snackBar:MatSnackBar, public dialog: MatDialog, private route: ActivatedRoute, private router:Router, private queryService:QueriesService) {
     
    this.cancellationForm = fb.group({
      title: ['', Validators.required],
      descriptionbefore7: ['', Validators.required],
      descriptionafter7: ['', Validators.required]
    })
  }

  ngOnInit(){
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.id = params.get('id');
        if (this.id) {
          this.is_id_exist = true;
          this.spaceService.getCancellationByIdPolicy(this.id).subscribe((res:any)=>{
            this.title = res.cancellationPolicy.policyName;
            this.descbefore7 = res.cancellationPolicy.before7;
            this.descafter7 = res.cancellationPolicy.after7;
          })
        }
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getTitle(event:any){
    this.title = event.target.value;
  }

  getDescBefore7(event:any){
    this.descbefore7 = event.target.value;
  }

  getDescAfter7(event:any){
    this.descafter7 = event.target.value;
  }

  submit(){
    if (this.queryService.hasPermission('Cancellation Policy', 'edit')) {
      let data = {
        'before7': this.descbefore7,
        'after7': this.descafter7,
        'policyName': this.title,
        'cancellationId': this.id
      }
      if(this.cancellationForm.valid){
          this.spaceService.updateCancellationPolicy(data).subscribe((res:any) => {
            this.openSnackBar(res.result.message, "Dismiss");
  
            this.router.navigate(['/home/cancellation-policy']);
          })
      } else{
        this.cancellationForm.markAllAsTouched();
      }
    } else {
      this.queryService.accessDeniedAlert()
    }
  }
}
