import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { SpaceService } from '../../services/space.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-visit-schedule',
  templateUrl: './visit-schedule.component.html',
  styleUrls: ['./visit-schedule.component.css'],
  providers: [DatePipe]
})
export class VisitScheduleComponent {

  workSpace = [
    { workSpaceName: 'Coworking Space', typeOfSpace: "Coworking" },
    { workSpaceName: 'Managed Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Private Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Shared Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Virtual Office', typeOfSpace: "Long-Term" },
    { workSpaceName: 'Coworking Café/Restaurant', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Shoot Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Recording Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Podcast Studio', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Activity Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Turf', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Sports Venue', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Party Space', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Banquet Hall', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Gallery', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Classroom', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Private Cabin', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Meeting Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Training Room', typeOfSpace: "Short-Term" },
    { workSpaceName: 'Event Space', typeOfSpace: "Short-Term" },
  ]
  workSpaceNames: any = [];
  startDate: any;
  endDate: any;
  spaceType:any;
  allBookings: any = [];
  bookingType!: string;
  selectedSpaceName = [];
  selectedBookingType: string = 'allBooking';
  selectedStatus:any;
  bookingIdValueForSearch: any;
  hostSpaceName: any = [];
  spaceNames: any = [];
  host:any[] = [];
  selectedHost: any;
  user_search:any;
  pageSize = 25;
  currentPage = 0;
  paginatedBookings = [];

  constructor(private datePipe: DatePipe,private spaceService:SpaceService) { }

  ngOnInit(): void {
    this.getWorkSpace();
    this.getAllSpaceByHostId();
    this.getAllHostSpaces();
    this.getHost();
  }

  getHost(){
    this.spaceService.getHost().subscribe((res:any) => {
      for(let i=0; i<res.data.host.length; i++){
        this.host.push(res.data.host[i]);
      }
    })
  }

  getWorkSpace() {
    for (let i = 0; i < this.workSpace.length; i++) {
      this.workSpaceNames.push(this.workSpace[i]?.workSpaceName);
    }
  }

  dateRangeChange() {
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    if (this.startDate && this.endDate || !this.startDate && !this.endDate) {
      this.getAllHostSpaces();
    }
  }

  imageErrorHandler(event:any){
    this.spaceService.handleImageError(event);
  }

  getAllSpaceByHostId(){
    this.spaceService.getAllSpaceNameByHostId().subscribe((res:any)=>{
      this.hostSpaceName = res.data.spaces;
      for (let i = 0; i < this.hostSpaceName.length; i++) {
        this.spaceNames.push(this.hostSpaceName[i]);
      }
      console.log(this.spaceNames)
    })
  }

  getAllHostSpaces(){
    let data = {
      // userId: this.userId,
      // bookingType: this.selectedBookingType,
      spaceType: this.spaceType,
      startDate: this.startDate ? this.startDate+ ' 00:00:00' : this.startDate,
      endDate: this.endDate ? this.endDate+ ' 23:59:59' : this.endDate,
      bookingStatus:this.selectedStatus,
      bookingId : this.bookingIdValueForSearch,
      spaceId : this.selectedSpaceName,
      hostId: this.selectedHost,
      user_search : this.user_search
    };
    this.spaceService.getHostVisit(data).subscribe((data:any)=>{
      if(data.result.success){
        this.allBookings = data?.result?.visit;
        this.updatePaginatedBookings();
      }else{
        this.allBookings=[];
      }
    })
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.allBookings.slice(startIndex, endIndex);
    console.log(this.paginatedBookings);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }
}
