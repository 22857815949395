<div *ngIf="hostList" class="review-popup p-3">
    <div style="display: flex; justify-content: space-between;">
        <label class="top-label">Select your host</label>
        <button mat-button class="cross material-icons" style="background-color: red; color: white;"
            (click)="closeDialog(null)">
            X
        </button>
    </div>

    <div class="scrollable-content assign_lead">
        <ng-select [items]="hostList" bindLabel="name" bindValue="id" [(ngModel)]="selected" [multiple]="true"
            [closeOnSelect]="false" [placeholder]="selected ? '':'Select Host' ">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox"  [ngModel]="item$.selected" /> {{ item.name | uppercase
                }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input class="custom-checkbox" id="item-{{ index }}" type="checkbox" [disabled]="item.disabled" [ngModel]="item$.selected">{{ item.name
                    }}
            </ng-template>
        </ng-select>
    </div>

    <div class="mt-5" style="display: flex; justify-content: end;">
        <button mat-button class="material-icons" style="background-color: green; color: white;"
            (click)="assignLead()">Assign</button>
    </div>
</div>