<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Paywall History</h3>
      </div>
      <div class="card-body">
        <div class="card tabs-menu-body">
          <div class="card-header ps-2">
            <h3 class="card-title">Filter Transaction</h3>
          </div>
          <div class="row mx-0 align-items-center py-4 gy-md-1 gy-2">
            <div class="col-md-3">
              <div>
                <div class="filter-checkbox">
                  <ng-select class="form-select drop_down form-control" [(ngModel)]="hostId"
                    [placeholder]="hostId  ? '' :'Select Host'">
                    <ng-option *ngFor="let host of host" [value]="host.id">
                      {{host.companyName}} - {{host.mobile}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width" appearance="fill" class=" calender w-100">
                <input class="place_txt" a matInput [matDatepickerFilter]="startDateFilter" [(ngModel)]="startDate"
                  [matDatepicker]="start" placeholder="Start Date" (dateChange)="onStartDateChange($event)">
                  <i (click)="startDate = null" class="fa fa-times " aria-hidden="true"></i>
                <mat-datepicker-toggle matIconSuffix [for]="start" style="color: #f76900;"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="fill" class="w-100">
                <input matInput [matDatepickerFilter]="endDateFilter" [matDatepicker]="end" [(ngModel)]="endDate"
                  placeholder="End Date" (dateChange)="onEndDateChange($event)">
                  <i (click)="endDate = null" class="fa fa-times " aria-hidden="true"></i>
                <mat-datepicker-toggle matIconSuffix [for]="end" style="color: #f76900;"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
              </mat-form-field>
            </div>
            <span class="col-md-2">
              <button (click)="getFilteredData()" class="btn btn-primary" type="button"><i class="fe fe-search"></i>
                Filter</button>
            </span>
          </div>

        </div>
        <div class="card tabs-menu-body">
          <div class="card-header ps-2">
            <h3 class="card-title">Paywall History</h3>
          </div>
          <div class="card-body">
            <div>
              <div class="col-md-12">
                <div class="div align-items-center" style="display: flex; justify-content: end;gap:30px">
                  <label class="form-label">Search</label>
                  <input type="text" matInput (input)="applyFilter($event.target)">
                </div>
              </div>
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort="sort" class="tableContainer w-100">
                  
                  <ng-container matColumnDef="serial">
                    <th style="width: 7%;" mat-header-cell *matHeaderCellDef mat-sort-header> S.no
                    </th>
                    <td style="width: 7%;" mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                  </ng-container>

                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr. No. </th>
                    <td mat-cell *matCellDef="let row; let i = index">{{ (paginator.pageIndex *
                      paginator.pageSize) + (i + 1) }} </td>

                  </ng-container>

                  <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Date and Time </th>
                    <td mat-cell *matCellDef="let row"> {{row.createdAt | date: 'dd-MM-yyyy'}},
                      {{row.createdAt | date: 'hh:mma'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="hostName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Host Company Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.hostCompanyName}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice No. </th>
                    <td mat-cell *matCellDef="let row"> {{row.invoiceNo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="space">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Space Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.spaceName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="creditPlanName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.paywallPlanName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="paywallPrice">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan Price</th>
                    <td mat-cell *matCellDef="let row"> {{row.paywallPrice}} </td>
                  </ng-container>
                  <ng-container matColumnDef="GST">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GST(18%)</th>
                    <td mat-cell *matCellDef="let row"> {{row.GST}} </td>
                  </ng-container>
                  <ng-container matColumnDef="grandTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Payable Price</th>
                    <td mat-cell *matCellDef="let row"> {{row.grandTotal}} </td>
                  </ng-container>


                  <ng-container matColumnDef="paymentMethod">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Method</th>
                    <td mat-cell *matCellDef="let row"> {{row.paymentMethod}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row"><button class="btn btn-primary" (click)="openInvoice(row)"><i
                          class="fa fa-download text-white"></i>
                        Download
                        Invoice</button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row text-center" *matNoDataRow>
                    <td class="mat-cell" colspan="10">No data matching the filter</td>
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator pageIndex="0" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                [showFirstLastButtons]="true" [length]="dataSource?.data?.length">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>