import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { SpaceService } from '../.././services/space.service';
import { ConfirmDialogBoxComponent } from '../confirm-dialog-box/confirm-dialog-box.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css'],
  providers: [DatePipe, MatDialog]
})

export class BookingDetailsComponent {

  id: any;
  spaceDetails: any;
  bookingPeriod: any;
  paymentDetail: any;
  formattedDates: any;
  min_hrs: number;

  constructor(private datePipe: DatePipe, private spaceService: SpaceService, private route: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getSpaceDetail();
  }

  getSpaceDetail() {
    this.spaceService.getHostSpaceDetail(this.id).subscribe((data: any) => {
      this.spaceDetails = data?.data?.bookingdetail[0];
      console.log(this.spaceDetails);
      console.log(typeof(Number(this.spaceDetails.minimum_hours)));
      this.min_hrs = Number(this.spaceDetails.minimum_hours);
      this.bookingPeriod = this.spaceDetails?.bookingPeriods ?? [];
      if (this.bookingPeriod.length) {
        this.formattedDates = this.bookingPeriod.map((date: moment.MomentInput) => moment(date).format('DD-MM-YYYY'));
      }
      this.paymentDetail = JSON.parse(this.spaceDetails?.payment_detail);
    })
  }

  calculateHourDifference(startTime: string, endTime: string) {
    let [startHourInteger, startMinute] = startTime.split(":").map(Number);
    let [endHourInteger, endMinute] = endTime.split(":").map(Number);
  
    // Handle cases where minutes are missing
    if (isNaN(startMinute)) {
      startMinute = 0;
    }
    if (isNaN(endMinute)) {
      endMinute = 0;
    }
  
    // Special case: if startTime and endTime are both 00:00, return 24 hours
    if (startHourInteger === 0 && startMinute === 0 && endHourInteger === 0 && endMinute === 0) {
      return 24;
    }
  
    // Convert hours to minutes and add minutes
    const startTotalMinutes = startHourInteger * 60 + startMinute;
    const endTotalMinutes = endHourInteger * 60 + endMinute;
  
    // Calculate time difference in minutes
    let timeDifferenceInMinutes = endTotalMinutes - startTotalMinutes;
  
    // Handle negative time difference (if endTime is past midnight)
    if (timeDifferenceInMinutes < 0) {
      timeDifferenceInMinutes += 24 * 60; // Add 24 hours in minutes
    }
  
    // Return time difference in hours
    return timeDifferenceInMinutes / 60;
  }

  formatTimestamp(timestamp: number, type: any) {
    if (type == 'date') {

      return this.datePipe.transform(timestamp * 1000, 'dd-MM-yyyy');
    } else {
      return this.datePipe.transform(timestamp * 1000, 'shortTime');
    }
  }

  handleImageError(event: any) {
    const imgElement = event.target as HTMLImageElement
    imgElement.src = 'assets/images/details_placeholder_image.jpg';
    imgElement.alt = 'Failed to Load Image';
  }


  openConfirmation(confirmationType: string) {
    let payload = {
      show: true,
      title: `${confirmationType} Booking`,
      price: this.spaceDetails.bookingPrice
    };
    const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
      data: payload
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let payload = {
          show: true,
          title: `${confirmationType} Booking`,
          message: `Are you sure you want to ${confirmationType} this booking?`,
        };
        const dialog_Ref = this.dialog.open(ConfirmDialogBoxComponent, {
          width: '500px',
          data: payload
        });
        dialog_Ref.afterClosed().subscribe((result) => {
          if (result) {
          }
        })
      }
    });
  }

  formatTime(timeStr: any) {
    if (!timeStr) return '';

    const [hours, minutes] = timeStr.split(':');
    const hoursInt = parseInt(hours, 10);
    const period = hoursInt >= 12 ? 'PM' : 'AM';
    const hours12 = hoursInt % 12 || 12;
    return `${hours12}:${minutes} ${period}`;
  }

}
