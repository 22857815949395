import { Routes } from "@angular/router";
import { AddSpaceComponent } from "./add-space/add-space.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { HomeComponent } from "./home/home.component";
import { EditHostProfileComponent } from "./hostview/edit-host-profile/edit-host-profile.component";
import { HostviewComponent } from "./hostview/hostview.component";
import { CreditWalletHistoryComponent } from "./invoice-management/credit-wallet-history/credit-wallet-history.component";
import { EnterpriseEnquiryComponent } from "./invoice-management/enterprise-enquiry/enterprise-enquiry.component";
import { PaywallHistoryComponent } from "./invoice-management/paywall-history/paywall-history.component";
import { CoWorkingLeadsComponent } from "./leads/co-working-leads.component";
import { LeadPriceComponent } from "./leads/lead-price/lead-price.component";
import { LeadsComponent } from "./leads/leads.component";
import { LoginComponent } from "./login/login.component";
import { ManagersComponent } from "./managers/manager.component";
import { MeetingRoomComponent } from "./meeting_rooms/meetingroom.component";
import { AddSubscriptionPlanComponent } from "./paywall-subscription/add-subscription-plan/add-subscription-plan.component";
import { SubscriptionPlansComponent } from "./paywall-subscription/subscription-plans/subscription-plans.component";
import { permissionGuard, submodulePermissionGuard } from "./permission-guard.guard";
import { AddPermissionComponent } from "./permission/add-permission/add-permission.component";
import { PermissionListComponent } from "./permission/permission-list/permission-list.component";
import { QueriesComponent } from "./queries/queries.component";
import { AddRibbonComponent } from "./ribbon-management/add-ribbon/add-ribbon.component";
import { RibbonListComponent } from "./ribbon-management/ribbon-list/ribbon-list.component";
import { SpaceDetailComponent } from "./space-detail/space-detail.component";
import { AllSpacesReviewComponent } from "./spaces/all-spaces-review/all-spaces-review.component";
import { AmenitiesListComponent } from "./spaces/amenities-list/amenities-list.component";
import { AmenitiesComponent } from "./spaces/amenities/amenities.component";
import { BookingDetailsComponent } from "./spaces/booking-details/booking-details.component";
import { BookingReportComponent } from "./spaces/booking-report/booking-report.component";
import { BookingsComponent } from "./spaces/bookings/bookings.component";
import { CancellationPolicyComponent } from "./spaces/cancellation-policy/cancellation-policy.component";
import { MyBookingsComponent } from "./spaces/my-bookings/my-bookings.component";
import { SpaceRequestCreateComponent } from "./spaces/space-request-create/space-request-create.component";
import { SpaceRequestDeleteComponent } from "./spaces/space-request-delete/space-request-delete.component";
import { SpaceRequestEditComponent } from "./spaces/space-request-edit/space-request-edit.component";
import { SpaceReviewComponent } from "./spaces/space-review/space-review.component";
import { SpacesComponent } from "./spaces/spaces.component";
import { UpdateCancellationPolicyComponent } from "./spaces/update-cancellation-policy/update-cancellation-policy.component";
import { ViewSpaceEditComponent } from "./spaces/view-space-edit/view-space-edit.component";
import { ViewSpacesComponent } from "./spaces/view-spaces/view-spaces.component";
import { VisitScheduleComponent } from "./spaces/visit-schedule/visit-schedule.component";
import { SubscriptionInquiryComponent } from "./subscription-inquiry/subscription-inquiry.component";
import { TeamCabinComponent } from "./teamcabins/teamcabin.component";
import { EditUserProfileComponent } from "./userview/edit-user-profile/edit-user-profile.component";
import { UserviewComponent } from "./userview/userview.component";
import { AddCreditPlansComponent } from "./wallet/add-credit-plans/add-credit-plans.component";
import { AddCreditPointComponent } from "./wallet/add-credit-point/add-credit-point.component";
import { CreditWalletPlansComponent } from "./wallet/credit-wallet-plans/credit-wallet-plans.component";
import { WorkersComponent } from "./workers/workers.component";
export const routes: Routes = [

  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    component: HomeComponent,
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      {
        path: "spaces", component: SpacesComponent, data: { Title: 'Space' }, canActivate: [permissionGuard]
      },
      { path: "add-space", component: AddSpaceComponent, data: { Title: 'Space', Type: 'add' }, canActivate: [permissionGuard] },
      { path: "update-space/:id", component: AddSpaceComponent, data: { Title: 'Space', Type: 'edit' }, canActivate: [permissionGuard] },
      { path: "space-review/:id", component: SpaceReviewComponent, data: { Title: 'Space' }, canActivate: [permissionGuard] },
      { path: "all-spaces-review", component: AllSpacesReviewComponent, data: { Title: 'Review' }, canActivate: [permissionGuard] },
      { path: "queries", component: QueriesComponent, data: { Title: 'Queries' }, canActivate: [permissionGuard] },
      { path: "space-detail/:id", component: SpaceDetailComponent },
      { path: "teamcabins", component: TeamCabinComponent },
      { path: "meeting-rooms", component: MeetingRoomComponent },
      {
        path: "booking-list", component: MyBookingsComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Bookings', subTitle: 'List' }
      },
      {
        path: "bookings", component: BookingsComponent
      },
      {
        path: "booking-report", component: BookingReportComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Bookings', subTitle: 'Report' }
      },
      {
        path: "booking-details/:id", component: BookingDetailsComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Bookings', subTitle: 'List' }
      },
      { path: "host-view", component: HostviewComponent, data: { Title: 'Host View' }, canActivate: [permissionGuard] },
      { path: "edit-host-profile/:id", component: EditHostProfileComponent, data: { Title: 'Host View', Type: 'edit' }, canActivate: [permissionGuard] },
      { path: "user-view", component: UserviewComponent, data: { Title: 'User View' }, canActivate: [permissionGuard] },
      { path: "edit-user-profile/:id", component: EditUserProfileComponent, data: { Title: 'User View', Type: 'edit' }, canActivate: [permissionGuard] },
      { path: "amenities", component: AmenitiesComponent, data: { Title: 'Amenities', Type: 'add' }, canActivate: [permissionGuard] },
      { path: "update-amenities/:id", component: AmenitiesComponent, data: { Title: 'Amenities', Type: 'edit' }, canActivate: [permissionGuard] },
      { path: "amenities-list", component: AmenitiesListComponent, data: { Title: 'Amenities' }, canActivate: [permissionGuard] },
      { path: "visit-schedule", component: VisitScheduleComponent, data: { Title: 'Visit Schedule' }, canActivate: [permissionGuard] },
      { path: "cancellation-policy", component: CancellationPolicyComponent, data: { Title: 'Cancellation Policy' }, canActivate: [permissionGuard] },
      { path: "update-cancellation-policy/:id", component: UpdateCancellationPolicyComponent, data: { Title: 'Cancellation Policy', Type: 'edit' }, canActivate: [permissionGuard] },
      { path: "space-request-create", component: SpaceRequestCreateComponent, data: { Title: 'Space Request', Type: 'view' }, canActivate: [permissionGuard] },
      { path: "space-request-edit", component: SpaceRequestEditComponent, data: { Title: 'Space Request', Type: 'view' }, canActivate: [permissionGuard] },
      { path: "space-request-delete", component: SpaceRequestDeleteComponent, data: { Title: 'Space Request', Type: 'view' }, canActivate: [permissionGuard] },
      { path: "view-spaces/:viewItem/:id", component: ViewSpacesComponent, data: { Title: 'Space Request' }, canActivate: [permissionGuard] },
      { path: "view-update-space/:viewItem/:id", component: ViewSpaceEditComponent, data: { Title: 'Space Request' }, canActivate: [permissionGuard] },
      { path: "managers", component: ManagersComponent, data: { Title: 'Managers' }, canActivate: [permissionGuard] },
      { path: "workers", component: WorkersComponent, data: { Title: 'Workers' }, canActivate: [permissionGuard] },
      {
        path: "credit-point", component: AddCreditPointComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Credit Wallet', subTitle: 'Add Point', Type: 'add' }
      },
      {
        path: "package-list", component: CreditWalletPlansComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Credit Wallet', subTitle: 'Package' }
      },
      {
        path: "create-package", component: AddCreditPlansComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Credit Wallet', subTitle: 'Package', Type: 'add' }
      },
      {
        path: "update-package", component: AddCreditPlansComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Credit Wallet', subTitle: 'Package', Type: 'edit' }
      },
      {
        path: "create-plan", component: AddSubscriptionPlanComponent, data: { Title: 'Paywall',Type:'add' }, canActivate: [permissionGuard]
      },
      {
        path: "update-plan", component: AddSubscriptionPlanComponent, data: { Title: 'Paywall', Type: 'edit' }, canActivate: [permissionGuard]
      },
      { path: "plans-list", component: SubscriptionPlansComponent, data: { Title: 'Paywall' }, canActivate: [permissionGuard] },
      { path: "credit-inquiry", component: EnterpriseEnquiryComponent },
      { path: "subscription-inquiry", component: SubscriptionInquiryComponent },
      {
        path: "paywall-transaction", component: PaywallHistoryComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Invoice Management', subTitle: 'Paywall' }
      },
      {
        path: "wallet-transaction", component: CreditWalletHistoryComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Invoice Management', subTitle: 'Credit Wallet' }
      },
      { path: "add-ribbon", component: AddRibbonComponent, data: { Title: 'Ribbon',Type:'add' }, canActivate: [permissionGuard] },
      { path: "edit-ribbon/:id", component: AddRibbonComponent, data: { Title: 'Ribbon', Type:'edit' }, canActivate: [permissionGuard] 
    },
      { path: "ribbon-list", component: RibbonListComponent, data: { Title: 'Ribbon' }, canActivate: [permissionGuard] },
      {
        path: "leads-co-working", component: CoWorkingLeadsComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Leads', subTitle: 'Co Working' }
      },
      {
        path: "leads-long-term", component: LeadsComponent, canActivate: [submodulePermissionGuard],
        data: { Title: 'Leads', subTitle: 'Long Term' },
      },
      { path: "lead-price", component: LeadPriceComponent, data: { Title: 'Lead Price',Type:'view' }, canActivate: [permissionGuard] },
      { path: "queries", component: QueriesComponent, data: { Title: 'Queries' }, canActivate: [permissionGuard] },
      { path: "add-permission", component: AddPermissionComponent, data: { Title: 'Department',Type:'view' }, canActivate: [permissionGuard] 
    },
      { path: "update-permission", component: AddPermissionComponent, data: { Title: 'Department',Type:'edit' }, canActivate: [permissionGuard] 
    },
      { path: "permission", component: PermissionListComponent, data: { Title: 'Department' }, canActivate: [permissionGuard] },
      { path: "faqs", component: FaqsComponent, data: { Title: 'FAQs' }, canActivate: [permissionGuard] }
    ],
  },
];

export const navigatableComponents = [
  LoginComponent,
  HomeComponent,
  SpacesComponent,
  ManagersComponent,
  QueriesComponent,
  SpaceDetailComponent,
  LeadsComponent,
  WorkersComponent,
  AddSpaceComponent,
  TeamCabinComponent,
  MeetingRoomComponent,
];
