import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpaceService } from 'src/app/services/space.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-review',
  templateUrl: './edit-review.component.html',
  styleUrls: ['./edit-review.component.css']
})
export class EditReviewComponent implements OnInit {

  public ref;
  public reviewForm: FormGroup;
  public isLoading: boolean = false;
  public spaceId: any;
  submitForm:boolean = false;
  myReview:any;

  constructor(private _fb: FormBuilder,
    private spaceService: SpaceService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.reviewForm = this._fb.group({
      rating: ['', Validators.required],
      review: ['', [Validators.required, Validators.maxLength(250)]],
      book_again:['']
    });
  }

  ngOnInit(){
    this.getReviewData(this.data.id);
  }

  getReviewData(id:any){
    this.spaceService.getOneReviewById(id).subscribe((res:any) => {
      this.myReview = res.data;
      this.spaceId = this.myReview.spaceId;
      this.reviewForm.patchValue({
        rating: this.myReview.rating.toString(),
        additionalComments: this.myReview.Review || ''
      });
    })
  }

  onCheckChange(event:any){
    this.reviewForm.patchValue({
      book_again: event.target.checked
    })
  }
  
  onSumbit() { 
    console.log(this.reviewForm.value);
    let payload = ({
      rating:this.reviewForm.value.rating,
      Review:this.reviewForm.value.review,
    })
    if(this.reviewForm.valid && !this.isLoading){
      this.spaceService.updateMyReview(this.data.id, this.spaceId, payload).subscribe((res:any) => {
        console.log(res);
        if (res.result.success) {
          this.closeDialog({ success: true, message: res.result.message });
        }
      })
    } else{
      this.submitForm = true;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public closeDialog(options) {
    this.ref.close(options);
  }

}
