import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['id', 'departmentName', 'status', 'Actions'];
  pageSizeOptions = [25,50,75,100];
  pageSize = 25;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorPageSize') paginatorPageSize!: MatPaginator;


  constructor(
    private queryService: QueriesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllDepartment()
  }

  updateDetails(departmentId:number){
    if (this.queryService.hasPermission('Department', 'edit')) {
      this.router.navigate(["/home/update-permission"], { queryParams: { departmentId }})
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  getAllDepartment() {
    this.queryService.getAllDepartment()
      .then(res => {
        if (res.success) {
          this.dataSource = new MatTableDataSource(res.data);
          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }, 200);
        }
      })
      .catch(error => {
        console.log(error);
      })
  }


  applyFilter(value: any) {
    value = value.value.trim().toLowerCase();
    this.dataSource.filter = value;
  }


  changeStatus(e:any,list: any): void {
    e.preventDefault()
    if (this.queryService.hasPermission('Department', 'edit')) {
      const data = {
        status: list.status === "0" ? "1" : "0"
      }
      this.queryService.updateDepartmentStatus(list.id, data).then((res: any) => {
        if (res.success) {
          this.getAllDepartment()
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

}