<div class="review-popup">
    <div class="review-popup-header">
        <h2>Write a review</h2>
        <button mat-button type="button" (click)="closeDialog(null)" class="btn-close">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="review-popup-content">
        <form [formGroup]="reviewForm">
            <div class="review-popup-rating">
                <div *ngFor="let rating of [5, 4, 3, 2, 1]" class="review-popup-rating-single">
                    <input formControlName="rating" [value]="rating.toString()" type="radio"
                        [id]="'review-rating-' + rating">
                    <label [for]="'review-rating-' + rating">
                        <ul class="product-rating-c">
                            <li *ngFor="let star of [1, 2, 3, 4, 5]" class="">
                                <i class="fa" [ngClass]="{'fa-star': star <= rating, 'fa-star-o': star > rating}"
                                    aria-hidden="true"></i>
                            </li>
                        </ul>
                    </label>
                    <mat-error *ngIf="reviewForm.get('rating')?.errors?.required && (reviewForm.get('rating').touched || reviewForm.get('rating').dirty)">
                        Please select an option
                    </mat-error>
                </div>

                <!-- <small class="text-danger" *ngIf="!reviewForm?.get('rating').valid && submitForm">*Please select a rating</small> -->
            </div>
            <div class="review-popup-cmnt">
                <label for="review-popup-cmnt-textarea">Additional Comments</label>
                <textarea formControlName="review" maxlength="250" id="review-popup-cmnt-textarea"
                    [(ngModel)]="myReview.Review" required>
                </textarea>
                <mat-error *ngIf="reviewForm.get('review')?.errors?.required && (reviewForm.get('review').touched || reviewForm.get('review').dirty) && submitForm">
                    Please add a review
                </mat-error>
            </div>
            <div class="form-check mb-4">
                <input (change)="onCheckChange($event)" type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label text-dark" style="font-weight: 500;" for="exampleCheck1">Yes I would book
                    again should be part of Submit a review.</label>
            </div>
        </form>
        <div class="review-popup-grp-btn">
            <button mat-button type="button" (click)="closeDialog(null)" class="outline-orng-btn custum-btn" style="background-color: #0052cc; color: white;"> Not Now </button>
            <button mat-button type="button" (click)="onSumbit()" class="custum-btn" [class.disabled]="isLoading" style="background-color: green; color: white;"> Submit review
            </button>
        </div>
    </div>
</div>