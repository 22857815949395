import {
  Component,
  ViewContainerRef
} from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";
import { LoaderService } from "../services/loader.service";
// import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
// import {LocationListBottomSheet} from './location-list-bottom-sheet.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { SendMessageDialog } from "../leads/send-message-dialog.component";
import { AppGlobals } from "../services/app-globals";
import { ManagerService } from "../services/manager.service";
import { QueriesService } from "../services/queries.service";
import { SpaceService } from "../services/space.service";
import { LogoutDialog } from "./logout-dialog.component";
@Component({
  selector: "app-root",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent {
  loadAPI: Promise<any>;
  public objLoaderStatus: boolean = false;
  public isMobile = false;
  public space_locations;
  public space_details;
  public location_ids;
  public list_locations;
  public selectedLocationId;
  public space_ids;
  public spaces_data;
  public dialogRef: MatDialogRef<any>;
  public is_admin;
  public loading: boolean;
  permission: any;
  isPermissionloading: boolean = false;
  totalPendingReviews: any=0;
  totalPendingRequest: any=0;
  subPendingQuery: any=0;
  creditPendingQuery: any=0;
  totalNewListing: any = 0;
  totalEditListing: any = 0;
  totalDeleteListing: any = 0;
  constructor(
    private router: Router,
    // private bottomSheet: MatBottomSheet,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _appGlobals: AppGlobals,
    private viewContainerRef: ViewContainerRef,
    private managerService: ManagerService,
    public queriesService: QueriesService,
    private _spaceService: SpaceService
  ) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
    if (window.innerWidth < 600) {
      this.isMobile = true;
    }
  }


  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => {
      this.objLoaderStatus = val;
    });
    this.authenticationService.validateSession().then((res) => {
      if (!res.verified) {
        this.router.navigate(["login"]);
      }
      else {
        this._getSpaceDetails();
      }
    });
    this.isPermissionloading = !!localStorage.getItem("permission")
    this.getAllData()
  }

  getAllData() {
    const data1 = { selectedStars: [], status: "0" };
    const data2 = { status: "pending" };
   
    const getAllReviews$ = this._spaceService.getAllReviews(data1);
    const getSubPendingQuery$ = this.queriesService.getSubscriptionInquiry('pending');
    const getCreditPendingQuery$ = this.queriesService.getCreditInquiry('pending');
    const spaceCreateRequest$ = this._spaceService.spaceCreateRequest(data2);
    const spaceUpdateRequest$ = this._spaceService.spaceUpdateRequest(data2);
    const spaceDeleteRequest$ = this._spaceService.spaceDeleteRequest(data2);

    forkJoin({
      reviews: getAllReviews$,
      createRequest: spaceCreateRequest$,
      updateRequest: spaceUpdateRequest$,
      deleteRequest: spaceDeleteRequest$,
      subQuery: getSubPendingQuery$,
      cerditQuery: getCreditPendingQuery$,
    }).subscribe(
      (results:any) => {
        if (results.reviews?.success){
          this.totalPendingReviews = results.reviews.data.length
        }
        if (results.createRequest?.data?.success || results.updateRequest?.data?.success || results.deleteRequest?.data?.success){
          this.totalNewListing = results.createRequest.data.data.length
          this.totalEditListing = results.updateRequest.data.data.length
          this.totalDeleteListing = results.deleteRequest.data.data.length
          this.totalPendingRequest = this.totalNewListing + this.totalEditListing + this.totalDeleteListing
        } 
        if (results.subQuery.success || results.cerditQuery.success){
          this.subPendingQuery = results.subQuery.data.length
          this.creditPendingQuery = results.cerditQuery.data.length
        }
      },
      (error) => {
        console.error("Error in one of the requests:", error);
      }
    );
  }


  canAccess(module: string, Permissiontype?: string): boolean {
    let type = Permissiontype ? Permissiontype : 'view'
    return this.queriesService.hasPermission(module, type);
  }

  isMinimized: boolean = false;

  toggleSidebar(): void {
    this.isMinimized = !this.isMinimized;
  }

  minimizeSidebar(): void {
    this.isMinimized = true
  }





  private _getSpaceDetails() {
    this.managerService.getSpaceDetails().then((res) => {
      this.space_ids = res.data?.space_ids;
      this.spaces_data = res.data?.spaces_data;
      this.is_admin = res.data?.is_admin;
      // this._appGlobals.setSpaceIDS(this.space_ids);
      this._appGlobals.setIsAdmin(this.is_admin);
      this._appGlobals.setSpacesData(this.spaces_data);
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("loader")
      ) {
        isFound = true;
      }
    }
    if (!isFound) {
      var dynamicScripts = ["assets/js/dashboard.js"];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }
  }

  openLogoutDialog() {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;
    this.dialogRef = this.dialog.open(LogoutDialog, config);
    this.dialogRef.afterClosed().subscribe((result) => {
      result.success ? this.router.navigate(["login"]) : "";
      this.dialogRef = null;
      const arr = ['token', 'permission']
      for (let keys of arr) {
        localStorage.removeItem(keys)
      }
    });

  }

  public sendProposalDialog() {
    if (this.queriesService.hasPermission('Send Proposal', 'add')) {
      let config = new MatDialogConfig();
      config.disableClose = true;
      config.minWidth = '95vw';
      config.minHeight = '95vh';
      const dialog = this.dialog.open(SendMessageDialog, config);
    } else {
      this.queriesService.accessDeniedAlert()
    }
  }

  title = "flexo-admin";
}
