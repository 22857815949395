import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {SelectionModel} from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { QueriesService } from '../services/queries.service';
import {GlobalVariables} from '../global/global-variables';
import { PageEvent } from '@angular/material/paginator';

import { FilterPipe } from '../filter.pipe';
import * as FileSaver from 'file-saver';

export interface queries {
  s_no: number;
  alternate_id: string;
  entity_alternate_id: string;
  total_amount: number;
  entity_name: string;
  entity_type: number;
  status: number;
  paid_status: number;
  send_status: number;
  tracked_with_bhaifi: number;
}

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.css']
})
export class QueriesComponent implements OnInit {

  public list_queries;
  public search_text;
  public list_space;
  public selected_spaces: any[];
  pageSize = 25; // Default page size
  currentPage = 0;
  paginatedBookings = [];
  constructor(
    private loaderService: LoaderService,
    private _querieservice: QueriesService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public confirm_dialog: MatDialog,
    public confirm_viewContainerRef: ViewContainerRef,
    private _appGlobals: AppGlobals,
    public snackBar: MatSnackBar
  ) {
    this._appGlobals.spacesData.subscribe(spaces => this.list_space = spaces);
  }

  dialogRef: MatDialogRef<any>
  confirm_dialogRef: MatDialogRef<any>

  public _queries_data: queries[];

  ngOnInit() {
    this.selected_spaces = [];
    this.populatequeries();
    
  }

  public changeSpace() {
    this.populatequeries();
  }

  public populatequeries () {
    let selected_spaces = this.selected_spaces;
    if (selected_spaces.includes('-1')) {
      selected_spaces = [];
    }

    this._querieservice.getAll(selected_spaces)
    .then(res => {
      if (res.success) {
        this._queries_data = Object.assign([], res.data);
        this.list_queries = this._queries_data;
        this.updatePaginatedBookings();
      } 
      else {
        this._openSnackBar(res.message || "Some error occured", 'Dismiss');
      }
    })
    .catch(error=> {
      console.log(error);
    })
  }

  public clearSearchInput() {
    this.search_text = '';
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updatePaginatedBookings() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedBookings = this.list_queries.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedBookings();
  }

}