import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-spacecreatepopup',
  templateUrl: './spacecreatepopup.component.html',
  styleUrls: ['./spacecreatepopup.component.css']
})
export class SpacecreatepopupComponent implements OnInit {
  data1: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<SpacecreatepopupComponent>) {
  }
  ngOnInit(): void {
    this.data1 = this.data.message;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
