<div class="row main_row" >
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3 class="card-title">Add Flexo Credits</h3>
        <a (click)="navigateToList()" class="btn v3"><i class="fa fa-list"></i>Package List
        </a>
      </div>
      <div class="card-body">
        <form [formGroup]="addCreditPointForm">
          <div class="row g-3 panel-body tabs-menu-body">
            <div class="col-12">
              <div class="form-group">
                <label class="required form-label">Host <span class="text-danger">*</span></label>
                <div class="filter-checkbox">
                  <ng-select class="form-select drop_down form-control" formControlName="hostId"
                    [placeholder]="addCreditPointForm.get('hostId').invalid  ? 'Select Host' : ''">
                    <ng-option *ngFor="let host of host" [value]="host.id">
                      {{host.companyName}} - {{host.mobile}}
                    </ng-option>
                  </ng-select>
                  <mat-error
                    *ngIf="addCreditPointForm.get('hostId').invalid && addCreditPointForm.get('hostId').touched">
                    Host is required
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="col-12">
              <label class="required form-label">Flexo Credits <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="balance" placeholder="Enter flexo credits" type="number"
                  class="form-control " (keydown)="disablePoint($event)" onwheel="this.blur()">
                <mat-error
                  *ngIf="addCreditPointForm.get('balance').invalid && addCreditPointForm.get('balance').touched">
                  Flexo credit is required
                </mat-error>
              </div>
            </div>
            <div class="col-12 mt-3">
              <label class="required form-label">Amount <span class="text-danger">*</span></label>
              <div class="filter-checkbox">
                <input formControlName="amount" placeholder="Enter amount" type="number"
                  class="form-control " onwheel="this.blur()">
                <mat-error *ngIf="addCreditPointForm.get('amount').invalid && addCreditPointForm.get('amount').touched">
                  Amount is required
                </mat-error>
              </div>
            </div>
            <div class="btn-list mt-4">
              <a class="btn btn-primary-light" style="float: right; color: white;" (click)="submit()">
                Submit
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>