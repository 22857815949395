import { Component } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-img-delete',
  templateUrl: './confirm-img-delete.component.html',
  styleUrls: ['./confirm-img-delete.component.css'],
})
export class ConfirmImgDeleteComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmImgDeleteComponent>,
    public snackBar: MatSnackBar
  ){}
    
  ngOnInit(): void {  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
