<div class="review-popup p-3">
  <div>
    <label class="top-label">Create Lead</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="scrollable-content">
    <form [formGroup]="spaceForm" (ngSubmit)="submitForm()" class="space-form w-100">

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Space type</mat-label>
            <mat-select placeholder="Select your space type" multiple name="inquirySpaceCapacity"
              formControlName="spaceType" required>
              <mat-option *ngFor="let option of spaceTypeOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('spaceType')?.errors?.required && (spaceForm?.get('spaceType').dirty || spaceForm?.get('spaceType').touched)">
              Space type is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Location</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let location of selectedLocations" [removable]="true"
                (removed)="removeLocation(location)">
                {{ location }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input [(ngModel)]="lead_data.location" id="inputBox" matInput formControlName="location" #autocomplete
                type="text" placeholder="Enter a place" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addLocation($event)" required />
            </mat-chip-list>
            <mat-error
              *ngIf="spaceForm?.get('location')?.errors?.required && (spaceForm?.get('location').dirty || spaceForm?.get('location').touched)"
              class="text-danger">
              Location Name is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City</mat-label>
            <mat-select placeholder="Select City" multiple name="inquirySpaceCapacity" formControlName="city_name"
              required>
              <mat-option *ngFor="let city of cityOptions" [value]="city">{{city}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('city_name')?.errors?.required && (spaceForm?.get('city_name').dirty || spaceForm?.get('city_name').touched)">
              City is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>No. of seats</mat-label>
            <mat-select placeholder="Select number of seats" [(ngModel)]="no_of_seats" name="inquirySpaceCapacity"
              formControlName="number_of_seats" required>
              <mat-option *ngFor="let seat of no_of_persons" [value]="seat">{{seat}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm.get('number_of_seats')?.errors?.required && (spaceForm.get('number_of_seats').dirty || spaceForm.get('number_of_seats').touched)">
              Number of seats is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Approx Start</mat-label>
            <mat-select placeholder="Select Approx Start Time" [(ngModel)]="lead_data.approx_start"
              name="inquirySpaceCapacity" formControlName="approx_start" required>
              <mat-option value="immediate">Immediate</mat-option>
              <mat-option value="1 week">Within 1 week</mat-option>
              <mat-option value="2 weeks">Within 2 weeks</mat-option>
              <mat-option value="1 month">Within 1 month</mat-option>
              <mat-option value="2 months">Within 2 months</mat-option>
              <mat-option value="3 months">Within 3 months</mat-option>
              <mat-option value="after 3 months">After 3 months</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('approx_start')?.errors?.required && (spaceForm?.get('approx_start').dirty || spaceForm?.get('approx_start').touched)">
              Start time is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Budget min/max</mat-label>
            <mat-select placeholder="Select Approx Start Time" [(ngModel)]="lead_data.budget"
              name="inquirySpaceCapacity" formControlName="budget" required>
              <mat-option value="100">100</mat-option>
              <mat-option value="200">200</mat-option>
              <mat-option value="300">300</mat-option>
              <mat-option value="400">400</mat-option>
              <mat-option value="500">500</mat-option>
              <mat-option value="600">600</mat-option>
              <mat-option value="700">700</mat-option>
              <mat-option value="50000+">50000 +</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('budget')?.errors?.required && (spaceForm?.get('budget').dirty || spaceForm?.get('budget').touched)">
              Budget is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tenure</mat-label>
            <mat-select placeholder="Select Approx Start Time" [(ngModel)]="lead_data.tenure"
              name="inquirySpaceCapacity" formControlName="tenure" required>
              <mat-option value="month on month">Month on Month</mat-option>
              <mat-option value="1 month">1 Month</mat-option>
              <mat-option value="2 months">2 Months</mat-option>
              <mat-option value="1 month">Within 1 Month</mat-option>
              <mat-option value="2 months">Within 2 Months</mat-option>
              <mat-option value="3 months">Within 3 Months</mat-option>
              <mat-option value="after 3 months">After 3 Months</mat-option>
              <mat-option value="60 months">All the way to 60 Months</mat-option>
              <mat-option value="60 months +">60 Months +</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('tenure')?.errors?.required && (spaceForm?.get('tenure').dirty || spaceForm?.get('tenure').touched)">
              Tenure is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Lead Price</mat-label>
            <input onkeypress="return /[0-9]/i.test(event.key)" [(ngModel)]="lead_data.lead_price" maxlength="10"
              type="tel" matInput type="text" formControlName="lead_price" placeholder="Enter lead price" required
              class="form-control">
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm?.get('lead_price')?.errors?.required && (spaceForm?.get('lead_price').dirty || spaceForm?.get('lead_price').touched)"
            class="text-danger">
            Price is required.
          </mat-error>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput onkeypress="return /[a-zA-Z ]/.test(event.key)" [(ngModel)]="lead_data.firstName"
              maxlength="30" type="text" formControlName="firstName" placeholder="Enter lead name" class="form-control"
              required />
            <mat-error
              *ngIf="spaceForm?.get('firstName')?.errors?.required && (spaceForm?.get('firstName').dirty || spaceForm?.get('firstName').touched)"
              class="text-danger">
              First Name is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput onkeypress="return /[a-zA-Z ]/.test(event.key)" [(ngModel)]="lead_data.lastName"
              maxlength="30" type="text" formControlName="lastName" placeholder="Enter last name" class="form-control"
              required />
            <mat-error
              *ngIf="spaceForm.get('lastName')?.errors?.required && (spaceForm.get('lastName').dirty || spaceForm.get('lastName').touched)"
              class="text-danger">
              Last Name is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Lead Email</mat-label>
            <input matInput type="text" formControlName="userEmail" [(ngModel)]="lead_data.userEmail"
              placeholder="Enter lead email" class="form-control" required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" />
            <mat-error
              *ngIf="spaceForm?.get('userEmail')?.errors?.required && (spaceForm?.get('userEmail').dirty || spaceForm?.get('userEmail').touched)"
              class="text-danger">
              Email is required.
            </mat-error>
            <mat-error *ngIf="spaceForm?.get('userEmail')?.errors?.pattern">
              Enter correct email format
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Lead Phone</mat-label>
            <input matInput type="text" formControlName="userMobile" [(ngModel)]="lead_data.userMobile"
              placeholder="Enter lead phone" class="form-control" required onkeypress="return /[0-9]/i.test(event.key)"
              minlength="10" maxlength="10" type="tel" />
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm?.get('userMobile')?.errors?.required && (spaceForm?.get('userMobile').dirty || spaceForm?.get('userMobile').touched)"
            class="text-danger">
            Lead Phone is required.
          </mat-error>
          <mat-error *ngIf="spaceForm?.get('userMobile')?.errors?.minlength" class="text-danger">
            Minimum 10 digits are required.
          </mat-error>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Lead Company</mat-label>
            <input matInput formControlName='company_name' [(ngModel)]="lead_data.company_name" maxlength="30"
              placeholder="Enter lead company" class="form-control" required
              onkeypress="return /[a-zA-Z ]/.test(event.key)" type="text" />
          </mat-form-field>
          <mat-error
            *ngIf="spaceForm?.get('company_name')?.errors?.required && (spaceForm?.get('company_name').dirty || spaceForm?.get('company_name').touched)"
            class="text-danger">
            Lead Company is required.
          </mat-error>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Source</mat-label>
            <mat-select placeholder="Select Lead Source" [(ngModel)]="lead_data.lead_source" name="inquirySpaceCapacity"
              formControlName="lead_source" required>
              <mat-option value="Website inquiry">Website Inquiry</mat-option>
              <mat-option value="Website chart">Website Chat</mat-option>
              <mat-option value="Whatsapp">Whatsapp</mat-option>
              <mat-option value="Inbound call">Inbound Call</mat-option>
              <mat-option value="Social media">Social Media</mat-option>
              <mat-option value="Social Media - Paid Ads">Social Media - Paid Ads</mat-option><mat-option
                value="Google ads">Google Ads</mat-option>
              <mat-option value="Referral lead">Referral Leads</mat-option>
            </mat-select>
            <mat-error
              *ngIf="spaceForm?.get('lead_source')?.errors?.required && (spaceForm?.get('lead_source').dirty || spaceForm?.get('lead_source').touched)">
              Lead Source is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-md-12">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tag</mat-label>
            <mat-select placeholder="Select Tag" name="inquirySpaceCapacity" multiple formControlName="tags"
              [errorStateMatcher]="errorStateMatcher" (selectionChange)="onSelectionChange()">
              <mat-option [value]="item" *ngFor="let item of tagsOptions"
                [disabled]="isOptionDisabled(item)">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput onkeypress="return /[a-zA-Z ]/.test(event.key)"
              [(ngModel)]="lead_data.inquiryDescription" type="text" formControlName="inquiryDescription"
              placeholder="Enter your description" class="form-control" required></textarea>
            <mat-error
              *ngIf="spaceForm?.get('inquiryDescription')?.errors?.required && (spaceForm?.get('inquiryDescription').dirty || spaceForm?.get('inquiryDescription').touched)"
              class="text-danger">
              Description is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <button mat-raised-button color="accent" type="submit" [disabled]="!spaceForm.valid">Submit</button>
      </div>
    </form>
  </div>
</div>