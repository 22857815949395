import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-add-credit-plans',
  templateUrl: './add-credit-plans.component.html',
  styleUrls: ['./add-credit-plans.component.css']
})
export class AddCreditPlansComponent implements OnInit {
  packageId: number;
  host: any[] = [];
  addPackageForm: FormGroup | any;
  formData: any;

  editors: Editor[] = [];
  toolbar: Toolbar = [
    ['bold'],
    ['strike']
  ];

  constructor(private fb: FormBuilder, private queryService: QueriesService, private route: ActivatedRoute, private router: Router) {
    this.packageId = this.route.snapshot.queryParams['packageId']
  }

  ngOnInit(): void {
    this.addPackageForm = this.fb.group({
      title: ['', Validators.required],
      amount: ['', Validators.required],
      creditPoint: ['', Validators.required],
      sortOrder: ['', Validators.required],
      discription: this.fb.array([])
    });
    if (this.packageId) {
      this.getCreditPackageByID()
    } else {
      this.addField();
    }
    this.initializeEditors();
  }

  get discription(): FormArray {
    return this.addPackageForm.get('discription') as FormArray;
  }


  navigateToList() {
    if (this.queryService.hasSubmodulePermission('Flexo Credits', 'Package', 'view')) {
      this.router.navigate(['/home/package-list'])
    } else {
      this.queryService.accessDeniedAlert()
    }
  }



  addField(): void {
    this.discription.push(this.fb.group({
      label: ['', Validators.required]
    }));
    this.initializeEditors();
  }

  removeField(index: number): void {
    this.discription.removeAt(index);
    this.editors.splice(index, 1)
  }

  submit(): void {
    this.addPackageForm.markAllAsTouched()
    if (this.addPackageForm.valid) {
      this.formData = {
        ...this.addPackageForm.value,
        discription: this.addPackageForm.value?.discription.reduce((acc: any, val: any) => {
          acc.push(val.label);
          return acc;
        }, [])
      }
      if (this.packageId) {
        this.updatePackage()
      } else {
        this.addPackage()
      }
    }
  }

  addPackage(): void {
    if (this.queryService.hasSubmodulePermission('Flexo Credits', 'Package', 'add')) {
      this.queryService.addCreditPackage(this.formData).then((res: any) => {
        if (res.success) {
          this.addPackageForm.reset()
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  updatePackage(): void {
    if (this.queryService.hasSubmodulePermission('Flexo Credits', 'Package', 'edit')) {
      this.queryService.updateCreditPackage(this.packageId, this.formData).then((res: any) => {
        if (res.success) {
          this.router.navigate(["/home/package-list"])
        }
        this.queryService.openSnackBar(res.message)
      })
    } else {
      this.queryService.accessDeniedAlert()
    }
  }

  getCreditPackageByID(): void {
    this.queryService.getCreditPackageByID(this.packageId).then((res: any) => {
      const data = {
        ...res.data,
        discription: JSON.parse(res.data.discription).map(val => {
          return { label: val }
        })
      }
      const length = data.discription.length
      if (data) {
        for (let i = 0; i < length; i++) {
          this.addField();
        }
        this.addPackageForm.patchValue(data)
      }
    })
  }


  getEditor(index: number): Editor {
    return this.editors[index];
  }

  initializeEditors() {
    this.editors = this.discription.controls.map(() => new Editor());
  }

  ngOnDestroy() {
    this.editors.forEach(editor => editor.destroy());
  }

}