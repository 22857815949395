import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-space-detail',
  templateUrl: './space-detail.component.html',
  styleUrls: ['./space-detail.component.css']
})
export class SpaceDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
