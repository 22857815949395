import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class MemberService {

  public selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);
  }

  private base_url = 'api/v1/community/member';
  private updateDetailsUrl = environment.apiUrl + this.base_url + '/update';
  private deleteDetailsUrl = environment.apiUrl + this.base_url + '/delete';
  private addDetailsUrl = environment.apiUrl + this.base_url + '/add';
  private addLeadUrl = environment.apiUrl + 'api/v1/admin/createLead';
  private updateLeadUrl = environment.apiUrl + 'api/v1/admin/updateLead/';
  private getAllUrl = environment.apiUrl + this.base_url + '/all';
  private getAllIndividualsUrl = environment.apiUrl + this.base_url + '/allIndividuals';
  private getListByCompanyUrl = environment.apiUrl + this.base_url + '/allByCompany';
  private getProfileDetailsUrl = environment.apiUrl + this.base_url + '/profileDetails';
  private getPersonalDetailsUrl = environment.apiUrl + this.base_url + '/personalDetails';
  private getMembershipDetailsUrl = environment.apiUrl + this.base_url + '/membershipDetails';
  private getPerformaInvoiceDetailsUrl = environment.apiUrl + this.base_url + '/performaInvoiceDetails';
  private getInvoiceDetailsUrl = environment.apiUrl + this.base_url + '/invoiceDetails';
  private searchDetailsUrl = environment.apiUrl + this.base_url + '/search';
  private getContractDetailsUrl = environment.apiUrl + this.base_url + '/contractDetails';
  private getLocationUrl = environment.apiUrl + '/api/v1/user/getAllLocations';
  private getCItyUrl = environment.apiUrl + 'api/v1/getAllCity';
  private probability_factor_Url = environment.apiUrl + '/api/v1/admin/getLeadPrice'

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")},`,
  });

  private _options = {
    headers: this.headers,
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAll(): Promise<any> {
    let location_condition = this.selectedLocationId != 0 ? `?location_id=${this.selectedLocationId}` : '';

    return this.http.get(this.getAllUrl + location_condition,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllIndividualList(): Promise<any> {
    let location_condition = this.selectedLocationId != 0 ? `?location_id=${this.selectedLocationId}` : '';

    return this.http.get(this.getAllIndividualsUrl + location_condition,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addLead(details): Promise<any> {
    return this.http.post(this.addLeadUrl, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateLead(id:any, details:any): Promise<any> {
    return this.http.put(this.updateLeadUrl + id, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getProfileDetails(member_id: string): Promise<any> {
    return this.http.get(this.getProfileDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getPersonalDetails(member_id: string): Promise<any> {
    return this.http.get(this.getPersonalDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  getMembershipDetails(member_id: number): Promise<any> {
    return this.http.get(this.getMembershipDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  getInvoiceDetails(member_id: number): Promise<any> {
    return this.http.get(this.getInvoiceDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  getPerformaInvoiceDetails(member_id: number): Promise<any> {
    return this.http.get(this.getPerformaInvoiceDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  addDetails(details: any): Promise<any> {
    return this.http.post(this.addDetailsUrl, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateDetails(details: any, member_id: string): Promise<any> {
    return this.http.put(this.updateDetailsUrl + '/' + member_id, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  searchByMobile(mobile): Promise<any> {
    return this.http.get(this.searchDetailsUrl + '?mobile=' + mobile,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  getListByCompany(company_id): Promise<any> {
    return this.http.get(this.getListByCompanyUrl + '/' + company_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getContractDetails(member_id: number): Promise<any> {
    return this.http.get(this.getContractDetailsUrl + '/' + member_id,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  };

  deleteDetails(member_id: string): Promise<any> {
    return this.http.put(this.deleteDetailsUrl + '/' + member_id, {}, this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllLocations(){
    return this.http.get(this.getLocationUrl, this._options);
  }

  getAllCity(){
    return this.http.get(this.getCItyUrl, this._options);
  }

  getProbabilityFactor(){
    return this.http.get(this.probability_factor_Url, this._options);
  }
}
