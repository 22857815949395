import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManagerService } from '../services/manager.service';
import { LoaderService } from '../services/loader.service';
import { environment } from '../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'show-image',
    templateUrl: './show-image-dialog.component.html',
    styleUrls: ['./show-image-dialog.component.css']
})

export class ShowImageDialog {
    public ref;
    public id;
    public image;
    public aws_base_url = "https://s3.ap-south-1.amazonaws.com/" + environment.aws_bucket_path + '/details_images/';

    constructor(
        public dialogRef: MatDialogRef<any>,
        private managerService: ManagerService,
        public snackBar: MatSnackBar,
        private loaderService: LoaderService
    ) { }

    openSnackBar(message: string, action: string) {
        this.loaderService.displayLoader(false);
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }

    closeDialog(options) {
        this.ref.close(options);
    }

}