<div>
   <div>
     <label class="top-label">Update Worker details</label>
     <i class=" cross material-icons" (click)="closeDialog(null)">
      close
     </i>
   </div>
   <form #workerForm="ngForm" (ngSubmit)="update()" class="worker-form">
   
   
    <mat-form-field class="worker-form-inputs" appearance="outline">
     <mat-label>Name</mat-label>
     <input type="string" matInput placeholder="Name" name="name" [(ngModel)]="worker.name">
   </mat-form-field>
   <mat-form-field class="worker-form-inputs" appearance="outline">
      <mat-label>Email</mat-label>
      <input type="string" matInput placeholder="Email" name="email" [(ngModel)]="worker.email">
    </mat-form-field>
   <mat-form-field class="worker-small-form-inputs" appearance="outline">
     <mat-label>Mobile</mat-label>
     <input type="number" matInput placeholder="Mobile" name="mobile" [(ngModel)]="worker.mobile" >
   </mat-form-field>
   <mat-form-field class="worker-small-form-inputs" appearance="outline">
      <mat-label>Free Trial</mat-label>
      <input type="number" matInput placeholder="Free Trial" name="free_trial" [(ngModel)]="worker.free_trial" >
    </mat-form-field>
    <mat-form-field class="worker-small-form-inputs" appearance="outline">
      <mat-label>Credits</mat-label>
      <input type="number" matInput placeholder="Credits" name="credits" [(ngModel)]="worker.credits" >
    </mat-form-field>
    <mat-form-field class="worker-small-form-inputs" appearance="outline">
      <mat-label>Visits</mat-label>
      <input type="number" matInput placeholder="Visits" name="visits" [(ngModel)]="worker.visits" >
    </mat-form-field>
  <div>
      <button mat-raised-button color="accent" type="submit"  [disabled]="!workerForm.form.valid" >Submit</button>
    </div>
   </form>
 </div>
 